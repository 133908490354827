import { Injectable } from '@angular/core';
import { ServiceDeliveryPoint, ConnectorType, AdditionalCabling  } from './dropdown-interface';

@Injectable({
  providedIn: 'root'
})
export class SetDropdownsService {

  // siteTypes: string[] = ['Data Centre 3rd Party','Data Centre Infraco','NBN POI - Telstra Exchange','NBN POI - Depot','Non-Premises','Premises','Telstra Exchange','Cable Landing Station'];
  sdpTypes: string[] = ['Antenna', 'Carrier Room', 'Colo', 'Common Area', 'Customer Cabinet', 'Customer Pit', 'Exchange Rack', 'Floor', 'Infraco Pit', 'Meet Me Room', 'NBC ODF', 'NBN Access Seeker ODF', 'NBN FAN', 'Roof Top', 'TEBA ODF', 'Yard'];
  // dcOperators: string[] = ['5G Networks', 'AIRTRUNK', 'CDC', 'Citec', 'DCI', 'Digital Realty', 'Equinix', 'Fujitsu', 'Global Switch', 'Hansen Tech', 'Interactive', 'iseek Communications', 'Leading Edge', 'Macquarie DC', 'NextDC', 'NextGen', 'Over the Wire Ptd Ltd', 'Springfield Land Corporation', 'VOCUS', 'YourDC', 'Infraco']
  // dcSiteNames: string[] = ['5G Networks', 'SYD1', 'SYD2', 'MEL1', 'Eastern Creek (EC1/EC2/EC3)', 'Hume (H1,H2,H3)','Hume (H4,H5)','Fyshwick (F1)','Fyshwick (F2)','Citec','DCI','SYD10/SYD11','MEL10','MEL11','SY1/SY2','SY3','SY4/SY5','SY6','SY7','CA1','ME1/ME2','ME4','ME5','BR1','PE1','PE2/PE3','AE1','Fujitsu North Ryde','Fujitsu Greystanes','Fujitsu Homebush','Fujitsu Noble Park','Fujitsu EMP','Fujitsu Malaga','Global Switch','Hansen Doncaster','Hansen South melbourne','Interactive Artarmon','Interactive Port Melbourne','Interactive EMP','iseek Woolongabba','iseek Eagle Farm','iseek Brisbane City','TMW1','NTL1','ABX1','IC1','IC2/IC3','S1','S2','S3','C1','M1','M2','M3','B1','B2','P1','P2','NextGen P2','Over the Wire','Polaris','VDC-SYD01','VDC-SYD04','VDC-SYD02','VDC-SYD03','VDC-MEL03','VDC-PER07','VDC-PER01','VDC-PER09','YourDC Hawthorn','YourDC Edinburgh','St Leonards','Clayton'];
  connectorTypes: string[] = ['LCAPC', 'LCPC', 'Optitap SCAPC (Non-Premises)', 'Optitip MT Pinned (Non-Premises)', 'Pigtail LCAPC', 'Pigtail LCPC', 'Pigtail SCAPC', 'Pigtail SCPC', 'SCAPC', 'SCPC', 'Splice', 'Structured Cable'];
  additionalCablingList: string[] = ['Customer to provide', 'OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 20 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC', 'Patch LCAPC', 'Patch LCPC', 'Patch SCAPC', 'Patch SCPC', 'Structured Cable (OEF)'];
  
  
  constructor() { }

  // NEW ==============================================
  getServiceDeliveryPointValues(serviceType: string, subProduct: string, siteType: string): ServiceDeliveryPoint {

    let dropdownInterface: ServiceDeliveryPoint = { list: [], defaultValue: '' };

    const lowerServiceTyoe = serviceType ? serviceType.toLowerCase() : '';
    const lowerSubProduct = subProduct ? subProduct.toLowerCase() : '';
    const lowerSiteType = siteType ? siteType.toLowerCase() : '';
    const combinedValue = `${lowerServiceTyoe}-${lowerSubProduct}-${lowerSiteType}`;


    switch (combinedValue) {
      // RG Network
      case 'rg-network-data centre 3rd party'.toLowerCase():
        dropdownInterface.list = ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-network-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list = ['Exchange Rack', 'Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-network-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['NBN Access Seeker ODF'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-network-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list = ['NBN Access Seeker ODF'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-network-Non-Premises'.toLowerCase():
        dropdownInterface.list = ['Infraco Pit'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-network-Premises'.toLowerCase():
        dropdownInterface.list = ['Antenna', 'Common Area', 'Customer Cabinet', 'Floor', 'Roof Top', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-network-Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['Antenna', 'Customer Cabinet', 'Exchange Rack', 'NBN Access Seeker ODF', 'NBC ODF', 'Roof Top', 'TEBA ODF', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-network-Cable Landing Station'.toLowerCase():
        dropdownInterface.list = ['Common Area', 'Customer Cabinet', 'Exchange Rack', 'Floor', 'Roof Top', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      // RG Access

      case 'rg-Access-Data Centre 3rd Party'.toLowerCase():
        dropdownInterface.list = ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Access-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list = ['Exchange Rack', 'Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Access-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['NBN Access Seeker ODF'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Access-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list = ['NBN Access Seeker ODF'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Access-Non-Premises'.toLowerCase():
        dropdownInterface.list = ['Infraco Pit'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Access-Premises'.toLowerCase():
        dropdownInterface.list = ['Antenna', 'Common Area', 'Customer Cabinet', 'Floor', 'Roof Top', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Access-Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['Antenna', 'Customer Cabinet', 'Exchange Rack', 'NBN Access Seeker ODF', 'NBC ODF', 'Roof Top', 'TEBA ODF', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Access-Cable Landing Station'.toLowerCase():
        dropdownInterface.list = ['Common Area', 'Customer Cabinet', 'Exchange Rack', 'Floor', 'Roof Top', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      //RG Mobiles SMC

      case 'rg-Mobiles SMC-Data Centre 3rd Party'.toLowerCase():
        dropdownInterface.list = ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles SMC-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list = ['Exchange Rack', 'Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles SMC-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['NBN Access Seeker ODF'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles SMC-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list = ['NBN Access Seeker ODF'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles SMC-Non-Premises'.toLowerCase():
        dropdownInterface.list = ['Infraco Pit'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles SMC-Premises'.toLowerCase():
        dropdownInterface.list = ['Antenna', 'Common Area', 'Customer Cabinet', 'Floor', 'Roof Top', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles SMC-Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['Antenna', 'Customer Cabinet', 'Exchange Rack', 'NBN Access Seeker ODF', 'NBC ODF', 'Roof Top', 'TEBA ODF', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles SMC-Cable Landing Station'.toLowerCase():
        dropdownInterface.list = ['Common Area', 'Customer Cabinet', 'Exchange Rack', 'Floor', 'Roof Top', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      //RG Mobiles Macro

      case 'rg-Mobiles Macro-Data Centre 3rd Party'.toLowerCase():
        dropdownInterface.list = ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles Macro-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list = ['Exchange Rack', 'Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles Macro-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['NBN Access Seeker ODF'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles Macro-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list = ['NBN Access Seeker ODF'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles Macro-Non-Premises'.toLowerCase():
        dropdownInterface.list = ['Infraco Pit'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles Macro-Premises'.toLowerCase():
        dropdownInterface.list = ['Antenna', 'Common Area', 'Customer Cabinet', 'Floor', 'Roof Top', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles Macro-Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['Antenna', 'Customer Cabinet', 'Exchange Rack', 'NBN Access Seeker ODF', 'NBC ODF', 'Roof Top', 'TEBA ODF', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles Macro-Cable Landing Station'.toLowerCase():
        dropdownInterface.list = ['Common Area', 'Customer Cabinet', 'Exchange Rack', 'Floor', 'Roof Top', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      // BL OLD

      case 'BL-NBN EE-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['NBN FAN'];
        dropdownInterface.defaultValue = 'NBN FAN';
        return dropdownInterface;

      case 'BL-NBN EE-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list = ['NBN FAN'];
        dropdownInterface.defaultValue = 'NBN FAN';
        return dropdownInterface;

      case 'BL-NBN EE-Premises'.toLowerCase():
        dropdownInterface.list = ['Common Area', 'Floor'];
        dropdownInterface.defaultValue = 'Common Area';
        return dropdownInterface;

      case 'BL-NBN EE-Non-Premises'.toLowerCase():
        dropdownInterface.list = ['Infraco Pit'];
        dropdownInterface.defaultValue = 'Infraco Pit';
        return dropdownInterface;

      case 'BL-NBN Fixed Wireless-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['NBN FAN'];
        dropdownInterface.defaultValue = 'NBN FAN';
        return dropdownInterface;

      case 'BL-NBN Fixed Wireless-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list = ['NBN FAN'];
        dropdownInterface.defaultValue = 'NBN FAN';
        return dropdownInterface;

      case 'BL-NBN Fixed Wireless-Premises'.toLowerCase():
        dropdownInterface.list = ['Common Area', 'Floor', 'Roof Top'];
        dropdownInterface.defaultValue = 'Common Area';
        return dropdownInterface;

      case 'BL-NBN Fixed Wireless-Non-Premises'.toLowerCase():
        dropdownInterface.list = ['Infraco Pit'];
        dropdownInterface.defaultValue = 'Infraco Pit';
        return dropdownInterface;

      // // BL NEW

      // case 'BL-NBN EE-Telstra Exchange'.toLowerCase():
      //   dropdownInterface.list = ['NBN FAN'];
      //   dropdownInterface.defaultValue = 'NBN FAN';
      //   return dropdownInterface;

      // case 'BL-NBN EE-Premises'.toLowerCase():
      //   dropdownInterface.list = ['Common Area', 'Floor'];
      //   dropdownInterface.defaultValue = 'Common Area';
      //   return dropdownInterface;

      // case 'BL-NBN EE-Non-Premises'.toLowerCase():
      //   dropdownInterface.list = ['Infraco Pit'];
      //   dropdownInterface.defaultValue = 'Infraco Pit';
      //   return dropdownInterface;

      // case 'BL-NBN Fixed Wireless-Telstra Exchange'.toLowerCase():
      //   dropdownInterface.list = ['NBN FAN'];
      //   dropdownInterface.defaultValue = 'NBN FAN';
      //   return dropdownInterface;

      // case 'BL-NBN Fixed Wireless-Premises'.toLowerCase():
      //   dropdownInterface.list = ['Common Area', 'Floor', 'Roof Top'];
      //   dropdownInterface.defaultValue = 'Common Area';
      //   return dropdownInterface;

      // case 'BL-NBN Fixed Wireless-Non-Premises'.toLowerCase():
      //   dropdownInterface.list = ['Infraco Pit'];
      //   dropdownInterface.defaultValue = 'Infraco Pit';
      //   return dropdownInterface;

      //BN network

      case 'BN-network-data centre 3rd party'.toLowerCase():
        dropdownInterface.list = ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-network-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list = ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-network-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['NBN Access Seeker ODF'];
        dropdownInterface.defaultValue = 'NBN Access Seeker ODF';
        return dropdownInterface;

      case 'BN-network-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list = ['NBN Access Seeker ODF'];
        dropdownInterface.defaultValue = 'NBN Access Seeker ODF';
        return dropdownInterface;

      case 'BN-network-Non-Premises'.toLowerCase():
        dropdownInterface.list = ['Infraco Pit'];
        dropdownInterface.defaultValue = 'Infraco Pit';
        return dropdownInterface;

      case 'BN-network-Premises'.toLowerCase():
        dropdownInterface.list = ['Antenna', 'Common Area', 'Customer Cabinet', 'Floor', 'Roof Top', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-network-Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['Customer Cabinet'  ,'Exchange Rack'  ,'TEBA ODF','NBC ODF','Yard'  ,'Roof Top','Antenna'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-network-Cable Landing Station'.toLowerCase():
        dropdownInterface.list = ['Common Area','Customer Cabinet','Floor','Roof Top','Antenna', 'Yard','Exchange Rack'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      //BN Access

      case 'BN-Access-Data Centre 3rd Party'.toLowerCase():
        dropdownInterface.list = ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-Access-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list = ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-Access-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['NBN Access Seeker ODF'];
        dropdownInterface.defaultValue = 'NBN Access Seeker ODF';
        return dropdownInterface;

      case 'BN-Access-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list = ['NBN Access Seeker ODF'];
        dropdownInterface.defaultValue = 'NBN Access Seeker ODF';
        return dropdownInterface;

      case 'BN-Access-Non-Premises'.toLowerCase():
        dropdownInterface.list = ['Infraco Pit'];
        dropdownInterface.defaultValue = 'Infraco Pit';
        return dropdownInterface;

      case 'BN-Access-Premises'.toLowerCase():
        dropdownInterface.list = ['Antenna', 'Common Area', 'Customer Cabinet', 'Floor', 'Roof Top', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-Access-Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['Antenna', 'Customer Cabinet', 'Exchange Rack', 'NBC ODF', 'Roof Top', 'TEBA ODF', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-Access-Cable Landing Station'.toLowerCase():
        dropdownInterface.list = ['Common Area', 'Customer Cabinet', 'Exchange Rack', 'Floor', 'Roof Top', 'Yard', 'Antenna'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      // TW Network

      case 'TW-network-data centre 3rd party'.toLowerCase():
        dropdownInterface.list = ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'TW-network-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list = ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'TW-network-Non-Premises'.toLowerCase():
        dropdownInterface.list = ['Infraco Pit'];
        dropdownInterface.defaultValue = 'Infraco Pit';
        return dropdownInterface;

      case 'TW-network-Premises'.toLowerCase():
        dropdownInterface.list = ['Antenna', 'Common Area', 'Customer Cabinet', 'Floor', 'Roof Top', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'TW-network-Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['Antenna', 'Customer Cabinet', 'Exchange Rack', 'TEBA ODF', 'NBC ODF', 'Roof Top', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'TW-network-Cable Landing Station'.toLowerCase():
        dropdownInterface.list = ['Common Area', 'Customer Cabinet', 'Exchange Rack', 'Floor', 'Roof Top', 'Yard', 'Antenna'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      // TW Acsess

      case 'TW-Access-data centre 3rd party'.toLowerCase():
        dropdownInterface.list = ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'TW-Access-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list = ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'TW-Access-Non-Premises'.toLowerCase():
        dropdownInterface.list = ['Infraco Pit'];
        dropdownInterface.defaultValue = 'Infraco Pit';
        return dropdownInterface;

      case 'TW-Access-Premises'.toLowerCase():
        dropdownInterface.list = ['Antenna', 'Common Area', 'Customer Cabinet', 'Floor', 'Roof Top', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'TW-Access-Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['Antenna', 'Customer Cabinet', 'Exchange Rack', 'TEBA ODF', 'NBC ODF', 'Roof Top', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'TW-Access-Cable Landing Station'.toLowerCase():
        dropdownInterface.list = ['Common Area', 'Customer Cabinet', 'Exchange Rack', 'Floor', 'Roof Top', 'Yard', 'Antenna'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      default:
        dropdownInterface.list = ['Antenna', 'Carrier Room', 'Colo', 'Common Area', 'Customer Cabinet', 'Customer Pit', 'Exchange Rack', 'Floor', 'Infraco Pit', 'Meet Me Room', 'NBC ODF', 'NBN Access Seeker ODF', 'NBN FAN', 'Roof Top', 'TEBA ODF', 'Yard'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;
    }
  }

  getConnectorTypesValues(serviceType: string, subProduct: string, siteType: string): ConnectorType {

    let dropdownInterface: ConnectorType = { list: [], defaultValue: '' };

    const lowerServiceTyoe = serviceType ? serviceType.toLowerCase() : '';
    const lowerSubProduct = subProduct ? subProduct.toLowerCase() : '';
    const lowerSiteType = siteType ? siteType.toLowerCase() : '';
    const combinedValue = `${lowerServiceTyoe}-${lowerSubProduct}-${lowerSiteType}`;


    switch (combinedValue) {
      // RG network
      case 'rg-network-data centre 3rd party'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-network-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-network-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','LCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-network-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','LCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-network-Non-Premises'.toLowerCase():
        dropdownInterface.list =  ['Optitip MT Pinned (non premise)'];
        dropdownInterface.defaultValue = 'Optitip MT Pinned (non premise)';
        return dropdownInterface;

      case 'rg-network-Premises'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','Splice','Structured Cable' ,'Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-network-Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','Splice','Structured Cable' ,'Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-network-Cable Landing Station'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','Splice','Structured Cable' ,'Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      //RG Access

      case 'rg-Access-Data Centre 3rd Party'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Access-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Access-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','LCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Access-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','LCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Access-Non-Premises'.toLowerCase():
        dropdownInterface.list =  ['Optitip MT Pinned (non premise)'];
        dropdownInterface.defaultValue = 'Optitip MT Pinned (non premise)';
        return dropdownInterface;

      case 'rg-Access-Premises'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','Splice','Structured Cable' ,'Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Access-Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','Splice','Structured Cable' ,'Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Access-Cable Landing Station'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','Splice','Structured Cable' ,'Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      //RG Mobiles SMC

      case 'rg-Mobiles SMC-Data Centre 3rd Party'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Mobiles SMC-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Mobiles SMC-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','LCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Mobiles SMC-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','LCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Mobiles SMC-Non-Premises'.toLowerCase():
        dropdownInterface.list =  ['Optitip MT Pinned (non premise)'];
        dropdownInterface.defaultValue = 'Optitip MT Pinned (non premise)';
        return dropdownInterface;

      case 'rg-Mobiles SMC-Premises'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','Splice','Structured Cable' ,'Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Mobiles SMC-Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','Splice','Structured Cable' ,'Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Mobiles SMC-Cable Landing Station'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','Splice','Structured Cable' ,'Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      //RG Mobiles Macro

      case 'rg-Mobiles Macro-Data Centre 3rd Party'.toLowerCase():
        dropdownInterface.list = ['SCAPC', 'Optitip MT Pinned (non premise)', 'Splice'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Mobiles Macro-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list = ['SCAPC', 'Optitip MT Pinned (non premise)', 'Splice'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Mobiles Macro-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['SCAPC', 'Optitip MT Pinned (non premise)', 'Splice'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Mobiles Macro-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list = ['SCAPC', 'Optitip MT Pinned (non premise)', 'Splice'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Mobiles Macro-Non-Premises'.toLowerCase():
        dropdownInterface.list = ['SCAPC', 'Optitip MT Pinned (non premise)', 'Splice'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Mobiles Macro-Premises'.toLowerCase():
        dropdownInterface.list = ['SCAPC', 'Optitip MT Pinned (non premise)', 'Splice'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Mobiles Macro-Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['SCAPC', 'Optitip MT Pinned (non premise)', 'Splice'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'rg-Mobiles Macro-Cable Landing Station'.toLowerCase():
        dropdownInterface.list = ['SCAPC', 'Optitip MT Pinned (non premise)', 'Splice'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      // BL OLD

      case 'BL-NBN EE-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'BL-NBN EE-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list = ['SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'BL-NBN EE-Premises'.toLowerCase():
        dropdownInterface.list = ['SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'BL-NBN EE-Non-Premises'.toLowerCase():
        dropdownInterface.list = ['OptiTap SCAPC (non premise)'];
        dropdownInterface.defaultValue = 'OptiTap SCAPC (non premise)';
        return dropdownInterface;

      case 'BL-NBN Fixed Wireless-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'BL-NBN Fixed Wireless-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list = ['SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'BL-NBN Fixed Wireless-Premises'.toLowerCase():
        dropdownInterface.list = ['SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'BL-NBN Fixed Wireless-Non-Premises'.toLowerCase():
        dropdownInterface.list = ['OptiTip MT Pinned (non premise)'];
        dropdownInterface.defaultValue = 'OptiTip MT Pinned (non premise)';
        return dropdownInterface;
        
      // // BL NEW

      // case 'BL-NBN EE-Telstra Exchange'.toLowerCase():
      //   dropdownInterface.list =  ['SCAPC'];
      //   dropdownInterface.defaultValue = 'SCAPC';
      //   return dropdownInterface;

      // case 'BL-NBN EE-Premises'.toLowerCase():
      //   dropdownInterface.list =  ['SCAPC'];
      //   dropdownInterface.defaultValue = 'SCAPC';
      //   return dropdownInterface;

      // case 'BL-NBN EE-Non-Premises'.toLowerCase():
      //   dropdownInterface.list =  ['OptiTap SCAPC (non premise)'];
      //   dropdownInterface.defaultValue = 'OptiTap SCAPC (non premise)';
      //   return dropdownInterface;

      // case 'BL-NBN Fixed Wireless-Telstra Exchange'.toLowerCase():
      //   dropdownInterface.list =  ['SCAPC'];
      //   dropdownInterface.defaultValue = 'SCAPC';
      //   return dropdownInterface;

      // case 'BL-NBN Fixed Wireless-Premises'.toLowerCase():
      //   dropdownInterface.list =  ['SCAPC'];
      //   dropdownInterface.defaultValue = 'SCAPC';
      //   return dropdownInterface;

      // case 'BL-NBN Fixed Wireless-Non-Premises'.toLowerCase():
      //   dropdownInterface.list =  ['OptiTap SCAPC (non premise)'];
      //   dropdownInterface.defaultValue = 'OptiTap SCAPC (non premise)';
      //   return dropdownInterface;

      //BN network

      case 'BN-network-data centre 3rd party'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'BN-network-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'BN-network-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','LCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'BN-network-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','LCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'BN-network-Non-Premises'.toLowerCase():
        dropdownInterface.list =  ['Optitip MT Pinned (non premise)'];
        dropdownInterface.defaultValue = 'Optitip MT Pinned (non premise)';
        return dropdownInterface;

      case 'BN-network-Premises'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'BN-network-Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'BN-network-Cable Landing Station'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      //BN Access

      case 'BN-Access-Data Centre 3rd Party'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'BN-Access-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'BN-Access-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','LCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'BN-Access-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','LCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'BN-Access-Non-Premises'.toLowerCase():
        dropdownInterface.list =  ['Optitip MT Pinned (non premise)'];
        dropdownInterface.defaultValue = 'Optitip MT Pinned (non premise)';
        return dropdownInterface;

      case 'BN-Access-Premises'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'BN-Access-Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      case 'BN-Access-Cable Landing Station'.toLowerCase():
        dropdownInterface.list =  ['SCAPC','Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'SCAPC';
        return dropdownInterface;

      // TW Network

      case 'TW-network-data centre 3rd party'.toLowerCase():
        dropdownInterface.list =  ['Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'Pigtail SCAPC';
        return dropdownInterface;

      case 'TW-network-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list =  ['Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'Pigtail SCAPC';
        return dropdownInterface;

      case 'TW-network-Non-Premises'.toLowerCase():
        dropdownInterface.list =  ['Optitip MT Pinned (non premise)'];
        dropdownInterface.defaultValue = 'Optitip MT Pinned (non premise)';
        return dropdownInterface;

      case 'TW-network-Premises'.toLowerCase():
        dropdownInterface.list =  ['Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'Pigtail SCAPC';
        return dropdownInterface;

      case 'TW-network-Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'Pigtail SCAPC';
        return dropdownInterface;

      case 'TW-network-Cable Landing Station'.toLowerCase():
        dropdownInterface.list =  ['Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'Pigtail SCAPC';
        return dropdownInterface;

      // TW Acsess

      case 'TW-Access-data centre 3rd party'.toLowerCase():
        dropdownInterface.list =  ['Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'Pigtail SCAPC';
        return dropdownInterface;

      case 'TW-Access-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list =  ['Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'Pigtail SCAPC';
        return dropdownInterface;

      case 'TW-Access-Non-Premises'.toLowerCase():
        dropdownInterface.list =  ['Optitip MT Pinned (non premise)']; 
        dropdownInterface.defaultValue = 'Optitip MT Pinned (non premise)';
        return dropdownInterface;

      case 'TW-Access-Premises'.toLowerCase():
        dropdownInterface.list =  ['Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'Pigtail SCAPC';
        return dropdownInterface;

      case 'TW-Access-Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'Pigtail SCAPC';
        return dropdownInterface;

      case 'TW-Access-Cable Landing Station'.toLowerCase():
        dropdownInterface.list =  ['Pigtail SCAPC'];
        dropdownInterface.defaultValue = 'Pigtail SCAPC';
        return dropdownInterface;

      default:
        dropdownInterface.list =  ['LCAPC', 'LCPC', 'Optitap SCAPC (non premises)', 'Optitip MT Pinned (non premises)', 'Pigtail LCAPC', 'Pigtail LCPC', 'Pigtail SCAPC', 'Pigtail SCPC', 'SCAPC', 'SCPC', 'Splice', 'Structured Cable'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;
    }
  }


  getAdditionalCablingList(serviceType: string, subProduct: string, siteType: string): AdditionalCabling {

    let dropdownInterface: AdditionalCabling = { list: [], defaultValue: '' };

    const lowerServiceTyoe = serviceType ? serviceType.toLowerCase() : '';
    const lowerSubProduct = subProduct ? subProduct.toLowerCase() : '';
    const lowerSiteType = siteType ? siteType.toLowerCase() : '';
    const combinedValue = `${lowerServiceTyoe}-${lowerSubProduct}-${lowerSiteType}`;


    switch (combinedValue) {

      // RG network
      case 'rg-network-data centre 3rd party'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-network-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-network-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-network-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-network-Non-Premises'.toLowerCase():
        dropdownInterface.list =  ['OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-network-Premises'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-network-Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC', 'Structured Cable (OEF)'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-network-Cable Landing Station'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      //RG Access

      case 'rg-Access-Data Centre 3rd Party'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Access-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Access-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Access-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Access-Non-Premises'.toLowerCase():
        dropdownInterface.list =  ['OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Access-Premises'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Access-Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC', 'Structured Cable (OEF)'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Access-Cable Landing Station'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      //RG Mobiles SMC

      case 'rg-Mobiles SMC-Data Centre 3rd Party'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles SMC-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles SMC-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles SMC-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles SMC-Non-Premises'.toLowerCase():
        dropdownInterface.list =  ['OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles SMC-Premises'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles SMC-Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC', 'Structured Cable (OEF)'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles SMC-Cable Landing Station'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      //RG Mobiles Macro

      case 'rg-Mobiles Macro-Data Centre 3rd Party'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles Macro-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles Macro-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles Macro-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles Macro-Non-Premises'.toLowerCase():
        dropdownInterface.list =  ['OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles Macro-Premises'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles Macro-Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC', 'Structured Cable (OEF)'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'rg-Mobiles Macro-Cable Landing Station'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      // BL OLD

      case 'BL-NBN EE-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['Patch LCPC', 'Patch LCAPC', 'Patch SCPC', 'Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BL-NBN EE-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list = ['Patch LCPC', 'Patch LCAPC', 'Patch SCPC', 'Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BL-NBN EE-Premises'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BL-NBN Fixed Wireless-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['Patch LCPC', 'Patch LCAPC', 'Patch SCPC', 'Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BL-NBN Fixed Wireless-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list = ['Patch LCPC', 'Patch LCAPC', 'Patch SCPC', 'Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BL-NBN Fixed Wireless-Premises'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BL-NBN Fixed Wireless-Non-Premises'.toLowerCase():
        dropdownInterface.list =  ['OptiTip Drop Cable x 20 metres SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      // // BL NEW

      // case 'BL-NBN EE-Telstra Exchange'.toLowerCase():
      //   dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
      //   dropdownInterface.defaultValue = '';
      //   return dropdownInterface;

      // case 'BL-NBN EE-Premises'.toLowerCase():
      //   dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
      //   dropdownInterface.defaultValue = '';
      //   return dropdownInterface;

      // case 'BL-NBN Fixed Wireless-Telstra Exchange'.toLowerCase():
      //   dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
      //   dropdownInterface.defaultValue = '';
      //   return dropdownInterface;

      // case 'BL-NBN Fixed Wireless-Premises'.toLowerCase():
      //   dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
      //   dropdownInterface.defaultValue = '';
      //   return dropdownInterface;

      // case 'BL-NBN Fixed Wireless-Non-Premises'.toLowerCase():
      //   dropdownInterface.list =  ['OptiTip Drop Cable x 20 metres SCAPC'];
      //   dropdownInterface.defaultValue = '';
      //   return dropdownInterface;

      //BN network

      case 'BN-network-data centre 3rd party'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-network-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-network-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-network-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-network-Non-Premises'.toLowerCase():
        dropdownInterface.list =  ['OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-network-Premises'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-network-Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC', 'Structured Cable (OEF)'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-network-Cable Landing Station'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      //BN Access

      case 'BN-Access-Data Centre 3rd Party'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-Access-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-Access-NBN POI - Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-Access-NBN POI - Depot'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-Access-Non-Premises'.toLowerCase():
        dropdownInterface.list =  ['OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-Access-Premises'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-Access-Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC', 'Structured Cable (OEF)'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'BN-Access-Cable Landing Station'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      // TW Network

      case 'TW-network-data centre 3rd party'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'TW-network-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'TW-network-Non-Premises'.toLowerCase():
        dropdownInterface.list =  ['OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'TW-network-Premises'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'TW-network-Telstra Exchange'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC', 'Structured Cable (OEF)'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'TW-network-Cable Landing Station'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      // TW Acsess

      case 'TW-Access-data centre 3rd party'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'TW-Access-Data Centre Infraco'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'TW-Access-Non-Premises'.toLowerCase():
        dropdownInterface.list =  ['OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'TW-Access-Premises'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'TW-Access-Telstra Exchange'.toLowerCase():
        dropdownInterface.list = ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC', 'Structured Cable (OEF)'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      case 'TW-Access-Cable Landing Station'.toLowerCase():
        dropdownInterface.list =  ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;

      default:
        dropdownInterface.list =  ['Customer to provide', 'OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 20 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC', 'Patch LCAPC', 'Patch LCPC', 'Patch SCAPC', 'Patch SCPC', 'Structured Cable (OEF)'];
        dropdownInterface.defaultValue = '';
        return dropdownInterface;
    }
  }

  getAdditionalCablingDefaultValue(serviceType: string, subProduct: string, siteType: string): string {

    const lowerServiceTyoe = serviceType ? serviceType.toLowerCase() : '';
    const lowerSubProduct = subProduct ? subProduct.toLowerCase() : '';
    const lowerSiteType = siteType ? siteType.toLowerCase() : '';
    const combinedValue = `${lowerServiceTyoe}-${lowerSubProduct}-${lowerSiteType}`;

    switch (combinedValue) {

      // RG network
      case 'rg-network-data centre 3rd party'.toLowerCase():
        return 'no';

      case 'rg-network-Data Centre Infraco'.toLowerCase():
        return 'no';

      case 'rg-network-NBN POI - Telstra Exchange'.toLowerCase():
        return 'no';

      case 'rg-network-NBN POI - Depot'.toLowerCase():
        return 'no';

      case 'rg-network-Non-Premises'.toLowerCase():
        return 'no';

      case 'rg-network-Premises'.toLowerCase():
        return 'no';

      case 'rg-network-Telstra Exchange'.toLowerCase():
        return 'no';

      case 'rg-network-Cable Landing Station'.toLowerCase():
        return 'no';

      // RG Access

      case 'rg-Access-Data Centre 3rd Party'.toLowerCase():
        return 'no';

      case 'rg-Access-Data Centre Infraco'.toLowerCase():
        return 'no';

      case 'rg-Access-NBN POI - Telstra Exchange'.toLowerCase():
        return 'no';

      case 'rg-Access-NBN POI - Depot'.toLowerCase():
        return 'no';

      case 'rg-Access-Non-Premises'.toLowerCase():
        return 'no';

      case 'rg-Access-Premises'.toLowerCase():
        return 'no';

      case 'rg-Access-Telstra Exchange'.toLowerCase():
        return 'no';

      case 'rg-Access-Cable Landing Station'.toLowerCase():
        return 'no';

      //RG Mobiles SMC

      case 'rg-Mobiles SMC-Data Centre 3rd Party'.toLowerCase():
        return 'no';

      case 'rg-Mobiles SMC-Data Centre Infraco'.toLowerCase():
        return 'no';

      case 'rg-Mobiles SMC-NBN POI - Telstra Exchange'.toLowerCase():
        return 'no';

      case 'rg-Mobiles SMC-NBN POI - Depot'.toLowerCase():
        return 'no';

      case 'rg-Mobiles SMC-Non-Premises'.toLowerCase():
        return 'no';

      case 'rg-Mobiles SMC-Premises'.toLowerCase():
        return 'no';

      case 'rg-Mobiles SMC-Telstra Exchange'.toLowerCase():
        return 'no';

      case 'rg-Mobiles SMC-Cable Landing Station'.toLowerCase():
        return 'no';

      // RG Mobiles Macro

      case 'rg-Mobiles Macro-Data Centre 3rd Party'.toLowerCase():
        return 'no';

      case 'rg-Mobiles Macro-Data Centre Infraco'.toLowerCase():
        return 'no';

      case 'rg-Mobiles Macro-NBN POI - Telstra Exchange'.toLowerCase():
        return 'no';

      case 'rg-Mobiles Macro-NBN POI - Depot'.toLowerCase():
        return 'no';

      case 'rg-Mobiles Macro-Non-Premises'.toLowerCase():
        return 'no';

      case 'rg-Mobiles Macro-Premises'.toLowerCase():
        return 'no';

      case 'rg-Mobiles Macro-Telstra Exchange'.toLowerCase():
        return 'no';

      case 'rg-Mobiles Macro-Cable Landing Station'.toLowerCase():
        return 'no';

     // BL 

      case 'BL-NBN EE-NBN POI - Telstra Exchange'.toLowerCase():
        return 'no';

      case 'BL-NBN EE-Premises'.toLowerCase():
        return 'no';

      case 'BL-NBN EE-NBN POI - Depot'.toLowerCase():
        return 'no';

      case 'BL-NBN Fixed Wireless-NBN POI - Telstra Exchange'.toLowerCase():
        return 'no';

      case 'BL-NBN Fixed Wireless-NBN POI - Depot'.toLowerCase():
        return 'no';

      case 'BL-NBN Fixed Wireless-Premises'.toLowerCase():
        return 'no';

      case 'BL-NBN Fixed Wireless-Non-Premises'.toLowerCase():
        return 'yes';

      default:
        return '';
    }
  }

  sortCables(cables: string[]): string[] {
    return cables.sort((a, b) => {
      return a.localeCompare(b, undefined, { numeric: true });
    });
  }


































































































  // OLD ===============================================

  // getServiceDeliveryPointValues(serviceType: string, subProduct: string, siteType: string): string[] {

  //   const lowerServiceTyoe = serviceType.toLowerCase();
  //   const lowerSubProduct = subProduct.toLowerCase();
  //   const lowerSiteType = siteType.toLowerCase();
  //   const combinedValue = `${lowerServiceTyoe}-${lowerSubProduct}-${lowerSiteType}`;


  //   switch (combinedValue) {
  //     // RG Network
  //     case 'rg-network-data centre 3rd party'.toLowerCase():
  //       return ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];

  //     case 'rg-network-Data Centre Infraco'.toLowerCase():
  //       return ['Exchange Rack', 'Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];

  //     case 'rg-network-NBN POI - Telstra Exchange'.toLowerCase():
  //       return ['NBN Access Seeker ODF'];

  //     case 'rg-network-NBN POI - Depot'.toLowerCase():
  //       return ['NBN Access Seeker ODF'];

  //     case 'rg-network-Non-Premises'.toLowerCase():
  //       return ['Infraco Pit'];

  //     case 'rg-network-Premises'.toLowerCase():
  //       return ['Antenna', 'Common Area', 'Customer Cabinet', 'Floor', 'Roof Top', 'Yard'];

  //     case 'rg-network-Telstra Exchange'.toLowerCase():
  //       return ['Antenna', 'Customer Cabinet', 'Exchange Rack', 'NBN Access Seeker ODF', 'NBC ODF', 'Roof Top', 'TEBA ODF', 'Yard'];

  //     case 'rg-network-Cable Landing Station'.toLowerCase():
  //       return ['Common Area', 'Customer Cabinet', 'Exchange Rack', 'Floor', 'Roof Top', 'Yard'];

  //     // RG Access

  //     case 'rg-Access-Data Centre 3rd Party'.toLowerCase():
  //       return ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];

  //     case 'rg-Access-Data Centre Infraco'.toLowerCase():
  //       return ['Exchange Rack', 'Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];

  //     case 'rg-Access-NBN POI - Telstra Exchange'.toLowerCase():
  //       return ['ANBN Access Seeker ODF'];

  //     case 'rg-Access-NBN POI - Depot'.toLowerCase():
  //       return ['NBN Access Seeker ODF'];

  //     case 'rg-Access-Non-Premises'.toLowerCase():
  //       return ['Infraco Pit'];

  //     case 'rg-Access-Premises'.toLowerCase():
  //       return ['Antenna', 'Common Area', 'Customer Cabinet', 'Floor', 'Roof Top', 'Yard'];

  //     case 'rg-Access-Telstra Exchange'.toLowerCase():
  //       return ['Antenna', 'Customer Cabinet', 'Exchange Rack', 'NBN Access Seeker ODF', 'NBC ODF', 'Roof Top', 'TEBA ODF', 'Yard'];

  //     case 'rg-Access-Cable Landing Station'.toLowerCase():
  //       return ['Common Area', 'Customer Cabinet', 'Exchange Rack', 'Floor', 'Roof Top', 'Yard'];

  //     //RG Mobiles SMC

  //     case 'rg-Mobiles SMC-Data Centre 3rd Party'.toLowerCase():
  //       return ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];

  //     case 'rg-Mobiles SMC-Data Centre Infraco'.toLowerCase():
  //       return ['Exchange Rack', 'Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];

  //     case 'rg-Mobiles SMC-NBN POI - Telstra Exchange'.toLowerCase():
  //       return ['NBN Access Seeker ODF'];

  //     case 'rg-Mobiles SMC-NBN POI - Depot'.toLowerCase():
  //       return ['NBN Access Seeker ODF'];

  //     case 'rg-Mobiles SMC-Non-Premises'.toLowerCase():
  //       return ['Infraco Pit'];

  //     case 'rg-Mobiles SMC-Premises'.toLowerCase():
  //       return ['Antenna', 'Common Area', 'Customer Cabinet', 'Floor', 'Roof Top', 'Yard'];

  //     case 'rg-Mobiles SMC-Telstra Exchange'.toLowerCase():
  //       return ['Antenna', 'Customer Cabinet', 'Exchange Rack', 'NBN Access Seeker ODF', 'NBC ODF', 'Roof Top', 'TEBA ODF', 'Yard'];

  //     case 'rg-Mobiles SMC-Cable Landing Station'.toLowerCase():
  //       return ['Common Area', 'Customer Cabinet', 'Exchange Rack', 'Floor', 'Roof Top', 'Yard'];

  //     //RG Mobiles Macro

  //     case 'rg-Mobiles Macro-Data Centre 3rd Party'.toLowerCase():
  //       return ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];

  //     case 'rg-Mobiles Macro-Data Centre Infraco'.toLowerCase():
  //       return ['Exchange Rack', 'Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];

  //     case 'rg-Mobiles Macro-NBN POI - Telstra Exchange'.toLowerCase():
  //       return ['NBN Access Seeker ODF'];

  //     case 'rg-Mobiles Macro-NBN POI - Depot'.toLowerCase():
  //       return ['NBN Access Seeker ODF'];

  //     case 'rg-Mobiles Macro-Non-Premises'.toLowerCase():
  //       return ['Infraco Pit'];

  //     case 'rg-Mobiles Macro-Premises'.toLowerCase():
  //       return ['Antenna', 'Common Area', 'Customer Cabinet', 'Floor', 'Roof Top', 'Yard'];

  //     case 'rg-Mobiles Macro-Telstra Exchange'.toLowerCase():
  //       return ['Antenna', 'Customer Cabinet', 'Exchange Rack', 'NBN Access Seeker ODF', 'NBC ODF', 'Roof Top', 'TEBA ODF', 'Yard'];

  //     case 'rg-Mobiles Macro-Cable Landing Station'.toLowerCase():
  //       return ['Common Area', 'Customer Cabinet', 'Exchange Rack', 'Floor', 'Roof Top', 'Yard'];

  //     // BL 

  //     case 'BL-NBN EE-Telstra Exchange'.toLowerCase():
  //       return ['NBN FAN'];

  //     case 'BL-NBN EE-Premises'.toLowerCase():
  //       return ['Common Area', 'Floor'];

  //     case 'BL-NBN EE-Non-Premises'.toLowerCase():
  //       return ['Infraco Pit'];

  //     case 'BL-NBN Fixed Wireless-Telstra Exchange'.toLowerCase():
  //       return ['NBN FAN'];

  //     case 'BL-NBN Fixed Wireless-Premises'.toLowerCase():
  //       return ['Common Area', 'Floor', 'Roof Top'];

  //     case 'BL-NBN Fixed Wireless-Non-Premises'.toLowerCase():
  //       return ['Infraco Pit'];

  //     //BN network

  //     case 'BN-network-data centre 3rd party'.toLowerCase():
  //       return ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];

  //     case 'BN-network-Data Centre Infraco'.toLowerCase():
  //       return ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];

  //     case 'BN-network-NBN POI - Telstra Exchange'.toLowerCase():
  //       return ['NBN Access Seeker ODF']; //default

  //     case 'BN-network-NBN POI - Depot'.toLowerCase():
  //       return ['NBN Access Seeker ODF']; //default

  //     case 'BN-network-Non-Premises'.toLowerCase():
  //       return ['Infraco Pit']; //default

  //     case 'BN-network-Premises'.toLowerCase():
  //       return ['Antenna', 'Common Area', 'Customer Cabinet', 'Floor', 'Roof Top', 'Yard'];

  //     case 'BN-network-Telstra Exchange'.toLowerCase():
  //       return ['Antenna', 'Customer Cabinet', 'Exchange Rack', 'NBN Access Seeker ODF', 'NBC ODF', 'Roof Top', 'TEBA ODF', 'Yard'];

  //     case 'BN-network-Cable Landing Station'.toLowerCase():
  //       return ['Common Area', 'Customer Cabinet', 'Exchange Rack', 'Floor', 'Roof Top', 'Yard'];

  //     //BN Access

  //     case 'BN-Access-Data Centre 3rd Party'.toLowerCase():
  //       return ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];

  //     case 'BN-Access-Data Centre Infraco'.toLowerCase():
  //       return ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];

  //     case 'BN-Access-NBN POI - Telstra Exchange'.toLowerCase():
  //       return ['ANBN Access Seeker ODF']; //default

  //     case 'BN-Access-NBN POI - Depot'.toLowerCase():
  //       return ['NBN Access Seeker ODF']; //default

  //     case 'BN-Access-Non-Premises'.toLowerCase():
  //       return ['Infraco Pit']; //default

  //     case 'BN-Access-Premises'.toLowerCase():
  //       return ['Antenna', 'Common Area', 'Customer Cabinet', 'Floor', 'Roof Top', 'Yard'];

  //     case 'BN-Access-Telstra Exchange'.toLowerCase():
  //       return ['Antenna', 'Customer Cabinet', 'Exchange Rack', 'NBN Access Seeker ODF', 'NBC ODF', 'Roof Top', 'TEBA ODF', 'Yard'];

  //     case 'BN-Access-Cable Landing Station'.toLowerCase():
  //       return ['Common Area', 'Customer Cabinet', 'Exchange Rack', 'Floor', 'Roof Top', 'Yard'];

  //     // TW Network

  //     case 'TW-network-data centre 3rd party'.toLowerCase():
  //       return ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];

  //     case 'TW-network-Data Centre Infraco'.toLowerCase():
  //       return ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];

  //     case 'TW-network-Non-Premises'.toLowerCase():
  //       return ['Infraco Pit']; //default

  //     case 'TW-network-Premises'.toLowerCase():
  //       return ['Antenna', 'Common Area', 'Customer Cabinet', 'Floor', 'Roof Top', 'Yard'];

  //     case 'TW-network-Telstra Exchange'.toLowerCase():
  //       return ['Antenna', 'Customer Cabinet', 'Exchange Rack', 'TEBA ODF', 'NBC ODF', 'Roof Top', 'Yard'];

  //     case 'TW-network-Cable Landing Station'.toLowerCase():
  //       return ['Common Area', 'Customer Cabinet', 'Exchange Rack', 'Floor', 'Roof Top', 'Yard', 'Antenna'];

  //     // TW Acsess

  //     case 'TW-Access-data centre 3rd party'.toLowerCase():
  //       return ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];

  //     case 'TW-Access-Data Centre Infraco'.toLowerCase():
  //       return ['Customer Cabinet', 'Carrier Room', 'Colo', 'Meet Me Room'];

  //     case 'TW-Access-Non-Premises'.toLowerCase():
  //       return ['Infraco Pit']; //default

  //     case 'TW-Access-Premises'.toLowerCase():
  //       return ['Antenna', 'Common Area', 'Customer Cabinet', 'Floor', 'Roof Top', 'Yard'];

  //     case 'TW-Access-Telstra Exchange'.toLowerCase():
  //       return ['Antenna', 'Customer Cabinet', 'Exchange Rack', 'TEBA ODF', 'NBC ODF', 'Roof Top', 'Yard'];

  //     case 'TW-Access-Cable Landing Station'.toLowerCase():
  //       return ['Common Area', 'Customer Cabinet', 'Exchange Rack', 'Floor', 'Roof Top', 'Yard', 'Antenna'];

  //     default:
  //       return ['Antenna', 'Carrier Room', 'Colo', 'Common Area', 'Customer Cabinet', 'Customer Pit', 'Exchange Rack', 'Floor', 'Infraco Pit', 'Meet Me Room', 'NBC ODF', 'NBN Access Seeker ODF', 'NBN FAN', 'Roof Top', 'TEBA ODF', 'Yard'];
  //   }
  // }

  // checkServiceDeliveryPointDefaultValueIfAny(serviceType: string, subProduct: string, siteType: string): string {
   
  //   const lowerServiceTyoe = serviceType.toLowerCase();
  //   const lowerSubProduct = subProduct.toLowerCase();
  //   const lowerSiteType = siteType.toLowerCase();
  //   const combinedValue = `${lowerServiceTyoe}-${lowerSubProduct}-${lowerSiteType}`;

  //   switch (combinedValue) {
  //     // BL 

  //     case 'BL-NBN EE-Telstra Exchange'.toLowerCase():
  //       return 'NBN FAN';

  //     case 'BL-NBN EE-Premises'.toLowerCase():
  //       return 'Common Area';

  //     case 'BL-NBN EE-Non-Premises'.toLowerCase():
  //       return 'Infraco Pit';

  //     case 'BL-NBN Fixed Wireless-Telstra Exchange'.toLowerCase():
  //       return 'NBN FAN';

  //     case 'BL-NBN Fixed Wireless-Premises'.toLowerCase():
  //       return 'Common Area';

  //     case 'BL-NBN Fixed Wireless-Non-Premises'.toLowerCase():
  //       return 'Infraco Pit';

  //     // TW

  //     case 'TW-Access-Non-Premises'.toLowerCase():
  //       return 'Infraco Pit';

  //     case 'TW-network-Non-Premises'.toLowerCase():
  //       return 'Infraco Pit';

  //     // BN

  //     case 'BN-network-NBN POI - Telstra Exchange'.toLowerCase():
  //       return 'NBN Access Seeker ODF'; //default

  //     case 'BN-network-NBN POI - Depot'.toLowerCase():
  //       return 'NBN Access Seeker ODF'; //default

  //     case 'BN-network-Non-Premises'.toLowerCase():
  //       return 'Infraco Pit'; //default

  //     case 'BN-Access-NBN POI - Telstra Exchange'.toLowerCase():
  //       return 'ANBN Access Seeker ODF'; //default

  //     case 'BN-Access-NBN POI - Depot'.toLowerCase():
  //       return 'NBN Access Seeker ODF'; //default

  //     case 'BN-Access-Non-Premises'.toLowerCase():
  //       return 'Infraco Pit'; //default

  //     case 'TW-network-Non-Premises'.toLowerCase():
  //       return 'Infraco Pit'; //default

  //     default:
  //       return '';

  //   }
  // }

  // getConnectorTypesValues(serviceType: string, subProduct: string, siteType: string): string[] {

  //   const lowerServiceTyoe = serviceType.toLowerCase();
  //   const lowerSubProduct = subProduct.toLowerCase();
  //   const lowerSiteType = siteType.toLowerCase();
  //   const combinedValue = `${lowerServiceTyoe}-${lowerSubProduct}-${lowerSiteType}`;


  //   switch (combinedValue) {
  //     // RG network
  //     case 'rg-network-data centre 3rd party'.toLowerCase():
  //       return ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];

  //     case 'rg-network-Data Centre Infraco'.toLowerCase():
  //       return ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];

  //     case 'rg-network-NBN POI - Telstra Exchange'.toLowerCase():
  //       return ['SCAPC','LCAPC'];

  //     case 'rg-network-NBN POI - Depot'.toLowerCase():
  //       return ['SCAPC','LCAPC'];

  //     case 'rg-network-Non-Premises'.toLowerCase():
  //       return ['Optitip MT Pinned (non premise)'];

  //     case 'rg-network-Premises'.toLowerCase():
  //       return ['SCAPC','Splice','Structured Cable' ,'Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];

  //     case 'rg-network-Telstra Exchange'.toLowerCase():
  //       return ['SCAPC','Splice','Structured Cable' ,'Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];

  //     case 'rg-network-Cable Landing Station'.toLowerCase():
  //       return ['SCAPC','Splice','Structured Cable' ,'Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];

  //     //RG Access

  //     case 'rg-Access-Data Centre 3rd Party'.toLowerCase():
  //       return ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];

  //     case 'rg-Access-Data Centre Infraco'.toLowerCase():
  //       return ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];

  //     case 'rg-Access-NBN POI - Telstra Exchange'.toLowerCase():
  //       return ['SCAPC','LCAPC'];

  //     case 'rg-Access-NBN POI - Depot'.toLowerCase():
  //       return ['SCAPC','LCAPC'];

  //     case 'rg-Access-Non-Premises'.toLowerCase():
  //       return ['Optitip MT Pinned (non premise)'];

  //     case 'rg-Access-Premises'.toLowerCase():
  //       return ['SCAPC','Splice','Structured Cable' ,'Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];

  //     case 'rg-Access-Telstra Exchange'.toLowerCase():
  //       return ['SCAPC','Splice','Structured Cable' ,'Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];

  //     case 'rg-Access-Cable Landing Station'.toLowerCase():
  //       return ['SCAPC','Splice','Structured Cable' ,'Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];

  //     //RG Mobiles SMC

  //     case 'rg-Mobiles SMC-Data Centre 3rd Party'.toLowerCase():
  //       return ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];

  //     case 'rg-Mobiles SMC-Data Centre Infraco'.toLowerCase():
  //       return ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];

  //     case 'rg-Mobiles SMC-NBN POI - Telstra Exchange'.toLowerCase():
  //       return ['SCAPC','LCAPC'];

  //     case 'rg-Mobiles SMC-NBN POI - Depot'.toLowerCase():
  //       return ['SCAPC','LCAPC'];

  //     case 'rg-Mobiles SMC-Non-Premises'.toLowerCase():
  //       return ['Optitip MT Pinned (non premise)'];

  //     case 'rg-Mobiles SMC-Premises'.toLowerCase():
  //       return ['SCAPC','Splice','Structured Cable' ,'Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];

  //     case 'rg-Mobiles SMC-Telstra Exchange'.toLowerCase():
  //       return ['SCAPC','Splice','Structured Cable' ,'Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];

  //     case 'rg-Mobiles SMC-Cable Landing Station'.toLowerCase():
  //       return ['SCAPC','Splice','Structured Cable' ,'Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];

  //     //RG Mobiles Macro

  //     case 'rg-Mobiles Macro-Data Centre 3rd Party'.toLowerCase():
  //       return ['SCAPC','Optitip MT Pinned (non premise)', 'Splice'];

  //     case 'rg-Mobiles Macro-Data Centre Infraco'.toLowerCase():
  //       return ['SCAPC','Optitip MT Pinned (non premise)', 'Splice'];

  //     case 'rg-Mobiles Macro-NBN POI - Telstra Exchange'.toLowerCase():
  //       return ['SCAPC','Optitip MT Pinned (non premise)', 'Splice'];

  //     case 'rg-Mobiles Macro-NBN POI - Depot'.toLowerCase():
  //       return ['SCAPC','Optitip MT Pinned (non premise)', 'Splice'];

  //     case 'rg-Mobiles Macro-Non-Premises'.toLowerCase():
  //       return ['SCAPC','Optitip MT Pinned (non premise)', 'Splice'];

  //     case 'rg-Mobiles Macro-Premises'.toLowerCase():
  //       return ['SCAPC','Optitip MT Pinned (non premise)', 'Splice'];

  //     case 'rg-Mobiles Macro-Telstra Exchange'.toLowerCase():
  //       return ['SCAPC','Optitip MT Pinned (non premise)', 'Splice'];

  //     case 'rg-Mobiles Macro-Cable Landing Station'.toLowerCase():
  //       return ['SCAPC','Optitip MT Pinned (non premise)', 'Splice'];

  //     // BL 

  //     case 'BL-NBN EE-Telstra Exchange'.toLowerCase():
  //       return ['SCAPC'];

  //     case 'BL-NBN EE-Premises'.toLowerCase():
  //       return ['SCAPC'];

  //     case 'BL-NBN EE-Non-Premises'.toLowerCase():
  //       return ['OptiTap SCAPC (non premise)'];

  //     case 'BL-NBN Fixed Wireless-Telstra Exchange'.toLowerCase():
  //       return ['SCAPC'];

  //     case 'BL-NBN Fixed Wireless-Premises'.toLowerCase():
  //       return ['SCAPC'];

  //     case 'BL-NBN Fixed Wireless-Non-Premises'.toLowerCase():
  //       return ['OptiTap SCAPC (non premise)'];

  //     //BN network

  //     case 'BN-network-data centre 3rd party'.toLowerCase():
  //       return ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];

  //     case 'BN-network-Data Centre Infraco'.toLowerCase():
  //       return ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];

  //     case 'BN-network-NBN POI - Telstra Exchange'.toLowerCase():
  //       return ['SCAPC','LCAPC']; //default

  //     case 'BN-network-NBN POI - Depot'.toLowerCase():
  //       return ['SCAPC','LCAPC']; //default

  //     case 'BN-network-Non-Premises'.toLowerCase():
  //       return ['Optitip MT Pinned (non premise)']; //default

  //     case 'BN-network-Premises'.toLowerCase():
  //       return ['SCAPC','Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];

  //     case 'BN-network-Telstra Exchange'.toLowerCase():
  //       return ['SCAPC','Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];

  //     case 'BN-network-Cable Landing Station'.toLowerCase():
  //       return ['SCAPC','Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];

  //     //BN Access

  //     case 'BN-Access-Data Centre 3rd Party'.toLowerCase():
  //       return ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];

  //     case 'BN-Access-Data Centre Infraco'.toLowerCase():
  //       return ['SCAPC','SCPC','LCAPC','LCPC','Structured Cable','Pigtail LCPC','Pigtail LCAPC','Pigtail SCPC','Pigtail SCAPC'];

  //     case 'BN-Access-NBN POI - Telstra Exchange'.toLowerCase():
  //       return ['SCAPC','LCAPC']; //default

  //     case 'BN-Access-NBN POI - Depot'.toLowerCase():
  //       return ['SCAPC','LCAPC']; //default

  //     case 'BN-Access-Non-Premises'.toLowerCase():
  //       return ['Optitip MT Pinned (non premise)'];  //default

  //     case 'BN-Access-Premises'.toLowerCase():
  //       return ['SCAPC','Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];

  //     case 'BN-Access-Telstra Exchange'.toLowerCase():
  //       return ['SCAPC','Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];

  //     case 'BN-Access-Cable Landing Station'.toLowerCase():
  //       return ['SCAPC','Pigtail LCPC' ,'Pigtail LCAPC'  ,'Pigtail SCPC' ,'Pigtail SCAPC'];

  //     // TW Network

  //     case 'TW-network-data centre 3rd party'.toLowerCase():
  //       return ['Pigtail SCAPC'];

  //     case 'TW-network-Data Centre Infraco'.toLowerCase():
  //       return ['Pigtail SCAPC'];

  //     case 'TW-network-Non-Premises'.toLowerCase():
  //       return ['Optitip MT Pinned (non premise)']; //default

  //     case 'TW-network-Premises'.toLowerCase():
  //       return ['Pigtail SCAPC'];

  //     case 'TW-network-Telstra Exchange'.toLowerCase():
  //       return ['Pigtail SCAPC'];

  //     case 'TW-network-Cable Landing Station'.toLowerCase():
  //       return ['Pigtail SCAPC'];

  //     // TW Acsess

  //     case 'TW-Access-data centre 3rd party'.toLowerCase():
  //       return ['Pigtail SCAPC'];

  //     case 'TW-Access-Data Centre Infraco'.toLowerCase():
  //       return ['Pigtail SCAPC'];

  //     case 'TW-Access-Non-Premises'.toLowerCase():
  //       return ['Optitip MT Pinned (non premise)']; //default

  //     case 'TW-Access-Premises'.toLowerCase():
  //       return ['Pigtail SCAPC'];

  //     case 'TW-Access-Telstra Exchange'.toLowerCase():
  //       return ['Pigtail SCAPC'];

  //     case 'TW-Access-Cable Landing Station'.toLowerCase():
  //       return ['Pigtail SCAPC'];

  //     default:
  //       return ['LCAPC', 'LCPC', 'Optitap SCAPC (non premises)', 'Optitip MT Pinned (non premises)', 'Pigtail LCAPC', 'Pigtail LCPC', 'Pigtail SCAPC', 'Pigtail SCPC', 'SCAPC', 'SCPC', 'Splice', 'Structured Cable'];
  //   }
  // }

  // checkConnectorTypesDefaultValueIfAny(serviceType: string, subProduct: string, siteType: string): string{
    
  //   const lowerServiceTyoe = serviceType.toLowerCase();
  //   const lowerSubProduct = subProduct.toLowerCase();
  //   const lowerSiteType = siteType.toLowerCase();
  //   const combinedValue = `${lowerServiceTyoe}-${lowerSubProduct}-${lowerSiteType}`;

  //   switch (combinedValue) {
  //      // RG network
  //      case 'rg-network-data centre 3rd party'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-network-Data Centre Infraco'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-network-NBN POI - Telstra Exchange'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-network-NBN POI - Depot'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-network-Non-Premises'.toLowerCase():
  //       return 'Optitip MT Pinned (non premise)';

  //     case 'rg-network-Premises'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-network-Telstra Exchange'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-network-Cable Landing Station'.toLowerCase():
  //       return 'SCAPC';

  //     //RG Access

  //     case 'rg-Access-Data Centre 3rd Party'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Access-Data Centre Infraco'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Access-NBN POI - Telstra Exchange'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Access-NBN POI - Depot'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Access-Non-Premises'.toLowerCase():
  //       return 'Optitip MT Pinned (non premise)';

  //     case 'rg-Access-Premises'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Access-Telstra Exchange'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Access-Cable Landing Station'.toLowerCase():
  //       return 'SCAPC';

  //     //RG Mobiles SMC

  //     case 'rg-Mobiles SMC-Data Centre 3rd Party'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Mobiles SMC-Data Centre Infraco'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Mobiles SMC-NBN POI - Telstra Exchange'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Mobiles SMC-NBN POI - Depot'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Mobiles SMC-Non-Premises'.toLowerCase():
  //       return 'Optitip MT Pinned (non premise)';

  //     case 'rg-Mobiles SMC-Premises'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Mobiles SMC-Telstra Exchange'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Mobiles SMC-Cable Landing Station'.toLowerCase():
  //       return 'SCAPC';

  //     //RG Mobiles Macro

  //     case 'rg-Mobiles Macro-Data Centre 3rd Party'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Mobiles Macro-Data Centre Infraco'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Mobiles Macro-NBN POI - Telstra Exchange'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Mobiles Macro-NBN POI - Depot'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Mobiles Macro-Non-Premises'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Mobiles Macro-Premises'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Mobiles Macro-Telstra Exchange'.toLowerCase():
  //       return 'SCAPC';

  //     case 'rg-Mobiles Macro-Cable Landing Station'.toLowerCase():
  //       return 'SCAPC';

  //     // BL 

  //     case 'BL-NBN EE-Telstra Exchange'.toLowerCase():
  //       return 'SCAPC';

  //     case 'BL-NBN EE-Premises'.toLowerCase():
  //       return 'SCAPC';

  //     case 'BL-NBN EE-Non-Premises'.toLowerCase():
  //       return 'OptiTap SCAPC (non premise)';

  //     case 'BL-NBN Fixed Wireless-Telstra Exchange'.toLowerCase():
  //       return 'SCAPC';

  //     case 'BL-NBN Fixed Wireless-Premises'.toLowerCase():
  //       return 'SCAPC';

  //     case 'BL-NBN Fixed Wireless-Non-Premises'.toLowerCase():
  //       return 'OptiTap SCAPC (non premise)';

  //     //BN network

  //     case 'BN-network-data centre 3rd party'.toLowerCase():
  //       return 'SCAPC';

  //     case 'BN-network-Data Centre Infraco'.toLowerCase():
  //       return 'SCAPC';

  //     case 'BN-network-NBN POI - Telstra Exchange'.toLowerCase():
  //       return 'SCAPC';

  //     case 'BN-network-NBN POI - Depot'.toLowerCase():
  //       return 'SCAPC';

  //     case 'BN-network-Non-Premises'.toLowerCase():
  //       return 'Optitip MT Pinned (non premise)';

  //     case 'BN-network-Premises'.toLowerCase():
  //       return 'SCAPC';

  //     case 'BN-network-Telstra Exchange'.toLowerCase():
  //       return 'SCAPC';

  //     case 'BN-network-Cable Landing Station'.toLowerCase():
  //       return 'SCAPC';

  //     //BN Access

  //     case 'BN-Access-Data Centre 3rd Party'.toLowerCase():
  //       return 'SCAPC';

  //     case 'BN-Access-Data Centre Infraco'.toLowerCase():
  //       return 'SCAPC';

  //     case 'BN-Access-NBN POI - Telstra Exchange'.toLowerCase():
  //       return 'SCAPC';

  //     case 'BN-Access-NBN POI - Depot'.toLowerCase():
  //       return 'SCAPC';

  //     case 'BN-Access-Non-Premises'.toLowerCase():
  //       return 'Optitip MT Pinned (non premise)';  //default

  //     case 'BN-Access-Premises'.toLowerCase():
  //       return 'SCAPC';

  //     case 'BN-Access-Telstra Exchange'.toLowerCase():
  //       return 'SCAPC';

  //     case 'BN-Access-Cable Landing Station'.toLowerCase():
  //       return 'SCAPC';

  //     // TW Network

  //     case 'TW-network-data centre 3rd party'.toLowerCase():
  //       return 'Pigtail SCAPC';

  //     case 'TW-network-Data Centre Infraco'.toLowerCase():
  //       return 'Pigtail SCAPC';

  //     case 'TW-network-Non-Premises'.toLowerCase():
  //       return 'Optitip MT Pinned (non premise)'; //default

  //     case 'TW-network-Premises'.toLowerCase():
  //       return 'Pigtail SCAPC';

  //     case 'TW-network-Telstra Exchange'.toLowerCase():
  //       return 'Pigtail SCAPC';

  //     case 'TW-network-Cable Landing Station'.toLowerCase():
  //       return 'Pigtail SCAPC';

  //     // TW Acsess

  //     case 'TW-Access-data centre 3rd party'.toLowerCase():
  //       return 'Pigtail SCAPC';

  //     case 'TW-Access-Data Centre Infraco'.toLowerCase():
  //       return 'Pigtail SCAPC';

  //     case 'TW-Access-Non-Premises'.toLowerCase():
  //       return 'Optitip MT Pinned (non premise)'; //default

  //     case 'TW-Access-Premises'.toLowerCase():
  //       return 'Pigtail SCAPC';

  //     case 'TW-Access-Telstra Exchange'.toLowerCase():
  //       return 'Pigtail SCAPC';

  //     case 'TW-Access-Cable Landing Station'.toLowerCase():
  //       return 'Pigtail SCAPC';

  //     default:
  //       return '';
  //   }
  // }

  // getAdditionalCablingList(serviceType: string, subProduct: string, siteType: string): string[]{

  //   const lowerServiceTyoe = serviceType.toLowerCase();
  //   const lowerSubProduct = subProduct.toLowerCase();
  //   const lowerSiteType = siteType.toLowerCase();
  //   const combinedValue = `${lowerServiceTyoe}-${lowerSubProduct}-${lowerSiteType}`;


  //   switch (combinedValue) {

  //     // RG network
  //     case 'rg-network-data centre 3rd party'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-network-Data Centre Infraco'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-network-NBN POI - Telstra Exchange'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-network-NBN POI - Depot'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-network-Non-Premises'.toLowerCase():
  //       return ['OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC'];

  //     case 'rg-network-Premises'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-network-Telstra Exchange'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC', 'Structured Cable (OEF)'];

  //     case 'rg-network-Cable Landing Station'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     //RG Access

  //     case 'rg-Access-Data Centre 3rd Party'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-Access-Data Centre Infraco'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-Access-NBN POI - Telstra Exchange'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-Access-NBN POI - Depot'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-Access-Non-Premises'.toLowerCase():
  //       return ['OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC'];

  //     case 'rg-Access-Premises'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-Access-Telstra Exchange'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC', 'Structured Cable (OEF)'];

  //     case 'rg-Access-Cable Landing Station'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     //RG Mobiles SMC

  //     case 'rg-Mobiles SMC-Data Centre 3rd Party'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-Mobiles SMC-Data Centre Infraco'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-Mobiles SMC-NBN POI - Telstra Exchange'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-Mobiles SMC-NBN POI - Depot'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-Mobiles SMC-Non-Premises'.toLowerCase():
  //       return ['OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC'];

  //     case 'rg-Mobiles SMC-Premises'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-Mobiles SMC-Telstra Exchange'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC', 'Structured Cable (OEF)'];

  //     case 'rg-Mobiles SMC-Cable Landing Station'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     //RG Mobiles Macro

  //     case 'rg-Mobiles Macro-Data Centre 3rd Party'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-Mobiles Macro-Data Centre Infraco'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-Mobiles Macro-NBN POI - Telstra Exchange'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-Mobiles Macro-NBN POI - Depot'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-Mobiles Macro-Non-Premises'.toLowerCase():
  //       return ['OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC'];

  //     case 'rg-Mobiles Macro-Premises'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'rg-Mobiles Macro-Telstra Exchange'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC', 'Structured Cable (OEF)'];

  //     case 'rg-Mobiles Macro-Cable Landing Station'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     // BL 

  //     case 'BL-NBN EE-Telstra Exchange'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'BL-NBN EE-Premises'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'BL-NBN Fixed Wireless-Telstra Exchange'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'BL-NBN Fixed Wireless-Premises'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'BL-NBN Fixed Wireless-Non-Premises'.toLowerCase():
  //       return ['OptiTip Drop Cable x 20 metres SCAPC'];

  //     //BN network

  //     case 'BN-network-data centre 3rd party'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'BN-network-Data Centre Infraco'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'BN-network-NBN POI - Telstra Exchange'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'BN-network-NBN POI - Depot'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'BN-network-Non-Premises'.toLowerCase():
  //       return ['OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC'];

  //     case 'BN-network-Premises'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'BN-network-Telstra Exchange'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC', 'Structured Cable (OEF)'];

  //     case 'BN-network-Cable Landing Station'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     //BN Access

  //     case 'BN-Access-Data Centre 3rd Party'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'BN-Access-Data Centre Infraco'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'BN-Access-NBN POI - Telstra Exchange'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'BN-Access-NBN POI - Depot'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'BN-Access-Non-Premises'.toLowerCase():
  //       return ['OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC'];

  //     case 'BN-Access-Premises'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'BN-Access-Telstra Exchange'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC', 'Structured Cable (OEF)'];

  //     case 'BN-Access-Cable Landing Station'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     // TW Network

  //     case 'TW-network-data centre 3rd party'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'TW-network-Data Centre Infraco'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'TW-network-Non-Premises'.toLowerCase():
  //       return ['OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC'];

  //     case 'TW-network-Premises'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'TW-network-Telstra Exchange'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC', 'Structured Cable (OEF)'];

  //     case 'TW-network-Cable Landing Station'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     // TW Acsess

  //     case 'TW-Access-data centre 3rd party'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'TW-Access-Data Centre Infraco'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'TW-Access-Non-Premises'.toLowerCase():
  //       return ['OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC'];

  //     case 'TW-Access-Premises'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     case 'TW-Access-Telstra Exchange'.toLowerCase():
  //       return['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC', 'Structured Cable (OEF)'];

  //     case 'TW-Access-Cable Landing Station'.toLowerCase():
  //       return ['Patch LCPC','Patch LCAPC','Patch SCPC','Patch SCAPC'];

  //     default:
  //       return ['Customer to provide', 'OptiTip Drop Cable x 100 metres STUB', 'OptiTip Drop Cable x 150 metres STUB', 'OptiTip Drop Cable x 16 metres LCPC', 'OptiTip Drop Cable x 16 metres SCAPC', 'OptiTip Drop Cable x 20 metres SCAPC', 'OptiTip Drop Cable x 30 metres LCPC', 'OptiTip Drop Cable x 30 metres SCAPC', 'OptiTip Drop Cable x 30 metres STUB', 'OptiTip Drop Cable x 80 metres LCPC', 'OptiTip Drop Cable x 80 metres SCAPC', 'Patch LCAPC', 'Patch LCPC', 'Patch SCAPC', 'Patch SCPC', 'Structured Cable (OEF)'];
  //   }
  // }
}

<div class="container-fluid bulk-request-container">
  <main aria-labelledby="bulkQuoteRequestDetailsHeader">
  <div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div>
  <div class="row">
    <div class="col-4 page-header-container">
      <h1 class="bulk-request-label" id="bulkQuoteRequestDetailsHeader"
        [attr.aria-label]="isModifyBulkQuote? aemLabelAuthoring?.pageHeader.modifyQuotePageHeader:  aemLabelAuthoring?.pageHeader.pageHeaderLabel">
        {{isModifyBulkQuote? aemLabelAuthoring?.pageHeader.modifyQuotePageHeader: aemLabelAuthoring?.pageHeader.pageHeaderLabel}}</h1>
      <div  *ngIf="bulkRequestId" class="bulk-request-ref-id"><span aria-label="Bulk quote reference"
          class="color-grey-41 font-16">{{aemLabelAuthoring?.pageHeader.pageSubHeaderLabel}}</span> <b
          class="font-16 color-black"> {{bulkRequestId}}</b> <span class="pl-20 font-16 fw-500 color-black"><img
            src="../../../../assets/images/Submitted.svg" class="saved-icon" />
          {{aemLabelAuthoring?.pageHeader?.saved}}</span>
      </div>
    </div>
    <div class="col-4">
      <app-page-stepper [stepperData]="stepperData" [stepName]="stepName"></app-page-stepper>
    </div>
    <div class="col col-center d-flex align-items-center w-100 j-right">
      <span  class="p-r-0 font-18">Fields marked with <span class="color-red">*</span> are mandatory</span>
    </div>
  </div>

  <div class="d-flex w-100 mt-40 align-items-center  justify-content-between align-items-center">
    <div class="d-flex align-items-center gap-3">
      <div><img src="../../../../assets/images/Details-Doc.svg" alt=""></div>
      <h2 class="font-24 font-bold color-black" [aria-label]="aemLabelAuthoring?.quoteRequestDetails">{{aemLabelAuthoring?.quoteRequestDetails}}</h2>
    </div>

  </div>

  <hr />
</main>

  <form [formGroup]="bulkRequestDetailsForm" class="row">

    <div class="pt-40">
      <div class="row pb-20">
        <div class="col-5">
          <div class="row">
            <div class="col">
              <div class="d-flex justify-content-between pb-10">
                <div>
                  <span class="font-14 color-black">{{aemLabelAuthoring?.estimatedQuoteRequsts}} <span
                      class="color-red">*</span> </span>
                </div>
                <div>
                  <span class="font-14 color-grey-51">{{aemLabelAuthoring?.maximum500Requests}}</span>
                </div>
              </div>
              <div>
                <input maxlength="3" name="estimatedQuoteRequestsBulk" [attr.aria-label]="aemLabelAuthoring?.estimatedQuoteRequsts" aria-describedby="estimatedQuote_error"  aria-required="true" (keypress)="numberOnly($event)" class="w-100 pad-14 font-16" type="text"  (paste)="onPasteCheck($event)"
                  placeholder="Enter estimated quote requests in this bulk"  formControlName="estimatedQuoteRequestsBulk"
                  [ngClass]="{'invalid-form-control': ((bulkRequestDetailsForm?.controls?.estimatedQuoteRequestsBulk?.touched || bulkRequestDetailsForm?.controls?.estimatedQuoteRequestsBulk?.dirty) && bulkRequestDetailsForm?.controls?.estimatedQuoteRequestsBulk?.invalid)}">
              </div>

              <ng-container
                *ngIf="bulkRequestDetailsForm?.controls?.estimatedQuoteRequestsBulk?.invalid && (bulkRequestDetailsForm?.controls?.estimatedQuoteRequestsBulk?.dirty || bulkRequestDetailsForm?.controls?.estimatedQuoteRequestsBulk?.touched)">
                <small id="estimatedQuote_error"
                  *ngIf="!bulkRequestDetailsForm?.controls?.estimatedQuoteRequestsBulk?.errors?.required && bulkRequestDetailsForm?.controls?.estimatedQuoteRequestsBulk?.invalid"
                  class="text-danger errorMsg d-block">
                  <img class="error-icon"
                    src="../../../../assets/images/icon-error.svg" />
                     {{aemLabelAuthoring?.enterRequestEstimate}}
                </small>
                <small id="estimatedQuote_error"
                  *ngIf="(bulkRequestDetailsForm?.controls?.estimatedQuoteRequestsBulk?.dirty || bulkRequestDetailsForm?.controls?.estimatedQuoteRequestsBulk?.touched) && bulkRequestDetailsForm?.controls?.estimatedQuoteRequestsBulk?.errors?.required"
                  class="text-danger errorMsg d-block">
                  <img class="error-icon" src="../../../../assets/images/icon-error.svg" />
                  {{aemLabelAuthoring?.estimatedBulkQuoteReq}}
                </small>
              </ng-container>
            </div>
          </div>
        </div>


        <div class="col-5">
          <div class="row">
            <div class="col">
              <div class="row pb-10">
                <div class="col pr-0">
                  <span class="font-14 color-black" aria-required="true" required>{{aemLabelAuthoring?.responseRequiredDate}} <span
                      class="color-red">*</span>
                    <img src="../../../../assets/images/Info-Withdraw.svg" [ngbTooltip]="dateTipContent"
                      [content]="dateTooltipContentModel.content" automationType="anchor" tabindex="0"
                      automationId="anchorAddOrderDateInfo" placement="right" #tooltipCustRd="ngbTooltip"
                      (focusin)="tooltipCustRd.open()" (focusout)="tooltipCustRd.close()" class="infoIcon pl-5"
                      alt="Response required date field">
                  </span>
                  <ng-template #dateTipContent>
                    <div class="text-left"> <span class="pl-10 font-600">Criteria:</span>
                      <ul class="font-500">
                        <li> At least 2 business days after request submission </li>
                        <li> Business days (Monday to Friday) only </li>
                      </ul>
                    </div>
                    <!-- <app-cui-tooltip-content [model]="dateTooltipContentModel"></app-cui-tooltip-content> -->
                  </ng-template>
                </div>
                <div class="col text-right pl-0">
                  <span class="font-14 color-grey-51">{{aemLabelAuthoring?.calendarFormat}}</span>
                </div>
              </div>
              <div style="position: relative;bottom: 20px" aria-required="true" aria-describedby="custDateErrMsg">
                <app-cui-datepicker  aria-label="Response required date" [datePlaceholder]="'dd/mm/yyyy'"
                  [modelDate]="serviceDatePicker" [datePickerId]="custRequestedDate" [navigation]="false"
                  [model]="custRequestedDate" (dateSelected)="onChangeServiceDate($event)" aria-required="true"
                  [accessibleHeader]="'Response required date required'" [parentFormGroup]="bulkRequestDetailsForm" class="datepicker_core mb-1"
                  [minDate]="fromDate" [maxDate]="toMaxDate" [isManualEntry] = "false"
                  [ngClass]="{'invalid-form-control': (bulkRequestDetailsForm?.controls?.custRequestedDate?.touched && bulkRequestDetailsForm?.controls?.custRequestedDate?.invalid)}"  aria-describedby="custDateErrMsg">
                </app-cui-datepicker>
              </div>

              <span role="alert" *ngIf="bulkRequestDetailsForm?.controls?.custRequestedDate?.errors?.requiredField"
                class="text-danger errorMsg align-date-err-msg" id="custDateErrMsg"><img class="error-icon"
                  src="../../../../assets/images/icon-error.svg" /> {{aemLabelAuthoring?.validationMsg?.custRequestedDateReqMsg}}
              </span>
              <span role="alert"
                *ngIf="bulkRequestDetailsForm?.controls?.custRequestedDate?.invalid && !bulkRequestDetailsForm?.controls?.custRequestedDate?.errors?.requiredField"
                class="text-danger errorMsg align-date-err-msg" id="custDateErrMsg"><img class="error-icon"
                  src="../../../../assets/images/icon-error.svg" /> {{aemLabelAuthoring?.validationMsg?.custRequestedDateMsg}}
              </span>

            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="row pb-40">
        <div class="col-5">
          <div class="row">
            <div class="col">
              <div class="row pb-10">
                <div class="col pr-0">
                  <span class="font-14 color-black">{{aemLabelAuthoring?.bulkQuoteName}}</span>
                </div>
              </div>
              <div>
                <input (paste)="onPasteCheckNumber($event)" aria-describedby="bulkQuoteName_error"  (keydown)="onNumericKeyPressDown($event)" class="w-100 pad-14 font-16" type="text" maxlength="256" [attr.aria-label]="aemLabelAuthoring?.bulkQuoteName"
                  [placeholder]="aemLabelAuthoring?.placeholder?.enterBulkQuoteName" formControlName="bulkQuoteName" [ngClass]="{'invalid-form-control': ((bulkRequestDetailsForm.controls.bulkQuoteName.touched || bulkRequestDetailsForm.controls.bulkQuoteName.dirty) && bulkRequestDetailsForm.controls.bulkQuoteName.invalid)}">
              </div>
              <ng-container
                *ngIf="bulkRequestDetailsForm?.controls?.bulkQuoteName?.invalid && (bulkRequestDetailsForm?.controls?.bulkQuoteName?.dirty || bulkRequestDetailsForm?.controls?.bulkQuoteName?.touched)">
                <small id="bulkQuoteName_error"
                  *ngIf="!bulkRequestDetailsForm?.controls?.bulkQuoteName?.errors?.required && bulkRequestDetailsForm?.controls?.bulkQuoteName?.invalid"
                  class="text-danger errorMsg d-block">
                  <img class="error-icon" src="../../../../assets/images/icon-error.svg" />
                  {{aemLabelAuthoring?.validationMsg?.thirdPartyInfoMsg}}
                </small>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="row">
            <div class="col">
              <div class="row pb-10">
                <div class="col pr-0" style="padding-right: 0;">
                  <span class="font-14 color-black">{{aemLabelAuthoring?.relatedBulkQuoteReference}}
                  </span>
                </div>
                <div class="col text-right pl-0">
                  <span class="font-14 color-grey-51">{{aemLabelAuthoring?.maximum1Requests}}</span>
                </div>
              </div>
              <div>
                <input class="w-100 pad-14 font-16" type="text" maxlength="255" aria-describedby="relatedBulkQuoteReference_error" [attr.aria-label]="aemLabelAuthoring?.relatedBulkQuoteReference"
                  [placeholder]="aemLabelAuthoring?.placeHolderRelatedBulkQuoteRefernce" formControlName="bulkQuoteReference"
                  [ngClass]="{'invalid-form-control': ((bulkRequestDetailsForm?.controls?.bulkQuoteReference?.touched || bulkRequestDetailsForm?.controls?.bulkQuoteReference?.dirty) && bulkRequestDetailsForm?.controls?.bulkQuoteReference?.invalid)}">
              </div>
              <ng-container
                *ngIf="bulkRequestDetailsForm?.controls?.bulkQuoteReference?.invalid && (bulkRequestDetailsForm?.controls?.bulkQuoteReference?.dirty || bulkRequestDetailsForm?.controls?.bulkQuoteReference?.touched)">
                <small id="relatedBulkQuoteReference_error"
                  *ngIf="!bulkRequestDetailsForm?.controls?.bulkQuoteReference?.errors?.required && bulkRequestDetailsForm?.controls?.bulkQuoteReference?.invalid"
                  class="text-danger errorMsg d-block">
                  <img class="error-icon" src="../../../../assets/images/icon-error.svg" />
                  {{aemLabelAuthoring?.placeholder?.inputValidationMsg}}
                </small>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <!--  -->

      <div class="row pb-40">
        <div class="col-10">
          <div style="padding-bottom: 10px;"><span class="font-14">{{aemLabelAuthoring?.bulkQuoteSummary}}</span></div>
          <textarea class="w-100 pad-14" placeholder="Enter quote summary" name="" id="bulkQuoteSummary" cols="30" maxlength="4000"
          [attr.aria-label]="aemLabelAuthoring?.bulkQuoteSummary" rows="4" formControlName="bulkQuoteSummary" (keypress)="onQuoteSummaryKeyPress($event, bulkRequestDetailsForm?.value?.bulkQuoteSummary?.length)"
            [ngClass]="{'invalid-form-control': ((bulkRequestDetailsForm?.controls?.bulkQuoteSummary?.touched || bulkRequestDetailsForm?.controls?.bulkQuoteSummary?.dirty) && bulkRequestDetailsForm?.controls?.bulkQuoteSummary?.invalid)}"></textarea>

          <div class="row">
            <div class="col">
              <span role="alert"
                *ngIf="(bulkRequestDetailsForm?.controls?.bulkQuoteSummary?.touched || bulkRequestDetailsForm?.controls?.bulkQuoteSummary?.dirty) && bulkRequestDetailsForm?.controls?.bulkQuoteSummary?.invalid"
                class="help-block errorMsg"><img class="error-icon" src="../../../../assets/images/icon-error.svg" />
                {{aemLabelAuthoring.validationMsg.thirdPartyInfoMsg}}</span>
            </div>
            <div class="col">
              <div class="text-right">
                <span
                  class="text-right-blue color-green">{{getLengthOfbulkQuoteSummary()}}/4000</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="serviceType == 'RG' ">

      <!-- Business Driver -->
      <div class="row pb-40">
        <div class="col-5">
          <label class="required-field font-14" for="businessDriver">
            {{aemLabelAuthoring?.businessDriver}}
          </label>
          <app-cui-searchable-dropdown name="businessDriver" [model]="businessDriverDropDownModel" aria-label="Select business driver" role="combobox"
            (click)="selectBusinessDriver()"  (focusout)="selectBusinessDriver()" [parentFormGroup]="bulkRequestDetailsForm" [labelName]="'businessDriver'"
            [placeholder]="'Select business driver'" [formControlName]="'businessDriver'" [isRequired]=true
            [items]="businessDriverList | orderBy:'value'" automationType="dropdown" automationId="businessDriver"
            [noAutoComplete]=false id="businessDriver" class="customer-dropDown"
            [ngClass]="{'invalid-form-control': (bulkRequestDetailsForm?.controls?.businessDriver?.invalid && bulkRequestDetailsForm?.controls?.businessDriver?.touched)}"
            required>
          </app-cui-searchable-dropdown>
          <span role="alert" *ngIf="showAlertBusinessDriver
          && bulkRequestDetailsForm?.controls?.businessDriver?.invalid" class="text-danger errorMsg"><img class="error-icon"
            src="../../../assets/images/icon-error.svg" /> {{aemLabelAuthoring?.validationMsg?.businessDriver}}
        </span>
          <!-- <ng-container *ngIf="showAlertBusinessDriver  && bulkRequestDetailsForm?.controls?.businessDriver?.invalid">
            <small  role="alert" class="text-danger errorMsg">
              <img class="error-icon" alt="error icon" src="../../../../assets/images/icon-error.svg" />
              {{aemLabelAuthoring?.validationMsg?.businessDriver}}
            </small>
          </ng-container> -->
        </div>
      </div>


      <div class="row">
        <div class="col-10 externalref">
          <div class="row">
            <div class="col-4">
              <label class="required-field font-14" for="customerType" >
                {{aemLabelAuthoring?.externalReference}}
              </label>

              <app-cui-searchable-dropdown aria-describedby="extRef_error" name="customerType" [model]="externalReferenceDropDownModel" id="type" aria-label="Select external reference" role="combobox"
                (click)="selectExternalRef()" (focusout)="selectExternalRef()" [parentFormGroup]="bulkRequestDetailsForm"
                [labelName]="'externalReferenceDropDown'"  [isRequired]="true" required
                [placeholder]="aemLabelAuthoring?.placeholder?.externalReference" formControlName="extReferenceType1"
                [items]="externalReferenceList" automationType="dropdown" automationId="drpdwnCustomerType"
                [noAutoComplete]="false" id="customerType" class="customer-dropDown"
                [ngClass]="{'invalid-form-control': (bulkRequestDetailsForm?.controls?.extReferenceType1?.invalid && bulkRequestDetailsForm?.controls?.extReferenceType1?.touched)}">
              </app-cui-searchable-dropdown>

              <span role="alert" *ngIf="showAlertExternalRef
              && bulkRequestDetailsForm?.controls?.extReferenceType1?.invalid" class="help-block errorMsg"><img class="error-icon"
                src="../../../assets/images/icon-error.svg" /> {{aemLabelAuthoring?.validationMsg?.externalRefrence}}
            </span>
              <!-- <ng-container *ngIf="showAlertExternalRef  && bulkRequestDetailsForm?.controls?.extReferenceType1?.invalid">
                <small id="extRef_error" class="text-danger errorMsg">
                  <img class="error-icon" src="../../../../assets/images/icon-error.svg" />
                  {{aemLabelAuthoring?.validationMsg?.externalRefrence}}
                </small>
              </ng-container> -->

            </div>
            <div class="col-4">
              <div> <span class="pl-5 font-14">{{aemLabelAuthoring?.value}} <span class="color-red">*</span></span> </div>

              <div class=" text-bold-18 ms-1">
                <input type="text" maxlength="64" aria-describedby="extVal_error" class="form-control text-box" aria-label="Value required field"
                  [placeholder]="aemLabelAuthoring?.placeholder?.externalValue" id="extReferenceValue1"
                  formControlName="extReferenceValue1" name="extReferenceValue1" required
                  [ngClass]="{'invalid-form-control': ((bulkRequestDetailsForm.controls.extReferenceValue1.touched || bulkRequestDetailsForm.controls.extReferenceValue1.dirty) && bulkRequestDetailsForm.controls.extReferenceValue1.invalid)}">
                <!-- <ng-container
                  *ngIf="bulkRequestDetailsForm.controls.extReferenceValue1.invalid && (bulkRequestDetailsForm.controls.extReferenceValue1.dirty || bulkRequestDetailsForm.controls.extReferenceValue1.touched)">
                  <small
                    *ngIf="!bulkRequestDetailsForm.controls.extReferenceValue1.errors.required && bulkRequestDetailsForm.controls.extReferenceValue1.invalid"
                    class="text-danger errorMsg d-block">
                    <img class="error-icon" alt="error icon"
                      src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring?.validationMsg?.thirdPartyInfoMsg}}
                  </small>
                </ng-container> -->

                <ng-container
                  *ngIf="bulkRequestDetailsForm?.controls['extReferenceValue1']?.invalid && (bulkRequestDetailsForm?.controls['extReferenceValue1']?.dirty || bulkRequestDetailsForm?.controls['extReferenceValue1']?.touched)">
                  <small id="extVal_error"
                    *ngIf="!bulkRequestDetailsForm?.controls['extReferenceValue1']?.errors?.required && bulkRequestDetailsForm?.controls['extReferenceValue1']?.invalid"
                    class="text-danger errorMsg d-block">
                    <img class="error-icon" src="../../../../assets/images/icon-error.svg" /> The
                    following special
                    characters [<>;] are unable to be used, please revise your input
                  </small>

                  <small id="extVal_error"
                    *ngIf="bulkRequestDetailsForm.controls['extReferenceValue1']?.errors?.required && bulkRequestDetailsForm?.controls['extReferenceValue1']?.invalid"
                    class="text-danger errorMsg d-block">
                    <img class="error-icon" src="../../../../assets/images/icon-error.svg" />
                    Please enter value
                  </small>
                </ng-container>
              </div>
            </div>
          </div>

          <ng-container *ngFor="let externalRefernceForm of externalRefernces?.controls; let i = index;"
            formArrayName="externalRefernces">
            <div class="head-values" *ngIf="externalRefernces?.controls" [formGroup]="externalRefernceForm">
              <div class="row">
                <div class="col-4 text-bold-18 mt-4 ms-1"> {{aemLabelAuthoring?.externalReference}}
                </div>
                <div class="col-4 text-bold-18 mt-4 ms-1"><label>{{aemLabelAuthoring?.value}}</label>
                </div>
              </div>
              <div class="row details-font">
                <div class="col-4 text-bold-18 external-ref" #tref tabindex="-1">
                  <app-cui-searchable-dropdown name="extReferenceType" [model]="externalReferenceDropDownModelAdd" aria-label="Select external reference" role="combobox"
                    id="type" [labelName]="'externalReferenceDropDown'" [placeholder]="'Select external reference'"
                    formControlName="extReferenceType"  [isRequired]=false [items]="externalReferenceList" automationType="dropdown"
                    automationId="drpdwnCustomerType" [noAutoComplete]=false id="customerType"
                    class="customer-dropDown">
                  </app-cui-searchable-dropdown>
                </div>
                <div class="col-4  text-bold-18 ms-1">
                  <input type="text" class="form-control text-box" maxlength="64" aria-label="Value field"
                    [placeholder]="aemLabelAuthoring?.placeholder?.externalValue" formControlName="extReferenceValue"
                    [ngClass]="{'invalid-form-control': (externalRefernceForm?.controls?.extReferenceValue?.value?.length  && externalRefernceForm?.controls?.extReferenceValue?.invalid || externalRefernceForm.get('extReferenceValue').hasError('forbiddenChars') && (externalRefernceForm?.controls?.extReferenceValue?.dirty || externalRefernceForm?.controls?.extReferenceValue?.touch))}">

                  <ng-container
                    *ngIf="externalRefernceForm.get('extReferenceValue').hasError('forbiddenChars')">
                    <small
                      *ngIf="externalRefernceForm.get('extReferenceValue').hasError('forbiddenChars')"
                      class="text-danger errorMsg d-block">
                      <img class="error-icon" alt="error icon" src="../../../../assets/images/icon-error.svg" />
                      The following special characters [<>;] are unable to be used, please revise your input
                    </small>
                  </ng-container>
                </div>
                <div class="col-2"> <button (keypress)="deleteExternalRefernce(i)" role="button" attr.aria-label="Remove external reference {{externalRefernceForm.controls['extReferenceType'].value}}"
                    (click)="deleteExternalRefernce(i)" class="removeBtn" [disabled]="isModifyBulkQuote"><img
                      alt="remove icon" src="../../../../assets/images/Remove.svg">
                    <label class="ms-2 remove-font">Remove</label>
                  </button>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="addBtnReview btnRow">
            <button mat-button class="app-btn-green add-btn" role="button" (click)="addExternalRefernceForm()" aria-label="Add external reference"
              [disabled]="isModifyBulkQuote">
              <div class="btn-svg"><img src="../../../../assets/images/Icon-Add-btn-active.svg" alt="add icon"></div>
              <label class="label-space">Add</label>
            </button>
          </div>

        </div>
      </div>
    </ng-container>

    </div>

    <div class="row">
      <div class="col-12 ms-3 submitCancelBtn btnRow">
        <div class="save-cncl-btn-cls">
          <button mat-button (click)="cancel()" class="cancelBtn save-cancel-btn">
            <span class="cancel-btn"><i class="icon-icon-ui-cross"></i>{{ aemLabelAuthoring?.button?.cancel }}
            </span>
          </button>
          <button [disabled]="false" class="app-btn-green app-btn-white save-cancel-btn" (click)="saveBulkRequestDetails()">
            <div class="btn-svg save-icon"></div>
            {{ aemLabelAuthoring?.button?.save }}
          </button>
          <label role="alert" *ngIf="isQuoteSavedStatus" style="position:absolute;left:-9999px">Successfully saved</label>
          <button mat-button class="save-cancel-btn app-btn-green"
          [ngClass]="isModifyBulkQuote? 'app-btn-white' : ''"
          [disabled]="bulkRequestDetailsForm?.invalid || externalRefernceForm?.invalid"
          (click)="next()">
            {{ aemLabelAuthoring?.button?.next }}
            <div class="btn-svg next-icon"></div>
          </button>
          <button *ngIf="isModifyBulkQuote" role="button" class="app-btn-green me-2" 
          [disabled]="bulkRequestDetailsForm?.invalid || externalRefernceForm?.invalid"
          (click)="saveBulkRequestDetails('tnc')">
          {{aemLabelAuthoring?.button?.submit}}
        </button>
        </div>
      </div>
    </div>
  </form>
</div>
<app-terms-conditions-modal [isBulkQuote]="true"  [bulkQuoteId]="bulkQuoteID" [bulkPayload]="bulkPayload"
  [tAndcText]="tAndcText" [isModifyBulkQuote]="isModifyBulkQuote" *ngIf="isTermsCondition" 
  (onClickClose)="onClickClose($event)">
</app-terms-conditions-modal>

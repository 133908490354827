<div class="container-fluid prod-container-fluid">
  <div class="toastrContainer" aria-live="assertive" role="alert" toastContainer></div>
  <div class="row" *ngIf="aemLabelAuthoring1">
    <label style="position:absolute;left:-9999px" aria-live="assertive" role="alert">Product details page</label>
    <div class="col-3 page-header-container">
      <main aria-labelledby="productDetailsHeader">
      <h1 id="productDetailsHeader" class="page-heading" *ngIf="!isModifyQuote"> {{
        aemLabelAuthoring1.pageHeader.pageHeaderLabel }} </h1>
      <span class="page-heading" *ngIf="isModifyQuote"> {{ aemLabelAuthoring1.pageHeader.modifyQuotePageHeader }}
      </span>
    </main>

      <div class="quote-ref-text" *ngIf="quoteRefId">
        {{ aemLabelAuthoring1.pageHeader.pageSubHeaderLabel }} <strong>{{quoteRefId}} </strong>
        <span *ngIf="isQuoteSaved" class="save-lbl"> <img src="../../../assets/images/Submitted.svg"
            class="saved-icon" /><strong> {{
            aemLabelAuthoring1.pageHeader.saved }}</strong></span>
      </div>
      <!-- <div class="quote-ref-text" *ngIf="custReference" >
          {{ aemLabelAuthoring.pageHeader.reviewPageSubHeaderLabel }} <strong>Ref1234 </strong>
         </div> -->
    </div>
    <div class="col">
      <app-page-stepper [stepperData]="stepperData" [stepName]="stepName"></app-page-stepper>
    </div>
    <div class="col col-center d-flex align-items-center w-100 j-right">
      <div class="p-r-30 font-18">Fields marked with <span class="star">*</span> are mandatory</div>
    </div>
  </div>
  <div *ngIf="aemLabelAuthoring">
    <form [formGroup]="productDetailsForm" class="row product-details-form-container">
      <div *ngFor="let data of productData; let i = index" class="product-radio">
        <img src="../../../../assets/images/SelectProduct_Icon.svg" class="fiber-icon" />
        <label class="radio-btn-label">{{aemLabelAuthoring.label.selectProduct}}</label>
        <mat-radio-group role="radiogroup" class="smart-ui-radio-group product-radio-grp mb-0"
          (change)="selectProduct($event)" formControlName="productName">
          <mat-radio-button id="select-product-radio-btn" role="radio"
            [attr.aria-checked]="isProductSelected ? 'true' : 'false'" class="col-2 smart-ui-button-group"
            (keydown.enter)="(!this.isProductSelected)? selectProduct() :''" value="Dark fiber"
            [checked]="isProductSelected" [disabled]="isModifyQuote">
            <div class="product-name" aria-label="Select product dark fibre"> {{data.name}}</div>
            <img class="dark-fibre-img" src="../../../../assets/images/DarkFiber_btn.svg" />
          </mat-radio-button>
        </mat-radio-group>

        <!-- <div class="smart-ui-radio-group mb-0" *ngFor="let data of productData; let i = index">
          <button class="col-2 smart-ui-button-group" (click)="selectProduct($event)">
            <span class="radio-uncheck-icon" *ngIf="!isProductSelected"></span>
          <img *ngIf="isProductSelected" class="radio-btn-checked-icon" src="../../../../assets/images/Submitted.svg"/>
            {{data.name}}
            <img src="../../../../assets/images/DarkFiber_btn.svg" />
          </button>
        </div> -->
        <hr *ngIf="isProductSelected" />
      </div>

      <div *ngIf="isProductSelected && productData?.length">
        <div *ngFor="let data of productData; let i = index">
          <div *ngIf="data.name === 'Dark Fibre'">
            <div class="sub-product radio-btn-container" *ngIf="serviceType === 'BL'">
              <label class="radio-btn-label">{{aemLabelAuthoring.label.subProduct.subProductHeading}}
                <span class="inpt-reqrd-cls">*</span>
              </label>
              <mat-radio-group role="radiogroup" class="smart-ui-radio-group sub-product-radio-container"
                (change)="onSubProductChange($event)" formControlName="subProduct" [disabled]="isModifyQuote">
                <mat-radio-button id="nbnEE" role="radio"
                  [attr.aria-checked]="(productDetailsForm.value.subProduct === 'NBN EE') ? 'true' : 'false'"
                  (keyup.enter)="subProductRadioFnc('NBN EE')" class="col-1 smart-ui-button-group sub-product-radio-btn"
                  value="{{aemLabelAuthoring.label.subProduct.subProduct1}}">
                  <div class="mat-custom-label"><span aria-label="Select sub-product required NBN EE">
                      {{aemLabelAuthoring.label.subProduct.subProduct1}}
                    </span>
                  </div>
                </mat-radio-button>
                <mat-radio-button id="nbnFixedWireless" role="radio"
                  [attr.aria-checked]="(productDetailsForm.value.subProduct === 'NBN Fixed Wireless') ? 'true' : 'false'"
                  (keyup.enter)="subProductRadioFnc('NBN Fixed Wireless')" class="col-1 smart-ui-button-group sub-product-radio-btn"
                  value="{{aemLabelAuthoring.label.subProduct.subProduct2}}">
                  <div class="mat-custom-label"><span aria-label="Select sub-product required NBN Fixed Wireless">
                    {{aemLabelAuthoring.label.subProduct.subProduct2}}</span>
                  </div>
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <hr  *ngIf="serviceType === 'BL' && productDetailsForm.value.subProduct ==='NBN Fixed Wireless'" />
            <div class="radio-btn-container" *ngIf="isSubProductSelected() && !isSubProductNbnEe">
              <label class="radio-btn-label">{{aemLabelAuthoring.label.doYouRequireDiversity}}
                <span class="inpt-reqrd-cls">*</span></label>
              <mat-radio-group role="radiogroup" class="smart-ui-radio-group diversity-radio-container"
                (change)="onDiversityButtonChange($event)" formControlName="doYouRequireDiversity"
                [disabled]="isModifyQuote">
                <mat-radio-button id="diversityYesRadio" role="radio"
                  [attr.aria-checked]="(productDetailsForm.value.doYouRequireDiversity === 'Yes') ? 'true' : 'false'"
                  (keyup.enter)="diversityRadioFnc('Yes')" class="col-1 smart-ui-button-group diversity-radio-btn"
                  [value]="'Yes'">
                  <div class="mat-custom-label"><span
                      aria-label="Do you require diversity required Yes">{{aemLabelAuthoring.label.yes}}</span></div>
                </mat-radio-button>
                <mat-radio-button id="diversityNoRadio" role="radio"
                  [attr.aria-checked]="(productDetailsForm.value.doYouRequireDiversity === 'No') ? 'true' : 'false'"
                  (keyup.enter)="diversityRadioFnc('No')" class="col-1 smart-ui-button-group diversity-radio-btn"
                  [value]="'No'">
                  <div class="mat-custom-label"><span>{{aemLabelAuthoring.label.no}}</span></div>
                </mat-radio-button>
              </mat-radio-group>
              <div class="col-6 radio-btn-container mt-5 diversity-requirement-label" *ngIf="isDiversityRequired">
                <label
                  class="radio-btn-label diversity-requirement-label">{{aemLabelAuthoring.label.diversityRequirement}}
                  <span class="inpt-reqrd-cls">*</span></label>
                <app-cui-searchable-dropdown name="diversityTypeList" [model]="diversityTypeDropDownModel"
                  [formControlName]="'describeYourDiversity'" id="type" [labelName]="'diversityTypeList'"
                  [isRequired]="true" [items]="diversityTypeList" automationType="dropdown"
                  automationId="drpdwndiversityType" [noAutoComplete]="false" id="diversityTypeList"
                  class="customer-dropDown" (ngModelChange)="diversityTypeChange($event)" required>
                </app-cui-searchable-dropdown>
              </div>

              <div class="col-sm-9 col-lg-9 col-md-4 pb-2 textarea-container" *ngIf="showThirdPartyInfo"
                [ngClass]="{'disable-input-wrapper' : isModifyQuote}">
                <label class="product-details-secondary-label"
                  for="thirdPartyPathInfo">{{aemLabelAuthoring.label.thirdPartyInfo}}
                  <span class="inpt-reqrd-cls">*</span></label>
                <textarea class="form-control textarea" rows="6" id="thirdPartyPathInfo" maxlength="256"
                  formControlName="thirdPartyPathInfo" name="thirdPartyPathInfo" attr.aria-label="document description"
                  placeholder="Enter information"
                  [ngClass]="{'invalid-form-control': (productDetailsForm.controls.thirdPartyPathInfo.touched &&
                   productDetailsForm.controls.thirdPartyPathInfo.invalid), 'disable-form-input' : isModifyQuote}"
                    aria-describedby="errorMsg_thirdPartyPathInfo"></textarea>
                  <ng-container
                  *ngIf="productDetailsForm.controls['thirdPartyPathInfo'].invalid && (productDetailsForm.controls['thirdPartyPathInfo'].dirty ||
                   productDetailsForm.controls['thirdPartyPathInfo'].touched)">

                  <small *ngIf="productDetailsForm.controls['thirdPartyPathInfo'].errors.required"
                    class="text-danger errorMsg" id="errorMsg_thirdPartyPathInfo">
                    <img class="error-icon"
                      src="../../../../assets/images/icon-error.svg" />{{aemLabelAuthoring.validationMsg.thirdPartyInfoRequiredMsg}}
                  </small>
                  <small
                    *ngIf="!productDetailsForm.controls['thirdPartyPathInfo'].errors.required && productDetailsForm.controls['thirdPartyPathInfo'].invalid"
                    class="text-danger errorMsg" id="errorMsg_thirdPartyPathInfo">
                    <img class="error-icon" src="../../../../assets/images/icon-error.svg" />
                    {{aemLabelAuthoring.validationMsg.thirdPartyInfoMsg}}
                  </small>

                </ng-container>
                  <div class="text-right">
                  <span class="text-right-blue">{{
                    productDetailsForm.value.thirdPartyPathInfo ? productDetailsForm.value.thirdPartyPathInfo.length : 0
                    }}/256</span>
                  <!-- <span role="alert"
                    *ngIf="productDetailsForm.controls.thirdPartyPathInfo.touched && productDetailsForm.controls.thirdPartyPathInfo.invalid"
                    class="help-block errorMsg"><img class="error-icon" src="../../../../assets/images/icon-error.svg"/>{{aemLabelAuthoring.validationMsg.thirdPartyInfoMsg}}</span> -->
                  
                </div>
              </div>

              <div class="col-sm-6 col-lg-6 col-md-6 pb-2" *ngIf="showFNN"
                [ngClass]="{'disable-input-wrapper' : isModifyQuote}">
                <label class="product-details-secondary-label" for="existingInfracoServiceInfo">
                  <span *ngIf="serviceType === 'RG'">{{aemLabelAuthoring.label.existingFNN}}</span>
                  <span *ngIf="serviceType !== 'RG'">{{aemLabelAuthoring.label.existingFNN_NON_RG}}</span>
                  <span class="inpt-reqrd-cls">*</span>
                </label>
                <input type="tel" class="form-control fnn-input" rows="6" id="existingInfracoServiceInfo" maxlength="64"
                  formControlName="existingInfracoServiceInfo" name="existingInfracoServiceInfo"
                  attr.aria-label="document description" placeholder="Enter value"
                  [ngClass]="{'invalid-form-control': (productDetailsForm.controls.existingInfracoServiceInfo.touched && 
                  productDetailsForm.controls.existingInfracoServiceInfo.invalid) || 
                  (!productDetailsForm.controls['existingInfracoServiceInfo'].errors?.required &&
                  productDetailsForm.controls['existingInfracoServiceInfo'].invalid), 'disable-form-input' : isModifyQuote}" 
                  aria-describedby="errorMsg_existingInfracoServiceInfo"/>
                <!-- <span role="alert"
                  *ngIf="productDetailsForm.controls.existingInfracoServiceInfo.touched && productDetailsForm.controls.existingInfracoServiceInfo.invalid"
                  class="help-block errorMsg"><img class="error-icon" src="../../../../assets/images/icon-error.svg"/>{{aemLabelAuthoring.validationMsg.fnnValidationMsg}}</span> -->
                <ng-container
                  *ngIf="productDetailsForm.controls['existingInfracoServiceInfo'].invalid && 
                  (productDetailsForm.controls['existingInfracoServiceInfo'].dirty || productDetailsForm.controls['existingInfracoServiceInfo'].touched)">

                  <small *ngIf="productDetailsForm.controls['existingInfracoServiceInfo'].touched &&
                   productDetailsForm.controls['existingInfracoServiceInfo'].errors.required"
                    class="text-danger errorMsg" id="errorMsg_existingInfracoServiceInfo">
                    <img class="error-icon"
                      src="../../../../assets/images/icon-error.svg" /> 
                      <span *ngIf="serviceType === 'RG'">{{aemLabelAuthoring.validationMsg.fnnRequiredMsg}}</span>
                      <span *ngIf="serviceType !== 'RG'">{{aemLabelAuthoring.validationMsg.fnnRequiredMsgnonRG}}</span>
                  </small>
                  <small
                    *ngIf="!productDetailsForm.controls['existingInfracoServiceInfo'].errors.required &&
                     productDetailsForm.controls['existingInfracoServiceInfo'].invalid"
                    class="text-danger errorMsg" id="errorMsg_existingInfracoServiceInfo">
                    <img class="error-icon" src="../../../../assets/images/icon-error.svg" />
                    {{aemLabelAuthoring.validationMsg.fnnValidationMsg}}
                  </small>

                </ng-container>

              </div>
            </div>
            <hr />
            <div class="radio-btn-container" *ngIf="isSubProductSelected()">
              <label class="radio-btn-label">{{aemLabelAuthoring.label.linkRequirement}}
                <span class="inpt-reqrd-cls">*</span></label>
              <mat-radio-group role="radiogroup" class="smart-ui-radio-group" formControlName="linkRequirement"
                (change)="onLinkRequirementRadioChange($event)">
                <mat-radio-button role="radio" id="link-req-single"
                  [attr.aria-checked]="(productDetailsForm.value.linkRequirement === 'Single fibre') ? 'true' : 'false'"
                  (keyup.enter)="linkRequirementRadioFnc('Single fibre')"
                  class="col-2 smart-ui-button-group link-requirement-radio-btn"
                  value="{{aemLabelAuthoring.label.singleFibre}}">
                  <div class="mat-custom-label"><span
                      aria-label="Define your fibre requirement required Single fibre">{{aemLabelAuthoring.label.singleFibre}}</span>
                  </div>
                </mat-radio-button>
                <mat-radio-button *ngIf="!isSubProductNbnEe" role="radio" id="link-req-dual"
                  [attr.aria-checked]="(productDetailsForm.value.linkRequirement === 'Dual fibre (fibre pair)') ? 'true' : 'false'"
                  (keyup.enter)="linkRequirementRadioFnc('Dual fibre (fibre pair)')"
                  class="col-2 smart-ui-button-group link-requirement-radio-btn"
                  value="{{aemLabelAuthoring.label.dualFibre}}">
                  <div class="mat-custom-label"><span>{{aemLabelAuthoring.label.dualFibre}}</span></div>
                </mat-radio-button>
              </mat-radio-group>
              <!-- START: Commented as part of IIEO-4573 -->
              <!-- <ng-container *ngIf="serviceType === 'RG'">
                <div class="radio-btn-container quantity-service-input">
                  <label class="product-details-secondary-label">{{aemLabelAuthoring.label.quantityOfService}}
                    <span class="inpt-reqrd-cls">*</span></label>
                  <span class="max-limit-text">{{quantityOfServiceMaxLenght()}}</span>
                  <input type="tel" [min]="1" maxlength="4" oninput="this.value = this.value.replace(/^0+|[^0-9]/, '')"
                    formControlName="quantityOfService" placeholder="Enter quantity of service"
                    aria-label="Quantity of service required" (keypress)="numberOnly($event)" onpaste="return false"
                    [ngClass]="{'invalid-form-control': isFormValid()}" />
                  <span role="alert" *ngIf="isFormValid()" class="help-block errorMsg"><img class="error-icon"
                      src="../../../../assets/images/icon-error.svg" />{{isQuantityOfServiceControlValid()}}</span>
                </div>
              </ng-container> -->
              <!-- END: Commented as part of IIEO-4573 -->
            </div>
            <hr *ngIf="serviceType === 'RG'">
            <ng-container *ngIf="serviceType === 'RG'">
              <div class="radio-btn-container">
                <label class="radio-btn-label">{{aemLabelAuthoring.label.terms}} <span
                    class="inpt-reqrd-cls">*</span></label>
                <mat-radio-group role="radiogroup" class="smart-ui-radio-group terms-radio-group"
                  formControlName="terms">
                  <mat-radio-button role="radio" id="termsRadio1"
                    [attr.aria-checked]="(productDetailsForm.value.terms === 3) ? 'true' : 'false'"
                    (keydown.enter)="termsRadioFnc(3)" class="col-2 smart-ui-button-group terms-radio-btn" [value]="3">
                    <div class="mat-custom-label"><span aria-label="Terms required 3 years">3
                        {{aemLabelAuthoring.label.years}}</span></div>
                  </mat-radio-button>
                  <mat-radio-button role="radio" id="termsRadio2"
                    [attr.aria-checked]="(productDetailsForm.value.terms === 5) ? 'true' : 'false'"
                    (keydown.enter)="termsRadioFnc(5)" class="col-2 smart-ui-button-group terms-radio-btn" [value]="5">
                    <div class="mat-custom-label"><span>5 {{aemLabelAuthoring.label.years}}</span></div>
                  </mat-radio-button>
                  <mat-radio-button role="radio" id="termsRadio3"
                    [attr.aria-checked]="(productDetailsForm.value.terms === 10) ? 'true' : 'false'"
                    (keydown.enter)="termsRadioFnc(10)" class="col-2 smart-ui-button-group terms-radio-btn"
                    [value]="10">
                    <div class="mat-custom-label"><span>10 {{aemLabelAuthoring.label.years}}</span></div>
                  </mat-radio-button>
                  <mat-radio-button role="radio" id="termsRadio4"
                    [attr.aria-checked]="(productDetailsForm.value.terms === 35) ? 'true' : 'false'"
                    (keydown.enter)="termsRadioFnc(35)" class="col-2 smart-ui-button-group terms-radio-btn"
                    [value]="35">
                    <div class="mat-custom-label"><span>35 {{aemLabelAuthoring.label.years}}</span></div>
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="submitCancelBtn btnRow">
        <div class="save-cncl-btn-cls">
          <button mat-button (click)="cancel()" class="cancelBtn save-cancel-btn">
            <span class="cancel-btn"><i class="icon-icon-ui-cross"></i>{{ aemLabelAuthoring1?.button.cancel }}
            </span></button>
          <button *ngIf="isProductSelected" class="app-btn-green app-btn-white save-cancel-btn" (click)="saveQuote()">
            <div class="btn-svg save-icon"></div>
            {{ aemLabelAuthoring1?.button.save }}
          </button>
          <label role="alert" *ngIf="saveQuoteShow" style="position:absolute;left:-9999px">Successfully saved</label>
          <button mat-button class="app-btn-green save-cancel-btn"
            [disabled]="!productDetailsForm.valid || isFormValid()" (click)="navigateToStepTwo()">
            {{ aemLabelAuthoring1?.button.next }}
            <div class="btn-svg next-icon"></div>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

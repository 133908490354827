import { NgxUiLoaderConfig, PB_DIRECTION, SPINNER } from "ngx-ui-loader";


export const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    logoUrl: '../../assets/images/Logo.svg',
    pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
    fgsPosition: 'center-center',
    blur: 30,
    fastFadeOut: true,
    fgsColor: "#11883c",
    gap: -85,
    logoSize: 250,
    overlayBorderRadius: "0",
    overlayColor: '#f8f9facc',
    pbThickness: 2,
    hasProgressBar: false,
    text: 'Loading...',
    textColor: "#11883c",
    textPosition: "center-center",
    fgsType: SPINNER.threeStrings,
};


export const ngxUiLoaderHttpConfig = {
    showForeground: true,
    exclude: [
        window['environment'].apiPoint + 'infraco-addresses/autocomplete-address-search',
        window['environment'].apiPoint + 'darkfibre/bulk-quote-requests/upload-csv/',
        window['environment'].apiPoint + 'inapp-notification/event-notifications',
        window['environment'].apiPoint + 'document-management/documents'
    ]
}

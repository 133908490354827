import { Component, EventEmitter, HostListener,OnInit, Output,Renderer2,ElementRef} from '@angular/core';
import { HomePageService } from '../shared/services/homePage.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { Router } from '@angular/router';
import { AuthService } from 'tw-core-ui';
import { CreateQuoteService } from '../quote-management/create-new-quote/create-quote.service';

@Component({
  selector: 'app-fibre-dropdown-menu',
  templateUrl: './fibre-dropdown-menu.component.html',
  styleUrls: ['./fibre-dropdown-menu.component.less']
})
export class FibreDropdownMenuComponent implements OnInit {
  isSubMenuOpen: boolean = false;
  customerDetails: any;
  @Output() closeDropdownMenu = new EventEmitter<void>();
  @Output() showDropdown = new EventEmitter<boolean>();
  private isMenuOpen: boolean = false;
  cidnSelected: string;
  infraCoCIDN = window['environment'].InfraCoCIDN;
  mainMenu: any[] = [];

  subMenu: any[] = [];
  selectedIndex: number;
  quotesSubMenu: any[] = [
    {
      title: 'New single quote',
      url: '/product-details'
    },
    {
      title: 'New bulk quote',
      url: '/bulk-quote-request-details'
    },
    {
      title: 'My quotes',
      url: '/myQuotes'
    },
  ];
  constructor(
    private homepageservice: HomePageService,
    private commonService: CommonService,
    private createQuoteService: CreateQuoteService,
    private router: Router,
    private authService: AuthService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.getCommonServices();
    // if(this.commonService.selectedCidn){
    //   this.cidnSelected = this.commonService.selectedCidn;
    // } else {
    //   this.getCommonServices();
    // }
    // this.cidnSelected = this.commonService.selectedCidn;
    // this.cidnSelected = '9999999998'
  }

  ngOnInit(): void {
    // this.getFibreMenu();
    window.onkeyup = function (event) {
      if (event.keyCode == 27) {
        (<HTMLElement>document.getElementsByClassName('drpdwn-menu')[0]).style.display = 'none';
        (<HTMLElement>document.getElementsByClassName('duct-access-dropdown-menu-container')[0]).style.display = 'none';
      }
    }
  }

  getCommonServices() {
    this.commonService.getDefaultCustomer().pipe().subscribe(resp => {
      if (resp) {
        this.setCustomerDropdown(resp);
      }
    });
  }

  setCustomerDropdown = (selectedHeaderValue?: string) => {
    const userName = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'];
    const profileDetails = this.homepageservice.userProfileDetails;
    if (profileDetails) {
      const custDetails = profileDetails?.data?.customerDetails;
      if (selectedHeaderValue) {
        const findCIDNobject = this.searchCidn(custDetails, selectedHeaderValue)
        this.cidnSelected = findCIDNobject?.cidn;
        this.getFibreMenu();
      }
    }
    // this.homepageservice
    //   .getUserProfile(userName)
    //   .then((response) => {
    //     const custDetails = response?.data?.customerDetails;
    //     if (selectedHeaderValue) {
    //       const findCIDNobject = this.searchCidn(custDetails, selectedHeaderValue)
    //       this.cidnSelected = findCIDNobject?.cidn;
    //       this.getFibreMenu();
    //     }
    //   })
  }

  navigatebh(title: string, event: KeyboardEvent): void {
    if (title.toLowerCase() === 'quotes'|| title.toLocaleLowerCase() === 'orders'
  ) {
      if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
        const firstSpanElement = this.el.nativeElement.querySelector('.sub-menu-list');
        if (firstSpanElement) {

          this.renderer.selectRootElement(firstSpanElement).focus();

          // this.renderer.selectRootElement(firstSpanElement).setAttribute('color','#11883c');
          event.preventDefault(); // Prevent scrolling or other default behavior
        }
      }


    }
  }

   @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // Check if the pressed key is "Esc"
    if (event.key === 'Escape' || event.key === 'Esc') {
      // Add your logic to close or dismiss the dropdown here
      this.isSubMenuOpen = false; // Example: Closing the dropdown
      this.closeDropdownMenu.emit();
    }
  }


  searchCidn(myArray, nameKey) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].customerName === nameKey) {
        return myArray[i];
      }
    }
  }

  getFibreMenu() {
    const roles = this.commonService.getroleDetails().customerList;
    const isUserMgmtRole = roles.some((obj) => (obj?.cidn === this.infraCoCIDN && this.cidnSelected === this.infraCoCIDN && obj?.role === 'Administrator' && obj?.function === 'User Management'));
    const isQuoteMgmtRole = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.function === 'Quote Request Management'));
    const isOrderMgmtRole = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.function === 'Order Management'));

    if (isQuoteMgmtRole) {
      this.mainMenu.push({
        title: 'Quotes',
        url: '',
        icon: '../../assets/images/Quote-Nav.svg'
      });
    }
    if (isOrderMgmtRole) {
      this.mainMenu.push({
        title: 'Orders',
        url: '',
        icon: '../../assets/images/Order-Menu-Icon.svg'
      });
    }
    if (isUserMgmtRole) {
      this.mainMenu.push({
        title: 'Domain Whitelist',
        url: '/whitelist',
        icon: '../../assets/images/domain-whitelist-icon.png'
      });

    }
    if (this.mainMenu.length === 0) {
      this.showDropdown.emit(false);
      this.mainMenu = [];
    } else {
      this.showDropdown.emit(true);
    }
  }

  showSubMenu(menu, index) {
    this.subMenu = [];
    this.isSubMenuOpen = true;
    this.selectedIndex = index;
    const roles = this.commonService.getroleDetails().customerList;
    const isReadWriteOnQuoteMgmt = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.role === 'Read & Write' && obj?.function === 'Quote Request Management'));
    const isReadOnlyRole = roles.some((obj) => (obj?.role === 'Read Only' && obj?.function === 'Quote Request Management'));
    if (menu === 'Quotes') {
      if (isReadWriteOnQuoteMgmt && (this.cidnSelected !== this.infraCoCIDN)) {
        // this.subMenu = this.quotesSubMenu;
         this.subMenu.push(
       {
       title: 'New single quote',
       url: '/product-details'
       },
       {
        title: 'New bulk quote',
        url: '/bulk-quote-request-details'
        },
       {
       title: 'My quotes',
       url: '/myQuotes'
       }
      )
      }  else if (isReadOnlyRole || (this.cidnSelected === this.infraCoCIDN)) {
        this.subMenu.push(
          {
            title: 'My quotes',
            url: '/myQuotes'
          })
      }
    }
    const isReadWriteOnOrderMgmt = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.role === 'Read & Write' && obj?.function === 'Order Management'));
    const isReadRoleOrderMgmt = roles.some((obj) => (obj?.role === 'Read Only' && obj?.function === 'Order Management'));
    if (menu === 'Orders') {
      if (isReadWriteOnOrderMgmt || isReadRoleOrderMgmt || (this.cidnSelected === this.infraCoCIDN)) {
        this.subMenu.push(
          {
            title: 'My orders',
            url: '/myOrders'
          })
      }
    }
  }

  closeMenu(subMenu) {
    if (subMenu === 'My quotes'){
      this.router.navigateByUrl('/myQuotes')
    }
    if (subMenu === 'My orders'){
      this.router.navigateByUrl('/myOrders')
    }
    if (subMenu === 'New bulk quote') {
      this.router.navigateByUrl('/bulk-quote-request-details')     
    }
    if (subMenu === 'New single quote') {
      this.createQuoteService.setProductDetailsFormValues(null);
      const navigationExtras = {
        state: {
          formData: null
        }
      }
      this.createQuoteService.isModifyExistingQuote(false);
      this.router.navigateByUrl('/product-details', navigationExtras);
      this.createQuoteService.isNewQuoteClicked(true);
    } else {
      this.createQuoteService.isNewQuoteClicked(false);
    }
    this.closeDropdownMenu.emit();
  }
  navigate(title) {
    if (title === 'Domain Whitelist') {
      this.router.navigate(['/whitelist']);
    }
  }

  mouseLeaveEvent = () => {
    this.closeDropdownMenu.emit();
  }

  handleKeyDown(event: KeyboardEvent, title: string): void {
    // Check if the key pressed is Enter (key code 13)
    if (event.key === 'Enter') {
      this.closeMenu(title);
    }
  }

  collapse(title, event) {

    // event.preventDefault();

    if (title === 'My quotes') {
      if (event.key === 'Tab' && !event.shiftKey) {
        this.closeDropdownMenu.emit();

      }
    }
  }

}

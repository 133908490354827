<div class="container-fluid">
  <div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div>
  <div class="row" *ngIf="aemLabelAuthoring1">
    <!-- <label style="position:absolute;left:-9999px" aria-live="assertive" role="alert">Site details page</label> -->
    <div class="row">

      <div class="col-3 page-header-container">
        <main aria-labelledby="siteDetailsHeader">
        <h1 id="siteDetailsHeader" class="page-heading" *ngIf="!isModifyQuote"> {{ aemLabelAuthoring1.pageHeader.pageHeaderLabel }} </h1>
        <span class="page-heading" *ngIf="isModifyQuote"> {{ aemLabelAuthoring1.pageHeader.modifyQuotePageHeader }} </span>
      </main>
        <div class="quote-ref-text">
          {{ aemLabelAuthoring1.pageHeader.pageSubHeaderLabel }} <strong>{{quoteRefId}} </strong>
          <span *ngIf="isQuoteSaved" class="save-lbl"> <img src="../../../assets/images/Submitted.svg" class="saved-icon" /><strong> {{
              aemLabelAuthoring1.pageHeader.saved }}</strong></span>
        </div>

      </div>

      <div class="col">
        <app-page-stepper [stepperData]="stepperData" [stepName]="stepName"></app-page-stepper>
      </div>
      <div class="col w-100 d-flex j-right align-items-center">
        <div class="p-r-30 font-18">Fields marked with <span class="star">*</span> are mandatory</div>
      </div>
    </div>
  </div>
  <div class="main-content">
    <app-tabs [aemLabelAuthoring]="aemLabelAuthoring" [savedData]="createQuotePayload" (TabChanged)="onTabChange($event)"></app-tabs>

     <div *ngIf="(serviceType === 'RG') && isContactFormEligible()">
      <app-contact-details [aemLabelAuthoring]="aemLabelAuthoring" [savedData]="createQuotePayload" *ngIf="!tabChanged"></app-contact-details>
    </div>

    <!-- {{contactDetailsForm | json}} -->

        <!-- {{ searchFormAEndAdress | json}}
    ---
    {{ latLongFormZEndAdress | json}}
    ---
    {{ isContactDetailsFormValid}} -->

    <div class="row">
      <div class="col-12 submitCancelBtn btnRow">
        <div class="save-cncl-btn-cls">
          <button mat-button (click)="navigateBack()" class="back-btn cancelBtn save-cancel-btn">
            <span class="cancel-btn">
              <div class="btn-svg back-icon"></div>
              {{ aemLabelAuthoring1?.button.back }}
            </span>
          </button>
          <button mat-button (click)="cancel()" class="cancelBtn save-cancel-btn">
            <span class="cancel-btn"><i class="icon-icon-ui-cross"></i>{{ aemLabelAuthoring1?.button.cancel }}
            </span>
          </button>
          <button [disabled]="!isSaveEnabled" class="app-btn-green app-btn-white save-cancel-btn" (click)="saveQuote()">
            <div class="btn-svg save-icon"></div>
            {{ aemLabelAuthoring1?.button.save }}
          </button>
           <label role="alert" *ngIf="saveQuoteShow" style="position:absolute;left:-9999px">Successfully saved</label>
          <button mat-button class="app-btn-green save-cancel-btn"
          [disabled]="!isContactDetailsFormValid || !isContactFormEligible()"
          (click)="navigateToStepThree()">
            {{ aemLabelAuthoring1?.button.next }}
            <div class="btn-svg next-icon"></div>
          </button>

        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, HostListener, ViewChild, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { forkJoin, Subscription } from 'rxjs';
import * as moment_ from 'moment-timezone';
import { CheckboxFilterComponent } from 'src/app/grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomDateFilterComponent } from 'src/app/grid-filter/custom-date-filter/custom-date-filter.component';
import { CustomTextFilterComponent } from 'src/app/grid-filter/custom-text-filter/custom-text-filter.component';
import { CommonService } from 'src/app/shared/services/common.service';
//import { myOrdersService } from './myquotes.service';

//import { CreateQuoteService } from '../create-new-quote/create-quote.service';
import { CustomerService } from 'src/app/admin-settings/customer-list/customer.service';
import { HomePageService } from 'src/app/shared/services/homePage.service';
import { AuthService } from 'tw-core-ui';
import { TitleCasePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { WorklistStatusRendererComponent } from 'src/app/quote-management/my-quotes/worklist-status-renderer/worklist-status-renderer.component';
import { MyOrdersService } from '../my-orders.service';
import { CreateQuoteService } from 'src/app/quote-management/create-new-quote/create-quote.service';
import { myQuotesService } from 'src/app/quote-management/my-quotes/myquotes.service';
import { MapsService } from 'src/app/maps.service';


@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./../../quote-management/my-quotes/my-quotes.component.less', './my-orders.component.less']
})

export class MyOrdersComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  public currentlyCheckedCount = 0;
  showFilterPop: boolean = false;
  myLinkedCustomerList: any;
  currentMoment = moment_;
  show: boolean = false;
  moment = moment_;
  placeholderFontSize = 8;
  colDefsAthleteEXcluded: {
  }[];
  currentSelected: { field: String; colId: string; tooltipField: string; filter: string; headerName: string; cellRendererFramework: any, fullHeaderName: string; customFilter: any, width: any, minWidth: any, maxWidth: any };
  checkedList: any[];
  public headerSubs: Subscription;
  isShowAwait: boolean = false;
  stopSelect: boolean = false;
  functionList: any;
  public searchvalue;
  public gridConfig: GridOptions;
  public businessList = [];
  public rowData: any;
  public columnDefs: any;
  focusableElement: HTMLElement;
  // infraCoCIDN = '9999999999';
  infraCoCIDN = window['environment'].InfraCoCIDN;
  public gridInitialized: Boolean = false;
  public aemLabelAuthoring: any;
  subscription: any;
  subscribedflag: Boolean = false;
  public options: GlobalConfig;
  public gridModel: any = {
    // CustomAgGrid = {
    gridOptions: {},
    keysToSuppress: [13], // Supress Enter key row selection
    labelAuthoring: {
      headerPageCount: '',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: '',
      noDataOnSearch: '',
      toTop: ''
    }
  };
  public filteredRowData: any = [];
  timeoutId: any;
  quoteStatus: number;
  columnHeaderId = [];
  cidnSelected: string;
  columnsPresent: boolean = true;
  companyName: any;
  linkedCIDNs: any;
  isReadWriteOnQuoteMgmt: boolean = false;
  isReadWriteOnOrderManagement: boolean = false;
  cidn: any;
  disableDwn: Boolean = false;
  selectedRows: any[] = [];
  userName: any;
  isReadOnlyRole: boolean = false;
  userTimeZone: string;
  isReadOnlyRoleOrder: boolean = false;

  constructor(private dataSvc: myQuotesService, private myOrderService: MyOrdersService, private router: Router, private commonService: CommonService,
    private customerService: CustomerService, private homepageservice: HomePageService, private createQuoteService: CreateQuoteService,
    private toastr: ToastrService, private mapService: MapsService, private authService: AuthService, @Inject(LOCALE_ID) private locale: string, public titleCasePipe: TitleCasePipe, private titleService: Title) {

    // Get Google maps key from API before going to order details page
    this.mapService.fetchGoogleMapsApiKey();

    this.titleService.setTitle('InfraCo Customer Portal - MyOrders page');

    this.getUserTimeZone();
    //  this.cidnSelected = '9999999998';
    if (localStorage.getItem('selectedHeaderCIDN')) {
      this.cidnSelected = localStorage.getItem('selectedHeaderCIDN')
    } else {
      this.getCommonServices();
    }
    this.options = this.toastr.toastrConfig;
    this.checkedList = [];
    //this.createGrid(null); //initialization call
    // this.cidnSelected = this.commonService.selectedCidn;


    // code for header change in myquotes
    this.customerService.getLinkedCIDNs().then(res => {
      if (res && res.data && res.data.businessNameDetails && res.data.businessNameDetails.length > 0) {
        // let businessList = [];
        const iteratedArr = res.data.businessNameDetails.map(({
          bussinessName,
          cidn
        }) => [bussinessName, cidn]);

        for (var i = 0; i < iteratedArr.length; i++) {
          const businessName = iteratedArr[i][0];
          const cidn = iteratedArr[i][1];
          if (businessName || cidn) {
            this.businessList.push({
              businessName: businessName,
              cidn: cidn
            });

          }
        }

      }
      else {
        this.userName = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail']
        this.homepageservice
          .getUserProfile(this.userName)
          .then((response) => {
            this.myLinkedCustomerList = response?.data?.customerDetails;
            if (this.myLinkedCustomerList) {
              this.myLinkedCustomerList.forEach((option) => {
                this.businessList.push({
                  businessName: option.customerName,
                  cidn: option.cidn
                });
              });
            }
          });
      }

      this.checkDefaultcustomerAndSetRoles();

    }).catch((err) => {
      console.log(err);
    });
  }

  checkDefaultcustomerAndSetRoles() {
    if (this.commonService.getDefaultCustomer()) {
      this.headerSubs = this.commonService.getDefaultCustomer().pipe().subscribe(resp => {
        this.createGrid(null);
        this.businessList.forEach(item => {

          if (item.businessName === resp) {
            this.cidnSelected = item.cidn;
            localStorage.setItem('selectedHeaderCIDN', this.cidnSelected)
            const roles = this.commonService.getroleDetails().customerList;
            this.isReadOnlyRoleOrder = false;
            this.isReadWriteOnOrderManagement = false;
            this.selectedRows = [];  
            roles.map((r) => {
              if (r.cidn === this.cidnSelected && r.role.includes('Read & Write') && r.function.includes('Quote Request Management')) {
                this.isReadWriteOnQuoteMgmt = true;
                return;
              }
              if (r.cidn === this.cidnSelected && r.role.includes('Read & Write') && r.function.includes('Order Management')) {
                this.isReadWriteOnOrderManagement = true;
                return;
              }
              if (r.cidn === this.cidnSelected && r.role.includes('Read Only') && r.function.includes('Quote Request Management')) {
                this.isReadOnlyRole = true;
                return;
              }
              if (r.cidn === this.cidnSelected && r.role.includes('Read Only') && r.function.includes('Order Management')) {
                this.isReadOnlyRoleOrder = true;
                return;
              }
            });
            // const isReadWriteOnQuoteMgmt = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.role === 'Read & Write' && obj?.function === 'Quote Request Management'));
            // const isReadOnlyRole = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.role === 'Read Only' && obj?.function === 'Quote Request Management'));
            if (this.isReadWriteOnOrderManagement || this.isReadOnlyRoleOrder) {
              this.gridInitialized = false;
              this.createGrid(null);
              this.checkedList = [];
            } else {
              this.router.navigate(['']);
            }

          }

        })

      })
    }
  }


  getUserTimeZone() {
    const userTimeZone = Intl.DateTimeFormat(this.locale, {
      timeZoneName: 'long'
    }).resolvedOptions().timeZone;

    this.userTimeZone = userTimeZone;
  }

  ngOnInit(): void {
    this.commonService.showTopButtonInFooter(true);
    const roles = this.commonService.getroleDetails().customerList;
    if (this.cidnSelected && roles.some((obj) => obj.role.includes('Read & Write') && obj.function.includes('Quote Request Management'))) {
      roles.map((r) => {
        if (r.cidn === this.cidnSelected && r.role.includes('Read & Write') && r.function.includes('Quote Request Management')) {
          this.isReadWriteOnQuoteMgmt = true;
          return;
        }
      });
    }
    if (this.cidnSelected && roles.some((obj) => obj.role.includes('Read & Write') && obj.function.includes('Order Management'))) {
      roles.map((r) => {
        if (r.cidn === this.cidnSelected && r.role.includes('Read & Write') && r.function.includes('Order Management')) {
          this.isReadWriteOnOrderManagement = true;
          return;
        }
      });
    }
    this.getLabels();
    // this.deleteCustomerNameIfNotInfraco();
    this.customerService.getLinkedCIDNs().then(res => {
      this.linkedCIDNs = res.data.businessNameDetails;
      this.checkCompanyName();
    }).catch((err) => {
    });
  }

  filterOnQuoteRefId(quoteRefId) {
    let dashboardCardFilter = {
      orderReferenceId: quoteRefId
    };
    setTimeout(() => {
      if (this.rowData && this.rowData.length && dashboardCardFilter && dashboardCardFilter.orderReferenceId) {
        var filterComponent = this.gridModel.gridOptions.api.getFilterInstance('aaa');
        if (filterComponent) {
          filterComponent.setModel({ value: dashboardCardFilter.orderReferenceId });
        }
        this.gridModel.gridOptions.api.onFilterChanged();
      }
    }, 800);
  }

  getCommonServices() {
    this.commonService.getDefaultCustomer().pipe().subscribe(resp => {
      if (resp) {
        this.setCustomerDropdown(resp);
      }
    });
  }

  setCustomerDropdown = (selectedHeaderValue?: string) => {
    const profileDetails = this.homepageservice.userProfileDetails;
    if (profileDetails) {
      const custDetails = profileDetails?.data?.customerDetails;
      if (selectedHeaderValue) {
        const findCIDNobject = this.searchCidn(custDetails, selectedHeaderValue)
        this.cidnSelected = findCIDNobject?.cidn;
        localStorage.setItem('selectedHeaderCIDN', this.cidnSelected)
      }
    }
  }

  searchCidn(myArray, nameKey) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].customerName === nameKey) {
        return myArray[i];
      }
    }
  }

  customDateSorter(date1: string, date2: string): number {
    if (!date1 && !date2) {
      return 0; // Treat both empty dates as equal
    }
    if (!date1) {
      return -1; // Treat empty date1 as smaller (earlier) for descending order
    }
    if (!date2) {
      return 1; // Treat empty date2 as greater (latest) for descending order
    }

    const dateObj1 = this.parseCustomDate(date1);
    const dateObj2 = this.parseCustomDate(date2);

    if (!isNaN(dateObj1) && !isNaN(dateObj2)) {
      // If both dates are valid, perform the comparison in descending order
      return dateObj2 - dateObj1;
    } else if (!isNaN(dateObj1) && isNaN(dateObj2)) {
      return 1; // date1 is valid, date2 is empty, so date1 should come after date2
    } else if (isNaN(dateObj1) && !isNaN(dateObj2)) {
      return -1; // date1 is empty, date2 is valid, so date2 should come before date1
    } else {
      return 0; // Both dates are empty or invalid
    }
  }

  parseCustomDate(dateString: string): number {
    if (!dateString) {
      return Number.MAX_SAFE_INTEGER; // Return a very large value for empty dates
    }

    const parts = dateString.split(' ');
    const day = parseInt(parts[0], 10);
    const month = this.getMonthNumber(parts[1]);
    const year = parseInt(parts[2], 10);
    const timeParts = parts[3]?.split(':');
    const hour = timeParts ? parseInt(timeParts[0], 10) : 0;
    const minute = timeParts ? parseInt(timeParts[1], 10) : 0;


    return -Date.UTC(year, month, day, hour, minute); // Negative sign for descending order
  }

  getMonthNumber(monthAbbreviation: string): number {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return months.indexOf(monthAbbreviation);
  }

  checkCompanyName() {
    this.linkedCIDNs.forEach(item => {
      if (item.cidn === this.cidnSelected) {
        this.companyName = item.bussinessName;
      }
    })

  }

  // deleteCustomerNameIfNotInfraco() {
  //   if (this.cidnSelected !== this.infraCoCIDN) {
  //     const index = this.functionList.findIndex((func) => func.name === 'Customer name');
  //     if (index !== -1) {
  //       this.functionList.splice(index, 1);
  //     }
  //   }
  //   else {
  //     return this.functionList;
  //   }
  // }
  showPop() {
    this.showFilterPop = true;
    setTimeout(() => {
      document.getElementById('colPrefTabUsr').style.display = 'block';
      this.focusableElement = document.getElementById('fir');
      this.focusableElement.focus();
    }, 1);
    this.commonService.trapFocusInModal('fir', 'rmv');
    // this.deleteCustomerNameIfNotInfraco();
  }

  closePopup() {
    this.showFilterPop = false;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // Check if the pressed key is "Esc"
    if (event.key === 'Escape' || event.key === 'Esc') {
      // Add your logic to close or dismiss the dropdown here
      this.showFilterPop = false; // Example: Closing the dropdown
    }
  }

  getLabels() {
    return new Promise((resolve, reject) => {
      this.myOrderService.getOrderLabels().then(() => {
        this.aemLabelAuthoring = this.myOrderService.orderLabelDataObj;
        this.gridModel.labelAuthoring = this.aemLabelAuthoring.form.grid;
        // this.successMsg();
        resolve(true);
      }).catch(() => {
        // reject(false);
      });
    });
  }

  createGrid(payload: any) {
    if (this.subscribedflag) {
      this.subscription.unsubscribe();
    } else {
      this.subscribedflag = true;
    }
    const promise1 = this.createColumnDefs();
    const promise2 = this.createRowData(payload);
    this.subscription = forkJoin([promise1, promise2]).subscribe(result => {
      this.createGridConfig();
    }, error => {
      this.createGridConfig();
      // this.router.navigateByUrl('/error');
    });
  }

  private createGridConfig() {
    this.gridConfig = <GridOptions>{};
    this.gridConfig.rowData = this.rowData;
    this.gridConfig.paginationPageSize = 10;
    this.gridConfig.columnDefs = this.columnDefs;
    this.gridConfig.suppressHorizontalScroll = true;
    this.gridConfig.pagination = true;
    this.gridConfig.rowSelection = 'single';
    this.gridInitialized = true;
    this.gridConfig.frameworkComponents = {
      CheckboxFilterComponent: <any>CheckboxFilterComponent,
      customTextFilter: <any>CustomTextFilterComponent,
      customDateFilter: <any>CustomDateFilterComponent
      //  FullwidthRowRenderer: <any>GridTilesCustomerViewComponent
    };
    this.gridModel.gridOptions = this.gridConfig;
    if (this.gridModel.gridOptions.api) {
      this.gridModel.gridOptions.api.sizeColumnsToFit();
    }
  }

  private createColumnDefs() {
    return new Promise((resolve, reject) => {
      this.myOrderService.getAllOrderGridHeader().then((res: any) => {
        this.functionList = res?.headers;
        // this.functionList.forEach((x: any) => {

        // });
      }).catch((err) => {
        console.log(err);
      });
      this.myOrderService.getGridHeader().then((res: any) => {
        this.columnDefs = res?.headers;
        this.columnDefs.forEach((x: any) => {
          this.columnHeaderId.push(x.field);
          if (x.cellRendererFramework) {
            if (x.headerName === 'Status') {
              x.cellRendererFramework = WorklistStatusRendererComponent
            }
          }
          if (x.field !== 'orderStatus' && x.field !== 'orderReferenceId' && x.field !== 'customerReference' && x.field !== 'serviceId' && x.field !== 'aEndAddress' && x.field !== 'zEndAddress' && x.field !== 'aendAddressSiteAlternativeName' && x.field !== 'zendAddressSiteAlternativeName') {
            x.getQuickFilterText = function () {
              return '';
            }
          }
          if (x.field === 'ourCommitmentDate' || x.field === 'lastModifiedDate' || x.field === 'orderSubmittedDate') {
            x.comparator = (date1: string, date2: string) => this.customDateSorter(date1, date2)
          }
        });
        if (this.cidnSelected !== this.infraCoCIDN) {
          const index = this.columnDefs.findIndex(ele => ele.field == 'customerName');
          const index2 = this.functionList.findIndex(ele => ele.field == 'customerName');
          if (index !== -1) {
            this.columnDefs[index].hide = true;
          }
          if (index2 !== -1) {
            this.functionList.splice(0, 1);
          }
        } else {
          const customerNames = {
            headerName: "Customer name",
            fullHeaderName: "Customer name",
            filter: "customTextFilter",
            field: "customerName",
            colId: "aa",
            tooltipField: "customerName",
            minWidth: 155,
            getQuickFilterText: params => {
              return '';
            }
          };
          // Check if the column is already present
          const isCustomerNameAdded = this.columnDefs.some(col => col.field === 'customerName');
          // Add the column only if it's not already present
          if (!isCustomerNameAdded) {
            this.columnDefs.splice(1, 0, customerNames); // Add as the second element (index 1)
          }
        }
        this.columnDefs.forEach((x: any) => {
          this.columnHeaderId.push(x.field);
        });
        if (this.functionList?.length) {
          this.functionList.map(f => {
            if (this.columnHeaderId.includes(f.value)) {
              f.checked = true;
            }
          });
        }
        this.checkedList = this.deepCopy(this.columnDefs);
        resolve(true);
      }).catch((err) => {
        console.log(err);
        reject(err);
      });
    });
  }
  deepCopy(element) {
    return JSON.parse(JSON.stringify(element));
  }

  downloadSummary() {
    const data = [];
    const msg = 'Please wait, preparing your download.'
    this.showToasterMessage(msg, '', this.options, 'success');
    const dataHeaders = ['Quote reference', 'Customer reference', 'Product', 'Sub-product', 'Order type', 'Status', 'Service ID', 
      'Site A end address', 'Site A alternative name','Site A end site type','Site A end data centre operator','Site A end data centre site name',
      'Site Z end address', 'Site Z alternative name','Site Z end site type','Site Z end data centre operator','Site Z end data centre site name', 
      'Provisional date range', 'Our commitment date', 'Last updated', 'Submitted date', 'Requestor', 'Requestor channel'];

    if (this.cidnSelected === this.infraCoCIDN) {
      dataHeaders.unshift('Customer name'); // Add 'Customer name' at the beginning of headers
    }

    let dataToBeDownloaded = [];

    if (this.selectedRows.length) {
      dataToBeDownloaded = this.selectedRows;
    } else {
      if (this.filteredRowData && this.filteredRowData.length > 0) {
        dataToBeDownloaded = this.filteredRowData;
      } else {
        if (this.rowData && this.rowData.length) {
          dataToBeDownloaded = this.rowData;
        }
      }
    }

    dataToBeDownloaded.forEach((selectedRow) => {
      const rowData = {};

      if (this.cidnSelected === this.infraCoCIDN) {
        rowData['Customer name'] = selectedRow.customerName ? selectedRow.customerName : '';
      }

      rowData['Quote reference'] = selectedRow.orderReferenceId ? selectedRow.orderReferenceId : '';
      rowData['Customer reference'] = selectedRow.customerReference ? selectedRow.customerReference : '';
      rowData['Product'] = selectedRow.productName ? selectedRow.productName : '';
      rowData['Sub-product'] = selectedRow.subProduct ? selectedRow.subProduct : '';
      rowData['Order type'] = selectedRow.orderType ? selectedRow.orderType : '';
      rowData['Status'] = selectedRow.orderStatus ? selectedRow.orderStatus : '';
      rowData['Service ID'] = selectedRow.serviceId ? selectedRow.serviceId : '';
      rowData['Site A end address'] = selectedRow.aEndAddress ? selectedRow.aEndAddress : '';
      rowData['Site A alternative name'] = selectedRow.aendAddressSiteAlternativeName ? selectedRow.aendAddressSiteAlternativeName : '';
      rowData['Site A end site type'] = selectedRow.aEndAddressSiteDeliveryType ? selectedRow.aEndAddressSiteDeliveryType : '';
      rowData['Site A end data centre operator'] = selectedRow.aEndAddressDCOperator ? selectedRow.aEndAddressDCOperator : '';
      rowData['Site A end data centre site name'] = selectedRow.aEndAddressDCSiteName ? selectedRow.aEndAddressDCSiteName : '';   
      rowData['Site Z end address'] = selectedRow.zEndAddress ? selectedRow.zEndAddress : '';
      rowData['Site Z alternative name'] = selectedRow.zendAddressSiteAlternativeName ? selectedRow.zendAddressSiteAlternativeName : '';
      rowData['Site Z end site type'] = selectedRow.zEndAddressSiteDeliveryType ? selectedRow.zEndAddressSiteDeliveryType : '';
      rowData['Site Z end data centre operator'] = selectedRow.zEndAddressDCOperator ? selectedRow.zEndAddressDCOperator : '';
      rowData['Site Z end data centre site name'] = selectedRow.zEndAddressDCSiteName ? selectedRow.zEndAddressDCSiteName : '';
      rowData['Provisional date range'] = selectedRow.provisionalDateRange ? selectedRow.provisionalDateRange : '';
      rowData['Our commitment date'] = selectedRow.ourCommitmentDate ? selectedRow.ourCommitmentDate : '';
      rowData['Last updated'] = selectedRow.lastModifiedDate ? selectedRow.lastModifiedDate : '';
      rowData['Submitted date'] = selectedRow.orderSubmittedDate ? selectedRow.orderSubmittedDate : '';
      rowData['Requestor'] = selectedRow.requestorUser ? selectedRow.requestorUser : '';
      rowData['Requestor channel'] = selectedRow.requestorChannel ? selectedRow.requestorChannel : '';

      data.push(rowData);
    });
    if(sessionStorage.getItem('setAllPageheaderVal')) {
      this.companyName = sessionStorage.getItem('setAllPageheaderVal');
    }
    const now = this.currentMoment().format('DDMMYYYYHHmmss');
    const timeInFileName = this.currentMoment().format('DDMMYYYYHHmm').toString();
    let fileName = 'InfraCoOrdersSummary-' + (this.companyName ? this.companyName : sessionStorage.getItem('defaultCustomerValue')) + '-' + timeInFileName;

    return new Angular5Csv(data, fileName, { headers: dataHeaders });
  }
  onCheckboxKeydown(event: KeyboardEvent, item: any) {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault(); // Prevent scrolling
      item.checked = !item.checked; // Toggle checkbox state
      this.getSelectedValue(
        item.checked,
        item.value,
        item.colId,
        item.tooltipField,
        item.filter,
        item.headerName,
        item.fullHeaderName,
        item.customFilter,
        item.cellRendererFramework
      );
    }
  }

  getSelectedValue(status: Boolean, value: String, colId: string, tooltipField: string, filter: string, headerName: string, fullHeaderName: string, customFilter: any, cellRendererFramework: any) {
    let index = 0;
    if (status) {
      if (headerName === 'Status') {
        this.currentSelected = { field: value, colId: colId, tooltipField: tooltipField, filter: filter, headerName: headerName, fullHeaderName: fullHeaderName, customFilter: customFilter, width: 250, minWidth: 250, maxWidth: 300, cellRendererFramework: WorklistStatusRendererComponent }
      }
      else {
        this.currentSelected = { field: value, colId: colId, tooltipField: tooltipField, filter: filter, headerName: headerName, fullHeaderName: fullHeaderName, customFilter: customFilter, cellRendererFramework: cellRendererFramework, width: 150, minWidth: 150, maxWidth: 300 };
      }
      this.checkedList.push(this.currentSelected);
    } else {
      const index = this.checkedList.findIndex(obj => obj.field === value); // Find the index of the object with id=2
      if (index !== -1) {
        this.checkedList.splice(index, 1); // Remove the object at the found index
      }
    }
    this.checkedList.sort((a, b) => a.colId.localeCompare(b.colId))
    this.checkedList.map(c => {
      if (c.headerName === 'Status') {
        c.cellRendererFramework = WorklistStatusRendererComponent
      }
      if (c.headerName === 'Last updated') {
        c.comparator = (date1: string, date2: string) => this.customDateSorter(date1, date2),
          c.getQuickFilterText = function () {
            return '';
          }
      }

      if (c.headerName === 'Submitted date') {
        c.sortable = true;
        c.comparator = (date1: string, date2: string) => this.customDateSorter(date1, date2),
          c.getQuickFilterText = function (params) {
            return '';
          }
      }

      if (c.headerName === 'Our commitment date') {
        c.sortable = true;
        c.comparator = (date1: string, date2: string) => this.customDateSorter(date1, date2),
          c.getQuickFilterText = function (params) {
            return '';
          }
      }

      if (c.field !== 'orderStatus' && c.field !== 'orderReferenceId' && c.field !== 'customerReference' && c.field !== 'serviceId' && c.field !== 'aEndAddress' && c.field !== 'zEndAddress' && c.field !== 'aendAddressSiteAlternativeName' && c.field !== 'zendAddressSiteAlternativeName') {
        c.getQuickFilterText = function (params) {
          return '';
        }
      }

    })
    if (this.checkedList.length === 0) {
      this.columnsPresent = false;
    } else {
      this.columnsPresent = true;
    }
    setTimeout(() => {
      if (this.gridModel.gridOptions.api) {
        this.gridModel.gridOptions.api.setColumnDefs([]);
        this.gridModel.gridOptions.api.setColumnDefs(this.checkedList);
        this.gridModel.gridOptions.api.sizeColumnsToFit();
      }
      setTimeout(() => {
        this.gridModel.gridOptions.api.setQuickFilter('');
        this.gridModel.gridOptions.api.setQuickFilter(this.searchvalue);
      }, 0);
    }, 500);
  }
  onInputChange() {
    if (!this.searchvalue || this.searchvalue.trim() === '') {
      this.gridModel.gridOptions.api.setQuickFilter('');
    }
  }

  reset() {
    this.gridModel.gridOptions.api.setQuickFilter('');
    this.show = false;
  }

  private createRowData(params?: any) {
    const param = params || null;
    this.rowData = [];
    return new Promise((resolve, reject) => {
      // const cidn = localStorage.getItem('selectedCidn');
      //  const cidn = '9999999999';
      // this.cidnSelected = this.commonService.selectedCidn;
      this.myOrderService.getOrderList(this.cidnSelected, this.userTimeZone).then((res) => {
        this.getRowData(res.data);
        resolve(true);
      }).catch((err) => {
        console.log(err);
        this.rowData = [];
        reject(err);
      });
    });

  }

  navigateToCreateQuote() {
    this.createQuoteService.setProductDetailsFormValues(null);
    const navigationExtras = {
      state: {
        formData: null
      }
    }
    this.router.navigateByUrl('/product-details', navigationExtras);
  }

  quickSearch() {
    this.gridModel.gridOptions.api.setQuickFilter(this.searchvalue);
  }
  clearsearch() {
    (<HTMLInputElement>document.getElementById("text")).value = '';
    this.gridModel.gridOptions.api.setQuickFilter('');
    this.gridModel.gridOptions.api.deselectAll();
    this.searchvalue = '';
  }

  getRowData(data) {
     this.rowData = data;
    this.rowData.map(r => {
      if (r.ourCommitmentDate) {
        r.provisionalDateRange = '';
      }
    });
    const navigationState = window.history.state;
    if (navigationState?.quoteId) {
      this.filterOnQuoteRefId(navigationState.quoteId);
    }
  }

  onSelectionChanged(arg: any) {
    this.selectedRows = arg.gridApi.getSelectedRows();
  }

  onFilterSelected(filteredData: any) {
    this.disableDwn = true;
    this.filteredRowData = [];
    if (filteredData.filteredRowData && filteredData.filteredRowData.length > 0) {
      filteredData.filteredRowData.forEach(row => {
        if (row.data) {
          this.filteredRowData.push(row.data);
        }
      });
    }

    if (this.rowData.length === this.filteredRowData.length) {
      this.gridModel.gridOptions.api.deselectAll();
    }
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
  }

  ngOnDestroy() {
    this.commonService.showTopButtonInFooter(false);
    if (this.headerSubs) {
      this.headerSubs.unsubscribe();
    }
  }

  onRowClicked(ev: any) {
    this.navigateToModify(ev.data);
  }
  navigateToCreateBulkQuote() {
    this.router.navigateByUrl('/bulk-quote-request-details');
  }

  navigateToModify(data) {
    this.dataSvc.saveSelectedID(data.quoteReferenceId);
    this.dataSvc.saveSnowID(data.snowSysId);
    this.dataSvc.saveSubmittedDate(data.submittedDate);
    localStorage.setItem('selectedHeaderCIDN', this.cidnSelected)
    // this.cidn = this.commonService.selectedCidn;
    if (data.orderStatus === 'Draft' && this.isReadWriteOnOrderManagement) {
      this.router.navigate([`order-site-details/${data.orderConvertedFrom}/${data.orderReferenceId}`]);
    } else if (data.orderStatus !== 'Draft') {
      this.router.navigate([`orderSummary/${data.orderConvertedFrom}/${data.orderReferenceId}`]);
    }
  }
}


<div class="container-fluid1" *ngIf="aemLabelAuthoring1 && aemLabelAuthoring && aemReviewLabelAuthoring">
  <div class="toastrContainer" aria-live="assertive" role="alert" toastContainer></div>
  <div class="row review-container mb-3">
    <label style="position:absolute;left:-9999px" aria-live="assertive" role="alert">Review and submit</label>
  
    <div class="col-3 page-header-container">
      <main aria-labelledby="reviewSubmitHeader">
      <h1 id="reviewSubmitHeader" class="page-heading" *ngIf="!isModifyQuote"> {{
        aemLabelAuthoring1?.pageHeader?.pageHeaderLabel }}</h1>
      <!-- <span class="accLabel">Fields marked with <span class="star">*</span> are mandatory</span> -->
      <span class="page-heading" *ngIf="isModifyQuote"> {{ aemLabelAuthoring1.pageHeader.modifyQuotePageHeader }}
      </span>
    </main>
      <div class="quote-ref-text">
        {{ aemLabelAuthoring1?.pageHeader?.pageSubHeaderLabel }} <strong>{{quoteRefId}} </strong>
        <span *ngIf="isQuoteSaved" class="save-lbl"> <img src="../../../assets/images/Submitted.svg"
            class="saved-icon" /><strong> {{
            aemLabelAuthoring1?.pageHeader.saved }}</strong></span>
        <div class="text-details custRefwarp" *ngIf="createQuotePayload?.customerReference?.length > 0">
          {{aemReviewLabelAuthoring?.label?.customerReference}}
          <strong>{{createQuotePayload?.customerReference}}</strong>
        </div>
      </div>
    </div>
    
    <div class="col-6">
      <app-page-stepper [stepperData]="stepperData" [stepName]="stepName"></app-page-stepper>
    </div>
  </div>
  <div>
    <!-- product&Address section -->
    <div class="background-color arrow-border">
      <div class="row">
        <div class="col-1 cart-img-container">
          <img class="product-img" src="../../../../assets/images/Product.svg" />
        </div>
        <div class="col-2 product-text title-padding">
          {{aemReviewLabelAuthoring?.label?.product}}
          <div class="prod-name  details-font">
            {{aemLabelAuthoring?.productName}}
          </div>
        </div>
        <div class="col-4 product-text title-padding">
          {{aemReviewLabelAuthoring?.label?.address}}
        </div>
        <div class="col-4 path-font"></div>
        <div class="col-1"></div>
      </div>
      <div class="row address-font">
        <div class="col-1 cart-img-container"></div>
        <div class="col-2"></div>
        <div class="col-4 path-font site-address-cls">
          <label>{{createQuotePayload.doYouRequireDiversity == "Yes" && createQuotePayload.describeYourDiversity==='All
            new services with diversity' ?
            aemReviewLabelAuthoring.label.path1SiteAEndAddress : aemReviewLabelAuthoring.label.siteAEndAddress}}</label>
        </div>
        <div class="col-4 path-font site-address-cls">
          <label>{{createQuotePayload.doYouRequireDiversity == "Yes" && createQuotePayload.describeYourDiversity==='All
            new services with diversity' ?
            aemReviewLabelAuthoring.label.path1SiteZEndAddress : aemReviewLabelAuthoring.label.siteZEndAddress}}</label>
        </div>
        <div class="col-1"></div>
      </div>
      <div class="row details-bold-text">
        <div class="col-1 cart-img-container"></div>
        <div class="col-2" *ngIf="serviceType && serviceType != 'BL'"></div>
        <div class="col-2 product-text sub-product prdct-lbl-name" *ngIf="serviceType && serviceType === 'BL'">
          {{aemReviewLabelAuthoring?.label?.subProduct}}
          <div class="prod-name">
            {{createQuotePayload ? createQuotePayload.subProduct : ''}}
          </div>
        </div>
        <!-- <div class="col-2" *ngIf="serviceType !== 'BL'"></div> -->
        <!-- <div class="col-2 sub-product">{{(serviceType === 'BL') ? (aemReviewLabelAuthoring?.label?.subProduct) : ''}}</div> -->
        <div class="col-4 text-bold site-address-cls">
          <label aria-live="assertive" class="visually-hidden">Path1 Site A end address is</label>
          <label>
            {{(createQuotePayload?.aEndAdress?.selectedAddress) ? (getSelectedAddress(createQuotePayload?.aEndAdress) |
            titlecase) :
            "-"}}
          </label>
        </div>
        <div class="col-4 text-bold site-address-cls">
          <label aria-live="assertive" class="visually-hidden">Path1 Site Z end address is</label>
          <label class="address-padding">
            {{(createQuotePayload?.zEndAdress?.selectedAddress) ? (getSelectedAddress(createQuotePayload?.zEndAdress) |
            titlecase) :
            "-"}}
          </label>
        </div>
        <div class="col-1"></div>
      </div>
      <div class="row details-bold-text">
        <div class="col-1 cart-img-container"></div>
        <div class="col-2"></div>
        <!-- <div class="col-2 sub-product-name">{{(serviceType === 'BL') ? (createQuotePayload?.subProduct) : ''}}</div> -->
        <div class="col-4 prod-name site-address-cls"><label class="alternate-text-wrap">
            {{createQuotePayload?.aEndAdressSiteAlternativeName ? createQuotePayload?.aEndAdressSiteAlternativeName :
            null}}
          </label></div>
        <div class="col-4 prod-name site-address-cls"><label class="alternate-text-wrap">
            {{createQuotePayload?.zEndAdressSiteAlternativeName ? createQuotePayload?.zEndAdressSiteAlternativeName :
            null}}
          </label></div>
        <div class="col-1 "></div>  
      </div>

      <div
        *ngIf="createQuotePayload.doYouRequireDiversity === 'Yes' && createQuotePayload.describeYourDiversity==='All new services with diversity'">
        <div class="row address-font">
          <div class="col-1 cart-img-container"></div>
          <div class="col-2"></div>
          <div class="col-8 site-address-cls-line"><hr></div>
          <div class="col-1"></div>
        </div>
        <div class="row address-font">
          <div class="col-1 cart-img-container"></div>
          <div class="col-2"></div>
          <div class="col-4 path-font site-address-cls">
            <label>{{aemReviewLabelAuthoring?.label?.path2SiteAEndAddress}}</label>
          </div>
          <div class="col-4 path-font site-address-cls">
            <label>{{aemReviewLabelAuthoring?.label?.path2SiteZEndAddress}}</label>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row address-font">
          <div class="col-1 cart-img-container"></div>
          <div class="col-2"></div>
          <div class="col-4 text-bold site-address-cls">
            <label aria-live="assertive" class="visually-hidden">Path2 Site A end address is</label>
            <label>
              {{(createQuotePayload?.a2EndAdress?.selectedAddress) ?
              (getSelectedAddress(createQuotePayload?.a2EndAdress) | titlecase)
              : "-"}}
            </label>
          </div>
          <div class="col-4 text-bold site-address-cls">
            <label aria-live="assertive" class="visually-hidden">Path2 Site Z end address is</label>
            <label class="address-padding">
              {{(createQuotePayload?.z2EndAdress?.selectedAddress) ?
              (getSelectedAddress(createQuotePayload?.z2EndAdress) | titlecase)
              : "-"}}
            </label>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row address-font">
          <div class="col-1 cart-img-container"></div>
          <div class="col-2"></div>
          <div class="col-4 prod-name site-address-cls"><label class="alternate-text-wrap">
              {{createQuotePayload?.a2EndAdressSiteAlternativeName ? createQuotePayload?.a2EndAdressSiteAlternativeName
              :
              null}}
            </label></div>
          <div class="col-4 prod-name site-address-cls"><label class="alternate-text-wrap">
              {{createQuotePayload?.z2EndAdressSiteAlternativeName ? createQuotePayload?.z2EndAdressSiteAlternativeName
              :
              null}}
            </label></div>
          <div class="col-1"></div>
        </div>
      </div>
    </div>







    

    <!-- Additional address details -->
    <div class="heading-text product-img">
      <img class="" src="../../../../assets/images/Details-Doc.svg" />
      <label class="head-margin font-24">{{aemReviewLabelAuthoring?.label?.additionalAddressDetails}}</label>
    </div>
    <hr class="half-width">
    <div class="address-container">
      <div class="row heading-text address-header">
        <div class="col-6 text-bold-18">{{createQuotePayload.doYouRequireDiversity == "Yes" &&
          createQuotePayload.describeYourDiversity==='All new services with diversity' ?
          aemReviewLabelAuthoring.label.path1SiteAEndAddress : aemReviewLabelAuthoring.label.siteAEndAddress}}</div>
        <div class="col-6 text-bold-18 "><label>{{createQuotePayload.doYouRequireDiversity == "Yes" &&
            createQuotePayload.describeYourDiversity==='All new services with diversity' ?
            aemReviewLabelAuthoring.label.path1SiteZEndAddress : aemReviewLabelAuthoring.label.siteZEndAddress}}</label>
        </div>
      </div>
      <div class="row heading-text">
        <div class="col-6 text-14-grey"><label>{{aemReviewLabelAuthoring?.label?.additionalAddressInformation}}</label>
        </div>
        <div class="col-6 text-14-grey"><label>{{aemReviewLabelAuthoring?.label?.additionalAddressInformation}}</label>
        </div>
      </div>
      <div class="row heading-text">
        <div class="col-6 text-bold ">
          <label aria-live="assertive" class="visually-hidden">Path1 Site A end address additional information
            is</label>
          <label>
            {{(createQuotePayload?.aEndAdress?.additionalAddressInfo) ?
            (createQuotePayload?.aEndAdress?.additionalAddressInfo) : "-"}}
          </label>
        </div>
        <div class="col-6 text-bold">
          <label aria-live="assertive" class="visually-hidden">Path1 Site Z end address additional information
            is</label>
          <label>
            {{(createQuotePayload?.zEndAdress?.additionalAddressInfo) ?
            (createQuotePayload?.zEndAdress?.additionalAddressInfo) : "-"}}
          </label>
        </div>
      </div>
      <ng-container
        *ngIf="createQuotePayload?.aEndAdress?.definedPropertyLocation == 'Yes' || createQuotePayload?.aEndAdress?.definedPropertyLocation == 'No'">
        <div class="row pty-location heading-text">
          <div class="col-6 text-14-grey"><label>{{aemReviewLabelAuthoring?.label?.definedPropertyLocation}}</label>
          </div>
          <div class="col-6 text-14-grey"><label>{{aemReviewLabelAuthoring?.label?.definedPropertyLocation}}</label>
          </div>
        </div>
        <div class="row heading-text">
          <div class="col-6 text-bold ">
            <label aria-live="assertive" class="visually-hidden">Path1 Site A end address defined Property Location
              is</label>
            <label>
              {{(createQuotePayload?.aEndAdress?.definedPropertyLocation) ?
              (createQuotePayload?.aEndAdress?.definedPropertyLocation) : "-"}}
            </label>
          </div>
          <div class="col-6 text-bold">
            <label aria-live="assertive" class="visually-hidden">Path1 Site Z end address defined Property Location
              is</label>
            <label>
              {{(createQuotePayload?.zEndAdress?.definedPropertyLocation) ?
              (createQuotePayload?.zEndAdress?.definedPropertyLocation) : "-"}}
            </label>
          </div>
        </div>
      </ng-container>
      <!-- <hr class="address-container-half-width"> -->
      <div *ngIf="createQuotePayload?.feasibilityRequestType === 'Detailed'">
        <div class="row pty-location heading-text text-bold-18 address-header">
          <div class="col-6"><label>{{createQuotePayload.doYouRequireDiversity == "Yes" &&
              createQuotePayload.describeYourDiversity==='All new services with diversity' ?
              aemReviewLabelAuthoring.label.Path1SiteAEndContactDetails :
              aemReviewLabelAuthoring.label.siteAEndContactDetails}}</label></div>
          <div class="col-6 "><label>{{createQuotePayload.doYouRequireDiversity == "Yes" &&
              createQuotePayload.describeYourDiversity==='All new services with diversity' ?
              aemReviewLabelAuthoring.label.Path1SiteZEndContactDetails :
              aemReviewLabelAuthoring.label.siteZEndContactDetails}}</label></div>
        </div>
        <div class="row heading-text text-14-grey">
          <div class="col-2"><label>{{aemReviewLabelAuthoring?.label?.siteContactName}}</label></div>
          <div class="col-2"><label>{{aemReviewLabelAuthoring?.label?.siteContactNumber}}</label></div>
          <div class="col-2"><label>{{aemReviewLabelAuthoring?.label?.siteContactEmail}}</label></div>
          <div class="col-2 "><label>{{aemReviewLabelAuthoring?.label?.siteContactName}}</label></div>
          <div class="col-2"><label>{{aemReviewLabelAuthoring?.label?.siteContactNumber}}</label></div>
          <div class="col-2"><label>{{aemReviewLabelAuthoring?.label?.siteContactEmail}}</label></div>
        </div>
        <div class="row heading-text text-bold">
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path1 Site A end address primary contact name
              is</label>
            {{(createQuotePayload?.aEndAdress?.primaryContactName) ?
            (createQuotePayload?.aEndAdress?.primaryContactName) : "-"}}
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path1 Site A end address primary contact number
              is</label>
            {{(createQuotePayload?.aEndAdress?.primaryContactNumber) ?
            (createQuotePayload?.aEndAdress?.primaryContactNumber) : "-"}}
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path1 Site A end address primary contact email
              is</label>
            {{(createQuotePayload?.aEndAdress?.primaryContactEmail) ?
            (createQuotePayload?.aEndAdress?.primaryContactEmail) : "-"}}
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path1 Site Z end address primary contact name
              is</label>
            {{(createQuotePayload?.zEndAdress?.primaryContactName) ?
            (createQuotePayload?.zEndAdress?.primaryContactName) : "-"}}
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path1 Site Z end address primary contact number
              is</label>
            {{(createQuotePayload?.zEndAdress?.primaryContactNumber) ?
            (createQuotePayload?.zEndAdress?.primaryContactNumber) : "-"}}
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path1 Site Z end address primary contact email
              is</label>
            {{(createQuotePayload?.zEndAdress?.primaryContactEmail) ?
            (createQuotePayload?.zEndAdress?.primaryContactEmail) : "-"}}
          </div>
        </div>
        <div class="row heading-text text-14-grey page-sub-headers">
          <div class="col-2"><label
              class="address-head">{{aemReviewLabelAuthoring?.label?.alternativeSiteContactName}}</label></div>
          <div class="col-2"><label
              class="address-head">{{aemReviewLabelAuthoring?.label?.alternativeSiteContactNumber}}</label></div>
          <div class="col-2"><label
              class="address-head">{{aemReviewLabelAuthoring?.label?.alternativeSiteContactEmail}}</label></div>
          <div class="col-2"><label
              class="address-head">{{aemReviewLabelAuthoring?.label?.alternativeSiteContactName}}</label></div>
          <div class="col-2"><label
              class="address-head">{{aemReviewLabelAuthoring?.label?.alternativeSiteContactNumber}}</label></div>
          <div class="col-2"><label
              class="address-head">{{aemReviewLabelAuthoring?.label?.alternativeSiteContactEmail}}</label></div>
        </div>
        <div class="row heading-text text-bold">
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path1 Site A end address alternative site contact name
              is</label>
            {{(createQuotePayload?.aEndAdress?.altContactName) ?
            (createQuotePayload?.aEndAdress?.altContactName) : "-"}}
            <!--  <span *ngIf="!createQuotePayload?.aEndAdress?.altContactName"> - </span> -->
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path1 Site A end address alternative site contact
              number is</label>
            {{(createQuotePayload?.aEndAdress?.altContactNumber) ? (createQuotePayload?.aEndAdress?.altContactNumber) :
            "-"}}
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path1 Site A end address alternative site contact email
              is</label>
            {{(createQuotePayload?.aEndAdress?.altContactEmail) ?(createQuotePayload?.aEndAdress?.altContactEmail) :
            "-"}}
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path1 Site Z end address alternative site contact name
              is</label>
            {{(createQuotePayload?.zEndAdress?.altContactName) ?(createQuotePayload?.zEndAdress?.altContactName) : "-"}}
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path1 Site Z end address alternative site contact
              number is</label>
            {{(createQuotePayload?.zEndAdress?.altContactNumber) ? (createQuotePayload?.zEndAdress?.altContactNumber) :
            "-"}}
          </div>
          <label aria-live="assertive" class="visually-hidden">Path1 Site Z end address alterntive site contact email
            is</label>
          <div class="col-2 title-wrap">{{(createQuotePayload?.zEndAdress?.altContactEmail) ?
            (createQuotePayload?.zEndAdress?.altContactEmail) : "-"}}</div>
        </div>
      </div>
    </div>


    <hr class="half-width"
      *ngIf="createQuotePayload.doYouRequireDiversity === 'Yes' && createQuotePayload.describeYourDiversity==='All new services with diversity'">
    <div class="address-container"
      *ngIf="createQuotePayload.doYouRequireDiversity === 'Yes'  && createQuotePayload.describeYourDiversity==='All new services with diversity'">
      <div class="row heading-text address-header">
        <div class="col-6 text-bold-18">{{aemReviewLabelAuthoring?.label?.path2SiteAEndAddress}}</div>
        <div class="col-6 text-bold-18 "><label>{{aemReviewLabelAuthoring?.label?.path2SiteZEndAddress}}</label></div>
      </div>
      <div class="row heading-text">
        <div class="col-6 text-14-grey"><label>{{aemReviewLabelAuthoring?.label?.additionalAddressInformation}}</label>
        </div>
        <div class="col-6 text-14-grey"><label>{{aemReviewLabelAuthoring?.label?.additionalAddressInformation}}</label>
        </div>
      </div>
      <div class="row heading-text">
        <div class="col-6 text-bold ">
          <label aria-live="assertive" class="visually-hidden">Path2 Site A end address additional information
            is</label>
          <label>
            {{(createQuotePayload?.a2EndAdress?.additionalAddressInfo) ?
            (createQuotePayload?.a2EndAdress?.additionalAddressInfo) : "-"}}
          </label>
        </div>
        <div class="col-6 text-bold">
          <label aria-live="assertive" class="visually-hidden">Path2 Site Z end address additional information</label>
          <label>
            {{(createQuotePayload?.z2EndAdress?.additionalAddressInfo) ?
            (createQuotePayload?.z2EndAdress?.additionalAddressInfo) : "-"}}
          </label>
        </div>
      </div>
      <ng-container
        *ngIf="createQuotePayload?.aEndAdress?.definedPropertyLocation == 'Yes' || createQuotePayload?.aEndAdress?.definedPropertyLocation == 'No'">
        <div class="row pty-location heading-text">
          <div class="col-6 text-14-grey"><label>{{aemReviewLabelAuthoring?.label?.definedPropertyLocation}}</label>
          </div>
          <div class="col-6 text-14-grey"><label>{{aemReviewLabelAuthoring?.label?.definedPropertyLocation}}</label>
          </div>
        </div>
        <div class="row heading-text">
          <div class="col-6 text-bold ">
            <label aria-live="assertive" class="visually-hidden">Path2 Site A end address defined Property location
              is</label>
            <label>
              {{(createQuotePayload?.a2EndAdress?.definedPropertyLocation) ?
              (createQuotePayload?.a2EndAdress?.definedPropertyLocation) : "-"}}
            </label>
          </div>
          <div class="col-6 text-bold">
            <label aria-live="assertive" class="visually-hidden">Path2 Site Z end address defined property location
              is</label>
            <label>
              {{(createQuotePayload?.z2EndAdress?.definedPropertyLocation) ?
              (createQuotePayload?.z2EndAdress?.definedPropertyLocation) : "-"}}
            </label>
          </div>
        </div>
      </ng-container>
      <hr class="address-container-half-width" *ngIf="createQuotePayload?.feasibilityRequestType === 'Detailed'">
      <div *ngIf="createQuotePayload?.feasibilityRequestType === 'Detailed'">
        <div class="row pty-location heading-text text-bold-18 address-header">
          <div class="col-6"><label>{{aemReviewLabelAuthoring?.label?.Path2SiteAEndContactDetails}}</label></div>
          <div class="col-6 "><label>{{aemReviewLabelAuthoring?.label?.Path2SiteZEndContactDetails}}</label></div>
        </div>
        <div class="row heading-text text-14-grey">
          <div class="col-2"><label>{{aemReviewLabelAuthoring?.label?.siteContactName}}</label></div>
          <div class="col-2"><label>{{aemReviewLabelAuthoring?.label?.siteContactNumber}}</label></div>
          <div class="col-2"><label>{{aemReviewLabelAuthoring?.label?.siteContactEmail}}</label></div>
          <div class="col-2 "><label>{{aemReviewLabelAuthoring?.label?.siteContactName}}</label></div>
          <div class="col-2"><label>{{aemReviewLabelAuthoring?.label?.siteContactNumber}}</label></div>
          <div class="col-2"><label>{{aemReviewLabelAuthoring?.label?.siteContactEmail}}</label></div>
        </div>
        <div class="row heading-text text-bold">
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path2 Site A end address primary contact name
              is</label>
            {{(createQuotePayload?.a2EndAdress?.primaryContactName) ?
            (createQuotePayload?.a2EndAdress?.primaryContactName) : "-"}}
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path2 Site A end address primary contact number
              is</label>
            {{(createQuotePayload?.a2EndAdress?.primaryContactNumber) ?
            (createQuotePayload?.a2EndAdress?.primaryContactNumber) : "-"}}
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path2 Site A end address primary contact email
              is</label>
            {{(createQuotePayload?.a2EndAdress?.primaryContactEmail) ?
            (createQuotePayload?.a2EndAdress?.primaryContactEmail) : "-"}}
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path2 Site Z end address primary contact name
              is</label>
            {{(createQuotePayload?.z2EndAdress?.primaryContactName) ?
            (createQuotePayload?.z2EndAdress?.primaryContactName) : "-"}}
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path2 Site Z end address primary contact number
              is</label>
            {{(createQuotePayload?.z2EndAdress?.primaryContactNumber) ?
            (createQuotePayload?.z2EndAdress?.primaryContactNumber) : "-"}}
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path2 Site Z end address primary contact email
              is</label>
            {{(createQuotePayload?.z2EndAdress?.primaryContactEmail) ?
            (createQuotePayload?.z2EndAdress?.primaryContactEmail) : "-"}}
          </div>
        </div>
        <div class="row heading-text text-details page-sub-headers">
          <div class="col-2"><label
              class="address-head">{{aemReviewLabelAuthoring?.label?.alternativeSiteContactName}}</label></div>
          <div class="col-2"><label
              class="address-head">{{aemReviewLabelAuthoring?.label?.alternativeSiteContactNumber}}</label></div>
          <div class="col-2"><label
              class="address-head">{{aemReviewLabelAuthoring?.label?.alternativeSiteContactEmail}}</label></div>
          <div class="col-2"><label
              class="address-head">{{aemReviewLabelAuthoring?.label?.alternativeSiteContactName}}</label></div>
          <div class="col-2"><label
              class="address-head">{{aemReviewLabelAuthoring?.label?.alternativeSiteContactNumber}}</label></div>
          <div class="col-2"><label
              class="address-head">{{aemReviewLabelAuthoring?.label?.alternativeSiteContactEmail}}</label></div>
        </div>
        <div class="row heading-text text-bold">
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path2 Site A end alternative Site Contact Name
              is</label>
            {{(createQuotePayload?.a2EndAdress?.altContactName) ?
            (createQuotePayload?.a2EndAdress?.altContactName) : "-"}}
            <!--  <span *ngIf="!createQuotePayload?.a2EndAdress?.altContactName"> - </span> -->
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path2 Site A end address alternative Site Contact
              Number is</label>
            {{(createQuotePayload?.a2EndAdress?.altContactNumber) ? (createQuotePayload?.a2EndAdress?.altContactNumber)
            :
            "-"}}
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path2 Site A end address alternative site contact email
              is</label>
            {{(createQuotePayload?.a2EndAdress?.altContactEmail) ?(createQuotePayload?.a2EndAdress?.altContactEmail) :
            "-"}}
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path2 Site Z end address alternative site contact name
              is</label>
            {{(createQuotePayload?.z2EndAdress?.altContactName) ?(createQuotePayload?.z2EndAdress?.altContactName) :
            "-"}}
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path2 Site z end address alternative site contact
              number is</label>
            {{(createQuotePayload?.z2EndAdress?.altContactNumber) ? (createQuotePayload?.z2EndAdress?.altContactNumber)
            :
            "-"}}
          </div>
          <div class="col-2 title-wrap">
            <label aria-live="assertive" class="visually-hidden">Path2 Site z end address alternative contact email
              is</label>
            {{(createQuotePayload?.z2EndAdress?.altContactEmail) ?
            (createQuotePayload?.z2EndAdress?.altContactEmail) : "-"}}
          </div>
        </div>
      </div>
    </div>

    <!-- product details -->
    <div class="heading-text product-img">
      <img class="" src="../../../../assets/images/Details-Doc.svg" />
      <label class="head-margin font-24">{{aemReviewLabelAuthoring?.label?.productDetails}}</label>
    </div>
    <hr class="half-width">
    <div class="row prod-row-padding heading-text">
      <div class="col-3"><label
          class="text-14-grey">{{aemReviewLabelAuthoring?.label?.fiberRequirement}}</label></div>
      <!-- START: Commented as part of IIEO-4573 -->
      <!-- <div class="col-3"><label class="text-details">{{aemReviewLabelAuthoring?.label?.quantityOfService}}</label></div> -->
      <!-- END: Commented as part of IIEO-4573 -->
      <div class="col-3" *ngIf="serviceType ==='RG'"><label class="text-14-grey">{{aemReviewLabelAuthoring?.label?.terms}}</label></div>
    </div>
    <div class="row heading-text">
      <div class="col-3">
        <label class="text-bold">
          {{(createQuotePayload?.linkRequirement) ? (createQuotePayload?.linkRequirement) : "-"}}
        </label>
      </div>
      <!-- START: Commented as part of IIEO-4573 -->
      <!-- <div class="col-3">
        <label class="text-bold">
          {{(createQuotePayload?.quantityOfService) ? (createQuotePayload?.quantityOfService) : "-"}}
        </label>
      </div> -->
      <!-- END: Commented as part of IIEO-4573 -->
      <div class="col-3" *ngIf="serviceType ==='RG'">
        <label class="text-bold">
          {{(createQuotePayload?.terms) ? (createQuotePayload?.terms + ' years') : "-"}}
        </label>
      </div>
    </div>
    <!-- Diversity details -->
    <div *ngIf="createQuotePayload.doYouRequireDiversity === 'Yes'">
      <div class="heading-text product-img">
        <img class="" src="../../../../assets/images/Details-Doc.svg" />
        <label class="head-margin font-24">{{aemReviewLabelAuthoring?.label?.diversityDetails}}</label>
      </div>
      <hr class="half-width">
      <div class="row heading-text">
        <div class="col-3">
          <label
            class="text-14-grey">{{aemReviewLabelAuthoring?.label?.diversityRequirement}}</label></div>
        <div class="col-2 " *ngIf="createQuotePayload?.existingInfracoServiceInfo;"><label
            class="text-14-grey">{{aemReviewLabelAuthoring?.label?.existingInfracoServiceInfo}}</label></div>
        <div class="col-2" [ngClass]="createQuotePayload?.existingInfracoServiceInfo ? 'site-lead-label' : 'p-0'"><label
            class="text-14-grey">{{aemReviewLabelAuthoring?.label?.siteAHaveLeadInDiversity}}</label>
        </div>
        <div class="col-2" [ngClass]="createQuotePayload?.existingInfracoServiceInfo ? 'siteZ-lead-label' : 'p-0'"><label
            class="text-14-grey">{{aemReviewLabelAuthoring?.label?.siteZHaveLeadInDiversity}}</label>
        </div>
      </div>
      <div class="row  heading-text ">
        <div class="col-3">
          <label aria-live="assertive" class="visually-hidden">Diversity Requirement is</label>
          <label class="text-bold">
            {{(createQuotePayload?.describeYourDiversity) ? (createQuotePayload?.describeYourDiversity) : "-"}}
          </label>
        </div>
        <div class="col-2" *ngIf="createQuotePayload?.existingInfracoServiceInfo;">
          <label aria-live="assertive" class="visually-hidden">existing InfracoService Info is</label>
          <label class="text-bold">
            {{(createQuotePayload?.existingInfracoServiceInfo) ? (createQuotePayload?.existingInfracoServiceInfo) :
            "-"}}
          </label>
        </div>
        <div class="col-2" [ngClass]="createQuotePayload?.existingInfracoServiceInfo ? 'site-lead-text' : 'p-0'">
          <label aria-live="assertive" class="visually-hidden">siteA HaveLeadIn Diversity is</label>
          <label class="text-bold">
            {{(createQuotePayload?.aEndLeadIn) ? (createQuotePayload?.aEndLeadIn) : "-"}}
          </label>
        </div>
        <div class="col-2" [ngClass]="createQuotePayload?.existingInfracoServiceInfo ? 'site-lead-text' : 'p-0'">
          <label aria-live="assertive" class="visually-hidden">siteZ HaveLeadIn Diversity is</label>
          <label class="text-bold">
            {{(createQuotePayload?.zEndLeadIn) ? (createQuotePayload?.zEndLeadIn) : "-"}}
          </label>
        </div>
      </div>
    </div>
    <!-- References -->
    <div class="heading-text product-img" *ngIf="serviceType ==='RG'">
      <img class="" src="../../../../assets/images/Details-Doc.svg" />
      <label class="head-margin font-24">{{aemReviewLabelAuthoring?.label?.references}}</label>
    </div>
    <hr class="half-width" *ngIf="serviceType ==='RG'">
    <div class="row heading-text" *ngIf="serviceType ==='RG'">
      <div class="col-3"><label class="text-14-grey">{{aemReviewLabelAuthoring?.label?.responseRequiredDate
          }}</label></div>
      <div class="col-3"><label class="text-14-grey">{{aemReviewLabelAuthoring?.label?.businessDriver}}</label></div>
    </div>
    <div class="row heading-text" *ngIf="serviceType ==='RG'">
      <div class="col-3">
        <label aria-live="assertive" class="visually-hidden">Response required date is</label>
        <label class="text-bold">{{responseDateRequired}}</label>
      </div>
      <div class="col-3">
        <label aria-live="assertive" class="visually-hidden">Business Driver is</label>
        <label class="text-bold">
          {{(createQuotePayload?.businessDriver) ? (createQuotePayload?.businessDriver) : "-"}}
        </label>
      </div>
    </div>
    <!-- External reference details -->
    <div class="row heading-text diversity-head" *ngIf="serviceType ==='RG'">
      <div class="col-3"><label
          class="text-14-grey">{{aemReviewLabelAuthoring?.label?.externalReference}}</label></div>
      <div class="col-3"><label class="text-14-grey ">{{aemReviewLabelAuthoring?.label?.value}}</label></div>
    </div>
    <div class="row heading-text" *ngIf="serviceType ==='RG'">
      <div class="col-5"><hr class="table-heading-divider"></div>
    </div>
    <!-- <hr class="half-width-reference" *ngIf="serviceType ==='RG'"> -->
    <ng-container *ngIf="serviceType ==='RG'">
      <div *ngFor="let extReference of createQuotePayload?.externalReference">
        <div class="row heading-text">
          <div class="row">
            <div class="col-3">
              <label aria-live="assertive" class="visually-hidden">External reference type is</label>
              <label class="text-bold">
                {{(extReference?.externalReferenceType) ? (extReference?.externalReferenceType) : "-"}}
              </label>
            </div>
            <div class="col-3">
              <label aria-live="assertive" class="visually-hidden">External reference value is</label>
              <label class="text-bold externalVal">
                {{(extReference?.externalReferenceValue) ? (extReference?.externalReferenceValue) : "-"}}
              </label>
            </div>
          </div> </div>
          <div class="row heading-text">
            <div class="col-5"><hr class="table-row-divider"></div>
          <!-- </div> -->
        </div>
      </div>
    </ng-container>
    <!-- <hr class="half-width-reference" *ngIf="serviceType ==='RG'"> -->
    <!-- Further considerations -->
    <div *ngIf="createQuotePayload?.requestorConstraints === 'Yes'">
      <div class="row font-24">
        <div class="heading-text product-img">
          <img class="" src="../../../../assets/images/Details-Doc.svg" />
          <label class="head-margin font-24">{{aemReviewLabelAuthoring?.label?.furtherConsiderations}}</label>
        </div>
      </div>
      <hr class="half-width">

      <div class="row heading-text">
        <div class="col-3"><label
            class="text-14-grey">{{aemReviewLabelAuthoring?.label?.preferredMaxDistance}}</label></div>
      </div>
      <div class="row heading-text">
        <div class="col-3">
          <label class="text-bold">
            {{(createQuotePayload?.preferredMaxDistance) ? (createQuotePayload?.preferredMaxDistance) : "-"}}
          </label>
        </div>
      </div>
      <div class="row page-sub-headers heading-text">
        <label class="text-bold-16 text-nowrap "
          aria-label="Maximum allowable Dark Fibre optical loss">{{aemReviewLabelAuthoring?.label?.maximumOpticalLoss}}</label>
        <div class="row mt-3">
          <div class="col-3"><label
              class="text-14-grey text-nowrap">{{aemReviewLabelAuthoring?.label?.waveLength}}</label></div>
          <div class="col-3"><label class="text-14-grey">{{aemReviewLabelAuthoring?.label?.valueDB}}</label>
          </div>
        </div>
      </div>
    <div class="row heading-text">
      <div class="col-5"><hr  class="table-heading-divider"></div>
      </div>
      <div *ngFor="let item of createQuotePayload?.maxAllowableOpticalLoss">
        <div class="row heading-text">
          <div class="row">
          <div class="col-3">
            <label class="text-bold">{{(item.wavelength) ? (item.wavelength) : "-"}}</label>
          </div>
          <div class="col-3">
            <label class="text-bold">{{(item.dbValue) ? (item.dbValue) : "-"}}</label>
          </div>
        </div></div>
        <div class="row heading-text">
          <div class="col-5"><hr class="table-row-divider"></div>
        </div>
      </div>
    </div>
    <!-- Funding details -->
    <div *ngIf="createQuotePayload?.feasibilityRequestType === 'Detailed'">
      <div class="row page-fund-details">
        <div class="heading-text product-img"><img class="" src="../../../../assets/images/Details-Doc.svg" />
          <label class="head-margin font-24">{{aemReviewLabelAuthoring?.label?.fundingDetails}}</label>
        </div>
      </div>
      <hr class="half-width">
      <div class="row" style="height:58px">
        <div class="col-3 mb-4 heading-text funding-det-container">
          <label class="text-14-grey">{{aemReviewLabelAuthoring?.label?.fundingSource}}</label>
          <label class="text-bold">
            {{createQuotePayload?.fundingSource}}
          </label>
        </div>
        <div class="col-3 funding-det-container" *ngIf="showFundingSourceCodes()"><label
            class="text-14-grey">{{aemReviewLabelAuthoring?.label?.l3Capex}}</label>
          <label class="text-bold">
            {{createQuotePayload?.l3WBSCodeCapex}}
          </label>
        </div>
        <div class="col-2 funding-det-container" *ngIf="showFundingSourceCodes()"><label
            class="text-14-grey">{{aemReviewLabelAuthoring?.label?.l3Opex}}</label>
          <label class="text-bold alternate-text-wrap">
            {{createQuotePayload?.l3WBSCodeOpex}}
          </label>
        </div>
        <div class="col-2 funding-det-container" *ngIf="createQuotePayload?.fundingSource === 'Other'"><label
            class="text-14-grey">{{aemReviewLabelAuthoring?.label?.otherPOW}}</label>
          <label class="text-bold">
            {{createQuotePayload?.otherPowImc}}
          </label>
        </div>
        <div class="col-3 heading-text funding-det-container"><label
            class="text-14-grey">{{aemReviewLabelAuthoring?.label?.preApprovalCost}}</label>
          <label class="text-bold">
            {{createQuotePayload?.preApprovalCost | currency }}
          </label>
        </div>
      </div>
    </div>
    <!-- Notes -->
    <div class="row page-fund-details">
      <div class="heading-text product-img">
        <img class="" src="../../../../assets/images/Details-Doc.svg" />
        <label class="head-margin notesHeadmargin font-24">{{aemReviewLabelAuthoring?.label?.notes}}</label>
      </div>
    </div>
    <hr class="half-width">
    <div class="row note-height heading-text">
      <div class="title-wrap text-length">
        <label class="text-bold customer-notes">
          {{(createQuotePayload?.customerNotes) ? (createQuotePayload?.customerNotes) : "-"}}
        </label>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="submitCancelBtnReview btnRow">
          <button mat-button (click)="navigateBack()" class="back-btn cancelBtn save-cancel-btn">
            <span class="cancel-btn">
              <div class="btn-svg back-icon"></div>
              {{ aemLabelAuthoring1?.button.back }}
            </span>
          </button>
          <button mat-button (click)="cancel()" class="cancelBtn save-cancel-btn">
            <span class="cancel-btn"><i class="icon-icon-ui-cross"></i>{{ aemLabelAuthoring1?.button.cancel }}
            </span>
          </button>
          <button class="app-btn-green app-btn-white save-cancel-btn" (click)="saveFeasibilityRequest(false, false)">
            <div class="btn-svg save-icon"></div>
            {{ aemLabelAuthoring1?.button.save }}
          </button>
           <label role="alert" attr.aria-label="Successfully saved" *ngIf="saveQuoteShow" class="alert-section">Successfully saved</label> 
          <button mat-button class="app-btn-green save-cancel-btn" (click)="saveFeasibilityRequest(true)">
            {{ aemLabelAuthoring1?.button.submit }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-terms-conditions-modal [tAndcText]="tAndcText" *ngIf="isTermsCondition" (onClickClose)="onClickClose($event)">
</app-terms-conditions-modal>

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-html-to-pdf',
  templateUrl: './html-to-pdf.component.html',
  styleUrls: ['./html-to-pdf.component.less']
})
export class HtmlToPdfComponent implements OnInit {
  @Input() status;
  @Input() statusSummary;
  @Input() aemLabelAuthoring;
  @Input() quoteRefId;
  @Input() noValueAvailable;
  @Input() custRefId;
  @Input() responseReq;
  @Input() lastSub;
  @Input() requestor;
  @Input() requestorChannel;
  @Input() submittedDate;
  @Input() showCustName;
  @Input() showSerNum;
  @Input() requestMet;
  @Input() requestorSolutionSummary;
  @Input() productRef;
  @Input() subProductRef;
  @Input() linkReq
  @Input() quantityServ
  @Input() terms
  @Input() customerName
  @Input() serviceNowReference

  @Input() showDiversity
  @Input() requestMetDiversity
  @Input() requestorSolutionSummaryDiversity

  @Input() describeYourDiversity
  @Input() aEndLead
  @Input() zEndLead

  @Input() aEndAdress
  @Input() aEndAdressSiteAlternativeName
  @Input() definedPropertyLoca
  @Input() addInformation
  @Input() aEndprimaryContactName
  @Input() aEndprimaryContactNumber
  @Input() aEndprimaryContactEmail
  @Input() aEndAltContactName
  @Input() aEndAltContactNumber
  @Input() aEndAltContactEmail
  @Input() aEndSiteType;
  @Input() aEndDcOperator;
  @Input() aEndDcSiteName;


  @Input() LatLngSiteZ
  @Input() zEndAdress
  @Input() zEndAdressSiteAlternativeName
  @Input() zdefinedPropertyLoca
  @Input() zaddInformation
  @Input() zEndprimaryContactName
  @Input() zEndprimaryContactNumber
  @Input() zEndprimaryContactEmail
  @Input() zEndAltContactName
  @Input() zEndAltContactNumber
  @Input() zEndAltContactEmail
  @Input() zEndSiteType;
  @Input() zEndDcOperator;
  @Input() zEndDcSiteName;

  @Input() LatLngSiteA2
  @Input() a2EndAdress
  @Input() a2EndAdressAlternativeCot
  @Input() a2definedPropertyLoca
  @Input() a2addInformation
  @Input() a2EndprimaryContactName
  @Input() a2EndprimaryContactNumber
  @Input() a2EndprimaryContactEmail
  @Input() a2EndAltContactName
  @Input() a2EndAltContactNumber
  @Input() a2EndAltContactEmail
  @Input() a2EndSiteType;
  @Input() a2EndDcOperator;
  @Input() a2EndDcSiteName;
  @Input() aEndConnectorType;
  @Input() aEndDemarcationPoint;
  @Input() aEndsdpType;
  @Input() aEndAccessType;
  @Input() aEndAdditionalCabling;
  @Input() aEndAdditionalCablingLocation;
  @Input() zEndConnectorType;
  @Input() zEndDemarcationPoint;
  @Input() zEndsdpType;
  @Input() zEndAccessType;
  @Input() zEndAdditionalCabling;
  @Input() zEndAdditionalCablingLocation;

  @Input() z2EndAdress
  @Input() z2EndAdressAlternativeCot
  @Input() z2definedPropertyLoca
  @Input() z2addInformation
  @Input() z2EndprimaryContactName
  @Input() z2EndprimaryContactNumber
  @Input() z2EndprimaryContactEmail
  @Input() z2EndAltContactName
  @Input() z2EndAltContactNumber
  @Input() z2EndAltContactEmail
  @Input() z2EndSiteType;
  @Input() z2EndDcOperator;
  @Input() z2EndDcSiteName;

  @Input() businessDriv

  @Input() externalReferences

  @Input() preferredMaxDistance
  @Input() fiberOpticalLoss
  @Input() dbVal
  @Input() noListValue

  @Input() fundingSource
  @Input() l3WBSCodeCapex
  @Input() l3WBSCodeOpex
  @Input() otherPowImc
  @Input() preApprovalCost

  @Input() concessionItems
  @Input() notMetItems
  @Input() indicativeLinkDeliveryTimingClass
  @Input() indicativeLinkdeliveryDays

  @Input() totalCapitalCost
  @Input() lateralBenefitValue
  @Input() siteAssessmentCost
  @Input() monthlyRecurringICACharge
  @Input() monthlyRecurringCharge
  @Input() totalMonthlyRecurringCharge

  @Input() rofr
  @Input() rofrFibreSize
  @Input() rofrFAPId
  @Input() rofrFAPLatitude
  @Input() rofrFAPLongitude
  @Input() totalFibreLength
  @Input() newFibreHaulLength
  @Input() newFibreBuriedLength
  @Input() newConduitLength
  @Input() leasedConduitLength
  @Input() leasedConduitTerm
  @Input() leasedConduitOwner
  @Input() estimatedLoss
  @Input() solutionSummary

  @Input() gridConfig
  @Input() gridInitialized
  @Input() gridModel

  @Input() quoteExpiry

  @Input() concessionItemsDiversity
  @Input() notMetItemsDiversity
  @Input() indicativeLinkDeliveryTimingClassDiversity
  @Input() indicativeLinkdeliveryDaysDiversity

  @Input() totalCapitalCostDiversity
  @Input() lateralBenefitValueDiversity
  @Input() siteAssessmentCostDiversity
  @Input() monthlyRecurringICAChargeDiversity
  @Input() monthlyRecurringChargeDiversity
  @Input() totalMonthlyRecurringChargeDiversity
  @Input() rofrDiversity
  @Input() rofrFibreSizeDiversity
  @Input() rofrFAPIdDiversity
  @Input() rofrFAPLatitudeDiversity
  @Input() rofrFAPLongitudeDiversity
  @Input() totalFibreLengthDiversity
  @Input() newFibreHaulLengthDiversity
  @Input() newFibreBuriedLengthDiversity
  @Input() newConduitLengthDiversity
  @Input() leasedConduitLengthDiversity
  @Input() leasedConduitTermDiversity
  @Input() leasedConduitOwnerDiversity
  @Input() solutionSummaryDiversity

  @Input() gridInitialized1
  @Input() gridModel1
  @Input() isDiversity
  @Input() existingInfracoServiceInfo;
  @Input() thirdPartyPathInfo;


  @Input() customerRequestedDate;
  @Input() provisionalDateRange;
  @Input() ourCommitmentDate;
  @Input() networkProximity;
  @Input() completedDate;
  @Input() orderType;
  @Input() serviceId;
  @Input() serviceDeliveryPoint;
  @Input() nodeCode;
  @Input() poleId;
  @Input() leadInRequired;
  @Input() zEndServiceDeliveryPoint;
  @Input() zNodeCode;
  @Input() zPoleId;
  @Input() zLeadInRequired;
  @Input() projectReferenceId;
  @Input() opportunityNo;
  @Input() customerContactName;
  @Input() customerContactNumber;
  @Input() customerContactEmail;
  @Input() isOrder;
  @Input() maxSignalLoss;
  @Input() serviceType;
  @Input() estimatedDistanceRange;
  @Input() nonRecurringCharge;
  @Input() siteName;
  @Input() siteNameZ;
  @Input() clusterId;
  @Input() miragepclId;
  @Input() networkProx;
  @Input() networkProxDiversity;
  @Input() preferredDistance;
  @Input() threeYearPriceMonth;
  @Input() fiveYearPriceMonth;
  @Input() Customcontract;
  @Input() customYearMonth;
  @Input() custTerm;
  @Input() preferredDistanceDiversity;
  @Input() threeYearPriceMonthDiversity;
  @Input() fiveYearPriceMonthDiversity;
  @Input() CustomcontractDiversity;
  @Input() customYearMonthDiversity;
  @Input() custTermDiversity;
  @Input() selectedTermForNonRGPath1;
  @Input() selectedTermForNonRGPath2;
  @Input() estimatedLossDiversity;
  @Input() isPredefinedQuote;
  @Input() isReadWriteOnOrderManagement;
  @Input() sentEnqPath1;
  @Input() orderPath;
  constructor() { }

  ngOnInit(): void {
    this.statusSummary = this.statusSummary ? this.statusSummary : 'Your quote request has been completed with detailed assessment outcome'
 }
  showAssessmentOutcome(status, firstBox?) {
    if (!this.isOrder) {
      return (status === 'Quote Ready' || status === 'Expired' || status === 'Quote Accepted' || status === 'Ordered' || status === 'Assessment Complete');
    } else if (this.orderPath.toLowerCase() ==='path1') {
      return true;
    } else {
      return false;
    }

  }

  showFundingSourceCodes() {
    return this.fundingSource === 'Other' || this.fundingSource?.toLowerCase().indexOf('sbc') > -1
  }

  showForQuoteOrderBasedOnRequestMet(value, isDiverse?) {
    if(!isDiverse) {
    if (this.requestMet == value) {
      return true
    } else {
      return false
    }
  } else {
    if (this.requestMetDiversity == value) {
      return true
    } else {
      return false
    }
  }
  }
  getMonthlyRecurringChargeNonRG() {
    if (this.isOrder && this.serviceType !== 'RG') {
      if (this.selectedTermForNonRGPath1 === 3 || this.selectedTermForNonRGPath2 === 3) {
        return this.threeYearPriceMonth;
      } else if (this.selectedTermForNonRGPath1 === 5 || this.selectedTermForNonRGPath1 === 5) {
        return this.fiveYearPriceMonth;
      } else if ((this.selectedTermForNonRGPath1 && this.selectedTermForNonRGPath1 !== 0) || 
      this.selectedTermForNonRGPath2 && this.selectedTermForNonRGPath2 !== 0) {
        return this.customYearMonth;
      }
  } else {
    return this.monthlyRecurringCharge;
  }
}
  // showRow(ref:any): boolean {
  //   if(ref.wavelength === '1310nm' && ref.dbValue !== 0 && ref.dbValue !== ''){
  //     return true;
  //   }else if(ref.wavelength === '1550nm' && ref.dbValue !== 0 && ref.dbValue !== ''){
  //     ref.wavelength = '1310nm';
  //     return true;
  //   }
  //   return false;
  //   return true;
  // }
}

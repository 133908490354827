import { Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { CreateQuoteService } from '../create-quote.service';
import { ReviewSubmitService } from '../review-and-submit/review-and-submit.service';
import { CustomTextField, CustomDropDown, TooltipContent } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { CustomDatePicker } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import * as moment_ from 'moment-timezone';
import { CommonService } from 'src/app/shared/services/common.service';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import Holidays from 'date-holidays';
declare var require: any;
const moment_business_days = require('moment-business-days');
import { DatePipe } from '@angular/common';
import { validateHorizontalPosition } from '@angular/cdk/overlay';
import { HomePageService } from 'src/app/shared/services/homePage.service';
import { CustomerService } from 'src/app/admin-settings/customer-list/customer.service';
import { AuthService } from 'tw-core-ui';
import { Subscription } from 'rxjs';
import { InputRefDirective } from './Input.ref.directive';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['../product-details/product-details.component.less', './request-details.component.less']

})
export class RequestDetailsComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  @ViewChildren("tref") tref: QueryList<ElementRef>;
  @ViewChildren("elmntref") elmntref: QueryList<ElementRef>;

  cidn: string = '';
  productData: any;
  saveQuoteShow:boolean=false;
  customerName: string = ''
  quoteRefId: string;
  public requestHeaderChange: Subscription;
  productDetailsForm: FormGroup;
  options: GlobalConfig;
  showAlertDriver: boolean = false;
  serviceDatePicker = {
    year: '',
    month: '',
    day: ''
  };
  users = [];
  add = [];
  DATA2: any;
  DATA3: any;
  DupData = [];
  keyValueObject: any;
  keyValueObject1: any;
  customerNotes: string;
  isModifyQuote: boolean = false;
  quoteDataToModify: any;
  modifiedFormFields = {};
  public businessDriverList = [];
  public businessList = [];
  isQuoteSaved: boolean = false;
  serviceType: string;
  public businessDriverDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Business driver Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public externalReferenceList = [];
  public externalReferenceDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'External reference Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public externalReferenceDropDownModelAdd: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'External reference Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public siteADiversityDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Should site A have lead in diversity? Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public siteZDiversityDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Should site Z have lead in diversity? Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public maximumOpticalLossDropDownList = [];
  public maximumOpticalLossDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Wavelenght(nm) Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  public maximumOpticalLossDropDownModel1: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Wavelenght(nm) Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  form = this.fb.group({
    lessons: this.fb.array([]),
    lessons1: this.fb.array([])
  });
  selectedTimeZone = 'Australia/Victoria';
  selectedServiceDate: any;
  dateRequired: string;
  createQuotePayload: any;
  // datepipe: any;
  public dateTooltipContentModel: TooltipContent = {
    content: 'At least 2 business days after request submission Business days (Monday to Friday) only',
    automationTypeTooltipIcon: 'image',
    automationIdTooltipIcon: 'imgAddressInformation'
  };
  toMaxDate;
  sorted;
  private regex: RegExp = new RegExp(/^\d*\.?\d{0,16}$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];
  userName: any;
  myLinkedCustomerList: any;
  cidnSelected: any;
  pattern = /^[a-zA-Z0-9!@#$%^&*()\-\_=+:'",.?|{}\n\[\]\/\`\~\\ ]*$/;
  showConstraintOption: boolean = true;
  isPreDefinedRoute: any;

  constructor(private createQuoteService: CreateQuoteService, private reviewSubmitService: ReviewSubmitService,
    private router: Router, private fb: FormBuilder, private utility: UtilityService,
    private commonService: CommonService, private toastr: ToastrService, public datepipe: DatePipe,
    private homepageservice: HomePageService, private customerService: CustomerService, private authService: AuthService, private titleService: Title) {
      this.titleService.setTitle('InfraCo Customer Portal -Request Details page');
    // this.cidn = '999999999'
    // this.cidn = this.commonService.selectedCidn;
    this.getCommonServices();
    this.customerName = this.commonService.setAllPageheaderVal;
    this.options = this.toastr.toastrConfig;

    // code for header change in myquotes
    this.customerService.getLinkedCIDNs().then(res => {
      if (res && res.data && res.data.businessNameDetails && res.data.businessNameDetails.length > 0) {
        // let businessList = [];
        const iteratedArr = res.data.businessNameDetails.map(({
          bussinessName,
          cidn
        }) => [bussinessName, cidn]);

        for (var i = 0; i < iteratedArr.length; i++) {
          const businessName = iteratedArr[i][0];
          const cidn = iteratedArr[i][1];
          if (businessName || cidn) {
            this.businessList.push({
              businessName: businessName,
              cidn: cidn
            });

          }
        }

      }
      else {
        this.userName = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail']
        this.homepageservice
          .getUserProfile(this.userName)
          .then((response) => {
            this.myLinkedCustomerList = response?.data?.customerDetails;
            if (this.myLinkedCustomerList) {
              this.myLinkedCustomerList.forEach((option) => {
                this.businessList.push({
                  businessName: option.customerName,
                  cidn: option.cidn
                });
              });
            }
          });
      }

    }).catch((err) => {

    });

    if (this.commonService.getDefaultCustomer()) {
      this.requestHeaderChange = this.commonService.getDefaultCustomer().pipe().subscribe(resp => {

        this.businessList.forEach(item => {

          if (item.businessName === resp) {
            this.cidnSelected = item.cidn;
            localStorage.setItem('selectedHeaderCIDN', this.cidnSelected)
            const roles = this.commonService.getroleDetails().customerList;
            const isReadWriteOnQuoteMgmt = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.role === 'Read & Write' && obj?.function === 'Quote Request Management'));
            const isReadOnlyRole = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.role === 'Read Only' && obj?.function === 'Quote Request Management'));
            if (isReadWriteOnQuoteMgmt || isReadOnlyRole) {
              this.router.navigate(['/myQuotes']);
            }
            else {
              this.router.navigate(['']);
            }

          }

        })

      })
    }
  }
  public aemLabelAuthoring1: any = null;
  public aemReviewLabelAuthoring: any = null
  public aemLabelAuthoring: any = null;
  public createCustomerForm: FormGroup;
  public lessonForm: FormGroup;
  public lessonForm1: FormGroup;
  public passData: any;
  public passData1: any;
  public passData2: any;
  public passData3: any;
  thirdPartyPathInfo: string;
  isDiversityRequired: boolean = false;
  isBusinessDriverTouched: boolean = false;
  public siteADiversityDropDownList: string[] = ["Yes", "No", "Best effort"];

  public siteZDiversityDropDownList: string[] = ["Yes", "No", "Best effort"];
  moment = moment_;
  momentBusiness = moment_business_days;

  public customerChannels: string[] = ["Amplitel", "Telstra Enterprise", "Telstra International", "Telstra Limited", "Telstra Wholesale", "External Direct"];
  stepperData = ['Product details', 'Site details', 'Request details', 'Review and submit']
  stepName = 'Request details';

  publicHolidays = [];
  fromDate;
  responseDate = {
    year: '',
    month: '',
    day: ''
  };
  ngOnInit(): void {
    this.serviceType = this.createQuoteService.getServiceType();
    window.scrollTo(0, 0);
    this.createForm();
    this.getLabels();
    this.getReviewDetailsLabels();
    this.getRequestDetailsValues();
    this.setDatePicker();
    this.formValueChange();
    this.productData = this.createQuoteService.getProductProductData();
    const navigationState = window.history.state;
    if (this.productData.length === 0) {
      this.getProductCatalogDataFromAPI();
    } else {
      this.createQuotePayload = this.createQuoteService.getProductDetailsFormValues();
      this.getDiversityDropdownValues();
      if (navigationState.formData) {
        const formValue = navigationState.formData;
        this.getExistingFormData(formValue)
      } else {
        this.getExistingFormData(this.createQuotePayload)
      }
    }
    this.createQuotePayload = this.createQuoteService.getProductDetailsFormValues();

    this.quoteRefId = this.createQuotePayload?.quoteReferenceId;
    if (!this.createQuotePayload?.quoteReferenceId) {
      this.createQuotePayload = sessionStorage.getItem('productDetailsFormValue');
      this.quoteRefId = this.createQuotePayload?.quoteReferenceId;
    }
    this.checkNonRGConditions();
    if (this.createQuotePayload?.doYouRequireDiversity === 'Yes') {
      this.createCustomerForm.controls['siteADiversityDropDown'].setValidators([Validators.required]);
      this.createCustomerForm.controls['siteZDiversityDropDown'].setValidators([Validators.required]);
    } else {
      this.createCustomerForm.controls['siteADiversityDropDown'].clearValidators();
      this.createCustomerForm.controls['siteZDiversityDropDown'].clearValidators();
    }
    this.createQuoteService.isModifyQuote.subscribe(val => {
      if (val) {
        this.isModifyQuote = val;
        this.siteADiversityDropDownModel.isDisabled = true;
        this.siteZDiversityDropDownModel.isDisabled = true;
        this.getRequestDetailsFormData();
        if (!navigationState.isModifyFlow) {
          this.createCustomerForm?.controls['customerNotes'].setValue('');
          this.createCustomerForm?.controls['responseRequiredDate'].reset();
          this.setDatePicker();
        }
        this.getModifiedFormValues();
      } else {
        this.isModifyQuote = false
      }
    })
  }

  getCommonServices() {
    this.commonService.getDefaultCustomer().pipe().subscribe(resp => {
      if (resp) {
        this.customerName = resp;
        this.setCustomerDropdown(resp);
      }
    });
  }
  checkNonRGConditions() {
    let aAdborId = this.createQuotePayload?.aEndAdress?.adborId ? this.createQuotePayload?.aEndAdress?.adborId : '';
    let zAdborId = this.createQuotePayload?.zEndAdress?.adborId ? this.createQuotePayload?.zEndAdress?.adborId : '';
     if (this.serviceType !== 'RG') {
       if (this.createQuotePayload.doYouRequireDiversity === 'No' &&
        (this.createQuotePayload.aEndpreDefinedNodeName !== null || this.createQuotePayload.aEndpreDefinedNodeName.toLowerCase() !== "no" || this.createQuotePayload.aEndpreDefinedNodeName.toLowerCase() !== "yes")
       && (this.createQuotePayload.zEndpreDefinedNodeName !== null || this.createQuotePayload.zEndpreDefinedNodeName.toLowerCase() !== "no" || this.createQuotePayload.zEndpreDefinedNodeName.toLowerCase() !== "yes")){

        this.createQuoteService
        .checkPredefinedRoute(this.cidn, aAdborId, zAdborId, this.quoteRefId)
        .then((res: any) => {
          this.showConstraintOption = res?.data?.predefinedRoute === true ? false : true;
          this.isPreDefinedRoute = res?.data?.predefinedRoute === true ? 'Yes' : 'No';
        })
        .catch((error) => { });

       }
     }
  }
  setCustomerDropdown = (selectedHeaderValue?: string) => {
    const profileDetails = this.homepageservice.userProfileDetails;
    if (profileDetails) {
      const custDetails = profileDetails?.data?.customerDetails;
      if (selectedHeaderValue) {
        const findCIDNobject = this.searchCidn(custDetails, selectedHeaderValue)
        this.cidn = findCIDNobject?.cidn;
      }
    }
  }

  searchCidn(myArray, nameKey) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].customerName === nameKey) {
        return myArray[i];
      }
    }
  }

  getLabels() {
    this.createQuoteService
      .getCreateQuoteModel()
      .then((data: any) => {
        this.aemLabelAuthoring1 = data;
      })
      .catch((error) => { });
  }
  getReviewDetailsLabels() {
    this.reviewSubmitService
      .getReviewDetailsPageLabel()
      .then((data: any) => {
        this.aemReviewLabelAuthoring = data;

      })
      .catch((error) => { });
  }
  getRequestDetailsValues() {
    this.reviewSubmitService
      .getCabinetsAccessModel()
      .then((data: any) => {
        this.aemLabelAuthoring = data;
      })
      .catch((error) => { });
  }

  navigateBack() {
    const navigationExtras = {
      state: {
        isModifyFlow: this.isModifyQuote
      }
    }
    if (this.isModifyQuote) {
      this.saveQuote(false, false, 'isBack');
    } else {
      this.saveQuote(false, true, 'isBack');
    }
    this.router.navigateByUrl('/site-details', navigationExtras);
  }

  cancel() {
    if (this.isModifyQuote) {
      this.modifyQuote(false, false);
    } else {
      this.saveQuote(false);
    }
    this.router.navigate(['/myQuotes']);
  }

  get lessons() {
    return this.form.controls["lessons"] as FormArray;

  }
  get lessons1() {
    return this.form.controls["lessons1"] as FormArray;
  }

  addLesson() {
    this.lessonForm = this.fb.group({
      externalReferenceType: [''],
      externalReferenceValue: ['', [Validators.pattern(this.pattern)]]
    });
    /* this.keyValueObject = {  externalReferenceType: this.passData,   externalReferenceValue: this.passData1 };
     this.passData = this.createCustomerForm.controls['customerType1'].value;
    this.passData1 = this.createCustomerForm.get('externalReference1').value;  */
    this.lessonForm['controls']['externalReferenceType'].updateValueAndValidity();
    this.lessonForm['controls']['externalReferenceValue'].updateValueAndValidity();
    this.lessons.push(this.lessonForm);
    setTimeout(() => this.tref?.last.nativeElement.focus(), 1);

  }
  addLesson1() {
    this.lessonForm1 = this.fb.group({
      wavelength: ['', Validators.required],
      dbValue: ['', [Validators.required, Validators.min(1), Validators.max(50), Validators.pattern(/^[0-9]+(.[0-9]{0,2})?$/)]]
    });
    this.createCustomerForm['controls']['dbValue'].updateValueAndValidity();
    this.lessonForm1['controls']['dbValue'].updateValueAndValidity();
    this.lessons1.push(this.lessonForm1);
    setTimeout(() => this.elmntref?.last.nativeElement.focus(), 1);
  }

  deleteLesson(lessonIndex: number) {
    this.lessons.removeAt(lessonIndex);
  }

  deleteLesson1(lessonIndex: number) {
    this.lessons1.removeAt(lessonIndex);
  }

  public responseRequiredDate: CustomDatePicker = {
    automationTypeImage: '',
    automationIdImage: '',
    automationTypeDatePicker: '',
    automationIdDatePicker: '',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: '',
    automationIdValidationError: '',
    placeholderCaption: '--/--/----',
    id: 'responseRequiredDate',
    tabindex: '',
    requiredText: 'Please select a date',
    validationMessage: '',
    isMandatory: false,
    name: 'responseRequiredDate',
    formControlName: 'responseRequiredDate',
    isDisabled: false,
    isWeekendsDisabled: true,
    isPublicHolidayDisabled: true,
    publicHolidayRegion: {
      country: 'AU',
      state: 'VIC',
      states: 'NSW,VIC'
    },
    isManualEntry: true,
    addBusinessDays: 2
  };

  getProductCatalogDataFromAPI(params?: any) {
    const param = params || null;
    return new Promise((resolve, reject) => {
      //const cidn = '99999999999';
      this.createQuoteService.getProductCatalogDataFromAPI(param, this.cidn).then((res) => {
        this.productData = this.createQuoteService.productDataObj
        this.createQuoteService.setProductData(this.productData);
        this.getDiversityDropdownValues();
        /* this.createQuotePayload = this.createQuoteService.getProductDetailsFormValues();
        const navigationState = window.history.state;
        if (navigationState.formData) {
          const formValue = navigationState.formData;
          this.getExistingFormData(formValue)

        } */
        //this.getDiversityDropdownValues();
        resolve(true);

      }).catch((err) => {
        this.productData = [];
        const msg = 'We can’t submit your request at this time. Please refresh and try again later.';
        this.showToasterMessage(msg, '', this.options, 'error');
        reject(err);
      });

    });
  }
  fundingSourceListChange(data) {

    const temp = JSON.stringify(data);
  }
  getExistingFormData(formValue) {
    if (formValue) {
      this.setFormValue('customerReference', formValue.customerReference);
      this.setFormValue('businessDriver', formValue.businessDriver);
      if (formValue.requestorConstraints) {
        this.setFormValue('requestorConstraints', formValue.requestorConstraints);
        this.createCustomerForm.controls['requestorConstraints'].setValue(formValue.requestorConstraints);
      } else if (this.createQuotePayload.requestorConstraints) {
        this.createCustomerForm.controls['requestorConstraints'].setValue(this.createQuotePayload.requestorConstraints);
      }
      const dateString = formValue.responseRequiredDate;
      if (dateString && this.serviceType === 'RG') {
        this.dateRequired = formValue.responseRequiredDate;
        const date = new Date(dateString);
        const fromDate = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
        };
        this.createCustomerForm.get('responseRequiredDate').setValue(fromDate);
      }
      let newArray = [];
      if (formValue?.externalReference?.length > 0) {
        newArray = formValue.externalReference.slice(1);
        newArray.forEach((data) => {
          const extForm = this.fb.group({
            externalReferenceType: [data.externalReferenceType],
            externalReferenceValue: [data.externalReferenceValue],
          });
          (this.form.get('lessons') as FormArray).push(extForm);
        });
      }
      if (formValue.customerType1) {
        this.setFormValue('customerType1', formValue.customerType1);
      } else if (this.createQuotePayload.externalReference?.length > 0) {
        this.createCustomerForm.controls['customerType1'].setValue(this.createQuotePayload.externalReference[0].externalReferenceType);
      }
      if (formValue.externalReference1) {
        this.setFormValue('externalReference1', formValue.externalReference1);
      } else if (this.createQuotePayload.externalReference?.length > 0) {
        this.createCustomerForm.controls['externalReference1'].setValue(this.createQuotePayload.externalReference[0].externalReferenceValue);
      }
      let newArray1 = [];
      if (formValue.maxAllowableOpticalLoss) {
        newArray1 = formValue.maxAllowableOpticalLoss.slice(1);
        newArray1.forEach((data) => {
          const maxForm = this.fb.group({
            wavelength: [data.wavelength],
            dbValue: [data.dbValue]
          });

          (this.form.get('lessons1') as FormArray).push(maxForm);

        });
      }
      if (formValue.maximumOpticalLossDropDown1) {
        this.setFormValue('maximumOpticalLossDropDown1', formValue.maximumOpticalLossDropDown1);
      } else if (this.createQuotePayload.maxAllowableOpticalLoss?.length > 0) {
        this.createCustomerForm.controls['maximumOpticalLossDropDown1'].setValue(this.createQuotePayload.maxAllowableOpticalLoss[0]?.wavelength);
      }
      if (formValue.maximumOpticalLossDropDownValue1) {
        this.setFormValue('maximumOpticalLossDropDownValue1', formValue.maximumOpticalLossDropDownValue1);
      } else if (this.createQuotePayload.maximumOpticalLossDropDownValue1?.length > 0) {
        this.createCustomerForm.controls['maximumOpticalLossDropDownValue1'].setValue(this.createQuotePayload.maxAllowableOpticalLoss[0]?.dbValue);
      }
      this.setFormValue('preferredMaxDistance', formValue.preferredMaxDistance);
      this.setFormValue('customerNotes', formValue.customerNotes);
      this.setFormValue('siteADiversityDropDown', formValue.aEndLeadIn);
      this.setFormValue('siteZDiversityDropDown', formValue.zEndLeadIn);
      if (formValue.aEndAdressSiteAlternativeName) {
        this.setFormValue('aEndAdressSiteAlternativeName', formValue.aEndAdressSiteAlternativeName)
      } else if (this.createQuotePayload.aEndAdressSiteAlternativeName?.length > 0 || this.createQuotePayload.businessDriver) {
        this.createCustomerForm.controls['aEndAdressSiteAlternativeName'].setValue(this.createQuotePayload.aEndAdressSiteAlternativeName);
      }
      if (formValue.zEndAdressSiteAlternativeName) {
        this.setFormValue('zEndAdressSiteAlternativeName', formValue.zEndAdressSiteAlternativeName);
      } else if (this.createQuotePayload.zEndAdressSiteAlternativeName?.length > 0 || this.createQuotePayload.businessDriver) {
        this.createCustomerForm.controls['zEndAdressSiteAlternativeName'].setValue(this.createQuotePayload.zEndAdressSiteAlternativeName);
      }
      if (formValue.a2EndAdressSiteAlternativeName) {
        this.setFormValue('a2EndAdressSiteAlternativeName', formValue.a2EndAdressSiteAlternativeName)
      } else if (this.createQuotePayload.a2EndAdressSiteAlternativeName?.length > 0) {
        this.createCustomerForm.controls['a2EndAdressSiteAlternativeName'].setValue(this.createQuotePayload.a2EndAdressSiteAlternativeName);
      }
      if (formValue.z2EndAdressSiteAlternativeName) {
        this.setFormValue('z2EndAdressSiteAlternativeName', formValue.z2EndAdressSiteAlternativeName);
      } else if (this.createQuotePayload.z2EndAdressSiteAlternativeName?.length > 0 || this.createQuotePayload.businessDriver) {
        this.createCustomerForm.controls['z2EndAdressSiteAlternativeName'].setValue(this.createQuotePayload.z2EndAdressSiteAlternativeName);
      }
    }
  }



  setFormValue(id, value) {
    if (id === 'requestorConstraints' && value === 'Yes') {
      this.onDiversityButtonChange({ value: value });
      if (this.lessonForm1) {
        this.lessonForm1['controls'][id].setValue(value);
      }
    } else if (id === 'externalReference' && value) {
      if (this.lessonForm) {
        this.lessonForm['controls'][id].setValue(value);
      }
    }
    this.createCustomerForm['controls'][id].patchValue(value);
  }

  saveQuote(val, plr?, isBack?) {
    if(!this.createCustomerForm) return;
    if((val=== false) && (isBack !=='isBack')){
      this.saveQuoteShow = true;
      setTimeout(() => {
        this.saveQuoteShow = false;
      }, 5000);
    }

    const DATA = this.createCustomerForm.value;
    let externalReference;
    if (this.serviceType === 'RG') {
      const test = this.lessons.value;
      this.DATA2 = this.lessons.value.filter(l => (l.externalReferenceType !== '' && l.externalReferenceValue !== ''));
      this.DupData = this.DATA2;
      this.passData = this.createCustomerForm.controls['customerType1'].value;
      this.passData1 = this.createCustomerForm.get('externalReference1').value;
      this.keyValueObject = { externalReferenceType: this.passData, externalReferenceValue: this.passData1 };

      if (this.DATA2.length > 0) {
        externalReference = [this.keyValueObject, ...this.DATA2];
      } else {
        externalReference = [this.keyValueObject]
      }
    }
let maxAllowableOpticalLoss;
    if (this.serviceType === 'RG' || this.showConstraintOption) {
      const test2 = this.lessons1.value;
      if (test2.length > 0) {
        this.DATA3 = this.lessons1.value.filter(l => (l.wavelength !== '' && l.dbValue !== ''));
      }

      this.passData2 = this.createCustomerForm.controls['maximumOpticalLossDropDown1'].value;
      this.passData3 = this.createCustomerForm.get('maximumOpticalLossDropDownValue1').value;
      this.keyValueObject1 = { wavelength: this.passData2, dbValue: this.passData3 };


      if (this.DATA3?.length > 0) {
        maxAllowableOpticalLoss = [this.keyValueObject1, ...this.DATA3,]
      } else {
        maxAllowableOpticalLoss = [this.keyValueObject1];
      }
    }

    const payload1 = {
      customerNotes: (this.createCustomerForm.controls['customerNotes']?.value)?.trimStart(),
      customerReference: this.createCustomerForm.get('customerReference').value,

      responseRequiredDate: this.dateRequired,
      // responseRequiredDate: "2023-10-10T00:00:00Z",
      aEndLeadIn: this.createCustomerForm.controls['siteADiversityDropDown'].value,
      zEndLeadIn: this.createCustomerForm.controls['siteZDiversityDropDown'].value,
      aEndAdressSiteAlternativeName: this.createCustomerForm.controls['aEndAdressSiteAlternativeName'].value,
      zEndAdressSiteAlternativeName: this.createCustomerForm.controls['zEndAdressSiteAlternativeName'].value,
      a2EndAdressSiteAlternativeName: this.createCustomerForm.controls['a2EndAdressSiteAlternativeName'].value,
      z2EndAdressSiteAlternativeName: this.createCustomerForm.controls['z2EndAdressSiteAlternativeName'].value
    };

    if (this.serviceType === 'RG') {
      payload1['customerType1'] = this.createCustomerForm.controls['customerType1'].value;
      payload1['externalReference1'] = this.createCustomerForm.controls['externalReference1'].value;
      payload1['externalReference'] = externalReference;
      payload1['businessDriver'] = this.createCustomerForm.controls['businessDriver'].value;
    }

    if (this.serviceType === 'RG' || this.showConstraintOption) {
      payload1['requestorConstraints'] = this.createCustomerForm.controls['requestorConstraints'].value;
      payload1['maxAllowableOpticalLoss'] = this.createCustomerForm.controls['requestorConstraints'].value === 'Yes' ? maxAllowableOpticalLoss : null;
      payload1['maximumOpticalLossDropDown1'] = this.createCustomerForm.controls['maximumOpticalLossDropDown1'].value;
      payload1['maximumOpticalLossDropDownValue1']= this.createCustomerForm.controls['maximumOpticalLossDropDownValue1'].value;
      payload1['preferredMaxDistance'] =  this.createCustomerForm.get('preferredMaxDistance').value;
    }

    const aEndAdress = this.createQuotePayload?.aEndAdress;
    const zEndAdress = this.createQuotePayload?.zEndAdress;
    const a2EndAdress = this.createQuotePayload?.a2EndAdress;
    const z2EndAdress = this.createQuotePayload?.z2EndAdress;
    const payload2 = {
      //cidn: '9999999999',
      cidn: this.createQuotePayload?.cidn,
      avoidPlrLogging: plr ? false : true,
      productName: this.createQuotePayload?.productName,
      subProduct: this.createQuotePayload?.subProduct,
      // customerName: 'InfraCo',
      customerName: this.createQuotePayload?.customerName,
      quoteReferenceId: this.quoteRefId,
      doYouRequireDiversity: this.createQuotePayload?.doYouRequireDiversity,
      describeYourDiversity: this.createQuotePayload?.describeYourDiversity,
      existingInfracoServiceInfo: this.createQuotePayload?.existingInfracoServiceInfo,
      thirdPartyPathInfo: this.createQuotePayload?.thirdPartyPathInfo,
      linkRequirement: this.createQuotePayload?.linkRequirement,
      quantityOfService: this.createQuotePayload?.quantityOfService,
      terms: this.createQuotePayload?.terms,
      aEndpreDefinedNodeName: this.createQuotePayload?.aEndpreDefinedNodeName,
      zEndpreDefinedNodeName: this.createQuotePayload?.zEndpreDefinedNodeName,
      a2EndpreDefinedNodeName: this.createQuotePayload?.a2EndpreDefinedNodeName,
      z2EndpreDefinedNodeName: this.createQuotePayload?.z2EndpreDefinedNodeName,
      sameAsAEndAddress: this.createQuotePayload?.sameAsAEndAddress,
      sameAsZEndAddress: this.createQuotePayload?.sameAsZEndAddress,
      sameAsAEndContact: this.createQuotePayload?.sameAsAEndContact,
      aEndAdress: {
        siteType: aEndAdress?.siteType ? aEndAdress.siteType : '',
        searchMethod: aEndAdress?.searchMethod ? aEndAdress.searchMethod : '',
        selectedAddress: aEndAdress?.selectedAddress ? aEndAdress.selectedAddress : '',
        definedPropertyLocation: aEndAdress?.definedPropertyLocation ? aEndAdress.definedPropertyLocation : '',
        additionalAddressInfo: aEndAdress?.additionalAddressInfo ? aEndAdress.additionalAddressInfo : '',
        adborId: aEndAdress?.adborId ? aEndAdress.adborId : '',
        latitude: aEndAdress?.latitude ? aEndAdress.latitude : '',
        longitude: aEndAdress?.longitude ? aEndAdress.longitude : '',
        nbnLocId: aEndAdress?.nbnLocId ? aEndAdress.nbnLocId : '',
        nodeCode: aEndAdress?.nodeCode ? aEndAdress.nodeCode : '',
        primaryContactName: aEndAdress?.primaryContactName ? aEndAdress.primaryContactName : '',
        primaryContactNumber: aEndAdress?.primaryContactNumber ? aEndAdress.primaryContactNumber : '',
        primaryContactEmail: aEndAdress?.primaryContactEmail ? aEndAdress.primaryContactEmail : '',
        altContactName: aEndAdress?.altContactName ? aEndAdress.altContactName : '',
        altContactNumber: aEndAdress?.altContactNumber ? aEndAdress.altContactNumber : '',
        altContactEmail: aEndAdress?.altContactEmail ? aEndAdress.altContactEmail : ''
      },
      zEndAdress: {
        siteType: zEndAdress?.siteType ? zEndAdress.siteType : '',
        searchMethod: zEndAdress?.searchMethod ? zEndAdress.searchMethod : '',
        selectedAddress: zEndAdress?.selectedAddress ? zEndAdress.selectedAddress : '',
        definedPropertyLocation: zEndAdress?.definedPropertyLocation ? zEndAdress.definedPropertyLocation : '',
        additionalAddressInfo: zEndAdress?.additionalAddressInfo ? zEndAdress.additionalAddressInfo : '',
        adborId: zEndAdress?.adborId ? zEndAdress.adborId : '',
        latitude: zEndAdress?.latitude ? zEndAdress.latitude : '',
        longitude: zEndAdress?.longitude ? zEndAdress.longitude : '',
        nbnLocId: zEndAdress?.nbnLocId ? zEndAdress.nbnLocId : '',
        nodeCode: zEndAdress?.nodeCode ? zEndAdress.nodeCode : '',
        primaryContactName: zEndAdress?.primaryContactName ? zEndAdress.primaryContactName : '',
        primaryContactNumber: zEndAdress?.primaryContactNumber ? zEndAdress.primaryContactNumber : '',
        primaryContactEmail: zEndAdress?.primaryContactEmail ? zEndAdress.primaryContactEmail : '',
        altContactName: zEndAdress?.altContactName ? zEndAdress.altContactName : '',
        altContactNumber: zEndAdress?.altContactNumber ? zEndAdress.altContactNumber : '',
        altContactEmail: zEndAdress?.altContactEmail ? zEndAdress.altContactEmail : ''
      },
      path2Z2SameAsAEndAddress: this.createQuotePayload?.path2Z2SameAsAEndAddress,
      path2Z2SameAsZEndAddress: this.createQuotePayload?.path2Z2SameAsZEndAddress,
      path2A2sameAsAEndContact: this.createQuotePayload?.path2A2sameAsAEndContact,
      path2A2sameAsZEndContact: this.createQuotePayload?.path2A2sameAsZEndContact,
      a2EndAdress: {
        siteType: a2EndAdress?.siteType ? a2EndAdress.siteType : '',
        searchMethod: a2EndAdress?.searchMethod ? a2EndAdress.searchMethod : '',
        selectedAddress: a2EndAdress?.selectedAddress ? a2EndAdress.selectedAddress : '',
        definedPropertyLocation: a2EndAdress?.definedPropertyLocation ? a2EndAdress.definedPropertyLocation : '',
        additionalAddressInfo: a2EndAdress?.additionalAddressInfo ? a2EndAdress.additionalAddressInfo : '',
        adborId: a2EndAdress?.adborId ? a2EndAdress.adborId : '',
        latitude: a2EndAdress?.latitude ? a2EndAdress.latitude : '',
        longitude: a2EndAdress?.longitude ? a2EndAdress.longitude : '',
        nbnLocId: a2EndAdress?.nbnLocId ? a2EndAdress.nbnLocId : '',
        nodeCode: a2EndAdress?.nodeCode ? a2EndAdress.nodeCode : '',
        primaryContactName: a2EndAdress?.primaryContactName ? a2EndAdress.primaryContactName : '',
        primaryContactNumber: a2EndAdress?.primaryContactNumber ? a2EndAdress.primaryContactNumber : '',
        primaryContactEmail: a2EndAdress?.primaryContactEmail ? a2EndAdress.primaryContactEmail : '',
        altContactName: a2EndAdress?.altContactName ? a2EndAdress.altContactName : '',
        altContactNumber: a2EndAdress?.altContactNumber ? a2EndAdress.altContactNumber : '',
        altContactEmail: a2EndAdress?.altContactEmail ? a2EndAdress.altContactEmail : ''
      },
      path2Z2sameAsAEndContact: this.createQuotePayload?.path2Z2sameAsAEndContact,
      path2Z2sameAsZEndContact: this.createQuotePayload?.path2Z2sameAsZEndContact,
      path2Z2sameAsA2EndContact: this.createQuotePayload?.path2Z2sameAsA2EndContact,
      z2EndAdress: {
        siteType: z2EndAdress?.siteType ? z2EndAdress.siteType : '',
        searchMethod: z2EndAdress?.searchMethod ? z2EndAdress.searchMethod : '',
        selectedAddress: z2EndAdress?.selectedAddress ? z2EndAdress.selectedAddress : '',
        definedPropertyLocation: z2EndAdress?.definedPropertyLocation ? z2EndAdress.definedPropertyLocation : '',
        additionalAddressInfo: z2EndAdress?.additionalAddressInfo ? z2EndAdress.additionalAddressInfo : '',
        adborId: z2EndAdress?.adborId ? z2EndAdress.adborId : '',
        latitude: z2EndAdress?.latitude ? z2EndAdress.latitude : '',
        longitude: z2EndAdress?.longitude ? z2EndAdress.longitude : '',
        nbnLocId: z2EndAdress?.nbnLocId ? z2EndAdress.nbnLocId : '',
        nodeCode: z2EndAdress?.nodeCode ? z2EndAdress.nodeCode : '',
        primaryContactName: z2EndAdress?.primaryContactName ? z2EndAdress.primaryContactName : '',
        primaryContactNumber: z2EndAdress?.primaryContactNumber ? z2EndAdress.primaryContactNumber : '',
        primaryContactEmail: z2EndAdress?.primaryContactEmail ? z2EndAdress.primaryContactEmail : '',
        altContactName: z2EndAdress?.altContactName ? z2EndAdress.altContactName : '',
        altContactNumber: z2EndAdress?.altContactNumber ? z2EndAdress.altContactNumber : '',
        altContactEmail: z2EndAdress?.altContactEmail ? z2EndAdress.altContactEmail : ''
      },
      feasibilityRequestType: this.createQuotePayload.feasibilityRequestType ? this.createQuotePayload.feasibilityRequestType : '',
      fundingSource: this.createQuotePayload.fundingSource ? this.createQuotePayload.fundingSource : '',
      l3WBSCodeCapex: this.createQuotePayload.l3WBSCodeCapex ? this.createQuotePayload.l3WBSCodeCapex : '',
      l3WBSCodeOpex: this.createQuotePayload.l3WBSCodeOpex ? this.createQuotePayload.l3WBSCodeOpex : '',
      otherPowImc: this.createQuotePayload.otherPowImc ? this.createQuotePayload.otherPowImc : '',
      preApprovalCost: this.createQuotePayload.preApprovalCost ? this.createQuotePayload.preApprovalCost : '',
      predefinedQuote: this.isPreDefinedRoute ? this.isPreDefinedRoute : 'No'
    };
    let payload = { ...payload1, ...payload2 };
    if (isBack) {
      this.removeSplChar(payload)
    }
    if (this.isModifyQuote) {
      this.modifyQuote(val, true);
    } else {
      this.saveFeasibilityRequest(payload, val);
    }
    this.createQuoteService.setProductDetailsFormValues(payload);
  }

  removeSplChar(payload) {
    const regexOpenAngularBraces = new RegExp('<');
    const regexCloseAngularBraces = new RegExp('>');
    const regexSemiColon = new RegExp(';');

    for (const key in payload) {
      const value = payload[key];
      if (typeof value === "object") {
        this.removeSplChar(value);
      } else {
        const match1 = regexOpenAngularBraces.test(value);
        const match2 = regexCloseAngularBraces.test(value);
        const match3 = regexSemiColon.test(value);
        if (match1 || match2 || match3) {
          payload[key] = '';
        }
      }
    }
  }

  saveFeasibilityRequest(payload, val) {

    if(this.serviceType !== "RG") {
      this.createQuoteService.nonRGPayloadPrepare(payload, this.serviceType);
    }

    return new Promise((resolve, reject) => {
      this.createQuoteService.saveNewQuote(payload, 'Save').then((res) => {
        //  this.quoteRefId = res.data.quoteReferenceId;
        this.isQuoteSaved = true;
        if (val) {
          this.router.navigateByUrl('/review-submit');
        }
        resolve(true);

      }).catch((err) => {
        const msg = err?.error?.message ? err.error.message : 'Something went wrong';
        this.showToasterMessage(msg, '', this.options, 'error');
        reject(err);
      });

    });
  }
  touchBusinessDriver() {
    // alert('touched');
    this.showAlertDriver = true;
  }

  createForm() {
    this.createCustomerForm = this.fb.group({
      //customerName: ['', [Validators.required, Validators.pattern(this.utility.getNameRegEx())]],
      aEndAdressSiteAlternativeName: ['', Validators.pattern(this.utility.getNameRegEx())],
      zEndAdressSiteAlternativeName: ['', Validators.pattern(this.utility.getNameRegEx())],
      a2EndAdressSiteAlternativeName: ['', Validators.pattern(this.utility.getNameRegEx())],
      z2EndAdressSiteAlternativeName: ['', Validators.pattern(this.utility.getNameRegEx())],
      customerNotes: ['', Validators.pattern(this.pattern)],
      customerReference: ['', Validators.pattern(this.pattern)],
      businessDriver: [''],
      responseRequiredDate: [this.fromDate],
      doYouRequireDiversity: ['No'],
      siteADiversityDropDown: [''],
      siteZDiversityDropDown: [''],
      preferredMaxDistance: [''],
      requestorConstraints: ['No', Validators.required],
      customerType1: ['Other', Validators.required],
      externalReference1: [''],
      externalReferenceValue: [''],
      maximumOpticalLossDropDown1: [''],
      maximumOpticalLossDropDownValue1: ['23', [Validators.required, Validators.min(1), Validators.max(50), Validators.pattern(/^[0-9]+(.[0-9]{0,2})?$/)]],
      dbValue: [''],
      externalReferenceType: ['']
    });

    if(this.serviceType==='RG'){
      this.createCustomerForm['controls']['businessDriver'].setValidators([Validators.required]);
      this.createCustomerForm['controls']['responseRequiredDate'].setValidators([Validators.required]);
      this.createCustomerForm['controls']['externalReference1'].setValidators([Validators.required, Validators.pattern(this.pattern)]);
    }
    this.getLabels();
  }
  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-bottom-right';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    // document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    //document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }

  public preferredMaxDistance: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblname',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxname',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divCheckServiceValidationErrMsg',
    placeholderCaption: 'value',
    id: 'preferredMaxDistance',
    tabindex: '0',
    maxlength: '255',
    headerText: '',
    headerTextAccessible: 'customer Name required',
    inputText: '',
    requiredText: 'Plese enter preferred maximum distance',
    validationMessage: 'Please enter values except ,`=~#!<>+@',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'preferredMaxDistance',
    formControlName: 'preferredMaxDistance',
    isDisabled: false,
    showValidationIcon: true
  };
  public OpticalLossValueModel: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblname',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxname',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divCheckServiceValidationErrMsg',
    placeholderCaption: 'value',
    id: 'dbValue',
    tabindex: '0',
    maxlength: '255',
    headerText: '',
    headerTextAccessible: 'customer Name required',
    inputText: '',
    requiredText: 'Plese enter optical loss value',
    validationMessage: 'Please enter values except ,`=~#!<>+@',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'dbValue',
    formControlName: 'dbValue',
    isDisabled: false,
    showValidationIcon: true
  };
  public maximumOpticalLossDropDownValue1: CustomTextField = {
    automationTypeLabel: 'label',
    automationIdLabel: 'lblname',
    automationTypeTextField: 'textbox',
    automationIdTextField: 'txtbxname',
    automationTypeRequired: '',
    automationIdRequired: '',
    automationTypeValidationError: 'div',
    automationIdValidationError: 'divCheckServiceValidationErrMsg',
    placeholderCaption: 'value',
    id: 'maximumOpticalLossDropDownValue1',
    tabindex: '0',
    maxlength: '255',
    headerText: '',
    headerTextAccessible: 'customer Name required',
    inputText: '',
    requiredText: 'Plese enter optical loss value',
    validationMessage: 'Please enter values except ,`=~#!<>+@',
    pattern: '',
    isMandatory: false,
    isKeyDownDefaultValidation: false,
    name: 'maximumOpticalLossDropDownValue1',
    formControlName: 'maximumOpticalLossDropDownValue1',
    isDisabled: false,
    showValidationIcon: true
  };

  saveInputValue(num1) {

  }

  onDiversityButtonChange(event) {
    if (event.value === 'Yes') {
      // this.getDiversityDropdownValues();
      this.isDiversityRequired = true;
      if (this.createQuotePayload.maxAllowableOpticalLoss?.length > 0) {
        this.createCustomerForm.controls['maximumOpticalLossDropDown1'].setValue(this.createQuotePayload.maxAllowableOpticalLoss[0]?.wavelength);
        this.createCustomerForm.controls['maximumOpticalLossDropDownValue1'].setValue(this.createQuotePayload.maxAllowableOpticalLoss[0]?.dbValue);
      } else {
        this.createCustomerForm.controls['maximumOpticalLossDropDown1'].setValue('1550nm');
        this.createCustomerForm.controls['maximumOpticalLossDropDownValue1'].setValue('23');
      }
    } else {
      this.isDiversityRequired = false;

    }
  }

  onRadioButtonChange(value) {
    this.onDiversityButtonChange({value});
    this.createCustomerForm.controls['requestorConstraints'].setValue(value)
  }

  private getDiversityDropdownValues() {
    this.businessDriverList = [];
    if(this.serviceType === 'RG'){
    this.createCustomerForm['controls']['businessDriver'].setValidators([Validators.required]);
    }
    if (this.productData) {
      this.productData.forEach(val => {
        if (val.name === 'Dark Fibre') {
          val.prodSpecCharValueUse.forEach(value => {
            if (value.name === 'Business Driver') {
              value.productSpecCharacteristicValue.forEach(a => { this.businessDriverList.push(a.value) })
            }
            if (value.name === 'External Reference Options') {
              value.productSpecCharacteristicValue.forEach(a => { this.externalReferenceList.push(a.value) })
            }
            if (value.name === 'Wave Length Options') {
              value.productSpecCharacteristicValue.forEach(a => { this.maximumOpticalLossDropDownList.push(a.value) })
              this.maximumOpticalLossDropDownList.sort();
            }
          })

          this.businessDriverList.sort(function (a, b) { return (a.trim().toLowerCase() > b.trim().toLowerCase() ? 1 : (a.trim().toLowerCase() === b.trim().toLowerCase() ? 0 : -1)) })
          this.externalReferenceList.sort(function (a, b) { return (a.trim().toLowerCase() > b.trim().toLowerCase() ? 1 : (a.trim().toLowerCase() === b.trim().toLowerCase() ? 0 : -1)) })
        }
      })
    }
  }
  setDatePicker() {
    let hd = new Holidays('AU', 'NSW');
    const today = this.moment().tz(this.selectedTimeZone).toDate();

    hd.getHolidays(today.getFullYear()).forEach(dateObj => {
      this.publicHolidays.push(dateObj.date.substr(0, 10));
    });

    if (today.getMonth() >= 10) {
      hd.getHolidays(today.getFullYear() + 1).forEach(dateObj => {
        this.publicHolidays.push(dateObj.date.substr(0, 10));
      });
    }
    this.momentBusiness.updateLocale('au', {
      holidays: this.publicHolidays,
      holidayFormat: 'YYYY-MM-DD'
    });
    let minDate
    if (moment_business_days(this.moment(today).format('YYYY-MM-DD'), 'YYYY-MM-DD').isBusinessDay()) {
      minDate = this.momentBusiness(today).businessAdd(2).toDate();
      this.responseRequiredDate.addBusinessDays = 2;
    } else {
      minDate = this.momentBusiness(today).businessAdd(3).toDate();
      this.responseRequiredDate.addBusinessDays = 3;
    }
    //    this.selectedServiceDate = '0/0/'; // value given to hide error msg
    this.fromDate = {
      year: minDate.getFullYear(),
      month: minDate.getMonth() + 1,
      day: minDate.getDate()
    };
    this.createCustomerForm.get('responseRequiredDate').setValue(this.fromDate);
    const date = new Date(this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day);
    this.dateRequired = this.fromDate.year + '-' + ((this.fromDate.month.toString()).length === 1 ? ('0' + this.fromDate.month) : (this.fromDate.month)) + '-' + ((this.fromDate.day.toString()).length === 1 ? ('0' + this.fromDate.day) : (this.fromDate.day)) + 'T00:00:00Z'
    this.moment(date).utc().format();
    this.toMaxDate = {
      year: minDate.getFullYear() + 1,
      month: minDate.getMonth() + 1,
      day: minDate.getDate()
    };
  }

  onChangeServiceDate($event) {
    this.selectedServiceDate = this.createCustomerForm.controls['responseRequiredDate'].value;
    //  const date = new Date(this.selectedServiceDate.year + '-' + this.selectedServiceDate.month + '-' + this.selectedServiceDate.day + 'T00:00:00Z');
    this.dateRequired = this.selectedServiceDate.year + '-' + ((this.selectedServiceDate.month.toString()).length === 1 ? ('0' + this.selectedServiceDate.month) : (this.selectedServiceDate.month)) + '-' + ((this.selectedServiceDate.day.toString()).length === 1 ? ('0' + this.selectedServiceDate.day) : (this.selectedServiceDate.day)) + 'T00:00:00Z'
    // this.moment(date).utc().format();
  }

  formValueChange() {
    this.createCustomerForm.valueChanges.subscribe(val => {
      if (this.isQuoteSaved) {
        this.isQuoteSaved = false;
      }
    })
  }

  getSelectedAddress(adr) {
    if (adr?.selectedAddress !== '(New address)') {
      return adr?.selectedAddress;
    } else {
      return (adr?.latitude + ',  ' + adr?.longitude);
    }
  }

  numberOnly(event): boolean {
    let value = event.target.value;
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let current: string = value;
    const position = event.target.selectionStart;
    const next: string = [current.slice(0, position), event.key == 'Decimal' ? '.' : event.key, current.slice(position)].join('');
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  getRequestDetailsFormData() {
    this.quoteDataToModify = this.createCustomerForm.value;
  }

  getModifiedFormValues() {
    this.createCustomerForm.valueChanges.subscribe((updatedval) => {
      for (const key of Object.keys(updatedval)) {
        if (updatedval[key] !== this.quoteDataToModify[key]) {
          this.modifiedFormFields[key] = updatedval[key]
        }
      }

      if (this.modifiedFormFields.hasOwnProperty('customerType1') || this.modifiedFormFields.hasOwnProperty('externalReference1')) {
        const DATA2 = this.lessons.value.filter(l => (l.externalReferenceType !== '' && l.externalReferenceValue !== ''));
        const val = updatedval['customerType1'];
        const val1 = updatedval['externalReference1'];
        const keyValueObject = { externalReferenceType: val, externalReferenceValue: val1 };
        let externalReference
        if (DATA2.length > 0) {
          externalReference = [keyValueObject, ...DATA2];
        } else {
          externalReference = [keyValueObject]
        }
        this.modifiedFormFields['externalReference'] = externalReference;
        delete this.modifiedFormFields['customerType1'];
        delete this.modifiedFormFields['externalReference1'];
      }

      if (this.modifiedFormFields.hasOwnProperty('maximumOpticalLossDropDown1') || this.modifiedFormFields.hasOwnProperty('maximumOpticalLossDropDownValue1')) {

        const DATA3 = this.lessons1?.value?.filter(l => (l.wavelength !== '' && l.dbValue !== ''));
        const val2 = updatedval['maximumOpticalLossDropDown1'];
        const val3 = updatedval['maximumOpticalLossDropDownValue1'];
        const keyValueObject1 = { wavelength: val2, dbValue: val3 };

        let maxAllowableOpticalLoss;
        if (DATA3?.length > 0) {
          maxAllowableOpticalLoss = [keyValueObject1, ...DATA3,]
        } else {
          maxAllowableOpticalLoss = [keyValueObject1];
        }
        this.modifiedFormFields['maxAllowableOpticalLoss'] = maxAllowableOpticalLoss;
        delete this.modifiedFormFields['maximumOpticalLossDropDown1'];
        delete this.modifiedFormFields['maximumOpticalLossDropDownValue1'];
      }

      if (this.modifiedFormFields.hasOwnProperty('siteADiversityDropDown')) {
        this.modifiedFormFields['aEndLeadIn'] = updatedval['siteADiversityDropDown']
        delete this.modifiedFormFields['siteADiversityDropDown'];
      }
      if (this.modifiedFormFields.hasOwnProperty('siteZDiversityDropDown')) {
        this.modifiedFormFields['zEndLeadIn'] = updatedval['siteZDiversityDropDown']
        delete this.modifiedFormFields['siteZDiversityDropDown'];
      }
    });

  }

  getMaxLoss() {
    const DATA3 = this.lessons1?.value?.filter(l => (l.wavelength !== '' && l.dbValue !== ''));
    if (DATA3.length > 0) {
      const data = [
        { dbValue: this.createCustomerForm.controls['maximumOpticalLossDropDownValue1'].value, wavelength: this.createCustomerForm.controls['maximumOpticalLossDropDown1'].value },
        ...DATA3]
      this.modifiedFormFields['maxAllowableOpticalLoss'] = data;
    }
  }

  modifyQuote(val, plr?) {
    if (this.createCustomerForm.controls['requestorConstraints'].value === 'Yes') {
      this.getMaxLoss();
    }
    this.modifiedFormFields['responseRequiredDate'] = this.dateRequired;
    //empty maxAllowableOpticalLoss incase of non-rg and pre-defined route during modify flow
    if(this.serviceType !== 'RG' && !this.showConstraintOption){
      this.modifiedFormFields['maxAllowableOpticalLoss'] = [];
      this.modifiedFormFields['preferredMaxDistance'] = "";
    }
    const payload = {
      action: 'Save',
      cidn: this.cidn,
      avoidPlrLogging: plr,
      quoteReferenceId: this.quoteRefId,
      modifiedDetails: Object.keys(this.modifiedFormFields),
      ...this.modifiedFormFields
    }
    if (val) {
      payload['avoidPlrLogging'] = false;
    }
    return new Promise((resolve, reject) => {
      this.createQuoteService.modifyFeasibilityRequest(payload).then((res) => {
        this.isQuoteSaved = true;
        if (val) {
          this.router.navigateByUrl('/review-submit');
        }
        resolve(true);

      }).catch((err) => {
        const msg = err?.error?.message ? err.error.message : 'Something went wrong';
        this.showToasterMessage(msg, '', this.options, 'error');
        reject(err);
      });

    });
  }

  onNotesKeyPress(event, val) {
    const element = document.getElementById('customerNotes');
    if(event.key===" ") {
      const label = `${(val+1)} out of 4000 characters`
      element.setAttribute('aria-label', label)
    }
  }
  ngOnDestroy(): void {
    if (this.requestHeaderChange) {
      this.requestHeaderChange.unsubscribe();
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyUp(event) {
    const activeElement = document.activeElement;
    const textareaElement = document.getElementById('customerNotes')
    if (event.ctrlKey) {
      if(activeElement === textareaElement) {
       const computedStyle = window.getComputedStyle(textareaElement);
       const currentHeight = parseInt(computedStyle.getPropertyValue('height'), 10);
       const currentRows = parseInt(textareaElement.getAttribute('rows'), 10)
        if (event.key === 'ArrowUp' && currentRows > 1) {
          textareaElement.setAttribute('rows', `${currentRows - 1}`)
            textareaElement.style.height = `${currentHeight - 10}px`;
        } else if (event.key === 'ArrowDown') {
          textareaElement.setAttribute('rows', `${currentRows + 1}`)
          textareaElement.style.height = `${currentHeight + 10}px`;
        }
      }
    }

    const externalRefElement = document.getElementById('externalReference1');
    const radioElement = document.getElementById('constraintYesRadio');
    const radioElement2 = document.getElementById('constraintNoRadio')
    if (radioElement) {
      if (activeElement === externalRefElement) {
        radioElement.removeAttribute('tabindex');
        radioElement2.removeAttribute('tabindex');
      }
      if (event.key === 'Tab') {
        radioElement.setAttribute('tabindex', '0');
        radioElement2.setAttribute('tabindex', '0');
      }
     
    }
  }


  validateAlphaNumeric(value: string): boolean {
    return /^[a-zA-Z\d\s]+$/.test(value);
  }


  typeAlphaNumeric(event: KeyboardEvent) {
    // checks for alphanumeric
    const char = event.key.toLowerCase();
    if (!((char >= '0' && char <= '9') || (char >= 'a' && char <= 'z') || char === ' ')) {
      event.preventDefault();
    }
  }

  onPasteCheckAlphaNumeric(event: ClipboardEvent): void {
    // on paste checks for alphanumeric
    const d = event.clipboardData.getData('text/plain');
    if (!this.validateAlphaNumeric(d)) {
      event.preventDefault();
    }
  }

}


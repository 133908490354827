import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomTextField, CustomDropDown, TooltipContent } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
// import { CabinetsUploadDocumentPageData } from './upload-cabinets-document.model';
import { CabinetsService } from '../cabinets.service';
import { DashboardService } from './../dashboard.service';
import { ToastrService, GlobalConfig, ToastContainerDirective } from 'ngx-toastr';
import { SuccessModalWithCopyLinkComponent } from './success-modal-with-copy-link/success-modal-with-copy-link.component';
import { SuccessModalWithCopyLinkService } from './success-modal-with-copy-link/success-modal-with-copy-link.service';
import { CommonService } from 'src/app/shared/services/common.service';
// import { ClipboardService } from 'ngx-clipboard';
import { CustomProgressBar } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-upload-cabinets-document',
  templateUrl: './upload-cabinets-document.component.html',
  styleUrls: ['./upload-cabinets-document.component.less']
})

export class UploadCabinetsDocumentComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  @Output() navigateToDocList = new EventEmitter();
  @Output() goToCabinet = new EventEmitter();
  public aemLabelAuthoring: any = null;
  public uploadDocumentForm: FormGroup;
  public documentTypeList = [];
  options: GlobalConfig;
  selectedDocumentType: string;
  comments: string;
  documentTitle: string;
  documentKeyword: string;
  public documentTypeModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Document Type Required Dropdown.Press Enter/return to expand the Dropdown and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  uploadedFile: {
    fileName: string,
    canShowProgressBar: boolean,
    isUploadDone: boolean,
    validationError: string
  } = {
      fileName: '',
      canShowProgressBar: false,
      isUploadDone: false,
      validationError: ''
    };
  fileToUpload: FileList = null;
  isFormSubmitted: boolean;
  uploadProgress = 0;
  uploadProgressSubscription: Subscription;
  public dateTooltipContentModel: TooltipContent = {
    content: 'Please include any key words that reflect the nature of this document.',
    automationTypeTooltipIcon: 'image',
    automationIdTooltipIcon: 'imgAddressInformation'
  };

  allowedExtensions: Array<String> = [
    "XLS",
    "XLSX",
    "XLSB",
    "CSV",
    "DOC",
    "DOCX",
    "PDF",
    "JPEG",
    "JPG",
    "PNG",
    "ICO",
    "BMP",
    "PPTX",
    "ZIP",
    "TXT",
    "EML",
    "MSG",
    "JSON",
    "XML"
  ];
  cabinetId: string;
  cabinetName: string;
  cabinetRole: string;
  cabinetsDetails: any;
  isInfracoAdmin: boolean;
  roles = [];
  isModalOpen: boolean = false;
  isCancelModalOpen: boolean = false;
  focusableElement: HTMLElement;

  public progressBarModel: CustomProgressBar = {
    statusLabel: '',
    showCompact: true,
    focus: false
  };
  constructor(
    private cabinetsService: CabinetsService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private dashBoardSvc: DashboardService,
    private SuccessModalService: SuccessModalWithCopyLinkService,
    private commonService: CommonService
  ) {
    const routeParams = this.activeRoute.snapshot.params;
    // this.cabinetId = decodeURIComponent(routeParams.id || '');
    // this.cabinetName = decodeURIComponent(routeParams.name || '');
    // this.cabinetRole = decodeURIComponent(routeParams.role || '');
    this.cabinetId = routeParams.id?.replace(/%[^%]+?0/g, ' ');
    this.cabinetName = routeParams.name?.replace(/%[^%]+?0/g, ' ');
    this.cabinetRole = routeParams.role?.replace(/%[^%]+?0/g, ' ');
  }

  ngOnInit() {
    if (this.cabinetId && this.cabinetName) {
      this.options = this.toastr.toastrConfig;
      this.createForm();
    } else {
      this.router.navigateByUrl('/cabinets');
    }
    const roles = this.commonService.getroleDetails().customerList;
    if (!roles.some((obj) => obj.role.includes('NBN') || obj.function.includes('NBN'))) {
      this.router.navigateByUrl('/access-denied');
    }
  }

  closeUploadModal() {
    this.isCancelModalOpen = false;
  }

  onSubmitDomain() {
    this.isCancelModalOpen = false;
    this.gotoListPage();
  }

  closeModal() {
    this.isModalOpen = false;
    this.gotoListPage();
  }

  onClickReplace() {
    this.uploadProgress = 0;
    let overWriteFile = true;
    this.isFormSubmitted = true;
    this.uploadedFile.canShowProgressBar = true;
    this.uploadedFile.isUploadDone = false;
    let jsonData: any = {
      cabinetId: this.cabinetId,
      documentTitle: this.documentTitle,
      documentType: this.selectedDocumentType || '',
      comments: this.comments,
      keyWords: this.documentKeyword
    }
    this.isModalOpen = false;
    this.uploadProgressSubscription = this.cabinetsService.uploadProgressStatus.subscribe(progress => {
      // Set an interval to increment the progress gradually
          const interval = setInterval(() => {
            this.uploadProgress = (this.uploadProgress < progress) ? this.uploadProgress++ : this.uploadProgress;
          }, 1);        
   }
    
 );
    this.cabinetsService.uploadDoc(jsonData, this.fileToUpload, overWriteFile).then((res: any) => {
      this.isFormSubmitted = false;
      if (res && res.data && res.data.fileName) {
        const filename = res.data.fileName;
        const modCid = this.encodeURIComponent(this.cabinetId);
        const modFilename = this.encodeURIComponent(this.documentTitle);
        const link = `${window.location.protocol}//${window.location.host}/document/${modCid}/${modFilename}`;
        const content = {
          successMsg: 'Congratulations, You have successfully replaced exisiting document.',
          title: 'External document URL',
          toastrMsg: 'Copy external document URL',
          link: link
        };
        this.cabinetsService.fetchError(null);
        this.SuccessModalService.show(SuccessModalWithCopyLinkComponent, content);
        document.getElementById("success-modal-with-copy-link ")?.focus();
        this.gotoListPage();
      } else {
        const msg = res.message || 'Something went wrong.';
        this.cabinetsService.fetchError(msg);
        this.gotoListPage();
        this.showToasterMessage(msg, '', this.options, 'error');
      }
    }).catch((err) => {
      this.isFormSubmitted = false;
      let msg;
      if (err?.error && err?.error?.message) {
        msg = err.error.message;
      } else {
        msg = 'Something went wrong.';
      }
      this.cabinetsService.fetchError(msg);
      this.gotoListPage();
      this.showToasterMessage(msg, '', this.options, 'error');
    });
  }

  createForm() {
    this.uploadDocumentForm = this.fb.group({
      ID: [undefined, [Validators.required]]
    });
    this.getLabels();
  }

  getLabels() {
    return new Promise((resolve, reject) => {
      this.cabinetsService.getuploadDocumetModel().then(() => {
        this.aemLabelAuthoring = this.cabinetsService.uploadLabelAuthoringDataObj;
        this.documentTypeList = this.aemLabelAuthoring.form.documentTypeList;
        resolve(true);
      }).catch(() => {
        reject(false);
      });
    });
  }

  navigate(path) {
    this.router.navigate([path]);
  }

  uploadFile(event: any): void {
    this.uploadedFile.canShowProgressBar = false;
    this.uploadedFile.isUploadDone = false;
    const regex = new RegExp('((\\\\)|(/)|(:)|(;)|(\\*)|(\\?)|(")|(<)|(>)|(\\|)|(#))', "g");
    if (event.item) {
      this.fileToUpload = event;
    } else if (event.files) {
      this.fileToUpload = event.files;
    }
    let fileArr = Array.from(this.fileToUpload);
    this.uploadedFile.fileName = fileArr[0].name;
    const fileExtension = fileArr[0].name.split('.').pop().toUpperCase();
    const fileSize = Math.round(fileArr[0].size * 0.00000095367432);
    if ((<any>this.allowedExtensions).indexOf(fileExtension) === -1) {
      this.uploadedFile.validationError = 'The upload of your attachment was unsuccessful. Please try again using one of the acceptable file types and if the issue persists please contact Telstra - InfraCo support team.';
      this.uploadedFile.fileName = '';
    } else if (fileSize > 25) {
      this.uploadedFile.validationError = 'The upload of your attachment was unsuccessful. Please try again using a file 25MB or smaller and if the issue persists please contact Telstra - InfraCo support team.';
      this.uploadedFile.fileName = '';
    } else if (this.uploadedFile.fileName.length > 100) {
      this.uploadedFile.validationError = 'The upload of your attachment was unsuccessful. Please try again using a file name of length 100 or smaller and if the issue persists please contact Telstra - InfraCo support team.';
      this.uploadedFile.fileName = '';
    } else if (this.uploadedFile.fileName.match(regex)) {
      this.uploadedFile.validationError = 'The upload of your attachment was unsuccessful. Please try again using a file name without \\ / : ; * ? “ < > | # and if the issue persists please contact Telstra - InfraCo support team.';
      this.uploadedFile.fileName = '';
    } else {
      this.uploadedFile.validationError = '';
    }
    this.documentTitle = this.uploadedFile.fileName;
    //  setTimeout(() => {
    //    this.documentTitle = this.uploadedFile.fileName;
    //    //this.uploadedFile.isUploadDone = true;
    //  }, 500);
  }

  deleteAttachment(): void {
    this.uploadedFile = {
      fileName: '',
      canShowProgressBar: false,
      isUploadDone: false,
      validationError: ''
    };
    this.documentTitle = '';
    this.uploadProgress = 0;
  }

  checkDisabled(): boolean {
  //  return !this.selectedDocumentType || !this.documentTitle || this.uploadedFile.validationError != '' || !this.uploadedFile.isUploadDone;
     return !this.selectedDocumentType || !this.documentTitle || this.uploadedFile.validationError != '' ;
  }

  gotoListPage() {
    const cabinetName = this.encodeURIComponent(this.cabinetName);
    const cabinetId = this.encodeURIComponent(this.cabinetId);
    const cabinetRole = this.encodeURIComponent(this.cabinetRole);
    this.router.navigate([`cabinet-list/${cabinetName}/${cabinetId}/${cabinetRole}`]);
  }

  onClickSave() {
    let overWriteFile = false;
    this.uploadedFile.canShowProgressBar = true;
    this.uploadedFile.isUploadDone = false;
    if (!this.isFormSubmitted) {
      this.isFormSubmitted = true;
      let jsonData: any = {
        cabinetId: this.cabinetId,
        documentTitle: this.documentTitle,
        documentType: this.selectedDocumentType || '',
        comments: this.comments,
        keyWords: this.documentKeyword
      }

      
    this.uploadProgressSubscription = this.cabinetsService.uploadProgressStatus.subscribe(progress => {
      // Set an interval to increment the progress gradually
      const interval = setInterval(() => {
        if (this.uploadProgress < progress) {
          this.uploadProgress += 1;
        }
      }, 1);        
      }
    );

      this.cabinetsService.uploadDoc(jsonData, this.fileToUpload, overWriteFile).then((res: any) => {
        this.isFormSubmitted = false;
        this.uploadedFile.isUploadDone = false;
        if (res["code"] === 400 && res["message"] == "Try renaming the file and uploading as the filename exists.") {
          this.isModalOpen = true;
          setTimeout(() => {
            this.focusableElement = document.getElementById('cls');
            if (this.focusableElement) {
              this.focusableElement.focus();
            }
          }, 1);
          this.commonService.trapFocusInModal('cls', 'cancel');
        
        } else if (res  && res.data.fileName ) {
            if (this.uploadProgress >= 100) { // upload is complete
              const modCid = this.encodeURIComponent(this.cabinetId);
              const modFilename = this.encodeURIComponent(this.documentTitle);
              const link = `${window.location.protocol}//${window.location.host}/document/${modCid}/${modFilename}`;
              const content = {
                successMsg: 'Congratulations, You have successfully uploaded a new document.',
                title: 'External document URL',
                toastrMsg: 'Copy external document URL',
                link: link
              };
              this.documentTitle = this.uploadedFile.fileName;
                this.uploadedFile.isUploadDone = true;
                this.cabinetsService.fetchError(null); 
                this.SuccessModalService.show(SuccessModalWithCopyLinkComponent, content);
                this.gotoListPage();
            }
        } else {
          const msg = res.message || 'Something went wrong.';
          this.cabinetsService.fetchError(msg);
          this.uploadProgress = 0;
          this.gotoListPage();
          this.showToasterMessage(msg, '', this.options, 'error');
        }
      }).catch((err) => {
        this.isFormSubmitted = false;
        this.uploadedFile.isUploadDone = false;
        this.uploadedFile.canShowProgressBar = false;
        this.uploadProgress = 0;
        if (err?.error && err?.error?.status === 400 && err.error.message == "Try renaming the file and uploading as the filename exists.") {
          this.isModalOpen = true;
        } else {
          let msg;
          if (err?.error && err?.error?.message) {
            msg = err.error.message;
          } else {
            msg = 'Something went wrong.';
          }
          this.cabinetsService.fetchError(msg);
          this.gotoListPage();
          this.showToasterMessage(msg, '', this.options, 'error');
        }
      });
    }
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-bottom-right';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
  }

  onClickCancel() {
    this.isCancelModalOpen = true;
    setTimeout(() => {
      this.focusableElement = document.getElementById('abc');
      if (this.focusableElement) {
        this.focusableElement.focus();
      }
    }, 1);
    this.commonService.trapFocusInModal('abc', 'yes');
  }

  encodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  goToCabinetNav() {
    this.goToCabinet.emit(true);
    this.router.navigateByUrl('/cabinet');
  }

}

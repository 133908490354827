import { Component, OnInit, ViewChild, OnDestroy, Inject, LOCALE_ID, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { CreateQuoteService } from 'src/app/quote-management/create-new-quote/create-quote.service';
import { MyOrdersService } from '../../my-orders.service';
import { SetDropdownsService } from '../../set-dropdowns.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { myQuotesService } from 'src/app/quote-management/my-quotes/myquotes.service';
import { Subscription } from 'rxjs';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import {CustomDropDown } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { ServiceDeliveryPoint, ConnectorType, AdditionalCabling } from '../../dropdown-interface';
@Component({
  selector: 'app-order-site-details',
  templateUrl: './order-site-details.component.html',
  styleUrls: ['./order-site-details.component.less'],
})
export class OrderSiteDetailsComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  stepperData = ['Site details', 'Request details', 'Review and submit'];
  stepName = 'Site details';
  aemLabelAuthoring1: any;
  aemLabelAuthoring: any;
  userTimeZone: string;
  orderDetailsData: any;
  cidnSelected: string;
  options: GlobalConfig;
  orderId: string;
  path: string;
  orderSiteDetailsForm: FormGroup;
  isOrderSiteDetailsSaved: boolean = false;
  isSavingInProgress: boolean = false;
  infraCoCIDN = window['environment'].InfraCoCIDN;
  public customerChange: Subscription;
  isOrderSaved:boolean=false;

  connectorTypeDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Connector type Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  }
  connectorTypeDropDownModel2: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Connector type Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  }
  siteDeliveryPointTypeDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Site delivery point type Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  }
  siteDeliveryPointTypeDropDownModel2: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Site delivery point type Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  }
  additionalCablingDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Additional cabling Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  }
  additionalCablingDropDownModel2: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Additional cabling Required Combobox.Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  }
  sdpTypesSiteA: string[] = [];
  sdpTypesSiteZ: string[] = [];
  connectorTypesSiteA: string[] = [];
  connectorTypesSiteZ: string[] = [];
  additionalCablingListSiteA: string[] = [];
  additionalCablingListSiteZ: string[] = [];
  serviceDeliveryPointModel: ServiceDeliveryPoint;
  additionalCablingModel: AdditionalCabling;
  connectorTypeModel: ConnectorType;
  sdpTypeSiteADefaultValue: string = '';
  sdpTypeSiteZDefaultValue: string = '';
  connectorTypesSiteADefaultValue: string = '';
  connectorTypesSiteZDefaultValue: string = '';
  additionalCablingListSiteADefaultValue: string = '';
  additionalCablingListSiteZDefaultValue: string = '';
  additionalCablingRadioSiteADefaultValue: string = '';
  additionalCablingRadioSiteZDefaultValue: string = '';
  constructor(
    private route: ActivatedRoute,
    private createQuoteService: CreateQuoteService,
    private myOrderService: MyOrdersService,
    private setDropdownsService :SetDropdownsService,
    @Inject(LOCALE_ID) private locale: string,
    private toastr: ToastrService,
    private router: Router,
    private commonService: CommonService,
    private dataSvc: myQuotesService,
    private utility: UtilityService,
    private titleService: Title
  ) {

    this.titleService.setTitle('InfraCo Customer Portal - Order Site Details page');
    // Get User TimeZone
    this.getUserTimeZone();
    this.options = this.toastr.toastrConfig;

    // Get CIDN
    if (localStorage.getItem('selectedHeaderCIDN')) {
      this.cidnSelected = localStorage.getItem('selectedHeaderCIDN')
    }
    if (this.infraCoCIDN === this.cidnSelected) {
      this.router.navigate(['/myOrders']);
    }

    this.customerChange = this.commonService.getCustomerName().subscribe((data) => {
      this.dataSvc.customerNameDropdownChange(true, data);
    });
    this.initializeForm();


    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (
          event.id === 1 &&
          event.url === event.urlAfterRedirects
        ) {
          this.router.navigateByUrl('/myOrders');
        }
      })
  }
  ngOnInit() {
    window.scrollTo(0, 0);
    this.route.paramMap.subscribe((params) => {
      const p = params.get('params');

      if (params.get('quoteId') && params.get('type')) {
        // Get order details
        this.getOrderDetails(params.get('quoteId'), params.get('type'));
        this.orderId = params.get('quoteId');
        this.path = params.get('type');

        const path = params.get('type');
        const quoteId = params.get('quoteId');
      }

      this.getLabels();
    });
    this.formValueChange();
  }

  formValueChange() {
    this.orderSiteDetailsForm.valueChanges.subscribe(val => {
      this.isSavingInProgress = false;
      if (this.isOrderSiteDetailsSaved) {
        this.isOrderSiteDetailsSaved = false;
      }
    })
  }


  initializeForm() {
    const numberPattern = /^[0-9]*$/;
    const forbiddenCharsPattern = /[<>;]/;

    this.orderSiteDetailsForm = new FormGroup({
      siteType: new FormControl({value: '', disabled: true}),
      siteDeliveryPointType: new FormControl('', Validators.required),
      connectorType: new FormControl('', Validators.required),
      demarcationPoint: new FormControl('', [Validators.required, Validators.pattern(/^[^<>;]*$/)]),
      dataCentreOprator: new FormControl({value: '', disabled: true}),
      dataCentreSiteName: new FormControl({value: '', disabled: true}),
      additionalCableRequired: new FormControl('', Validators.required),
      additionalCabling: new FormControl(''),
      additionalCablingLocation: new FormControl(''),
      siteType2: new FormControl({value: '', disabled: true}),
      siteDeliveryPointType2: new FormControl('', Validators.required),
      connectorType2: new FormControl('', Validators.required),
      demarcationPoint2: new FormControl('', [Validators.required, Validators.pattern(/^[^<>;]*$/)]),
      dataCentreOprator2: new FormControl({value: '', disabled: true}),
      dataCentreSiteName2: new FormControl({value: '', disabled: true}),
      additionalCableRequired2: new FormControl('', Validators.required),
      additionalCabling2: new FormControl(''),
      additionalCablingLocation2: new FormControl(''),
      serviceDeliveryPoint: new FormControl('', [Validators.pattern(/^[^<>;]*$/), Validators.maxLength(32)]),
      poleId: new FormControl('', [Validators.pattern(/^[^<>;]*$/), Validators.maxLength(32)]),
      nodeCode: new FormControl('', [Validators.pattern(/^[^<>;]*$/), Validators.maxLength(4)]),
      alternativeSiteName: new FormControl('', [Validators.pattern(this.utility.getNameRegEx()), Validators.maxLength(255)]),
      additionalAddressInfo: new FormControl('', [Validators.pattern(/^[^<>;]*$/), Validators.maxLength(250)]),
      newLeadInRequired: new FormControl('no'),
      siteContactName: new FormControl('', [Validators.required, Validators.pattern(/^[^<>;]*$/), Validators.maxLength(21), this.forbiddenCharsValidator(forbiddenCharsPattern)]),
      siteContactNumber: new FormControl('', [Validators.required, Validators.pattern(numberPattern), Validators.maxLength(10),Validators.minLength(10), this.forbiddenCharsValidator(forbiddenCharsPattern)]),
      siteContactEmail: new FormControl('', [Validators.required, Validators.pattern(this.utility.getEmailRegEx()), Validators.maxLength(100), this.forbiddenCharsValidator(forbiddenCharsPattern)]),
      serviceDeliveryPoint2: new FormControl('', [Validators.pattern(/^[^<>;]*$/), Validators.maxLength(32)]),
      poleId2: new FormControl('', [Validators.pattern(/^[^<>;]*$/), Validators.maxLength(32)]),
      nodeCode2: new FormControl('', [Validators.pattern(/^[^<>;]*$/), Validators.maxLength(4)]),
      alternativeSiteName2: new FormControl('', [Validators.pattern(this.utility.getNameRegEx()), Validators.maxLength(255)]),
      additionalAddressInfo2: new FormControl('', [Validators.pattern(/^[^<>;]*$/), Validators.maxLength(250)]),
      newLeadInRequired2: new FormControl('no'),
      siteContactName2: new FormControl('', [Validators.required, Validators.pattern(/^[^<>;]*$/), Validators.maxLength(21), this.forbiddenCharsValidator(forbiddenCharsPattern)]),
      siteContactNumber2: new FormControl('', [Validators.required, Validators.pattern(numberPattern), Validators.maxLength(10),Validators.minLength(10), this.forbiddenCharsValidator(forbiddenCharsPattern)]),
      siteContactEmail2: new FormControl('', [Validators.required, Validators.pattern(this.utility.getEmailRegEx()), Validators.maxLength(100), this.forbiddenCharsValidator(forbiddenCharsPattern)]),
    });
  }

  forbiddenCharsValidator(forbiddenCharsPattern: RegExp) {
    return (control: FormControl): { [key: string]: any } | null => {
      const forbidden = forbiddenCharsPattern.test(control.value);
      return forbidden ? { 'forbiddenChars': { value: control.value } } : null;
    };
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  convertDateFormatISO(d) {
    const date = new Date(d);
    return date.toISOString();
  }

  onRadioButtonChange(value){
    this.orderSiteDetailsForm.controls['newLeadInRequired'].setValue(value);
  }

  onRadioButton2Change(value){
    this.orderSiteDetailsForm.controls['newLeadInRequired2'].setValue(value);
  }


  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    // document.getElementsByClassName('overlay-container')[0].setAttribute('role', 'alert');
    // document.getElementsByClassName('overlay-container')[0].setAttribute('aria-live', 'polite');
  }


  getUserTimeZone() {
    const userTimeZone = Intl.DateTimeFormat(this.locale, {
      timeZoneName: 'long'
    }).resolvedOptions().timeZone;

    this.userTimeZone = userTimeZone;
  }

  isFormValid(): boolean {
    const newLeadInRequiredValue = this.orderSiteDetailsForm.get('newLeadInRequired').value;
    return this.orderSiteDetailsForm.valid && (newLeadInRequiredValue === 'yes' || newLeadInRequiredValue === 'no');
  }

  getLabels() {
    this.myOrderService
      .getOrderSiteDetailsPageLabel()
      .then((data: any) => {
        this.aemLabelAuthoring = data;
      })
      .catch((error) => { });
  }

  limitAdditionalAddressInfoLength() {
    const t = this.orderSiteDetailsForm.get('additionalAddressInfo');
    if (t.value.length > 250) {
      t.setValue(t.value.substr(0, 250), { emitEvent: false });
    }
  }

  getOrderDetails(oid, path) {

    const params = {
      cidn: this.cidnSelected,
      timeZone: this.userTimeZone,
      orderId: oid,
      path: path
    }

    // const params = {
    //   cidn: '9999999998',
    //   timeZone: 'Asia/Calcutta',
    //   orderId: 'IR100003158',
    //   path: 'Path1'
    // }
    return new Promise((resolve, reject) => {
      this.myOrderService.getSingleOrder(params.cidn, params.timeZone, params.orderId, params.path).then((res) => {
        if (res && res.data) {
          this.orderDetailsData = res.data;
          this.setDropdownValues(res.data); // Here we're preparing the dropdown values as per business requirement
          // this.setFormData(res.data);
        }
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });



  }

  setDropdownValues(data){
    let serviceType: string = data.serviceType ? data.serviceType : '';
    let subProduct: string = data.subProduct ? data.subProduct : '';
    let siteDeliveryTypeSiteA: string = data.aEndAdress.siteDeliveryType ? data.aEndAdress.siteDeliveryType : '';
    let siteDeliveryTypeSiteZ: string = data.zEndAdress.siteDeliveryType ? data.zEndAdress.siteDeliveryType : '';

    // service delivery dropdown & default value adding
    // for site A
    this.serviceDeliveryPointModel = this.setDropdownsService.getServiceDeliveryPointValues(serviceType,subProduct,siteDeliveryTypeSiteA);
    this.sdpTypesSiteA = this.serviceDeliveryPointModel.list.sort();
    this.sdpTypeSiteADefaultValue = this.serviceDeliveryPointModel.defaultValue;

    // for site Z
    this.serviceDeliveryPointModel = this.setDropdownsService.getServiceDeliveryPointValues(serviceType,subProduct,siteDeliveryTypeSiteZ);
    this.sdpTypesSiteZ = this.serviceDeliveryPointModel.list.sort();
    this.sdpTypeSiteZDefaultValue = this.serviceDeliveryPointModel.defaultValue;

    // connector type dropdown & default value adding
    // site A
    this.connectorTypeModel = this.setDropdownsService.getConnectorTypesValues(serviceType,subProduct,siteDeliveryTypeSiteA);
    this.connectorTypesSiteA = this.connectorTypeModel.list.sort();
    this.connectorTypesSiteADefaultValue = this.connectorTypeModel.defaultValue;

    // site Z
    this.connectorTypeModel = this.setDropdownsService.getConnectorTypesValues(serviceType,subProduct,siteDeliveryTypeSiteZ);
    this.connectorTypesSiteZ = this.connectorTypeModel.list.sort();
    this.connectorTypesSiteZDefaultValue = this.connectorTypeModel.defaultValue;

    // additional cabling dropdown & default value adding
    // site A
    this.additionalCablingModel = this.setDropdownsService.getAdditionalCablingList(serviceType,subProduct,siteDeliveryTypeSiteA);
    this.additionalCablingListSiteA = this.setDropdownsService.sortCables(this.additionalCablingModel.list);
    this.additionalCablingListSiteADefaultValue = this.additionalCablingModel.defaultValue;

    // site Z
    this.additionalCablingModel = this.setDropdownsService.getAdditionalCablingList(serviceType,subProduct,siteDeliveryTypeSiteZ);
    this.additionalCablingListSiteZ = this.setDropdownsService.sortCables(this.additionalCablingModel.list);
    this.additionalCablingListSiteZDefaultValue = this.additionalCablingModel.defaultValue;

    // additional cabling radio button default value adding
    // site A
    let defaultValueSiteA = this.setDropdownsService.getAdditionalCablingDefaultValue(serviceType,subProduct,siteDeliveryTypeSiteA);
    this.additionalCablingRadioSiteADefaultValue = defaultValueSiteA;

    // site Z
    let defaultValueSiteZ = this.setDropdownsService.getAdditionalCablingDefaultValue(serviceType,subProduct,siteDeliveryTypeSiteZ);
    this.additionalCablingRadioSiteZDefaultValue = defaultValueSiteZ;

    //checking if we need to remove validation for additional cabling for site-A
    if (!this.isConditionMet1()) {
      this.orderSiteDetailsForm.get('additionalCableRequired').clearValidators();
      this.orderSiteDetailsForm.get('additionalCableRequired').updateValueAndValidity();
    }

    //checking if we need to remove validation for additional cabling for site-Z
    if (!this.isConditionMet2()) {
      this.orderSiteDetailsForm.get('additionalCableRequired2').clearValidators();
      this.orderSiteDetailsForm.get('additionalCableRequired2').updateValueAndValidity();
    }

    this.setFormData(data);
  }

  setFormData(d) {
    const data = d;

    this.orderSiteDetailsForm.patchValue({
      siteContactName: data.aEndAdress?.orderSiteContactDetails[0]?.primarySiteContactName,
      siteContactNumber: data.aEndAdress?.orderSiteContactDetails[0]?.primarySiteContactNumber,
      siteContactEmail: data.aEndAdress?.orderSiteContactDetails[0]?.primarySiteContactEmail,
      serviceDeliveryPoint: data.aEndAdress?.serviceDeliveryPoint,
      poleId: data.aEndAdress?.poleId,
      nodeCode: data.aEndAdress?.nodeCode,
      newLeadInRequired: data.aEndAdress?.leadInRequired || 'no',
      alternativeSiteName: data.aEndAdress?.siteAlternativeName,
      additionalAddressInfo: data.aEndAdress?.additionalAddressInfo,
      siteType: data.aEndAdress?.siteDeliveryType ? data.aEndAdress?.siteDeliveryType : '',
      siteDeliveryPointType: data.aEndAdress?.serviceDeliveryPointType ? data.aEndAdress?.serviceDeliveryPointType : this.sdpTypeSiteADefaultValue,
      connectorType: data.aEndAdress?.connectorType ? data.aEndAdress?.connectorType : this.connectorTypesSiteADefaultValue,
      demarcationPoint: data.aEndAdress?.demarcationPoint ? data.aEndAdress?.demarcationPoint : '',
      dataCentreOprator: data.aEndAdress?.dcOperator ? data.aEndAdress?.dcOperator : '',
      dataCentreSiteName: data.aEndAdress?.dcSiteName ? data.aEndAdress?.dcSiteName : '',
      additionalCableRequired: data.aEndAdress?.additionalCablingRequired ? data.aEndAdress?.additionalCablingRequired : this.additionalCablingRadioSiteADefaultValue,
      additionalCabling: data.aEndAdress?.additionalCabling ? data.aEndAdress?.additionalCabling : this.additionalCablingListSiteADefaultValue,
      additionalCablingLocation: data.aEndAdress?.additionalCablingLocation ? data.aEndAdress?.additionalCablingLocation : '',


      siteContactName2: data.zEndAdress?.orderSiteContactDetails[0]?.primarySiteContactName || '',
      siteContactNumber2: data.zEndAdress?.orderSiteContactDetails[0]?.primarySiteContactNumber || '',
      siteContactEmail2: data.zEndAdress?.orderSiteContactDetails[0]?.primarySiteContactEmail || '',
      serviceDeliveryPoint2: data.zEndAdress?.serviceDeliveryPoint || '',
      poleId2: data.zEndAdress?.poleId || '',
      nodeCode2: data.zEndAdress?.nodeCode || '',
      newLeadInRequired2: data.zEndAdress?.leadInRequired || '' || 'no',
      alternativeSiteName2: data.zEndAdress?.siteAlternativeName || '',
      additionalAddressInfo2: data.zEndAdress?.additionalAddressInfo || '',
      siteType2: data.zEndAdress?.siteDeliveryType ? data.zEndAdress?.siteDeliveryType : '',
      siteDeliveryPointType2: data.zEndAdress?.serviceDeliveryPointType ? data.zEndAdress?.serviceDeliveryPointType : this.sdpTypeSiteZDefaultValue,
      connectorType2: data.zEndAdress?.connectorType ? data.zEndAdress?.connectorType : this.connectorTypesSiteZDefaultValue,
      demarcationPoint2: data.zEndAdress?.demarcationPoint ? data.zEndAdress?.demarcationPoint : '',
      dataCentreOprator2: data.zEndAdress?.dcOperator ? data.zEndAdress?.dcOperator : '',
      dataCentreSiteName2: data.zEndAdress?.dcSiteName ? data.zEndAdress?.dcSiteName : '',
      additionalCableRequired2: data.zEndAdress?.additionalCablingRequired ? data.zEndAdress?.additionalCablingRequired : this.additionalCablingRadioSiteZDefaultValue,
      additionalCabling2: data.zEndAdress?.additionalCabling ? data.zEndAdress?.additionalCabling : this.additionalCablingListSiteZDefaultValue,
      additionalCablingLocation2: data.zEndAdress?.additionalCablingLocation ? data.zEndAdress?.additionalCablingLocation : '',
    });
    
    const siteContactNumber = this.orderSiteDetailsForm.get('siteContactNumber');
    const siteContactEmail = this.orderSiteDetailsForm.get('siteContactEmail');
    const siteContactName = this.orderSiteDetailsForm.get('siteContactName');
    const serviceDeliveryPoint = this.orderSiteDetailsForm.get('serviceDeliveryPoint');
    const poleId = this.orderSiteDetailsForm.get('poleId');
    const nodeCode = this.orderSiteDetailsForm.get('nodeCode');
    const newLeadInRequired = this.orderSiteDetailsForm.get('newLeadInRequired');
    const alternativeSiteName = this.orderSiteDetailsForm.get('alternativeSiteName');
    const additionalAddressInfo = this.orderSiteDetailsForm.get('additionalAddressInfo');

    const siteContactNumber2 = this.orderSiteDetailsForm.get('siteContactNumber2');
    const siteContactEmail2 = this.orderSiteDetailsForm.get('siteContactEmail2');
    const siteContactName2 = this.orderSiteDetailsForm.get('siteContactName2');
    const serviceDeliveryPoint2 = this.orderSiteDetailsForm.get('serviceDeliveryPoint');
    const poleId2 = this.orderSiteDetailsForm.get('poleId2');
    const nodeCode2 = this.orderSiteDetailsForm.get('nodeCode2');
    const newLeadInRequired2 = this.orderSiteDetailsForm.get('newLeadInRequired2');
    const alternativeSiteName2 = this.orderSiteDetailsForm.get('alternativeSiteName2');
    const additionalAddressInfo2 = this.orderSiteDetailsForm.get('additionalAddressInfo2');


    // A
    if (siteContactName && siteContactName?.value?.length) {
      siteContactName.markAsTouched();
    }

    if (siteContactNumber && siteContactNumber?.value?.length) {
      siteContactNumber.markAsTouched();
    }

    if (siteContactEmail && siteContactEmail?.value?.length) {
      siteContactEmail.markAsTouched();
    }

    if (serviceDeliveryPoint && serviceDeliveryPoint?.value?.length) {
      serviceDeliveryPoint.markAsTouched();
    }

    if (poleId && poleId?.value?.length) {
      poleId.markAsTouched();
    }

    if (nodeCode && nodeCode?.value?.length) {
      nodeCode.markAsTouched();
    }

    if (newLeadInRequired && newLeadInRequired?.value?.length) {
      newLeadInRequired.markAsTouched();
    }

    if (alternativeSiteName && alternativeSiteName?.value?.length) {
      alternativeSiteName.markAsTouched();
    }

    if (additionalAddressInfo && additionalAddressInfo?.value?.length) {
      additionalAddressInfo.markAsTouched();
    }

    // Z

    if (siteContactName2 && siteContactName2?.value?.length) {
      siteContactName2.markAsTouched();
    }

    if (siteContactNumber2 && siteContactNumber2?.value?.length) {
      siteContactNumber2.markAsTouched();
    }

    if (siteContactEmail2 && siteContactEmail2?.value?.length) {
      siteContactEmail2.markAsTouched();
    }

    if (serviceDeliveryPoint2 && serviceDeliveryPoint2?.value?.length) {
      serviceDeliveryPoint2.markAsTouched();
    }

    if (poleId2 && poleId2?.value?.length) {
      poleId2.markAsTouched();
    }

    if (nodeCode2 && nodeCode2?.value?.length) {
      nodeCode2.markAsTouched();
    }

    if (newLeadInRequired2 && newLeadInRequired2?.value?.length) {
      newLeadInRequired2.markAsTouched();
    }

    if (alternativeSiteName2 && alternativeSiteName2?.value?.length) {
      alternativeSiteName2.markAsTouched();
    }

    if (additionalAddressInfo2 && additionalAddressInfo2?.value?.length) {
      additionalAddressInfo2.markAsTouched();
    }


  }

  saveOrderDetails(isSaved?: boolean, navTo?: string) {
    if (isSaved && (navTo === 'next')) {
      this.orderSiteDetailsForm.markAllAsTouched();
    }
    if (isSaved && (navTo === 'save')) {
      this.isOrderSaved=true;
     }
     setTimeout(() => {
       this.isOrderSaved = false;
     }, 5000);
    // this.isSavingInProgress = true;
    const formData = this.orderSiteDetailsForm.value;
    if(!this.orderDetailsData || !formData) return;
    let data = JSON.parse(JSON.stringify((this.orderDetailsData)));
    let payload = data;
    if (payload.aEndAdress.orderSiteContactDetails?.length > 0) {
      payload.aEndAdress.orderSiteContactDetails[0].primarySiteContactNameUpdated = (this.orderDetailsData.aEndAdress?.orderSiteContactDetails[0]?.primarySiteContactName === formData.siteContactName)? false : true;
      payload.aEndAdress.orderSiteContactDetails[0].primarySiteContactNumberUpdated = (this.orderDetailsData.aEndAdress?.orderSiteContactDetails[0]?.primarySiteContactNumber === formData.siteContactNumber)? false : true;
      payload.aEndAdress.orderSiteContactDetails[0].primarySiteContactEmailUpdated = (this.orderDetailsData.aEndAdress?.orderSiteContactDetails[0]?.primarySiteContactEmail === formData.siteContactEmail)? false : true;
      payload.aEndAdress.orderSiteContactDetails[0].primarySiteContactName = formData.siteContactName || '';
      payload.aEndAdress.orderSiteContactDetails[0].primarySiteContactNumber = formData.siteContactNumber || '';
      payload.aEndAdress.orderSiteContactDetails[0].primarySiteContactEmail = formData.siteContactEmail || '';
      payload.aEndAdress.orderSiteContactDetails[0].createdDate = this.convertDateFormatISO(this.orderDetailsData.aEndAdress?.orderSiteContactDetails[0]?.createdDate) || null;
      payload.aEndAdress.orderSiteContactDetails[0].modifiedDate = this.convertDateFormatISO(this.orderDetailsData.aEndAdress?.orderSiteContactDetails[0]?.modifiedDate) || null;

    } else {
      payload.aEndAdress.orderSiteContactDetails.push({
        'siteContactType': 'aEndAdress',
        'primarySiteContactName': formData.siteContactName || '',
        'primarySiteContactNumber': formData.siteContactNumber || '',
        'primarySiteContactEmail': formData.siteContactEmail || ''
      })
    }

    payload.aEndAdress.serviceDeliveryPoint = formData.serviceDeliveryPoint || '';

    payload.aEndAdress.serviceDeliveryPoint = formData.serviceDeliveryPoint || '';
    payload.aEndAdress.poleId = (this.orderDetailsData.serviceType === 'RG' && this.orderDetailsData.businessDriver === '5G Small Cell' && this.orderDetailsData.aEndAdress?.siteName === 'NON-PREMISES') ? formData.poleId : this.orderDetailsData.aEndAdress?.poleId || '';
    payload.aEndAdress.nodeCode = (this.orderDetailsData.serviceType === 'RG' && this.orderDetailsData.businessDriver === '5G Small Cell') ? formData.nodeCode : this.orderDetailsData.aEndAdress?.nodeCode || '';
    payload.aEndAdress.leadInRequired = (this.orderDetailsData.serviceType === 'RG' && this.orderDetailsData.businessDriver === '5G Small Cell' && this.orderDetailsData.aEndAdress?.siteName === 'NON-PREMISES') ? formData.newLeadInRequired : this.orderDetailsData.aEndAdress?.leadInRequired || '';

    payload.aEndAdress.siteAlternativeName = formData.alternativeSiteName || '';
    payload.aEndAdress.additionalAddressInfo = formData.additionalAddressInfo || '';
    // site A new fields start
    payload.aEndAdress.siteType = formData.siteType;
    payload.aEndAdress.serviceDeliveryPointType = formData.siteDeliveryPointType;
    payload.aEndAdress.connectorType = formData.connectorType;
    payload.aEndAdress.demarcationPoint = formData.demarcationPoint;
    payload.aEndAdress.dataCentreOprator = formData.dataCentreOprator;
    payload.aEndAdress.dataCentreSiteName = formData.dataCentreSiteName;
    payload.aEndAdress.additionalCablingRequired = formData.additionalCableRequired;
    payload.aEndAdress.additionalCabling = formData.additionalCabling;
    payload.aEndAdress.additionalCablingLocation = formData.additionalCablingLocation;
    //end
    payload.screenName ='orderSiteDetails';
    if (payload.zEndAdress.orderSiteContactDetails?.length > 0) {
      payload.zEndAdress.orderSiteContactDetails[0].primarySiteContactNameUpdated = (this.orderDetailsData.zEndAdress?.orderSiteContactDetails[0]?.primarySiteContactName === formData.siteContactName2)? false : true;
      payload.zEndAdress.orderSiteContactDetails[0].primarySiteContactNumberUpdated = (this.orderDetailsData.zEndAdress?.orderSiteContactDetails[0]?.primarySiteContactNumber === formData.siteContactNumber2)? false : true;
      payload.zEndAdress.orderSiteContactDetails[0].primarySiteContactEmailUpdated = (this.orderDetailsData.zEndAdress?.orderSiteContactDetails[0]?.primarySiteContactEmail === formData.siteContactEmail2)? false : true;
      payload.zEndAdress.orderSiteContactDetails[0].primarySiteContactName = formData.siteContactName2 || '';
      payload.zEndAdress.orderSiteContactDetails[0].primarySiteContactNumber = formData.siteContactNumber2 || '';
      payload.zEndAdress.orderSiteContactDetails[0].primarySiteContactEmail = formData.siteContactEmail2 || '';
      payload.zEndAdress.orderSiteContactDetails[0].createdDate = this.convertDateFormatISO(this.orderDetailsData.zEndAdress?.orderSiteContactDetails[0]?.createdDate) || null;
      payload.zEndAdress.orderSiteContactDetails[0].modifiedDate = this.convertDateFormatISO(this.orderDetailsData.zEndAdress?.orderSiteContactDetails[0]?.modifiedDate) || null;
    } else {
      payload.zEndAdress.orderSiteContactDetails.push({
        'siteContactType': 'zEndAdress',
        'primarySiteContactName': formData.siteContactName2 || '',
        'primarySiteContactNumber': formData.siteContactNumber2 || '',
        'primarySiteContactEmail': formData.siteContactEmail2 || ''
      })
    }

    payload.zEndAdress.serviceDeliveryPoint = formData.serviceDeliveryPoint2 || '';
    payload.zEndAdress.poleId = (this.orderDetailsData.serviceType === 'RG' && this.orderDetailsData.businessDriver === '5G Small Cell' && this.orderDetailsData?.zEndAdress?.siteName === 'NON-PREMISES') ? formData.poleId2 : this.orderDetailsData?.zEndAdress?.poleId || '';
    payload.zEndAdress.nodeCode = (this.orderDetailsData.serviceType === 'RG' && this.orderDetailsData.businessDriver === '5G Small Cell') ? formData.nodeCode2 : this.orderDetailsData?.zEndAdress?.nodeCode || '';
    payload.zEndAdress.leadInRequired = (this.orderDetailsData.serviceType === 'RG' && this.orderDetailsData.businessDriver === '5G Small Cell' && this.orderDetailsData?.zEndAdress?.siteName === 'NON-PREMISES') ? formData.newLeadInRequired2 : this.orderDetailsData?.zEndAdress?.leadInRequired || '';
    payload.zEndAdress.siteAlternativeName = formData.alternativeSiteName2 || '';
    payload.zEndAdress.additionalAddressInfo = formData.additionalAddressInfo2 || '';
    // site Z new fields start
    payload.zEndAdress.siteType = formData.siteType2;
    payload.zEndAdress.serviceDeliveryPointType = formData.siteDeliveryPointType2;
    payload.zEndAdress.connectorType = formData.connectorType2;
    payload.zEndAdress.demarcationPoint = formData.demarcationPoint2;
    payload.zEndAdress.dataCentreOprator = formData.dataCentreOprator2;
    payload.zEndAdress.dataCentreSiteName = formData.dataCentreSiteName2;
    payload.zEndAdress.additionalCablingRequired = formData.additionalCableRequired2;
    payload.zEndAdress.additionalCabling = formData.additionalCabling2;
    payload.zEndAdress.additionalCablingLocation = formData.additionalCablingLocation2;
    //end
    payload.timezone = this.userTimeZone;
    payload.quoteExpiryDate = this.convertDateFormatISO(this.orderDetailsData.quoteExpiryDate) || null;
    payload.customerRequestedDate = this.convertDateFormatISO(this.orderDetailsData.customerRequestedDate) || null;
    payload.createdDate = this.convertDateFormatISO(this.orderDetailsData.createdDate) || null;
    payload.modifiedDate = this.convertDateFormatISO(this.orderDetailsData.modifiedDate) || null;
    payload.aEndAdress.createdDate = this.convertDateFormatISO(this.convertDateFormatISO(this.orderDetailsData.aEndAdress?.createdDate) || null);
    payload.aEndAdress.modifiedDate = this.convertDateFormatISO(this.orderDetailsData.aEndAdress?.modifiedDate) || null;


    payload.zEndAdress.createdDate = this.convertDateFormatISO(this.orderDetailsData.zEndAdress?.createdDate) || null;
    payload.zEndAdress.modifiedDate = this.convertDateFormatISO(this.orderDetailsData.zEndAdress?.modifiedDate) || null;
    payload.action = 'Save'

    if (payload.maxSignalLoss) {
      delete payload.maxSignalLoss;
    }


    this.myOrderService.saveNewOrder(payload).then((res) => {
      if (isSaved) {
        this.isOrderSiteDetailsSaved = true;
        this.isSavingInProgress = false;
        // this.showToasterMessage('Order Details Saved Successfully', '', this.options, 'success');

        if (isSaved && navTo === 'cancel') {
          this.cancel();
        }

      } else {
        this.router.navigate(['/order-request-details', this.path, this.orderId]);
      }
    }).catch((err) => {
      this.isSavingInProgress = false;
      this.showToasterMessage(this.aemLabelAuthoring?.errorMsg?.genericError, '', this.options, 'error');
    });

  }

  cancel() {
    this.router.navigate(['/myOrders']);
  }

  onAdditionalInfoKeyPress(event, val,controlName) {
    const element = document.getElementById(controlName);
    if (event.key === " ") {
      const label = `${(val + 1)} out of 250 characters`
      element.setAttribute('aria-label', label)
    }
  }

  onNumericKeyPressDown(event: KeyboardEvent) {
    if (event.key >= '0' && event.key <= '9') {
      event.preventDefault();
    }
  }


  onPasteCheckNumber(event: ClipboardEvent): void {
    const d = event.clipboardData.getData('text/plain');
    if (this.validateNumber(d)) {
      event.preventDefault();
    }
  }

  validateNumber(value: string): boolean {
    return /^\d+$/.test(value);
  }


  validateAlphaNumeric(value: string): boolean {
    return /^[a-zA-Z\d\s]+$/.test(value);
  }


  typeAlphaNumeric(event: KeyboardEvent) {
    // checks for alphanumeric
    const char = event.key.toLowerCase();
    if (!((char >= '0' && char <= '9') || (char >= 'a' && char <= 'z') || char === ' ')) {
      event.preventDefault();
    }
  }

  onPasteCheckAlphaNumeric(event: ClipboardEvent): void {
    // on paste checks for alphanumeric
    const d = event.clipboardData.getData('text/plain');
    if (!this.validateAlphaNumeric(d)) {
      event.preventDefault();
    }
  }

  touchSiteDeliveryPointType(){

  }


  touchConnectorType(){

  }

  onAdditionalCableRequiredRadioButtonChange(event){
    this.orderSiteDetailsForm.controls['additionalCableRequired'].setValue(event);
  }

  touchAdditionalCabling(){

  }

  touchSiteDeliveryPointType2(){

  }

  touchConnectorType2(){

  }

  onAdditionalCablingRequiredChange(event){
    if(event.value && event.value == 'yes'){
      this.orderSiteDetailsForm.get('additionalCabling').setValidators([Validators.required]);
      this.orderSiteDetailsForm.get('additionalCablingLocation').setValidators([Validators.required, Validators.pattern(/^[^<>;]*$/)]);
      this.orderSiteDetailsForm.get('additionalCabling').updateValueAndValidity();
      this.orderSiteDetailsForm.get('additionalCablingLocation').updateValueAndValidity();
    }
    else{
      this.orderSiteDetailsForm.get('additionalCabling').clearValidators();
      this.orderSiteDetailsForm.get('additionalCablingLocation').clearValidators();
      this.orderSiteDetailsForm.get('additionalCabling').setValue('');
      this.orderSiteDetailsForm.get('additionalCablingLocation').setValue('');
      this.orderSiteDetailsForm.get('additionalCabling').updateValueAndValidity();
      this.orderSiteDetailsForm.get('additionalCablingLocation').updateValueAndValidity();  
    }
  }

  onAdditionalCablingRequiredChange2(event){
    if(event.value && event.value == 'yes'){
      this.orderSiteDetailsForm.get('additionalCabling2').setValidators([Validators.required]);
      this.orderSiteDetailsForm.get('additionalCablingLocation2').setValidators([Validators.required, Validators.pattern(/^[^<>;]*$/)]);
      this.orderSiteDetailsForm.get('additionalCabling2').updateValueAndValidity();
      this.orderSiteDetailsForm.get('additionalCablingLocation2').updateValueAndValidity();
    }
    else{
      this.orderSiteDetailsForm.get('additionalCabling2').clearValidators();
      this.orderSiteDetailsForm.get('additionalCablingLocation2').clearValidators();
      this.orderSiteDetailsForm.get('additionalCabling2').setValue('');
      this.orderSiteDetailsForm.get('additionalCablingLocation2').setValue('');
      this.orderSiteDetailsForm.get('additionalCabling2').updateValueAndValidity();
      this.orderSiteDetailsForm.get('additionalCablingLocation2').updateValueAndValidity();  
    }
  }

  onAdditionalCableRequiredRadioButtonChange2(event){
    this.orderSiteDetailsForm.controls['additionalCableRequired2'].setValue(event);
  }

  touchAdditionalCabling2(){

  }

  isConditionMet1(): boolean{
    let siteDeliveryType: string = this.orderDetailsData?.aEndAdress?.siteDeliveryType ? this.orderDetailsData?.aEndAdress?.siteDeliveryType.toLowerCase() : '';
    return !(this.orderDetailsData?.serviceType == 'BL' && this.orderDetailsData?.subProduct == 'NBN EE' && siteDeliveryType == 'non-premises');
  }

  isConditionMet2(): boolean{
    let siteDeliveryType: string = this.orderDetailsData?.zEndAdress?.siteDeliveryType ? this.orderDetailsData?.zEndAdress?.siteDeliveryType.toLowerCase() : '';
    return !(this.orderDetailsData?.serviceType == 'BL' && this.orderDetailsData?.subProduct == 'NBN EE' && siteDeliveryType == 'non-premises');
  }

  checkConditionForDcOperatorSiteA(): boolean{
    let siteDeliveryType = this.orderDetailsData?.aEndAdress?.siteDeliveryType ? this.orderDetailsData?.aEndAdress?.siteDeliveryType.toLowerCase() : '';
    return (this.orderDetailsData?.serviceType !== 'RG' && this.orderSiteDetailsForm.get('dataCentreOprator').value) || (this.orderDetailsData?.serviceType === 'RG' && (siteDeliveryType === 'data centre 3rd party' || siteDeliveryType === 'data centre infraco'));
  }

  checkConditionForDcSiteNameSiteA(): boolean{
    let siteDeliveryType = this.orderDetailsData?.aEndAdress?.siteDeliveryType ? this.orderDetailsData?.aEndAdress?.siteDeliveryType.toLowerCase() : '';
    return (this.orderDetailsData?.serviceType !== 'RG' && this.orderSiteDetailsForm.get('dataCentreSiteName').value) || (this.orderDetailsData?.serviceType === 'RG' && (siteDeliveryType === 'data centre 3rd party' || siteDeliveryType === 'data centre infraco'));
  }

  checkConditionForDcOperatorSiteZ(): boolean{
    let siteDeliveryType = this.orderDetailsData?.zEndAdress?.siteDeliveryType ? this.orderDetailsData?.zEndAdress?.siteDeliveryType.toLowerCase() : '';
    return (this.orderDetailsData?.serviceType !== 'RG' && this.orderSiteDetailsForm.get('dataCentreOprator2').value) || (this.orderDetailsData?.serviceType === 'RG' && (siteDeliveryType === 'data centre 3rd party' || siteDeliveryType === 'data centre infraco'));
  }

  checkConditionForDcSiteNameSiteZ(): boolean{
    let siteDeliveryType = this.orderDetailsData?.zEndAdress?.siteDeliveryType ? this.orderDetailsData?.zEndAdress?.siteDeliveryType.toLowerCase() : '';
    return (this.orderDetailsData?.serviceType !== 'RG' && this.orderSiteDetailsForm.get('dataCentreSiteName2').value) || (this.orderDetailsData?.serviceType === 'RG' && (siteDeliveryType === 'data centre 3rd party' || siteDeliveryType === 'data centre infraco'));
  }

  checkPoleIdConditionForSiteA(): boolean{
    let subProduct = this.orderDetailsData?.subProduct?.toLowerCase();
    let siteName = this.orderDetailsData?.aEndAdress?.siteName?.toLowerCase();
    return this.orderDetailsData?.serviceType === 'RG' && subProduct === 'mobiles smc' && siteName === 'non-premises'
  }

  checkPoleIdConditionForSiteZ(): boolean{
    let subProduct = this.orderDetailsData?.subProduct?.toLowerCase();
    let siteName = this.orderDetailsData?.zEndAdress?.siteName?.toLowerCase();
    return this.orderDetailsData?.serviceType === 'RG' && subProduct === 'mobiles smc' && siteName === 'non-premises'
  }

  checkNodeCodeConditionForSiteA(): boolean{
    let subProduct = this.orderDetailsData?.subProduct?.toLowerCase();
    let siteName = this.orderDetailsData?.aEndAdress?.siteName?.toLowerCase();
    return this.orderDetailsData?.serviceType === 'RG' && subProduct === 'mobiles smc' && siteName === 'non-premises'
  }

  checkNodeCodeConditionForSiteZ(): boolean{
    let subProduct = this.orderDetailsData?.subProduct?.toLowerCase();
    let siteName = this.orderDetailsData?.zEndAdress?.siteName?.toLowerCase();
    return this.orderDetailsData?.serviceType === 'RG' && subProduct === 'mobiles smc' && siteName === 'non-premises'
  }

  checkNewLeadInRequiredConditionForSiteA(): boolean {
    let subProduct = this.orderDetailsData?.subProduct?.toLowerCase();
    let siteName = this.orderDetailsData?.aEndAdress?.siteName?.toLowerCase();
    return this.orderDetailsData?.serviceType === 'RG' && subProduct === 'mobiles smc' && siteName === 'non-premises'
  }

  checkNewLeadInRequiredConditionForSiteZ(): boolean {
    let subProduct = this.orderDetailsData?.subProduct?.toLowerCase();
    let siteName = this.orderDetailsData?.zEndAdress?.siteName?.toLowerCase();
    return this.orderDetailsData?.serviceType === 'RG' && subProduct === 'mobiles smc' && siteName === 'non-premises'
  }

  ngOnDestroy(): void {
    if (this.customerChange) {
      this.customerChange.unsubscribe();
    }
  }
}

import { BehaviorSubject, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from 'tw-core-ui';

@Injectable()
export class CreateQuoteService {
  public gridDataObj: any = [];
  public productDataObj: any = [];
  private env: any = window['environment'];
  productDetailsFormValue: any;
  requestDetailsFormValue: any;
  serviceType: string;
  isNewQuoteButtonClicked = new BehaviorSubject<any>('');
  public isNewQuoteButtonClicked$ = this.isNewQuoteButtonClicked.asObservable();
  isModifyQuote = new BehaviorSubject<any>('');
  public isModifyQuote$ = this.isModifyQuote.asObservable();
  private aemDefaultUri = {
    createQuote: 'create-quote.json',
    productDetails: 'product-details.json',
    siteDetails: 'site-details.json',
    phoneCountryList: 'phoneCountryCode.json',
    productCatalog: 'product-catalog/product-offerings',
    successDetails: 'success-page-label.json',
    mockProductDetails: 'product-catalog-mock.json',
    termsModel: 'termsconditions.json',
    feasibility: 'darkfibre/feasibility-requests',
    mockSaveQuote: 'mock/save-quote.json',
    mockGetQuoteList: 'mock/my-quotes.json',
    predefinedRoute: 'infraco-addresses/pre-defined-routes/',
    mockEnqDetails: 'mock/quote-details.json'
  };

  private defaultUri = {
    autocompleteAddressSearch: "infraco-addresses/autocomplete-address-search",
    mockAutocompleteAddressSearch: "mock/autocomplete-address-search.json",
    searchUnstructuredAddresses: "infraco-addresses/search-unstructured-addresses",
    mockSearchUnstructuredAddresses: "mock/search-unstructured-addresses.json"
  };



  public createQuoteLabelAuthoringDataObj: any;
  public termsLabelAuthoringDataObj: any;
  public productDetailsLabelAuthoringDataObj: any;
  public siteDetailsLabelAuthoringDataObj: any;
  public successPageLabelAuthoringDataObj: any;
  quoteObj: any;

  constructor(private http: HttpClient, private authService: AuthService) { }

  setProductDetailsFormValues(val) {

    if (this.serviceType === "RG") {
      sessionStorage.setItem('productDetailsFormValue', val)
      this.productDetailsFormValue = val;
    }

    if(this.serviceType !== "RG"){
      sessionStorage.setItem('productDetailsFormValue', this.productDetailsFormValue);
      this.productDetailsFormValue = this.nonRGPayloadPrepare(val, this.serviceType);
    }
  }

  getProductDetailsFormValues() {
    return this.productDetailsFormValue;
  }

  setRequestDetailsFormValues(val) {
    sessionStorage.setItem('requestDetailsFormValue', val)
    this.requestDetailsFormValue = val;
  }

  getRequestDetailsFormValues() {
    return this.requestDetailsFormValue;
  }

  setProductData(val) {
    sessionStorage.setItem('productDataObj', val)
    this.productDataObj = val;
  }

  getProductProductData() {
    return this.productDataObj;
  }

  setQuoteDetails(val) {
    this.quoteObj = val;
  }

  getQuoteDetails() {
    return this.quoteObj;
  }
  getServiceType() {
    return this.serviceType;
  }

  setServiceType(type) {
    this.serviceType = type;
  }
  public getTermsConditionModel() {
    return new Promise((resolve, reject) => {
      if (!this.termsLabelAuthoringDataObj) {
        const worklistUrl = this.env.aemEndPoint + this.aemDefaultUri.termsModel;
        this.http.get<any>(worklistUrl)
          .subscribe(
            res => {
              this.termsLabelAuthoringDataObj = res.data.content;
              resolve(this.termsLabelAuthoringDataObj);
            },
            err => {
              this.termsLabelAuthoringDataObj = null;
              reject(err);
              return observableThrowError(err || 'Server error');
            }
          );
      } else {
        resolve(this.termsLabelAuthoringDataObj);
      }
    });
  }




  public getCreateQuoteModel() {
    return new Promise((resolve, reject) => {
      const createquoteUrl = this.env.aemEndPoint + this.aemDefaultUri.createQuote;
      this.http.get<any>(createquoteUrl)
        .subscribe(
          res => {
            this.createQuoteLabelAuthoringDataObj = res;
            resolve(this.createQuoteLabelAuthoringDataObj);
          },
          err => {
            this.createQuoteLabelAuthoringDataObj = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }


  public getProductDetailsPageLabel() {
    // this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      const productDetailsUrl = this.env.aemEndPoint + this.aemDefaultUri.productDetails;
      this.http.get<any>(productDetailsUrl)
        .subscribe(
          res => {
            this.productDetailsLabelAuthoringDataObj = res;
            resolve(this.productDetailsLabelAuthoringDataObj);
          },
          err => {
            this.productDetailsLabelAuthoringDataObj = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }

  public getSiteDetailsPageLabel() {
    // this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      const siteDetailsUrl = this.env.aemEndPoint + this.aemDefaultUri.siteDetails;
      this.http.get<any>(siteDetailsUrl)
        .subscribe(
          res => {
            this.siteDetailsLabelAuthoringDataObj = res;
            resolve(this.siteDetailsLabelAuthoringDataObj);
          },
          err => {
            this.siteDetailsLabelAuthoringDataObj = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }

  public getPhoneCountryList() {
    // this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      const siteDetailsUrl = this.env.aemEndPoint + this.aemDefaultUri.phoneCountryList;
      this.http.get<any>(siteDetailsUrl)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }

  public getSuccessPageLabel() {
    // this.sendSpinnerFlag.next(true);
    return new Promise((resolve, reject) => {
      const successDetailsUrl = this.env.aemEndPoint + this.aemDefaultUri.successDetails;
      this.http.get<any>(successDetailsUrl)
        .subscribe(
          res => {
            this.successPageLabelAuthoringDataObj = res;
            resolve(this.successPageLabelAuthoringDataObj);
          },
          err => {
            this.successPageLabelAuthoringDataObj = null;
            reject(err);
            return observableThrowError(err || 'Server error');
          }
        );
    });
  }

  public getProductCatalogDataFromAPI(params, cidn, isBulkQuote?) {
    // cidn = '9999999998';
    const isBulk = isBulkQuote ? 'Yes' : 'No';
    return new Promise<any>((resolve, reject) => {
      const tokenValue = this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : false;
      let httpOptions;
      if (tokenValue) {
        httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': tokenValue
          })
        };
      } else {
        httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': '*/*'
          })
        };
      }
       const url = this.env.apiPoint + this.aemDefaultUri.productCatalog + "?cidn=" + cidn + '&bulkQuote=' + isBulk;

      // To run in local
       // const url = this.env.aemEndPoint + this.aemDefaultUri.mockProductDetails;

      this.http.get<any>(url, httpOptions)
        .subscribe((res: any) => {
          if(res && res.serviceType){
            this.productDataObj = res['productOfferings'];
            this.serviceType = res['serviceType'];
          }
          resolve(res);
        }, err => {
          this.productDataObj = [];
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public autocompleteAddressSearch(query) {
    return new Promise<any>((resolve, reject) => {
      const tokenValue = this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : false;
      let httpOptions;
      if (tokenValue) {
        httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': tokenValue
          })
        };
      } else {
        httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': '*/*'
          })
        };
      }
      const url = this.env.apiPoint + this.defaultUri.autocompleteAddressSearch + "?query=" + query;

      //To run in local
      // const url = this.env.aemEndPoint + this.defaultUri.mockAutocompleteAddressSearch;

      this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public searchUnstructuredAddresses(payload) {
    return new Promise<any>((resolve, reject) => {
      const tokenValue = this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : false;
      let httpOptions;
      if (tokenValue) {
        httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Authorization': tokenValue
          })
        };
      } else {
        httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': '*/*'
          })
        };
      }
      const url = this.env.apiPoint + this.defaultUri.searchUnstructuredAddresses;

      //To run in local
      // const url = this.env.aemEndPoint + this.defaultUri.mockSearchUnstructuredAddresses;


      this.http.post<any>(url, payload, httpOptions)
        // this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    })
  }

  public saveNewQuote(params, val) {
    if (!params) {
      return new Promise((reject) => {
        reject(null);
      })
    }

    const username = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'];
    const payload = {
      quoteReferenceId: params.quoteReferenceId ? params.quoteReferenceId : '',
      action: val,
      avoidPlrLogging: params.avoidPlrLogging ? params.avoidPlrLogging : false,
      predefinedQuote: params.predefinedQuote ? params.predefinedQuote : 'No',
      customerName: params.customerName ? params.customerName : '',
      customerReference: params.customerReference ? params.customerReference : '',
      requestorUser: username,
      productName: params.productName ? params.productName : '',
      subProduct: params.subProduct ? params.subProduct : '',
      productIndication: params.productIndication ? params.productIndication : '',
      doYouRequireDiversity: params.doYouRequireDiversity ? params.doYouRequireDiversity : '',
      describeYourDiversity: params.describeYourDiversity ? params.describeYourDiversity : '',
      existingInfracoServiceInfo: params.existingInfracoServiceInfo ? params.existingInfracoServiceInfo : '',
      thirdPartyPathInfo: params.thirdPartyPathInfo ? params.thirdPartyPathInfo : '',
      linkRequirement: params.linkRequirement ? params.linkRequirement : '',
      quantityOfService: params.quantityOfService ? params.quantityOfService : '',
      terms: params.terms ? params.terms : '',
      feasibilityRequestType: params.feasibilityRequestType ? params.feasibilityRequestType : '',
      fundingSource: params.fundingSource ? params.fundingSource : '',
      l3WBSCodeCapex: params.l3WBSCodeCapex ? params.l3WBSCodeCapex : '',
      l3WBSCodeOpex: params.l3WBSCodeOpex ? params.l3WBSCodeOpex : '',
      otherPowImc: params.otherPowImc ? params.otherPowImc : '',
      preApprovalCost: params.preApprovalCost ? params.preApprovalCost : '',
      responseRequiredDate: params.responseRequiredDate ? params.responseRequiredDate : '',
      businessDriver: params.businessDriver ? params.businessDriver : '',
      requestorConstraints: params.requestorConstraints ? params.requestorConstraints : '',
      preferredMaxDistance: params.preferredMaxDistance ? params.preferredMaxDistance : '',
      customerNotes: params.customerNotes ? params.customerNotes : '',
      siteADiversityDropDown: params.siteADiversityDropDown ? params.siteADiversityDropDown : '',
      siteZDiversityDropDown: params.siteZDiversityDropDown ? params.siteZDiversityDropDown : '',
      aEndAdress: {
        siteType: params.aEndAdress?.siteType ? params?.aEndAdress?.siteType : '',
        searchMethod: params.aEndAdress?.searchMethod ? params?.aEndAdress?.searchMethod : '',
        selectedAddress: params.aEndAdress?.selectedAddress ? params?.aEndAdress?.selectedAddress : '',
        definedPropertyLocation: params.aEndAdress?.definedPropertyLocation ? params?.aEndAdress?.definedPropertyLocation : '',
        additionalAddressInfo: params.aEndAdress?.additionalAddressInfo ? params?.aEndAdress?.additionalAddressInfo : '',
        adborId: params.aEndAdress?.adborId ? params?.aEndAdress?.adborId : '',
        latitude: params.aEndAdress?.latitude ? params?.aEndAdress?.latitude : '',
        longitude: params.aEndAdress?.longitude ? params?.aEndAdress?.longitude : '',
        nbnLocId: params.aEndAdress?.nbnLocId ? params?.aEndAdress?.nbnLocId : '',
        nodeCode: params.aEndAdress?.nodeCode ? params?.aEndAdress?.nodeCode : '',
        primaryContactName: params.aEndAdress?.primaryContactName ? params?.aEndAdress?.primaryContactName : '',
        primaryContactNumber: params.aEndAdress?.primaryContactNumber ? params?.aEndAdress?.primaryContactNumber : '',
        primaryContactEmail: params.aEndAdress?.primaryContactEmail ? params?.aEndAdress?.primaryContactEmail : '',
        altContactName: params.aEndAdress?.altContactName ? params?.aEndAdress?.altContactName : '',
        altContactNumber: params.aEndAdress?.altContactNumber ? params?.aEndAdress?.altContactNumber : '',
        altContactEmail: params.aEndAdress?.altContactEmail ? params?.aEndAdress?.altContactEmail : '',
        primaryContactNameUpdated: params.aEndAdress?.primaryContactNameUpdated,
        primaryContactNumberUpdated: params.aEndAdress?.primaryContactNumberUpdated,
        primaryContactEmailUpdated: params.aEndAdress?.primaryContactEmailUpdated,
        altContactNameUpdated: params.aEndAdress?.altContactNameUpdated,
        altContactNumberUpdated: params.aEndAdress?.altContactNumberUpdated,
        altContactEmailUpdated: params.aEndAdress?.altContactEmailUpdated,
      },
      cidn: params.cidn ? params.cidn : '',

      aEndpreDefinedNodeName: params.aEndpreDefinedNodeName ? params?.aEndpreDefinedNodeName : params.aEndAdress?.definedPropertyLocation,
      zEndpreDefinedNodeName: params.zEndpreDefinedNodeName ? params?.zEndpreDefinedNodeName : params.zEndAdress?.definedPropertyLocation,
      a2EndpreDefinedNodeName: params.a2EndpreDefinedNodeName ? params?.a2EndpreDefinedNodeName : params.a2EndAdress?.definedPropertyLocation,
      z2EndpreDefinedNodeName: params.z2EndpreDefinedNodeName ? params?.z2EndpreDefinedNodeName : params.z2EndAdress?.definedPropertyLocation,

      aEndAdressSiteAlternativeName: params.aEndAdressSiteAlternativeName ? params?.aEndAdressSiteAlternativeName : '',
      zEndAdressSiteAlternativeName: params.zEndAdressSiteAlternativeName ? params?.zEndAdressSiteAlternativeName : '',
      a2EndAdressSiteAlternativeName: params.a2EndAdressSiteAlternativeName ? params.a2EndAdressSiteAlternativeName : '',
      z2EndAdressSiteAlternativeName: params.z2EndAdressSiteAlternativeName ? params.z2EndAdressSiteAlternativeName : '',
      aEndLeadIn: params.aEndLeadIn ? params.aEndLeadIn : '',
      zEndLeadIn: params.zEndLeadIn ? params.zEndLeadIn : '',
      sameAsAEndAddress: (params.sameAsAEndAddress && params.sameAsAEndAddress != 'No') ? 'Yes' : 'No',
      sameAsZEndAddress: (params.sameAsZEndAddress && params.sameAsZEndAddress != 'No') ? 'Yes' : 'No',

      path2Z2SameAsAEndAddress: (params.path2Z2SameAsAEndAddress && params.path2Z2SameAsAEndAddress != 'No') ? 'Yes' : 'No',
      path2Z2SameAsZEndAddress: (params.path2Z2SameAsZEndAddress && params.path2Z2SameAsZEndAddress != 'No') ? 'Yes' : 'No',

      sameAsAEndContact: (params.sameAsAEndContact && params.sameAsAEndContact != 'No') ? 'Yes' : 'No',
      path2A2sameAsAEndContact: (params.path2A2sameAsAEndContact && params.path2A2sameAsAEndContact != 'No') ? 'Yes' : 'No',
      path2A2sameAsZEndContact: (params.path2A2sameAsZEndContact && params.path2A2sameAsZEndContact != 'No') ? 'Yes' : 'No',
      path2Z2sameAsAEndContact: (params.path2Z2sameAsAEndContact && params.path2Z2sameAsAEndContact != 'No') ? 'Yes' : 'No',
      path2Z2sameAsZEndContact: (params.path2Z2sameAsZEndContact && params.path2Z2sameAsZEndContact != 'No') ? 'Yes' : 'No',
      path2Z2sameAsA2EndContact: (params.path2Z2sameAsA2EndContact && params.path2Z2sameAsA2EndContact != 'No') ? 'Yes' : 'No',
      zEndAdress: {
        siteType: params.zEndAdress?.siteType ? params?.zEndAdress?.siteType : '',
        searchMethod: params.zEndAdress?.searchMethod ? params?.zEndAdress?.searchMethod : '',
        selectedAddress: params.zEndAdress?.selectedAddress ? params?.zEndAdress?.selectedAddress : '',
        definedPropertyLocation: params.zEndAdress?.definedPropertyLocation ? params?.zEndAdress?.definedPropertyLocation : '',
        additionalAddressInfo: params.zEndAdress?.additionalAddressInfo ? params?.zEndAdress?.additionalAddressInfo : '',
        adborId: params.zEndAdress?.adborId ? params?.zEndAdress?.adborId : '',
        latitude: params.zEndAdress?.latitude ? params?.zEndAdress?.latitude : '',
        longitude: params.zEndAdress?.longitude ? params?.zEndAdress?.longitude : '',
        nbnLocId: params.zEndAdress?.nbnLocId ? params?.zEndAdress?.nbnLocId : '',
        nodeCode: params.zEndAdress?.nodeCode ? params?.zEndAdress?.nodeCode : '',
        primaryContactName: params.zEndAdress?.primaryContactName ? params?.zEndAdress?.primaryContactName : '',
        primaryContactNumber: params.zEndAdress?.primaryContactNumber ? params?.zEndAdress?.primaryContactNumber : '',
        primaryContactEmail: params.zEndAdress?.primaryContactEmail ? params?.zEndAdress?.primaryContactEmail : '',
        altContactName: params.zEndAdress?.altContactName ? params?.zEndAdress?.altContactName : '',
        altContactNumber: params.zEndAdress?.altContactNumber ? params?.zEndAdress?.altContactNumber : '',
        altContactEmail: params.zEndAdress?.altContactEmail ? params?.zEndAdress?.altContactEmail : '',
        primaryContactNameUpdated: params.zEndAdress?.primaryContactNameUpdated,
        primaryContactNumberUpdated: params.zEndAdress?.primaryContactNumberUpdated,
        primaryContactEmailUpdated: params.zEndAdress?.primaryContactEmailUpdated,
        altContactNameUpdated: params.zEndAdress?.altContactNameUpdated,
        altContactNumberUpdated: params.zEndAdress?.altContactNumberUpdated,
        altContactEmailUpdated: params.zEndAdress?.altContactEmailUpdated,
      },
      a2EndAdress: {
        siteType: params.a2EndAdress?.siteType ? params?.a2EndAdress?.siteType : '',
        searchMethod: params.a2EndAdress?.searchMethod ? params?.a2EndAdress?.searchMethod : '',
        selectedAddress: params.a2EndAdress?.selectedAddress ? params?.a2EndAdress?.selectedAddress : '',
        definedPropertyLocation: params.a2EndAdress?.definedPropertyLocation ? params?.a2EndAdress?.definedPropertyLocation : '',
        additionalAddressInfo: params.a2EndAdress?.additionalAddressInfo ? params?.a2EndAdress?.additionalAddressInfo : '',
        adborId: params.a2EndAdress?.adborId ? params?.a2EndAdress?.adborId : '',
        latitude: params.a2EndAdress?.latitude ? params?.a2EndAdress?.latitude : '',
        longitude: params.a2EndAdress?.longitude ? params?.a2EndAdress?.longitude : '',
        nbnLocId: params.a2EndAdress?.nbnLocId ? params?.a2EndAdress?.nbnLocId : '',
        nodeCode: params.a2EndAdress?.nodeCode ? params?.a2EndAdress?.nodeCode : '',
        primaryContactName: params.a2EndAdress?.primaryContactName ? params?.a2EndAdress?.primaryContactName : '',
        primaryContactNumber: params.a2EndAdress?.primaryContactNumber ? params?.a2EndAdress?.primaryContactNumber : '',
        primaryContactEmail: params.a2EndAdress?.primaryContactEmail ? params?.a2EndAdress?.primaryContactEmail : '',
        altContactName: params.a2EndAdress?.altContactName ? params?.a2EndAdress?.altContactName : '',
        altContactNumber: params.a2EndAdress?.altContactNumber ? params?.a2EndAdress?.altContactNumber : '',
        altContactEmail: params.a2EndAdress?.altContactEmail ? params?.a2EndAdress?.altContactEmail : '',
        primaryContactNameUpdated: params.a2EndAdress?.primaryContactNameUpdated,
        primaryContactNumberUpdated: params.a2EndAdress?.primaryContactNumberUpdated,
        primaryContactEmailUpdated: params.a2EndAdress?.primaryContactEmailUpdated,
        altContactNameUpdated: params.a2EndAdress?.altContactNameUpdated,
        altContactNumberUpdated: params.a2EndAdress?.altContactNumberUpdated,
        altContactEmailUpdated: params.a2EndAdress?.altContactEmailUpdated,
      },
      z2EndAdress: {
        siteType: params.z2EndAdress?.siteType ? params?.z2EndAdress?.siteType : '',
        searchMethod: params.z2EndAdress?.searchMethod ? params?.z2EndAdress?.searchMethod : '',
        selectedAddress: params.z2EndAdress?.selectedAddress ? params?.z2EndAdress?.selectedAddress : '',
        definedPropertyLocation: params.z2EndAdress?.definedPropertyLocation ? params?.z2EndAdress?.definedPropertyLocation : '',
        additionalAddressInfo: params.z2EndAdress?.additionalAddressInfo ? params?.z2EndAdress?.additionalAddressInfo : '',
        adborId: params.z2EndAdress?.adborId ? params?.z2EndAdress?.adborId : '',
        latitude: params.z2EndAdress?.latitude ? params?.z2EndAdress?.latitude : '',
        longitude: params.z2EndAdress?.longitude ? params?.z2EndAdress?.longitude : '',
        nbnLocId: params.z2EndAdress?.nbnLocId ? params?.z2EndAdress?.nbnLocId : '',
        nodeCode: params.z2EndAdress?.nodeCode ? params?.z2EndAdress?.nodeCode : '',
        primaryContactName: params.z2EndAdress?.primaryContactName ? params?.z2EndAdress?.primaryContactName : '',
        primaryContactNumber: params.z2EndAdress?.primaryContactNumber ? params?.z2EndAdress?.primaryContactNumber : '',
        primaryContactEmail: params.z2EndAdress?.primaryContactEmail ? params?.z2EndAdress?.primaryContactEmail : '',
        altContactName: params.z2EndAdress?.altContactName ? params?.z2EndAdress?.altContactName : '',
        altContactNumber: params.z2EndAdress?.altContactNumber ? params?.z2EndAdress?.altContactNumber : '',
        altContactEmail: params.z2EndAdress?.altContactEmail ? params?.z2EndAdress?.altContactEmail : '',
        primaryContactNameUpdated: params.z2EndAdress?.primaryContactNameUpdated,
        primaryContactNumberUpdated: params.z2EndAdress?.primaryContactNumberUpdated,
        primaryContactEmailUpdated: params.z2EndAdress?.primaryContactEmailUpdated,
        altContactNameUpdated: params.z2EndAdress?.altContactNameUpdated,
        altContactNumberUpdated: params.z2EndAdress?.altContactNumberUpdated,
        altContactEmailUpdated: params.z2EndAdress?.altContactEmailUpdated,
      },
      externalReference: params.externalReference ? params.externalReference : [],
      maxAllowableOpticalLoss: params.maxAllowableOpticalLoss ? params.maxAllowableOpticalLoss : null,
    }

    if (payload && this.serviceType !== "RG") {
      this.nonRGPayloadPrepare(payload, this.serviceType);
    }

    this.setProductDetailsFormValues(payload);
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
      })
    };
    const url = this.env.apiPoint + this.aemDefaultUri.feasibility;
    //To run in local
    // const url = this.env.aemEndPoint + this.aemDefaultUri.mockSaveQuote;

    // if(payload.aEndAdress.siteType === "a2EndAdress"){
    //   payload.aEndAdress.siteType = "aEndAdress";
    // }

    return new Promise<any>((resolve, reject) => {
      this.http.put<any>(url, payload, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public getQuoteList(cidn, timeZone) {
    // cidn = '9999999998';
    const username = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'];
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',

      })
    };
    const url = this.env.apiPoint + this.aemDefaultUri.feasibility + '?cidn=' + cidn + '&timezone=' + timeZone;
    //To run in local
    //  const url = this.env.aemEndPoint + this.aemDefaultUri.mockGetQuoteList;
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public checkPredefinedRoute(cidn, aEndAdborId, zEndAdborId, quoteReference) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',

      })
    };
    const url = this.env.apiPoint + this.aemDefaultUri.predefinedRoute + cidn + '?a-end-adborid=' + aEndAdborId + '&z-end-adborid=' + zEndAdborId
      + '&feasibility-id=' + quoteReference;
    //to run in local
    //const url = this.env.aemEndPoint + 'mock/predefined-route-check.json';
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(url, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });


  }

  public modifyFeasibilityRequest(params) {
    const username = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'];
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
      })
    };
    const url = this.env.apiPoint + this.aemDefaultUri.feasibility;
    //To run in local
    // const url = this.env.aemEndPoint + this.aemDefaultUri.mockSaveQuote;
    return new Promise<any>((resolve, reject) => {
      this.http.patch<any>(url, params, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }

  public sendTermEnquiry(params) {
    const username = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail'];
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Authorization': this.authService.getAuthorizationHeaderValue() ? this.authService.getAuthorizationHeaderValue() : '',
      })
    };
    const url = this.env.apiPoint + this.aemDefaultUri.feasibility;
    //To run in local
    //const url = this.env.aemEndPoint + this.aemDefaultUri.mockEnqDetails;
    return new Promise<any>((resolve, reject) => {
      this.http.patch<any>(url, params, httpOptions)
        .subscribe(res => {
          resolve(res);
        }, err => {
          reject(err);
          return observableThrowError(err || 'Server error');
        });
    });
  }


  isNewQuoteClicked(val) {
    this.isNewQuoteButtonClicked.next(val)
  }

  isModifyExistingQuote(val) {
    this.isModifyQuote.next(val)
  }

  nonRGPayloadPrepare(payload, serviceType) {

    if (payload && payload.aEndAdress && serviceType !== "RG") {
      payload['aEndAdress']['primaryContactName'] = "";
      payload['aEndAdress']['primaryContactNumber'] = "";
      payload['aEndAdress']['primaryContactEmail'] = "";
      payload['aEndAdress']['altContactName'] = "";
      payload['aEndAdress']['altContactNumber'] = "";
      payload['aEndAdress']['altContactEmail'] = "";}

    if (payload && payload.zEndAdress && serviceType !== "RG") {
      payload['zEndAdress']['primaryContactName'] = "";
      payload['zEndAdress']['primaryContactNumber'] = "";
      payload['zEndAdress']['primaryContactEmail'] = "";
      payload['zEndAdress']['altContactName'] = "";
      payload['zEndAdress']['altContactNumber'] = "";
      payload['zEndAdress']['altContactEmail'] = "";
    }

    if (payload && payload.a2EndAdress && serviceType !== "RG") {
      payload['a2EndAdress']['primaryContactName'] = "";
      payload['a2EndAdress']['primaryContactNumber'] = "";
      payload['a2EndAdress']['primaryContactEmail'] = "";
      payload['a2EndAdress']['altContactName'] = "";
      payload['a2EndAdress']['altContactNumber'] = "";
      payload['a2EndAdress']['altContactEmail'] = "";
    }

    if (payload && payload.z2EndAdress && serviceType !== "RG") {
      payload['z2EndAdress']['primaryContactName'] = "";
      payload['z2EndAdress']['primaryContactNumber'] = "";
      payload['z2EndAdress']['primaryContactEmail'] = "";
      payload['z2EndAdress']['altContactName'] = "";
      payload['z2EndAdress']['altContactNumber'] = "";
      payload['z2EndAdress']['altContactEmail'] = "";
    }


    if(payload && payload.sameAsAEndContact && payload.sameAsZEndContact && payload.path2Z2SameAsAEndAddress && payload.path2Z2SameAsZEndAddress && payload.path2A2sameAsAEndContact && payload.path2A2sameAsZEndContact && serviceType !== "RG"){
      payload['sameAsAEndContact'] = "";
      payload['sameAsZEndContact'] = "";
      payload['path2Z2SameAsAEndAddress'] = "";
      payload['path2Z2SameAsZEndAddress'] = "";
      payload['path2A2sameAsAEndContact'] = "";
      payload['path2A2sameAsZEndContact'] = "";
    }

    return payload;
  }


}

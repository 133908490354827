<div>

  <h2>Dark Fibre Order</h2>
  <p class="text-black">{{submittedDate}}</p>
  <h4 class="text-black">{{aemLabelAuthoring?.pageHeader?.pageHeaderLabel}}: {{quoteRefId}}</h4>

  <div class="row" style="position: relative;">
    <div ngSwitch="{{status | lowercase}}" class="col-10 mt-20">
      <span>
        <img class="quoteStatus" *ngIf="(status?.toLowerCase() === 'submitted' ||
         status?.toLowerCase() === 'accepted')" src="../../../assets/images/Submitted.svg" />
        <img class="quoteStatus" *ngIf="status?.toLowerCase() === 'cancelled'"
          src="../../../assets/images/Cancelled.svg" />
        <img class="quoteStatus" *ngIf="status?.toLowerCase() === 'expired'" src="../../../assets/images/Expired.svg" />
        <img class="quoteStatus" *ngIf="(status?.toLowerCase() === 'in progress' ||
         status?.toLowerCase() === 'appraisal - in progress'
        || status?.toLowerCase() === 'site assessment - in progress' ||
         status?.toLowerCase() === 'design - in progress' ||
         status?.toLowerCase() === 'construction - in progress')" src="../../../assets/images/In-Progress.svg" />
        <img class="quoteStatus" *ngIf="(status?.toLowerCase() === 'draft' ||
        status?.toLowerCase() === 'awaiting commercial review')" src="../../../assets/images/Draft.svg" />
        <img class="quoteStatus" *ngIf="status?.toLowerCase() === 'quote ready' ||
         status?.toLowerCase() === 'completed' ||
         status?.toLowerCase() === 'ordered' ||
         status?.toLowerCase() === 'complete'" src="../../../assets/images/Quote-Ready.svg" />
        <img class="quoteStatus" *ngIf="(status?.toLowerCase() === 'awaiting customer response' ||
         status?.toLowerCase() === 'appraisal - on hold' ||
        status?.toLowerCase() === 'site assessment - on hold' ||
         status?.toLowerCase() === 'design - on hold' ||
         status?.toLowerCase() === 'construction - on hold')"
          src="../../../assets/images/Awaiting-Customer-Response.svg" />
        <img class="quoteStatus" *ngIf="status?.toLowerCase() === 'awaiting dependent action'"
          src="../../../assets/images/Awaiting-Dependent-Action.svg" />
        <img class="quoteStatus" *ngIf="status?.toLowerCase() === 'withdrawn'"
          src="../../../assets/images/Withdrawn.svg" />
        <img class="quoteStatus" *ngIf="status?.toLowerCase() === 'withdraw submitted'"
          src="../../../assets/images/Withdrawn.svg" />
        <img class="quoteStatus" *ngIf="status?.toLowerCase() === 'quote accepted'"
          src="../../../assets/images/quote-accepted-icon.svg" />
        <img class="quoteStatus" *ngIf="status?.toLowerCase() === 'assessment complete'"
          src="../../../assets/images/AssessmentComplete.svg" />
        <span class="status row">{{status}}</span></span>
      <p style="position: absolute;
                top: 48px;
                left: 61px;
                font-size: 14px; " *ngIf="!isOrder">{{statusSummary}}</p>
    </div>

    <div class="col-2 quote-expiry-container" *ngIf="status === 'Quote Ready'">
      <span class="quote-expiry-heading">Quote expiry</span>
      <span class="quote-expiry-text"><span *ngIf="quoteExpiry; else noValueAvailable">{{quoteExpiry}} days
          left</span></span>
    </div>
  </div>
  <hr class="hrSum">
  <!--ONLY FOR ORDER SECTION  -->
  <div class="Summary" *ngIf="isOrder">
    <span class="summaryHead row">{{aemLabelAuthoring?.pageHeader?.subHeading}}</span>
    <div class="col-12 summaryContent">
      <div class="col-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.quoteReference}}</label><br>
        <label class="valuesummary" *ngIf="quoteRefId; else noValueAvailable">{{quoteRefId}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!quoteRefId">No value</label>
      </div>
      <div class="col-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.customerReference}}</label><br>
        <label class="valuesummary" *ngIf="custRefId; else noValueAvailable">{{custRefId}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!custRefId">No value</label>
      </div>
      <div class="col-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.customerRequestedDate}}</label><br>
        <label class="valuesummary"
          *ngIf="customerRequestedDate; else noValueAvailable">{{customerRequestedDate}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!customerRequestedDate">No value</label>
      </div>
      <div class="col-4">

        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.submittedDate}}</label><br>
        <label class="valuesummary" *ngIf="submittedDate; else noValueAvailable">{{submittedDate}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!submittedDate">No value</label>

        <!-- <label class="headersum">{{aemLabelAuthoring?.pageHeader?.lastUpdated}}</label><br>
          <label class="valuesummary" *ngIf="lastSub; else noValueAvailable">{{lastSub}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!lastSub">No value</label> -->
      </div>
    </div>

    <div class="col-12 summaryContent1">
      <div class="col-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.provisionalDateRange}}</label><br>
        <label class="valuesummary" *ngIf="provisionalDateRange; else noValueAvailable">{{provisionalDateRange}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!provisionalDateRange">No value</label>
      </div>
      <div class="col-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.ourCommitmentDate}}</label><br>
        <label class="valuesummary" *ngIf="ourCommitmentDate; else noValueAvailable">{{ourCommitmentDate}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!ourCommitmentDate">No value</label>
      </div>
      <div class="col-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.networkProximity}}</label><br>
        <label class="valuesummary" *ngIf="networkProximity; else noValueAvailable">{{networkProximity}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!networkProximity">No value</label>
      </div>
      <div class="col-4">

        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.completedDate}}</label><br>
        <label class="valuesummary" *ngIf="completedDate; else noValueAvailable">{{completedDate}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!completedDate">No value</label>

        <!-- <label class="headersum">{{aemLabelAuthoring?.pageHeader?.lastUpdated}}</label><br>
          <label class="valuesummary" *ngIf="lastSub; else noValueAvailable">{{lastSub}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!lastSub">No value</label> -->
      </div>
    </div>

    <div class="col-12 summaryContent1">
      <div class="col-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.orderType}}</label><br>
        <label class="valuesummary" *ngIf="orderType; else noValueAvailable">{{orderType}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!orderType">No value</label>
      </div>
      <div class="col-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.serviceId}}</label><br>
        <label class="valuesummary" *ngIf="serviceId; else noValueAvailable">{{serviceId}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!serviceId">No value</label>
      </div>

      <div class="col-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.lastUpdated}}</label><br>
        <label class="valuesummary" *ngIf="lastSub; else noValueAvailable">{{lastSub}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!lastSub">No value</label>
      </div>
    </div>


    <div class="col-12 summaryContent1">
      <div class="col-4 requestor-container">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestor}}</label><br>
        <label class="valuesummary summary-text-wrap" *ngIf="requestor; else noValueAvailable">{{requestor}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!requestor">No value</label>
      </div>
      <div class="col-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestorChannel}}</label><br>
        <label class="valuesummary" *ngIf="requestorChannel; else noValueAvailable">{{requestorChannel}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!requestorChannel">No value</label>
      </div>
      <div class="col-4" *ngIf="showCustName">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.customerName}}</label><br>
        <label class="valuesummary">{{customerName}}</label>
      </div>
    </div>
    <div class="col-12 summaryContent1" *ngIf="showSerNum && !isOrder">
      <div class="col-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.serviceNowReference}}</label><br>
        <label class="valuesummary">{{serviceNowReference}}</label>
      </div>
    </div>
  </div>
  <!-- END OF ORDER SECTION -->

  <!-- ONLY FOR QUOTE -->
  <div class="Summary" *ngIf="!isOrder">
    <span class="summaryHead row">{{aemLabelAuthoring?.pageHeader?.subHeading}}</span>
    <div class="col-12 summaryContent">
      <div class="col-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.quoteReference}}</label><br>
        <label class="valuesummary" *ngIf="quoteRefId; else noValueAvailable">{{quoteRefId}}</label>
      </div>
      <div class="col-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.customerReference}}</label><br>
        <label class="valuesummary summary-text-wrap" *ngIf="custRefId; else noValueAvailable">{{custRefId}}</label>
      </div>
      <div class="col-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.responseDate}}</label><br>
        <label class="valuesummary" *ngIf="responseReq; else noValueAvailable">{{responseReq}}</label>
      </div>
      <div class="col-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.lastUpdated}}</label><br>
        <label class="valuesummary" *ngIf="lastSub; else noValueAvailable">{{lastSub}}</label>
      </div>
    </div>
    <div class="col-12 summaryContent1">
      <div class="col-4 requestor-container">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestor}}</label><br>
        <label class="valuesummary summary-text-wrap" *ngIf="requestor; else noValueAvailable">{{requestor}}</label>
      </div>
      <div class="col-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestorChannel}}</label><br>
        <label class="valuesummary" *ngIf="requestorChannel; else noValueAvailable">{{requestorChannel}}</label>
      </div>
      <div class="col-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.submittedDate}}</label><br>
        <label class="valuesummary" *ngIf="submittedDate; else noValueAvailable">{{submittedDate}}</label>
      </div>
      <div class="col-4" *ngIf="showCustName">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.customerName}}</label><br>
        <label class="valuesummary">{{customerName}}</label>
      </div>
    </div>
    <div class="col-12 summaryContent1" *ngIf="showSerNum">
      <div class="col-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.serviceNowReference}}</label><br>
        <label class="valuesummary">{{serviceNowReference}}</label>
      </div>
    </div>
  </div>
  <!-- END OF QUOTE -->


  <div class="expandable assessment-outcome-container showDiversity"
    *ngIf="showAssessmentOutcome(status) && status == isOrder">
    <div class="headerCont">
      <label class="productLabel">{{aemLabelAuthoring?.pageHeader?.assessmentOutcomeHeading}}</label>
    </div>
    <div class="assessment-outcome-content">
      <div class="col-12 summaryContent">
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestMet}}</label><br>
          <label class="valuesum" *ngIf="requestMet === 'Yes'"> <img class="request-met-icon"
              src="../../../assets/images/Quote-Ready.svg" />{{requestMet}}</label>
          <label class="valuesum" *ngIf="requestMet === 'No'"><img class="request-met-icon"
              src="../../../assets/images/Cross-Circle-Fill.svg" />{{requestMet}}</label>
          <label class="valuesum" *ngIf="requestMet === 'Yes (with concession)'"><i
              class="icon-icon-alert-triangle"></i><span class="request-met-text">{{requestMet}}</span></label>
        </div>
        <div class="col-3" *ngIf="requestMet === 'No'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.notMetItems}}</label><br>
          <label class="valuesum" *ngIf="notMetItems; else noValueAvailable">{{notMetItems}}</label>
        </div>
        <div class="col-3" *ngIf="requestMet === 'Yes (with concession)'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.concessionItems}}</label><br>
          <label class="valuesum" *ngIf="concessionItems; else noValueAvailable">{{concessionItems}}</label>
        </div>
        <div class="col-9">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestorSolutionSummary}}</label><br>
          <label class="valuesum summary-text-wrap-1"
            *ngIf="requestorSolutionSummary; else noValueAvailable">{{requestorSolutionSummary}}</label>
        </div>
      </div>
    </div>
  </div>


  <!-- <div class="expandable assessment-outcome-container showDiversity"
    *ngIf="showDiversity && (status === 'Quote Ready' || status === 'Expired' || status === 'Quote Accepted')">
    <div class="headerCont">
      <label class="productLabel"
        style="margin-top:10px;">{{aemLabelAuthoring?.pageHeader?.assessmentoutcomePath2}}</label>
    </div>
    <div class="assessment-outcome-content">
      <div class="col-12 summaryContent">
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestMet}}</label><br>
          <label class="valuesum" *ngIf="requestMetDiversity === 'Yes'"> <img class="request-met-icon"
              src="../../../assets/images/Quote-Ready.svg" />{{requestMetDiversity}}</label>
          <label class="valuesum" *ngIf="requestMetDiversity === 'No'"><img class="request-met-icon"
              src="../../../assets/images/Cross-Circle-Fill.svg" />{{requestMetDiversity}}</label>
          <label class="valuesum" *ngIf="requestMetDiversity === 'Yes (with concession)'"><i
              class="icon-icon-alert-triangle"></i><span class="request-met-text">{{requestMetDiversity}}</span></label>
        </div>
        <div class="col-3" *ngIf="requestMetDiversity === 'No'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.notMetItems}}</label><br>
          <label class="valuesum" *ngIf="notMetItemsDiversity; else noValueAvailable">{{notMetItemsDiversity}}</label>
        </div>
        <div class="col-3" *ngIf="requestMetDiversity === 'Yes (with concession)'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.concessionItems}}</label><br>
          <label class="valuesum"
            *ngIf="concessionItemsDiversity; else noValueAvailable">{{concessionItemsDiversity}}</label>
        </div>
        <div class="col-9">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestorSolutionSummary}}</label><br>
          <label class="valuesum summary-text-wrap-1"
            *ngIf="requestorSolutionSummaryDiversity; else noValueAvailable">{{requestorSolutionSummaryDiversity}}</label>
        </div>
      </div>
    </div>
  </div> -->

  <div class="expandable assessment-outcome-container bg-white showDiversity"
    [ngClass]="(showDiversity && (status === 'Quote Ready' || status === 'Expired'  || status === 'Quote Accepted') && requestorSolutionSummaryDiversity?.length > 200)? 'showDiversity': ''">
    <div class="headerCont">
      <h2 class="productLabel">{{aemLabelAuthoring?.pageHeader?.darkFiber}}</h2>
    </div>
    <div class="assessment-outcome-content bg-white">
      <div class="col-12 summaryContent flex-class">
        <div>
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.product}}</label><br>
          <label class="valuesum" *ngIf="productRef; else noValueAvailable">{{productRef}}</label>
        </div>

        <div>
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.subProduct}}</label><br>
          <label class="valuesum" *ngIf="subProductRef; else noValueAvailable">{{subProductRef}}</label>
        </div>

        <div>
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.linkRequirement}}</label><br>
          <label class="valuesum" *ngIf="linkReq; else noValueAvailable">{{linkReq}}</label>
        </div>

        <div *ngIf="!isOrder">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.quantityService}}</label><br>
          <label class="valuesum" *ngIf="quantityServ; else noValueAvailable">{{quantityServ}}</label>
        </div>

        <!-- START: Terms should only be displayed in case of quote or in case of rg-->
        <div *ngIf="(serviceType == 'RG')">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.Terms}}</label><br>
          <label class="valuesum">{{terms}} years</label>
        </div>
        <!-- END: Terms should only be displayed in case of quote or in case of rg-->
      </div>
    </div>
  </div>

  <!-- START: Pricing section for order only -->
  <div class="expandable assessment-outcome-container bg-white showDiversity" *ngIf="isOrder">

    <div class="headerCont">
      <h2 class="productLabel">{{aemLabelAuthoring?.pageHeader?.pricing}}</h2>
    </div>
    <div class="assessment-outcome-content bg-white">
      <!--START: ONLY FOR RG-->
      <ng-container *ngIf="serviceType=='RG'">
        <div class="col-12 summaryContent">
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.totalCapitalCost}}</label><br>
            <label class="valuesum" *ngIf="totalCapitalCost; else noValueAvailable">{{totalCapitalCost | currency
              }}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!totalCapitalCost">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteAssessmentCost}}</label><br>
            <label class="valuesum" *ngIf="siteAssessmentCost; else noValueAvailable">{{siteAssessmentCost |
              currency}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!siteAssessmentCost">No value</label>
          </div>
        </div>
        <div class="col-12 summaryContent">
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.monthlyRecurringICACharge}}</label><br>
            <label class="valuesum" *ngIf="monthlyRecurringICACharge; else noValueAvailable">{{monthlyRecurringICACharge
              | currency}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!monthlyRecurringICACharge">No value</label>
          </div>
          <div class="col-3">

            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.monthlyRecurringCharge}}</label><br>
            <label class="valuesum" *ngIf="monthlyRecurringCharge; else noValueAvailable">{{monthlyRecurringCharge
              |
              currency }}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!monthlyRecurringCharge">No value</label>


          </div>

          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.totalMonthlyRecurringCharge}}</label><br>
            <label class="valuesum"
              *ngIf="totalMonthlyRecurringCharge; else noValueAvailable">{{totalMonthlyRecurringCharge | currency
              }}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!totalMonthlyRecurringCharge">No
              value</label>
          </div>
        </div>
      </ng-container>
      <!--END: ONLY FOR RG-->

      <!-- START: ONLY FOR NON RG -->
      <ng-container *ngIf="serviceType !=='RG'">
        <div class="col-12 summaryContent">
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.monthlyRecurringCharge}}</label><br>
            <label class="valuesum" *ngIf="getMonthlyRecurringChargeNonRG(); else noValueAvailable">{{getMonthlyRecurringChargeNonRG()}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!monthlyRecurringCharge">No value</label>

          </div>

          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.ContractTerms}}</label><br>
            <label class="valuesum">{{selectedTermForNonRGPath1}} years</label>
          </div>

          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.nonRecurringCharge}}</label><br>
            <label class="valuesum" *ngIf="nonRecurringCharge; else noValueAvailable">{{nonRecurringCharge}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!nonRecurringCharge">No value</label>
          </div>
        </div>
      </ng-container>
      <!-- END: ONLY FOR NON RG -->

    </div>
  </div>
  <!--END: Pricing section for order only  -->


  <div class="expandable assessment-outcome-container bg-white showDiversity" *ngIf="showDiversity && !isOrder">
    <div class="headerCont">
      <h2 class="productLabel">{{aemLabelAuthoring?.pageHeader?.diversity}}</h2>
    </div>
    <div class="assessment-outcome-content bg-white">
      <div class="row summaryContent">
        <div [ngClass]="existingInfracoServiceInfo ? 'col-4' : 'col-6'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.diversityReq}}</label><br>
          <label class="valuesum" [ngClass]="{'existing-info-avlbl': existingInfracoServiceInfo}"
            *ngIf="describeYourDiversity; else noValueAvailable">{{describeYourDiversity}}</label>
        </div>
        <div class="col-3" *ngIf="existingInfracoServiceInfo;">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.existingInfracoServiceInfo}}</label><br>
          <label class="valuesum"
            *ngIf="existingInfracoServiceInfo; else noValueAvailable">{{existingInfracoServiceInfo}}</label>
        </div>
        <div [ngClass]="existingInfracoServiceInfo ? 'col-8' : 'col-6'" *ngIf="thirdPartyPathInfo"
          style="margin-bottom: 1rem;">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.thirdPartyPathInfo}}</label><br>
          <label class="valuesum" *ngIf="thirdPartyPathInfo; else noValueAvailable">{{thirdPartyPathInfo}}</label>
        </div>
        <div [ngClass]="thirdPartyPathInfo ? 'col-6' : 'col-3'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteALead}}</label><br>
          <label class="valuesum" *ngIf="aEndLead; else noValueAvailable">{{aEndLead}}</label>
        </div>
        <div [ngClass]="thirdPartyPathInfo ? 'col-6' : 'col-3'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteZLead}}</label><br>
          <label class="valuesum" *ngIf="zEndLead; else noValueAvailable">{{zEndLead}}</label>
        </div>
      </div>
    </div>
  </div>

  <!-- START: SITE A end address for order only -->
  <div class="expandable assessment-outcome-container bg-white showDiversity" *ngIf="isOrder">
    <div class="headerCont">

      <h2 class="productLabel">{{showDiversity ?
        aemLabelAuthoring?.pageHeader?.SiteAPath1Adddetails:aemLabelAuthoring?.pageHeader?.SiteAAdddetails}}
      </h2>
    </div>
    <div class="col-11 assessment-outcome-content bg-white">
      <div class="row col-12">
        <div class="col-3 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.SiteAAdd}}</label><br>
          <label class="valuesum" *ngIf="aEndAdress; else noValueAvailable">{{aEndAdress}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndAdress">No value</label>
        </div>
        <div class="col-3 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternativeName}}</label><br>
          <label class="valuesum"
            *ngIf="aEndAdressSiteAlternativeName; else noValueAvailable">{{aEndAdressSiteAlternativeName}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndAdressSiteAlternativeName">No value</label>
        </div>
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteType}}</label><br>
          <label class="valuesum"
            *ngIf="aEndSiteType; else noValueAvailable">{{aEndSiteType}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndSiteType && isExpanded1">No value</label>
        </div>
        <!-- <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.serviceDeliveryPoint}}</label><br>
          <label class="valuesum" *ngIf="serviceDeliveryPoint; else noValueAvailable">{{serviceDeliveryPoint}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!serviceDeliveryPoint">No value</label>
        </div> -->
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.sdpType}}</label><br>
          <label class="valuesum"
            *ngIf="aEndsdpType; else noValueAvailable">{{aEndsdpType}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndsdpType && isExpanded1">No value</label>
        </div>
        <!-- <div class="col-3" *ngIf="serviceType == 'RG' &&  businessDriv == '5G Small Cell'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.nodeCode}}</label><br>
          <label class="valuesum" *ngIf="nodeCode; else noValueAvailable">{{nodeCode}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!nodeCode">No value</label>
        </div> -->
      </div>

      <div class="row col-12">
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.connectorType}}</label><br>
          <label class="valuesum"
            *ngIf="aEndConnectorType; else noValueAvailable">{{aEndConnectorType}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndConnectorType && isExpanded1">No value</label>
        </div>
        <div class="col-3 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.demarcationPoint}}</label><br>
          <label class="valuesum"
            *ngIf="aEndDemarcationPoint; else noValueAvailable">{{aEndDemarcationPoint}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndDemarcationPoint && isExpanded1">No value</label>
        </div>
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreOperator}}</label><br>
          <label class="valuesum"
            *ngIf="aEndDcOperator; else noValueAvailable">{{aEndDcOperator}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndDcOperator && isExpanded1">No value</label>
        </div>
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreSite}}</label><br>
          <label class="valuesum"
            *ngIf="aEndDcSiteName; else noValueAvailable">{{aEndDcSiteName}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndDcSiteName && isExpanded1">No value</label>
        </div>
      </div>
      <div class="row col-12 mb-4">
        <!-- <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.sdpType}}</label><br>
          <label class="valuesum"
            *ngIf="aEndsdpType; else noValueAvailable">aEndsdpType</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndsdpType && isExpanded1">No value</label>
        </div> -->
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.serviceDeliveryPoint}}</label><br>
          <label class="valuesum" *ngIf="serviceDeliveryPoint; else noValueAvailable">{{serviceDeliveryPoint}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!serviceDeliveryPoint && isExpanded1">No value</label>
        </div>
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.accessType}}</label><br>
          <label class="valuesum"
            *ngIf="aEndAccessType; else noValueAvailable">{{aEndAccessType}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndAccessType && isExpanded1">No value</label>
        </div>
        <div class="col-3 mb-4" *ngIf="subProductRef && subProductRef === 'mobile smc' && siteName == 'NON-PREMISES'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.poleId}}</label><br>
          <label class="valuesum" *ngIf="poleId; else noValueAvailable">{{poleId}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!poleId">No value</label>
        </div>
        <div class="col-3" *ngIf="subProductRef && subProductRef === 'mobile smc' && siteName == 'NON-PREMISES'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.nodeCode}}</label><br>
          <label class="valuesum" *ngIf="nodeCode; else noValueAvailable">{{nodeCode}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!nodeCode">No value</label>
        </div>
      </div>
      <div class="row col-12">
        <div class="col-3 mb-4" *ngIf="subProductRef && subProductRef === 'mobile smc' && siteName == 'NON-PREMISES'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leadInRequired}}</label><br>
          <label class="valuesum" *ngIf="leadInRequired; else noValueAvailable">{{leadInRequired}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!leadInRequired">No value</label>
        </div>
        <div class="col-3 mb-4" *ngIf="aEndAdditionalCabling">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.additionalCabling}}</label><br>
          <label class="valuesum"
            *ngIf="aEndAdditionalCabling; else noValueAvailable">{{aEndAdditionalCabling}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndAdditionalCabling && isExpanded1">No value</label>
        </div>
        <div class="col-3 mb-4" *ngIf="aEndAdditionalCablingLocation">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.additionalCablingLocation}}</label><br>
          <label class="valuesum"
            *ngIf="aEndAdditionalCablingLocation; else noValueAvailable">{{aEndAdditionalCablingLocation}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndAdditionalCablingLocation && isExpanded1">No value</label>
        </div>
      </div>
      <!-- <div class="row col-12" *ngIf="serviceType=='RG' && siteName =='NON-PREMISES' && businessDriv == '5G Small Cell'">
        <div class="col-3 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.poleId}}</label><br>
          <label class="valuesum" *ngIf="poleId; else noValueAvailable">{{poleId}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!poleId">No value</label>
        </div>
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leadInRequired}}</label><br>
          <label class="valuesum" *ngIf="leadInRequired; else noValueAvailable">{{leadInRequired}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!leadInRequired">No value</label>
        </div>
      </div> -->

      <div class="col-9 mb-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.addressInformation}}</label><br>
        <label class="valuesum" *ngIf="addInformation; else noValueAvailable">{{addInformation}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!addInformation">No value</label>
      </div>
      <div class="row col-12">
        <div class="col-3 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactName}}</label><br>
          <label class="valuesum"
            *ngIf="aEndprimaryContactName; else noValueAvailable">{{aEndprimaryContactName}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndprimaryContactName">No value</label>
        </div>
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactNumber}}</label><br>
          <label class="valuesum"
            *ngIf="aEndprimaryContactNumber; else noValueAvailable">{{aEndprimaryContactNumber}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndprimaryContactNumber">No value</label>
        </div>
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactEmail}}</label><br>
          <label class="valuesum"
            *ngIf="aEndprimaryContactEmail; else noValueAvailable">{{aEndprimaryContactEmail}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndprimaryContactEmail">No value</label>
        </div>
      </div>
    </div>
  </div>
  <!--END: SITE A end address for order only  -->

  <!-- START: SITE A end address for quote only -->
  <div class="expandable assessment-outcome-container bg-white showDiversity" *ngIf="!isOrder">
    <div class="headerCont">

      <h2 class="productLabel">{{isDiversity === 'Yes' && describeYourDiversity === 'All new services with diversity' ?
        aemLabelAuthoring?.pageHeader?.SiteAPath1Adddetails:aemLabelAuthoring?.pageHeader?.SiteAAdddetails}}
      </h2>
    </div>
    <div class="col-11 assessment-outcome-content bg-white">
      <div class="row col-12">
        <span class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.SiteAAdd}}</label><br>
          <label class="valuesum" *ngIf="aEndAdress; else noValueAvailable">{{aEndAdress}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternativeName}}</label><br>
          <label class="valuesum"
            *ngIf="aEndAdressSiteAlternativeName; else noValueAvailable">{{aEndAdressSiteAlternativeName}}</label>
        </span>
        <span class="col-4" *ngIf="definedPropertyLoca?.toLowerCase() == 'yes' || definedPropertyLoca?.toLowerCase() == 'no'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.propertyLocation}}</label><br>
          <label class="valuesum" *ngIf="definedPropertyLoca; else noValueAvailable">{{definedPropertyLoca}}</label>
        </span>
      </div>
      <!-- added new for non RG customer-->
      <div class="row col-12" *ngIf="serviceType !=='RG'">
        <!-- <div class="col-2"></div> -->
        <div class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteType}}</label><br>
          <label class="valuesum"
            *ngIf="aEndSiteType; else noValueAvailable">{{aEndSiteType}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndSiteType && isExpanded1">No value</label>
        </div>
        <div class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreOperator}}</label><br>
          <label class="valuesum"
            *ngIf="aEndDcOperator; else noValueAvailable">{{aEndDcOperator}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndDcOperator && isExpanded1">No value</label>
        </div>
        <div class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreSite}}</label><br>
          <label class="valuesum"
            *ngIf="aEndDcSiteName; else noValueAvailable">{{aEndDcSiteName}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndDcSiteName && isExpanded1">No value</label>
        </div>
      </div>
      <div class="col-9  row mb-5">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.addressInformation}}</label><br>
        <label class="valuesum" *ngIf="addInformation; else noValueAvailable">{{addInformation}}</label>
      </div>      
      <div class="row col-12" *ngIf=" serviceType == 'RG'">
        <span class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactName}}</label><br>
          <label class="valuesum"
            *ngIf="aEndprimaryContactName; else noValueAvailable">{{aEndprimaryContactName}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactNumber}}</label><br>
          <label class="valuesum"
            *ngIf="aEndprimaryContactNumber; else noValueAvailable">{{aEndprimaryContactNumber}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactEmail}}</label><br>
          <label class="valuesum"
            *ngIf="aEndprimaryContactEmail; else noValueAvailable">{{aEndprimaryContactEmail}}</label>
        </span>
      </div>
      <div class="row col-12" *ngIf=" serviceType == 'RG'">
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactName}}</label><br>
          <label class="valuesum" *ngIf="aEndAltContactName; else noValueAvailable">{{aEndAltContactName}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactNumber}}</label><br>
          <label class="valuesum" *ngIf="aEndAltContactNumber; else noValueAvailable">{{aEndAltContactNumber}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactEmail}}</label><br>
          <label class="valuesum" *ngIf="aEndAltContactEmail; else noValueAvailable">{{aEndAltContactEmail}}</label>
        </span>
      </div>
    </div>
  </div>
  <!--END: SITE A end address for quote only  -->


  <!-- START: SITE z end address for order only -->
  <div class="expandable assessment-outcome-container bg-white showDiversity" *ngIf="isOrder">
    <div class="headerCont">
      <h2 class="productLabel">{{ showDiversity ? aemLabelAuthoring?.pageHeader?.SiteZPath1Adddetails:
        aemLabelAuthoring?.pageHeader?.SiteZAdddetails}}</h2>
    </div>
    <div class="col-11  assessment-outcome-content bg-white">
      <div class="row col-12">
        <div class="col-3 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.SiteZaddress}}</label><br>
          <label class="valuesum" *ngIf="zEndAdress; else noValueAvailable">{{zEndAdress}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndAdress">No value</label>
        </div>
        <div class="col-3 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternativeName}}</label><br>
          <label class="valuesum"
            *ngIf="zEndAdressSiteAlternativeName; else noValueAvailable">{{zEndAdressSiteAlternativeName}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndAdressSiteAlternativeName">No value</label>
        </div>
        <div class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteType}}</label><br>
          <label class="valuesum"
            *ngIf="zEndSiteType; else noValueAvailable">{{zEndSiteType}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndSiteType && isExpanded1">No value</label>
        </div>
        <!-- <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.serviceDeliveryPoint}}</label><br>
          <label class="valuesum"
            *ngIf="zEndServiceDeliveryPoint; else noValueAvailable">{{zEndServiceDeliveryPoint}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndServiceDeliveryPoint">No value</label>
        </div> -->
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.sdpType}}</label><br>
          <label class="valuesum"
            *ngIf="zEndsdpType; else noValueAvailable">{{zEndsdpType}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndsdpType && isExpanded1">No value</label>
        </div>
        <!-- <div class="col-3" *ngIf="serviceType == 'RG' &&  businessDriv == '5G Small Cell'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.nodeCode}}</label><br>
          <label class="valuesum" *ngIf="zNodeCode; else noValueAvailable">{{zNodeCode}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zNodeCode">No value</label>
        </div> -->
      </div>
      <div class="row col-12">
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.connectorType}}</label><br>
          <label class="valuesum"
            *ngIf="zEndConnectorType; else noValueAvailable">{{zEndConnectorType}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndConnectorType && isExpanded1">No value</label>
        </div>
        <div class="col-3 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.demarcationPoint}}</label><br>
          <label class="valuesum"
            *ngIf="zEndDemarcationPoint; else noValueAvailable">{{zEndDemarcationPoint}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndDemarcationPoint && isExpanded1">No value</label>
        </div>
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreOperator}}</label><br>
          <label class="valuesum"
            *ngIf="zEndDcOperator; else noValueAvailable">{{zEndDcOperator}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndDcOperator && isExpanded1">No value</label>
        </div>
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreSite}}</label><br>
          <label class="valuesum"
            *ngIf="zEndDcSiteName; else noValueAvailable">{{zEndDcSiteName}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndDcSiteName && isExpanded1">No value</label>
        </div>
      </div>
      <div class="row col-12 mb-4">
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.serviceDeliveryPoint}}</label><br>
          <label class="valuesum"
            *ngIf="zEndServiceDeliveryPoint; else noValueAvailable">{{zEndServiceDeliveryPoint}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndServiceDeliveryPoint && isExpanded2">No value</label>
        </div>
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.accessType}}</label><br>
          <label class="valuesum"
            *ngIf="zEndAccessType; else noValueAvailable">{{zEndAccessType}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndAccessType && isExpanded1">No value</label>
        </div>
        <div class="col-3" *ngIf="subProductRef && subProductRef === 'mobile smc' && siteNameZ == 'NON-PREMISES'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.poleId}}</label><br>
          <label class="valuesum" *ngIf="zPoleId; else noValueAvailable">{{zPoleId}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zPoleId">No value</label>
        </div>
        <div class="col-3" *ngIf="subProductRef && subProductRef === 'mobile smc' && siteNameZ == 'NON-PREMISES'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.nodeCode}}</label><br>
          <label class="valuesum" *ngIf="zNodeCode; else noValueAvailable">{{zNodeCode}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zNodeCode">No value</label>
        </div>
      </div>
      <div class="row col-12">
        <div class="col-3 mb-4" *ngIf="subProductRef && subProductRef === 'mobile smc' && siteNameZ == 'NON-PREMISES'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leadInRequired}}</label><br>
          <label class="valuesum" *ngIf="zLeadInRequired; else noValueAvailable">{{zLeadInRequired}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zLeadInRequired">No value</label>
        </div>
        <div class="col-3 mb-4" *ngIf="zEndAdditionalCabling">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.additionalCabling}}</label><br>
          <label class="valuesum"
            *ngIf="zEndAdditionalCabling; else noValueAvailable">{{zEndAdditionalCabling}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndAdditionalCabling && isExpanded1">No value</label>
        </div>
        <div class="col-3 mb-4" *ngIf="zEndAdditionalCablingLocation">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.additionalCablingLocation}}</label><br>
          <label class="valuesum"
            *ngIf="zEndAdditionalCablingLocation; else noValueAvailable">{{zEndAdditionalCablingLocation}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndAdditionalCablingLocation && isExpanded1">No value</label>
        </div>
      </div>
      <!-- <div class="row col-12"
        *ngIf="serviceType == 'RG' &&  siteNameZ =='NON-PREMISES' && businessDriv == '5G Small Cell'">
        <div class="col-3 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.poleId}}</label><br>
          <label class="valuesum" *ngIf="zPoleId; else noValueAvailable">{{zPoleId}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zPoleId">No value</label>
        </div>
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leadInRequired}}</label><br>
          <label class="valuesum" *ngIf="zLeadInRequired; else noValueAvailable">{{zLeadInRequired}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zLeadInRequired">No value</label>
        </div>
      </div> -->

      <div class="col-9 mb-4">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.addressInformation}}</label><br>
        <label class="valuesum" *ngIf="zaddInformation; else noValueAvailable">{{zaddInformation}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!zaddInformation">No value</label>
      </div>
      <div class="row col-12">
        <div class="col-3 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactName}}</label><br>
          <label class="valuesum"
            *ngIf="zEndprimaryContactName; else noValueAvailable">{{zEndprimaryContactName}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndprimaryContactName">No value</label>
        </div>
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactNumber}}</label><br>
          <label class="valuesum"
            *ngIf="zEndprimaryContactNumber; else noValueAvailable">{{zEndprimaryContactNumber}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndprimaryContactNumber">No value</label>
        </div>
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactEmail}}</label><br>
          <label class="valuesum"
            *ngIf="zEndprimaryContactEmail; else noValueAvailable">{{zEndprimaryContactEmail}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndprimaryContactEmail">No value</label>
        </div>
      </div>
    </div>
  </div>
  <!--END: SITE z end address for order only  -->



  <!-- START: SITE z end address for quote only -->
  <div class="expandable assessment-outcome-container bg-white showDiversity" *ngIf="!isOrder">
    <div class="headerCont">
      <h2 class="productLabel">{{ isDiversity === 'Yes' && describeYourDiversity === 'All new services with diversity' ?
         aemLabelAuthoring?.pageHeader?.SiteZPath1Adddetails:
        aemLabelAuthoring?.pageHeader?.SiteZAdddetails}}</h2>
    </div>
    <div class="col-11  assessment-outcome-content bg-white">
      <!-- <div class="col-3">
    <img class="mapImage" src="../../../assets/images/Map Z.svg"/>
    </div> -->
      <div class="row col-12">
        <span class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.SiteZaddress}}</label><br>
          <label class="valuesum" *ngIf="zEndAdress; else noValueAvailable">{{zEndAdress}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternativeName}}</label><br>
          <label class="valuesum"
            *ngIf="zEndAdressSiteAlternativeName; else noValueAvailable">{{zEndAdressSiteAlternativeName}}</label>
        </span>
        <span class="col-4" *ngIf="zdefinedPropertyLoca?.toLowerCase() == 'yes' || zdefinedPropertyLoca?.toLowerCase() == 'no'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.propertyLocation}}</label><br>
          <label class="valuesum" *ngIf="zdefinedPropertyLoca; else noValueAvailable">{{zdefinedPropertyLoca}}</label>
        </span>
      </div>
      <!-- newly added fields for NON RG -->
      <div class="row col-12" *ngIf="serviceType !=='RG'">
        <!-- <div class="col-2"></div> -->
        <div class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteType}}</label><br>
          <label class="valuesum"
            *ngIf="zEndSiteType; else noValueAvailable">{{zEndSiteType}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndSiteType && isExpanded1">No value</label>
        </div>
        <div class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreOperator}}</label><br>
          <label class="valuesum"
            *ngIf="zEndDcOperator; else noValueAvailable">{{zEndDcOperator}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndDcOperator && isExpanded1">No value</label>
        </div>
        <div class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreSite}}</label><br>
          <label class="valuesum"
            *ngIf="zEndDcSiteName; else noValueAvailable">{{zEndDcSiteName}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndDcSiteName && isExpanded1">No value</label>
        </div>
      </div>
      <div class="col-10 row mb-5">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.addressInformation}}</label><br>
        <label class="valuesum" *ngIf="zaddInformation; else noValueAvailable">{{zaddInformation}}</label>
      </div>
      <div class="row col-12" *ngIf="serviceType == 'RG'">
        <span class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactName}}</label><br>
          <label class="valuesum"
            *ngIf="zEndprimaryContactName; else noValueAvailable">{{zEndprimaryContactName}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactNumber}}</label><br>
          <label class="valuesum"
            *ngIf="zEndprimaryContactNumber; else noValueAvailable">{{zEndprimaryContactNumber}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactEmail}}</label><br>
          <label class="valuesum"
            *ngIf="zEndprimaryContactEmail; else noValueAvailable">{{zEndprimaryContactEmail}}</label>
        </span>
      </div>
      <div class="row col-12" *ngIf="serviceType == 'RG'">
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactName}}</label><br>
          <label class="valuesum" *ngIf="zEndAltContactName; else noValueAvailable">{{zEndAltContactName}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactNumber}}</label><br>
          <label class="valuesum" *ngIf="zEndAltContactNumber; else noValueAvailable">{{zEndAltContactNumber}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactEmail}}</label><br>
          <label class="valuesum" *ngIf="zEndAltContactEmail; else noValueAvailable">{{zEndAltContactEmail}}</label>
        </span>
      </div>
    </div>
  </div>
  <!--END: SITE z end address for quote only  -->


  <!-- START: Contact section for order only -->
  <div class="expandable assessment-outcome-container bg-white showDiversity" *ngIf="isOrder">
    <div class="headerCont">
      <h2 class="productLabel">{{aemLabelAuthoring?.pageHeader?.customerContactDetails}}</h2>
    </div>

    <div class="row col-12 assessment-outcome-content bg-white">
      <div class="col-3">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.contactName}}</label><br>
        <label class="valuesum" *ngIf="customerContactName; else noValueAvailable">{{customerContactName}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!customerContactName">No value</label>
      </div>
      <div class="col-3">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.contactNumber}}</label><br>
        <label class="valuesum" *ngIf="customerContactNumber; else noValueAvailable">{{customerContactNumber}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!customerContactNumber">No value</label>
      </div>
      <div class="col-6">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.contactEmailAddress}}</label><br>
        <label class="valuesum" *ngIf="customerContactEmail; else noValueAvailable">{{customerContactEmail}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!customerContactEmail">No value</label>
      </div>
    </div>
  </div>
  <!--END: Contact section for order only  -->




  <div class="expandable assessment-outcome-container bg-white showDiversity" 
  *ngIf="isDiversity === 'Yes' && describeYourDiversity === 'All new services with diversity'">
    <div class="headerCont">
      <h2 class="productLabel">{{aemLabelAuthoring?.pageHeader?.SiteAPath2}}</h2>
    </div>
    <div class="col-11 assessment-outcome-content bg-white">
      <!-- <div class="col-3">
    <img class="mapImage" src="../../../assets/images/Map Z.svg"/>
    </div> -->
      <div class="row col-12">

        <span class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.SiteAAdd}}</label><br>
          <label class="valuesum" *ngIf="a2EndAdress; else noValueAvailable">{{a2EndAdress}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternativeName}}</label><br>
          <label class="valuesum"
            *ngIf="a2EndAdressAlternativeCot; else noValueAvailable">{{a2EndAdressAlternativeCot}}</label>
        </span>
        <span class="col-4" *ngIf="a2definedPropertyLoca?.toLowerCase() == 'yes' ||  a2definedPropertyLoca?.toLowerCase() == 'no'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.propertyLocation}}</label><br>
          <label class="valuesum" *ngIf="a2definedPropertyLoca; else noValueAvailable">{{a2definedPropertyLoca}}</label>
        </span>
      </div>
      <!-- newly added section for non RG customer -->
      <div class="row col-12" *ngIf="serviceType !=='RG'">
        <!-- <div class="col-2"></div> -->
        <div class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteType}}</label><br>
          <label class="valuesum"
            *ngIf="a2EndSiteType; else noValueAvailable">{{a2EndSiteType}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!a2EndSiteType && isExpanded1">No value</label>
        </div>
        <div class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreOperator}}</label><br>
          <label class="valuesum"
            *ngIf="a2EndDcOperator; else noValueAvailable">{{a2EndDcOperator}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!a2EndDcOperator && isExpanded1">No value</label>
        </div>
        <div class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreSite}}</label><br>
          <label class="valuesum"
            *ngIf="a2EndDcSiteName; else noValueAvailable">{{a2EndDcSiteName}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!a2EndDcSiteName && isExpanded1">No value</label>
        </div>
      </div>
      <div class="col-9 row mb-5">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.addressInformation}}</label><br>
        <label class="valuesum" *ngIf="a2addInformation; else noValueAvailable">{{a2addInformation}}</label>
      </div>
      <div class="row col-12" *ngIf="serviceType == 'RG'">
        <span class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactName}}</label><br>
          <label class="valuesum"
            *ngIf="a2EndprimaryContactName; else noValueAvailable">{{a2EndprimaryContactName}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactNumber}}</label><br>
          <label class="valuesum"
            *ngIf="a2EndprimaryContactNumber; else noValueAvailable">{{a2EndprimaryContactNumber}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactEmail}}</label><br>
          <label class="valuesum"
            *ngIf="a2EndprimaryContactEmail; else noValueAvailable">{{a2EndprimaryContactEmail}}</label>
        </span>
      </div>
      <div class="row col-12" *ngIf="serviceType == 'RG'">
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactName}}</label><br>
          <label class="valuesum" *ngIf="a2EndAltContactName; else noValueAvailable">{{a2EndAltContactName}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactNumber}}</label><br>
          <label class="valuesum" *ngIf="a2EndAltContactNumber; else noValueAvailable">{{a2EndAltContactNumber}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactEmail}}</label><br>
          <label class="valuesum" *ngIf="a2EndAltContactEmail; else noValueAvailable">{{a2EndAltContactEmail}}</label>
        </span>
      </div>
    </div>
  </div>

  <div class="expandable assessment-outcome-container bg-white showDiversity" 
  *ngIf="isDiversity === 'Yes' && describeYourDiversity === 'All new services with diversity'">
    <div class="headerCont">
      <h2 class="productLabel">{{aemLabelAuthoring?.pageHeader?.SiteZPath2}}</h2>
    </div>
    <div class="col-11 assessment-outcome-content bg-white">
      <div class="row col-12">
        <span class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.SiteZaddress}}</label><br>
          <label class="valuesum" *ngIf="zEndAdress; else noValueAvailable">{{z2EndAdress}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternativeName}}</label><br>
          <label class="valuesum"
            *ngIf="z2EndAdressAlternativeCot; else noValueAvailable">{{z2EndAdressAlternativeCot}}</label>
        </span>
        <span class="col-4" *ngIf="z2definedPropertyLoca?.toLowerCase() == 'yes' || z2definedPropertyLoca?.toLowerCase() == 'no'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.propertyLocation}}</label><br>
          <label class="valuesum" *ngIf="z2definedPropertyLoca; else noValueAvailable">{{z2definedPropertyLoca}}</label>
        </span>
      </div>
      <!-- newly added section for non RG customer -->
      <div class="row col-12" *ngIf="serviceType !=='RG'">
        <!-- <div class="col-2"></div> -->
        <div class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteType}}</label><br>
          <label class="valuesum"
            *ngIf="z2EndSiteType; else noValueAvailable">{{z2EndSiteType}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!z2EndSiteType && isExpanded1">No value</label>
        </div>
        <div class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreOperator}}</label><br>
          <label class="valuesum"
            *ngIf="z2EndDcOperator; else noValueAvailable">{{z2EndDcOperator}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!z2EndDcOperator && isExpanded1">No value</label>
        </div>
        <div class="col-4 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreSite}}</label><br>
          <label class="valuesum"
            *ngIf="z2EndDcSiteName; else noValueAvailable">{{z2EndDcSiteName}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!z2EndDcSiteName && isExpanded1">No value</label>
        </div>
      </div>
      <div class="col-9 row mb-5">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.addressInformation}}</label><br>
        <label class="valuesum" *ngIf="z2addInformation; else noValueAvailable">{{z2addInformation}}</label>
      </div>
      <div class="row col-12" *ngIf="serviceType == 'RG'">
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactName}}</label><br>
          <label class="valuesum"
            *ngIf="z2EndprimaryContactName; else noValueAvailable">{{z2EndprimaryContactName}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactNumber}}</label><br>
          <label class="valuesum"
            *ngIf="z2EndprimaryContactNumber; else noValueAvailable">{{z2EndprimaryContactNumber}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactEmail}}</label><br>
          <label class="valuesum"
            *ngIf="z2EndprimaryContactEmail; else noValueAvailable">{{z2EndprimaryContactEmail}}</label>
        </span>
      </div>
      <div class="row col-12" *ngIf="serviceType == 'RG'">
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactName}}</label><br>
          <label class="valuesum" *ngIf="z2EndAltContactName; else noValueAvailable">{{z2EndAltContactName}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactNumber}}</label><br>
          <label class="valuesum" *ngIf="z2EndAltContactNumber; else noValueAvailable">{{z2EndAltContactNumber}}</label>
        </span>
        <span class="col-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactEmail}}</label><br>
          <label class="valuesum" *ngIf="z2EndAltContactEmail; else noValueAvailable">{{z2EndAltContactEmail}}</label>
        </span>
      </div>
    </div>
  </div>

  <div class="expandable assessment-outcome-container bg-white showDiversity"
    *ngIf="serviceType == 'RG'">
    <div class="headerCont">
      <h2 class="productLabel">{{aemLabelAuthoring?.pageHeader?.References}}</h2>
    </div>
    <div class="content assessment-outcome-content bg-white">
      <div class="row col-12">
        <div class='col-3'>
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.businessDriver}}</label><br>
          <label class="valuesum" *ngIf="businessDriv; else noValueAvailable">{{businessDriv}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!businessDriv">No value</label>
        </div>
        <div class='col-3' *ngIf='isOrder'>
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.projectId}}</label><br>
          <label class="valuesum" *ngIf="projectReferenceId; else noValueAvailable">{{projectReferenceId}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!projectReferenceId">No value</label>
        </div>
        <div class='col-3' *ngIf='isOrder'>
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.opportunityNo}}</label><br>
          <label class="valuesum" *ngIf="opportunityNo; else noValueAvailable">{{opportunityNo}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!opportunityNo">No value</label>
        </div>
        <div class='col-3' *ngIf='isOrder'>
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.clusterId}}</label><br>
          <label class="valuesum" *ngIf="clusterId; else noValueAvailable">{{clusterId}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!clusterId">No value</label>
        </div>
      </div>
      <div class="row col-12 external" *ngIf='isOrder'>
        <div class='col-3'>
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.miragepclId}}</label><br>
          <label class="valuesum" *ngIf="miragepclId; else noValueAvailable">{{miragepclId}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!miragepclId">No value</label>
        </div>
      </div>
      <div class="row col-12 external">
        <div class="col-5" *ngIf="isOrder">
          <table class="table table-hover">
            <thead>
              <tr>
                <th class="headersum">{{aemLabelAuthoring?.pageHeader?.opticalLoss}}</th>
                <th class="headersum">{{aemLabelAuthoring?.pageHeader?.valueDb}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ref of maxSignalLoss">
                <th class="valuesum">{{ref.wavelength}}</th>
                <td class="valuesum">{{ref.dbValue}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-1" *ngIf="isOrder"></div>
        <div class="col-5">
          <table class="table table-hover">
            <thead>
              <tr>
                <th class="headersum">{{aemLabelAuthoring?.pageHeader?.externalReference}}</th>
                <th class="headersum">{{aemLabelAuthoring?.pageHeader?.value}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ref of externalReferences">
                <th class="valuesum">{{ref.externalReferenceType}}</th>
                <td class="valuesum">{{ref.externalReferenceValue}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </div>

  <div class="expandable assessment-outcome-container bg-white" *ngIf="!isOrder && !isPredefinedQuote">
    <div class="headerCont">
      <h2 class="productLabel">{{aemLabelAuthoring?.pageHeader?.considerations}}</h2>
    </div>
    <div class="content assessment-outcome-content bg-white">
      <div class="col-12">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.maximumDistance}}</label><br>
        <label class="valuesum" *ngIf="preferredMaxDistance; else noValueAvailable">{{preferredMaxDistance}}</label>
      </div>
      <div class="col-12 external">
        <label class="headersum col-5">{{aemLabelAuthoring?.pageHeader?.opticalLoss}}</label><br>
      </div>
      <div class="row col-10  mt-2">
        <label class="headersum col-5">{{aemLabelAuthoring?.pageHeader?.waveLength}}</label>
        <label class="headersum col-5">{{aemLabelAuthoring?.pageHeader?.valueDb}}</label>
      </div>
      <hr class="col-7 hrLineFirst">
      <div class="col-10 hrPadd">
        <ul class="col-5 listStyle" *ngIf="fiberOpticalLoss?.length; else noListValue">
          <li *ngFor="let ref1 of fiberOpticalLoss">{{ref1}}</li>
        </ul>
        <ul class="col-5 listStyle" *ngIf="dbVal?.length; else noListValue">
          <li *ngFor="let val1 of dbVal">{{val1}}</li>
        </ul>
      </div>
      <hr class="col-7 hrLine">
    </div>
  </div>

  <div class="expandable assessment-outcome-container bg-white showDiversity" *ngIf="(!isOrder && serviceType == 'RG')">
    <div class="headerCont">
      <h2 class="productLabel">{{aemLabelAuthoring?.pageHeader?.fundingDetails}}</h2>
    </div>
    <div class="assessment-outcome-content bg-white">
      <div class="row col-12 ">
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.fundingSource}}</label><br>
          <label class="valuesummary" *ngIf="fundingSource; else noValueAvailable">{{fundingSource}}</label>
        </div>
        <div class="col-3" *ngIf="showFundingSourceCodes()">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.l3Capex}}</label><br>
          <label class="valuesummary" *ngIf="requestorChannel; else noValueAvailable">{{l3WBSCodeCapex}}</label>
        </div>
        <div class="col-3" *ngIf="showFundingSourceCodes()">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.l3Opex}}</label><br>
          <label class="valuesummary" *ngIf="submittedDate; else noValueAvailable">{{l3WBSCodeOpex}}</label>
        </div>
        <div class="col-3" *ngIf="fundingSource==='Other'">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.otherPOW}}</label><br>
          <label class="valuesummary">{{otherPowImc}}</label>
        </div>
        <div class="col-3">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.preApprovalCost}}</label><br>
          <label class="valuesummary" *ngIf="fundingSource; else noValueAvailable">{{preApprovalCost |
            currency}}</label>
        </div>
      </div>
    </div>

  </div>

  <ng-container *ngIf="showAssessmentOutcome(status, true)">
    <span *ngIf="(serviceType == 'RG')"><label type="label"
        class="btn" class="assessment-outcome-header">
        {{(isDiversity === 'Yes' && describeYourDiversity === 'All new services with diversity') ?
         aemLabelAuthoring?.pageHeader?.path1AssessmentOutcome : aemLabelAuthoring?.pageHeader?.assessmentOutcome}} </label></span>
    <!--START: Show outcome section for quote and RG only-->
    <div class="assessment-outcome-tab"
      *ngIf="(serviceType == 'RG')">
      <div class="headerCont">
        <label class="productLabel">{{aemLabelAuthoring?.pageHeader?.outcome}}</label>
      </div>
      <div class="assessment-outcome-content">
        <div class="col-12 summaryContent">
          <div class="col-2">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestMet}}</label><br>
            <label class="valuesum" *ngIf="requestMet; else noValueAvailable">{{requestMet}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!requestMet">No value</label>
          </div>
          <div class="col-4" *ngIf="showForQuoteOrderBasedOnRequestMet('Yes (with concession)')">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.concessionItems}}</label><br>
            <label class="valuesum" *ngIf="concessionItems; else noValueAvailable">{{concessionItems}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!concessionItems">No value</label>
          </div>
          <div class="col-3" *ngIf="showForQuoteOrderBasedOnRequestMet('No')">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.notMetItems}}</label><br>
            <label class="valuesum" *ngIf="notMetItems; else noValueAvailable">{{notMetItems}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!notMetItems">No value</label>

          </div>
          <div class="col-3" *ngIf="!isOrder">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.indicativeLinkDeliveryTimingClass}}</label><br>
            <label class="valuesum"
              *ngIf="indicativeLinkDeliveryTimingClass; else noValueAvailable">{{indicativeLinkDeliveryTimingClass}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!indicativeLinkDeliveryTimingClass">No
              value</label>
          </div>
          <div class="col-3" style="padding-left: 10px;">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.indicativeLinkdeliveryDays}}</label><br>
            <label class="valuesum"
              *ngIf="indicativeLinkdeliveryDays; else noValueAvailable">{{indicativeLinkdeliveryDays}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!indicativeLinkdeliveryDays">No
              value</label>
          </div>
        </div>
      </div>
    </div>
    <!--END: Show outcome section for quote and RG only-->

    <!-- START: show pricing section for quote only -->
    <div class="assessment-outcome-tab" *ngIf="!isOrder && serviceType == 'RG'">
      <div class="headerCont">
        <label class="productLabel">{{aemLabelAuthoring?.pageHeader?.pricing}}</label>
      </div>
      <div class="assessment-outcome-content">
        <div class="col-12 summaryContent">
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.totalCapitalCost}}</label><br>
            <label class="valuesum" *ngIf="totalCapitalCost; else noValueAvailable">{{totalCapitalCost | currency
              }}</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.lateralBenefitValue}}</label><br>
            <label class="valuesum" *ngIf="lateralBenefitValue; else noValueAvailable">{{lateralBenefitValue |
              currency }}</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteAssessmentCost}}</label><br>
            <label class="valuesum" *ngIf="siteAssessmentCost; else noValueAvailable">{{siteAssessmentCost |
              currency }}</label>
          </div>
        </div>
        <div class="col-12 summaryContent">
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.monthlyRecurringICACharge}}</label><br>
            <label class="valuesum" *ngIf="monthlyRecurringICACharge; else noValueAvailable">{{monthlyRecurringICACharge
              | currency
              }}</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.monthlyRecurringCharge}}</label><br>
            <label class="valuesum" *ngIf="monthlyRecurringCharge; else noValueAvailable">{{monthlyRecurringCharge |
              currency }}</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.totalMonthlyRecurringCharge}}</label><br>
            <label class="valuesum"
              *ngIf="totalMonthlyRecurringCharge; else noValueAvailable">{{totalMonthlyRecurringCharge | currency
              }}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- END: show pricing section for quote only -->

    <!--START Solution section for non RG -->
    <!-- <div class="expandable assessment-outcome-tab" *ngIf="isOrder && serviceType !== 'RG'">
      <div class="headerCont">
        <label class="productLabel">{{aemLabelAuthoring?.pageHeader?.solution}}</label>
      </div>
      <div class="assessment-outcome-content">
        <div class="col-12 summaryContent">
          <div class="col-3 solution-custom-width">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.estimatedDistanceRange}}</label><br>
            <label class="valuesum"
              *ngIf="estimatedDistanceRange; else noValueAvailable">{{estimatedDistanceRange}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!estimatedDistanceRange">No value</label>
          </div>
        </div>

        <div class="row col-12 external">
          <div class="col-5">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="headersum">{{aemLabelAuthoring?.pageHeader?.opticalLoss}}</th>
                  <th class="headersum">{{aemLabelAuthoring?.pageHeader?.valueDb}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ref of maxSignalLoss">
                  <th class="valuesum">{{ref.wavelength}}</th>
                  <td class="valuesum">{{ref.dbValue}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div> -->
    <!-- END: Solution section for Non RG -->




    <!-- START: Solution section for quote & RG -->
    <div class="assessment-outcome-tab"
      *ngIf="(serviceType == 'RG')">
      <div class="headerCont">
        <label class="productLabel">{{aemLabelAuthoring?.pageHeader?.solution}}</label>
      </div>
      <div class="assessment-outcome-content">
        <div class="col-12 summaryContent">
          <div class="col-2 ">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofr}}</label><br>
            <label class="valuesum" *ngIf="rofr; else noValueAvailable">{{rofr}}</label>
          </div>
          <div class="col-2 ">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFibreSize}}</label><br>
            <label class="valuesum" *ngIf="rofrFibreSize; else noValueAvailable">{{rofrFibreSize}}</label>
          </div>
          <div class="col-2 ">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFAPId}}</label><br>
            <label class="valuesum" *ngIf="rofrFAPId; else noValueAvailable">{{rofrFAPId}}</label>
          </div>
          <div class="col-3 ">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFAPLatitude}}</label><br>
            <label class="valuesum" *ngIf="rofrFAPLatitude; else noValueAvailable">{{rofrFAPLatitude}}</label>
          </div>
          <div class="col-3 ">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFAPLongitude}}</label><br>
            <label class="valuesum" *ngIf="rofrFAPLongitude; else noValueAvailable">{{rofrFAPLongitude}}</label>
          </div>
        </div>
        <div class="col-12 summaryContent">
          <div class="col-4 solution-custom-width">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.totalFibreLength}}</label><br>
            <label class="valuesum" *ngIf="totalFibreLength; else noValueAvailable">{{totalFibreLength}}</label>
          </div>
          <div class="col-4 solution-custom-width">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.newFibreHaulLength}}</label><br>
            <label class="valuesum" *ngIf="newFibreHaulLength; else noValueAvailable">{{newFibreHaulLength}}</label>
          </div>
          <div class="col-4 solution-custom-width">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.newFibreBuriedLength}}</label><br>
            <label class="valuesum" *ngIf="newFibreBuriedLength; else noValueAvailable">{{newFibreBuriedLength}}</label>
          </div>
        </div>
        <div class="col-12 summaryContent">
          <div class="col-3 solution-custom-width">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.newConduitLength}}</label><br>
            <label class="valuesum" *ngIf="newConduitLength; else noValueAvailable">{{newConduitLength}}</label>
          </div>
          <div class="col-3 solution-custom-width">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leasedConduitLength}}</label><br>
            <label class="valuesum" *ngIf="leasedConduitLength; else noValueAvailable">{{leasedConduitLength}}</label>
          </div>
          <div class="solution-custom-width">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leasedConduitTerm}}</label><br>
            <label class="valuesum" *ngIf="leasedConduitTerm; else noValueAvailable">{{leasedConduitTerm}}</label>
          </div>
          <div class="col-3 solution-custom-width">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leasedConduitOwner}}</label><br>
            <label class="valuesum" *ngIf="leasedConduitOwner; else noValueAvailable">{{leasedConduitOwner}}</label>
          </div>
        </div>
        <div class="col-12 summaryContent">
          <div class="col-3 solution-custom-width" *ngFor="let val of estimatedLoss">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.estimatedLoss}} {{val.wavelength}}
              (dB)</label><br>
            <label class="valuesum" *ngIf="val.dbValue; else noValueAvailable">{{val.dbValue}}</label>
          </div>
        </div>
        <div class="col-12 summaryContent showDiversity" style="margin-top:40px">
          <div class="col-12">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestorSolutionSummary}}</label><br>
            <label class="valuesum summary-text-wrap"
              *ngIf="requestorSolutionSummary; else noValueAvailable">{{requestorSolutionSummary}}</label>
          </div>
        </div>
        <div class="col-12 summaryContent showDiversity">
          <div class="col-12">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.solutionSummary}}</label><br>
            <label class="valuesum summary-text-wrap"
              *ngIf="solutionSummary; else noValueAvailable">{{solutionSummary}}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Solution section for quote & RG -->

    <!-- START: Cable details show only for quote & RG -->
    <div class="expandable assessment-outcome-container bg-white"
      *ngIf="serviceType == 'RG'">
      <div class="headerCont">
        <h2 class="productLabel">Cable details</h2>
      </div>
      <div class="grid-content">
        <div class="grid-container cable-detail-grid" *ngIf="gridConfig && gridInitialized">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Cable Section ID</th>
                  <th>Cable Status</th>
                  <th>Cable Section length</th>
                  <th>Cable Size</th>
                  <th>Telstra Ltd Licensed Vacant Fibres</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of gridModel.gridOptions.rowData; let i=index;"
                  [ngClass]="i%2===0 ? 'even-row': 'odd-row'">
                  <td>{{item.cableSectionId}}</td>
                  <td>{{item.cableStatus}}</td>
                  <td>{{item.sectionLength}}</td>
                  <td>{{item.size}}</td>
                  <td>{{item.vacantFibreTLS}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
    <!-- END: Cable details show only for quote & RG -->
    <!--START: Show outcome section for quote & order nonRG only-->
    <label type="label"    *ngIf="(serviceType !== 'RG')" 
    class="btn" style="margin-top:50px;" class="assessment-outcome-header">
    {{isOrder || !(isDiversity === 'Yes' && describeYourDiversity === 'All new services with diversity')  ?  aemLabelAuthoring?.pageHeader?.assessmentOutcome : aemLabelAuthoring?.pageHeader?.path1AssessmentOutcome}}</label>
    <div class="assessment-outcome-tab" 
      *ngIf="(serviceType !== 'RG')">
      <!-- <div class="headerCont">
        <label class="productLabel">{{isOrder ?  aemLabelAuthoring?.pageHeader?.assessmentOutcome : aemLabelAuthoring?.pageHeader?.path1AssessmentOutcome}}</label>
      </div> -->
      <div class="assessment-outcome-content">
        <div class="col-12 summaryContent">
          <div class="col-3 solution-custom-width estimatedDisnonRg">
            <label class="headersum">{{isOrder ? aemLabelAuthoring?.pageHeader?.estimatedDistanceRange : 
              aemLabelAuthoring?.pageHeader?.estimatedDistance}}</label><br>
            <label class="valuesum" *ngIf="preferredDistance; else noValueAvailable">{{preferredDistance}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!totalFibreLength">No value</label>
          </div>
          <div class="col-3 solution-custom-width" *ngIf="!isOrder">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.networkProximity}}</label><br>
            <label class="valuesum" *ngIf="networkProx; else noValueAvailable">{{networkProx}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!newFibreHaulLength">No value</label>
          </div>
        </div>
        <div  class="col-12 summaryContent">
          <label class="headersum nonrgOpticalloss">{{isOrder ? aemLabelAuthoring?.pageHeader?.opticalLoss :
            aemLabelAuthoring?.pageHeader?.estimatedOpticalLoss}}</label>
        </div>
        <div class="col-12 summaryContent" *ngIf="estimatedLoss && estimatedLoss.length > 0">
          <div class="col-5 table-margin">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="headersum">{{aemLabelAuthoring?.pageHeader?.waveLength}}</th>
                  <th class="headersum">{{aemLabelAuthoring?.pageHeader?.valueDb}}</th>
                </tr>
              </thead>
              <tbody *ngFor="let ref of estimatedLoss">
                <tr>
                  <th class="valuesum">{{ ref.wavelength }}</th>
                  <td class="valuesum">{{ ref.dbValue }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- mat card start-->
      <div class="radio-btn-container mat-card-css">
        <div class="row term-container"
          *ngIf="(!isOrder && serviceType !== 'RG') && (status?.toLowerCase() !== 'assessment complete')">
          <ng-container>
            <!-- <label class="headerCont nonrgOpticalloss">{{aemLabelAuthoring?.pageHeader?.Selectterm}}</label><br> -->
            <div class="row">
              <mat-card class="col-3 term-card" [ngClass]="{'term-card-checked': selectedTermForNonRGPath1 === 3,
              'disabledRadio': (selectedTermForNonRGPath1 !== 3 && (status?.toLowerCase() === 'quote accepted' ||
              status?.toLowerCase() === 'ordered' || !isReadWriteOnOrderManagement || sentEnqPath1)),
                'mat-radio-disabled-checked': (selectedTermForNonRGPath1 === 3 && (status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'ordered'  ||
                 status?.toLowerCase() === 'expired' || !isReadWriteOnOrderManagement || sentEnqPath1))}">
                <div class="mat-custom-label">
                  <span class="charge">{{threeYearPriceMonth}}<label class="month">/month</label></span><br>
                  <span class="yearsSlc" aria-label="Terms required 3 years">3 years</span><br>
                  <span class="nonrecurCharge">{{aemLabelAuthoring?.pageHeader?.nonRecurringCharge}}: {{nonRecurringCharge}}</span>
                </div>
              </mat-card>
              <mat-card class="col-3 term-card offset-md-1" [ngClass]="{'term-card-checked': selectedTermForNonRGPath1 === 5,
              'disabledRadio': (selectedTermForNonRGPath1 !== 5 && (status?.toLowerCase() === 'quote accepted' ||
              status?.toLowerCase() === 'ordered' || !isReadWriteOnOrderManagement || sentEnqPath1)),
                'mat-radio-disabled-checked': (selectedTermForNonRGPath1 === 5 && (status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'ordered'  ||
                 status?.toLowerCase() === 'expired' || !isReadWriteOnOrderManagement || sentEnqPath1))}">
                <div class="mat-custom-label">
                  <span class="charge">{{fiveYearPriceMonth}}<label class="month">/month</label></span><br>
                  <span class="yearsSlc">5 years</span><br>
                  <span class="nonrecurCharge">{{aemLabelAuthoring?.pageHeader?.nonRecurringCharge}}: {{nonRecurringCharge}}</span>
                </div>
              </mat-card>
              <mat-card *ngIf="custTerm" class="col-2 offset-md-1 term-card" 
              [ngClass]="{'term-card-checked': selectedTermForNonRGPath1 === custTerm,
              'disabledRadio': (selectedTermForNonRGPath1 !== custTerm && (status?.toLowerCase() === 'quote accepted' ||
              status?.toLowerCase() === 'ordered' || !isReadWriteOnOrderManagement || sentEnqPath1)),
                'mat-radio-disabled-checked': (selectedTermForNonRGPath1 === custTerm && 
                (status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'ordered'  ||
                 status?.toLowerCase() === 'expired' || !isReadWriteOnOrderManagement || sentEnqPath1))}">
                <div class="mat-custom-label">
                  <span class="charge">{{customYearMonth}}<label class="month">/month</label></span><br>
                  <span class="yearsSlc">{{custTerm}} years</span><br>
                  <span class="nonrecurCharge">{{aemLabelAuthoring?.pageHeader?.nonRecurringCharge}}: {{nonRecurringCharge}}</span>
                </div>
              </mat-card>
            </div>
          </ng-container>
        </div>
      </div>

      <!--mat card end-->


    </div>
    <!--END: Show outcome section for quote and nonRG only-->
  </ng-container>

  <ng-container  *ngIf="(!isOrder &&
  isDiversity === 'Yes' && describeYourDiversity === 'All new services with diversity'
  && (status?.toLowerCase() === 'quote ready' || status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'expired'
    || status?.toLowerCase() === 'assessment complete')) || (isOrder && orderPath?.toLowerCase() === 'path2')">
    <label type="label" class="btn" style="margin-top:50px;" class="assessment-outcome-header">{{!isOrder ? aemLabelAuthoring?.pageHeader?.path2AssessmentOutcome : aemLabelAuthoring?.pageHeader?.assessmentOutcome}}</label>

    <div class="assessment-outcome-tab" *ngIf="serviceType == 'RG'">
      <div class="headerCont">
        <label class="productLabel">{{aemLabelAuthoring?.pageHeader?.outcome}}</label>
      </div>
      <div class="assessment-outcome-content">
        <div class="col-12 summaryContent">
          <div class="col-2">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestMet}}</label><br>
            <label class="valuesum" *ngIf="requestMetDiversity; else noValueAvailable">{{requestMetDiversity}}</label>
          </div>
          <div class="col-4" *ngIf="showForQuoteOrderBasedOnRequestMet('Yes (with concession)' , true)">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.concessionItems}}</label><br>
            <label class="valuesum"
              *ngIf="concessionItemsDiversity; else noValueAvailable">{{concessionItemsDiversity}}</label>
          </div>
          <div class="col-3" *ngIf="showForQuoteOrderBasedOnRequestMet('No', true)">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.notMetItems}}</label><br>
            <label class="valuesum" *ngIf="notMetItemsDiversity; else noValueAvailable">{{notMetItemsDiversity}}</label>

          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.indicativeLinkDeliveryTimingClass}}</label><br>
            <label class="valuesum"
              *ngIf="indicativeLinkDeliveryTimingClassDiversity; else noValueAvailable">{{indicativeLinkDeliveryTimingClassDiversity}}</label>
          </div>
          <div class="col-3" style="padding-left:10px">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.indicativeLinkdeliveryDays}}</label><br>
            <label class="valuesum"
              *ngIf="indicativeLinkdeliveryDaysDiversity; else noValueAvailable">{{indicativeLinkdeliveryDaysDiversity}}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="assessment-outcome-tab showDiversity" *ngIf="serviceType == 'RG'">
      <div class="headerCont">
        <label class="productLabel">{{aemLabelAuthoring?.pageHeader?.pricing}}</label>
      </div>
      <div class="assessment-outcome-content">
        <div class="col-12 summaryContent">
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.totalCapitalCost}}</label><br>
            <label class="valuesum" *ngIf="totalCapitalCostDiversity; else noValueAvailable">{{totalCapitalCostDiversity
              | currency
              }}</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.lateralBenefitValue}}</label><br>
            <label class="valuesum"
              *ngIf="lateralBenefitValueDiversity; else noValueAvailable">{{lateralBenefitValueDiversity |
              currency }}</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteAssessmentCost}}</label><br>
            <label class="valuesum"
              *ngIf="siteAssessmentCostDiversity; else noValueAvailable">{{siteAssessmentCostDiversity |
              currency }}</label>
          </div>
        </div>
        <div class="col-12 summaryContent">
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.monthlyRecurringICACharge}}</label><br>
            <label class="valuesum"
              *ngIf="monthlyRecurringICAChargeDiversity; else noValueAvailable">{{monthlyRecurringICAChargeDiversity
              | currency
              }}</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.monthlyRecurringCharge}}</label><br>
            <label class="valuesum"
              *ngIf="monthlyRecurringChargeDiversity; else noValueAvailable">{{monthlyRecurringChargeDiversity |
              currency }}</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.totalMonthlyRecurringCharge}}</label><br>
            <label class="valuesum"
              *ngIf="totalMonthlyRecurringChargeDiversity; else noValueAvailable">{{totalMonthlyRecurringChargeDiversity
              | currency
              }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="assessment-outcome-tab showDiversity" *ngIf="serviceType == 'RG'">
      <div class="headerCont">
        <label class="productLabel">{{aemLabelAuthoring?.pageHeader?.solution}}</label>
      </div>
      <div class="assessment-outcome-content">
        <div class="col-12 summaryContent">
          <div class="col-2 ">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofr}}</label><br>
            <label class="valuesum" *ngIf="rofrDiversity; else noValueAvailable">{{rofrDiversity}}</label>
          </div>
          <div class="col-2 ">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFibreSize}}</label><br>
            <label class="valuesum"
              *ngIf="rofrFibreSizeDiversity; else noValueAvailable">{{rofrFibreSizeDiversity}}</label>
          </div>
          <div class="col-2 ">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFAPId}}</label><br>
            <label class="valuesum" *ngIf="rofrFAPIdDiversity; else noValueAvailable">{{rofrFAPIdDiversity}}</label>
          </div>
          <div class="col-3 ">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFAPLatitude}}</label><br>
            <label class="valuesum"
              *ngIf="rofrFAPLatitudeDiversity; else noValueAvailable">{{rofrFAPLatitudeDiversity}}</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFAPLongitude}}</label><br>
            <label class="valuesum"
              *ngIf="rofrFAPLongitudeDiversity; else noValueAvailable">{{rofrFAPLongitudeDiversity}}</label>
          </div>
        </div>
        <div class="col-12 summaryContent">
          <div class="col-3 solution-custom-width">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.totalFibreLength}}</label><br>
            <label class="valuesum"
              *ngIf="totalFibreLengthDiversity; else noValueAvailable">{{totalFibreLengthDiversity}}</label>
          </div>
          <div class="col-3 solution-custom-width">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.newFibreHaulLength}}</label><br>
            <label class="valuesum"
              *ngIf="newFibreHaulLengthDiversity; else noValueAvailable">{{newFibreHaulLengthDiversity}}</label>
          </div>
          <div class="col-3 solution-custom-width">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.newFibreBuriedLength}}</label><br>
            <label class="valuesum"
              *ngIf="newFibreBuriedLengthDiversity; else noValueAvailable">{{newFibreBuriedLengthDiversity}}</label>
          </div>
        </div>
        <div class="col-12 summaryContent">
          <div class="col-3 solution-custom-width">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.newConduitLength}}</label><br>
            <label class="valuesum"
              *ngIf="newConduitLengthDiversity; else noValueAvailable">{{newConduitLengthDiversity}}</label>
          </div>
          <div class="col-3 solution-custom-width">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leasedConduitLength}}</label><br>
            <label class="valuesum"
              *ngIf="leasedConduitLengthDiversity; else noValueAvailable">{{leasedConduitLengthDiversity}}</label>
          </div>
          <div class="solution-custom-width">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leasedConduitTerm}}</label><br>
            <label class="valuesum"
              *ngIf="leasedConduitTermDiversity; else noValueAvailable">{{leasedConduitTermDiversity}}</label>
          </div>
          <div class="col-3 solution-custom-width">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leasedConduitOwner}}</label><br>
            <label class="valuesum"
              *ngIf="leasedConduitOwnerDiversity; else noValueAvailable">{{leasedConduitOwnerDiversity}}</label>
          </div>
        </div>
        <div class="col-12 summaryContent">
          <div class="col-3 solution-custom-width" *ngFor="let val of estimatedLoss">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.estimatedLoss}} {{val.wavelength}}
              (dB)</label><br>
            <label class="valuesum" *ngIf="val.dbValue; else noValueAvailable">{{val.dbValue}}</label>
          </div>
        </div>
        <div class="col-12 summaryContent showDiversity showDiversity2" style="margin-top:40px">
          <div class="col-12">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestorSolutionSummary}}</label><br>
            <label class="valuesum summary-text-wrap"
              *ngIf="requestorSolutionSummaryDiversity; else noValueAvailable">{{requestorSolutionSummaryDiversity}}</label>
          </div>
        </div>
        <div class="col-12 summaryContent showDiversity showDiversity2">
          <div class="col-12">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.solutionSummary}}</label><br>
            <div class="valuesum summary-text-wrap" *ngIf="solutionSummaryDiversity; else noValueAvailable">
              {{solutionSummaryDiversity}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="assessment-outcome-container bg-white showDiversity" *ngIf="serviceType == 'RG'">
      <div class="headerCont">
        <h2 class="productLabel">Cable details</h2>
      </div>
      <div class="grid-content">
        <div class="grid-container cable-detail-grid" *ngIf="gridInitialized1">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Cable Section ID</th>
                  <th>Cable Status</th>
                  <th>Cable Section length</th>
                  <th>Cable Size</th>
                  <th>Telstra Ltd Licensed Vacant Fibres</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of gridModel1.gridOptions.rowData; let i=index;"
                  [ngClass]="i%2===0 ? 'even-row': 'odd-row'">
                  <td>{{item.cableSectionId}}</td>
                  <td>{{item.cableStatus}}</td>
                  <td>{{item.sectionLength}}</td>
                  <td>{{item.size}}</td>
                  <td>{{item.vacantFibreTLS}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  <!--START: Show outcome section for quote with diversity and non RG only-->
  <div class="assessment-outcome-tab" 
  *ngIf="!isOrder && (serviceType !== 'RG')">
  <!-- <div class="headerCont">
    <label class="productLabel">{{aemLabelAuthoring?.pageHeader?.assessmentOutcome}}</label>
  </div> -->
  <!-- <label type="label" 
    class="btn" style="background: rgb(239, 239, 239);margin-top:50px;">
    {{aemLabelAuthoring?.pageHeader?.path2AssessmentOutcome}}</label> -->
  <div class="assessment-outcome-content">
    <div class="col-12 summaryContent">
      <div class="col-3 solution-custom-width estimatedDisnonRg">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.estimatedDistance}}</label><br>
        <label class="valuesum" *ngIf="preferredDistanceDiversity; else noValueAvailable">{{preferredDistanceDiversity}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!totalFibreLength">No value</label>
      </div>
      <div class="col-3 solution-custom-width">
        <label class="headersum">{{aemLabelAuthoring?.pageHeader?.networkProximity}}</label><br>
        <label class="valuesum" *ngIf="networkProxDiversity; else noValueAvailable">{{networkProxDiversity}}</label>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!newFibreHaulLength">No value</label>
      </div>
    </div>
    <div class="col-12 summaryContent" *ngIf="estimatedLossDiversity && estimatedLossDiversity.length > 0">
      <div class="col-5">
        <label class="headersum nonrgOpticalloss" style="margin-left: 8px;">{{aemLabelAuthoring?.pageHeader?.estimatedOpticalLoss}}</label><br>
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="headersum">{{aemLabelAuthoring?.pageHeader?.waveLength}}</th>
              <th class="headersum">{{aemLabelAuthoring?.pageHeader?.valueDb}}</th>
            </tr>
          </thead>
          <tbody *ngFor="let ref of estimatedLossDiversity">
            <tr>
              <th class="valuesum">{{ ref.wavelength }}</th>
              <td class="valuesum">{{ ref.dbValue }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- mat card start-->
  <div class="radio-btn-container mat-card-css">
    <div class="row term-container"
      *ngIf="(!isOrder && serviceType !== 'RG') && (status?.toLowerCase() !== 'assessment complete')">
      <ng-container>
        <!-- <label class="headerCont nonrgOpticalloss">{{aemLabelAuthoring?.pageHeader?.Selectterm}}</label><br> -->
        <div class="row">
          <mat-card class="col-3 term-card" [ngClass]="{'term-card-checked': selectedTermForNonRGPath2 === 3,
          'disabledRadio': (selectedTermForNonRGPath2 !== 3 && (status?.toLowerCase() === 'quote accepted' ||
          status?.toLowerCase() === 'ordered' || !isReadWriteOnOrderManagement || sentEnqPath1)),
            'mat-radio-disabled-checked': (selectedTermForNonRGPath2 === 3 && (status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'ordered'  ||
             status?.toLowerCase() === 'expired' || !isReadWriteOnOrderManagement || sentEnqPath1))}">
            <div class="mat-custom-label">
              <span class="charge">{{threeYearPriceMonthDiversity}}<label class="month">/month</label></span><br>
              <span class="yearsSlc" aria-label="Terms required 3 years">3 years</span><br>
              <span class="nonrecurCharge">{{aemLabelAuthoring?.pageHeader?.nonRecurringCharge}}: {{nonRecurringChargeDiversity}}</span>
            </div>
          </mat-card>
          <mat-card class="col-3 term-card offset-md-1" [ngClass]="{'term-card-checked': selectedTermForNonRGPath2 === 5,
          'disabledRadio': (selectedTermForNonRGPath2 !== 5 && (status?.toLowerCase() === 'quote accepted' ||
          status?.toLowerCase() === 'ordered' || !isReadWriteOnOrderManagement || sentEnqPath1)),
            'mat-radio-disabled-checked': (selectedTermForNonRGPath2 === 5 && (status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'ordered'  ||
             status?.toLowerCase() === 'expired' || !isReadWriteOnOrderManagement || sentEnqPath1))}">
            <div class="mat-custom-label">
              <span class="charge">{{fiveYearPriceMonthDiversity}}<label class="month">/month</label></span><br>
              <span class="yearsSlc">5 years</span><br>
              <span class="nonrecurCharge">{{aemLabelAuthoring?.pageHeader?.nonRecurringCharge}}: {{nonRecurringChargeDiversity}}</span>
            </div>
          </mat-card>
          <mat-card *ngIf="custTermDiversity" class="col-2 offset-md-1 term-card" 
          [ngClass]="{'term-card-checked': selectedTermForNonRGPath2 === custTermDiversity, 
          'disabledRadio': (selectedTermForNonRGPath2 !== custTermDiversity && (status?.toLowerCase() === 'quote accepted' ||
          status?.toLowerCase() === 'ordered' || !isReadWriteOnOrderManagement || sentEnqPath1)),
            'mat-radio-disabled-checked': (selectedTermForNonRGPath2 === custTermDiversity && (status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'ordered'  ||
             status?.toLowerCase() === 'expired' || !isReadWriteOnOrderManagement || sentEnqPath1))}">
            <div class="mat-custom-label">
              <span class="charge">{{customYearMonthDiversity}}<label class="month">/month</label></span><br>
              <span class="yearsSlc">{{custTermDiversity}} years</span><br>
              <span class="nonrecurCharge">{{aemLabelAuthoring?.pageHeader?.nonRecurringCharge}}: {{nonRecurringChargeDiversity}}</span>
            </div>
          </mat-card>
        </div>
      </ng-container>
    </div>
  </div>

  <!--mat card end-->


</div>
<!--END: Show outcome section for quote and nonRG only-->
  </ng-container>
</div>
<div class="summary-container">
  <div class="containerSummary">

    <div class="header">
      <main aria-labelledby="quoteSummaryHeader">
      <h1 class="headerSummary" id="quoteSummaryHeader">{{aemLabelAuthoring?.pageHeader?.pageHeaderLabel}}:
        {{quoteRefId}}</h1>
      </main>
      <div class="row">
        <div class="col-6">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item" role=""><a routerLink="/">{{aemLabelAuthoring?.pageHeader?.nav1}}</a></li>
              <i class="icon-icon-ui-right-arrow breadcrumb-icon"></i>
              <li class="breadcrumb-item mid" role="" (keypress)="navigateToquotes()" (click)="navigateToquotes()">
                <a [routerLink]="isOrder ? '/myOrders' : '/myQuotes'"
                  attr.aria-label="{{aemLabelAuthoring?.pageHeader?.nav2}}">{{aemLabelAuthoring?.pageHeader?.nav2}}</a>
              </li>
              <i class="icon-icon-ui-right-arrow breadcrumb-icon"></i>
              <li class="breadcrumb-item active" aria-current="page">{{aemLabelAuthoring?.pageHeader?.page}}</li>
            </ol>
          </nav>
        </div>
        <div class="col-6" style="text-align: right;">

    <div class="row button-container btn-margin">
      <div role="button" class="col btn-padding" (keypress)="modifyQuote()" tabindex="0"
        *ngIf="!hideModifyBtn() && isReadWriteOnQuoteMgmt && !isOrder && !disableModifyBtn()">
        <span class="button-text" (click)="modifyQuote()">
          <img class="icon-container" src="../../../assets/images/Modify-Icon.svg" />
          {{aemLabelAuthoring?.pageHeader?.modify}}</span>
      </div>

      <div role="button" class="col btn-padding" (keypress)="modifyQuote()"
        *ngIf="!hideModifyBtn() && isReadWriteOnQuoteMgmt && !isOrder && disableModifyBtn()"
        [ngClass]="{'disable-btn-wrapper': disableModifyBtn()}">
        <span class="button-text" (click)="modifyQuote()" [ngClass]="{'disable-btn': disableModifyBtn()}">
          <img class="icon-container" src="../../../assets/images/Modify-btn-disable.svg" />
          {{aemLabelAuthoring?.pageHeader?.modify}}</span>
      </div>

      <div  role="button" class="col btn-right"  tabindex="0" *ngIf="(status == 'Submitted' || status == 'In Progress' ||
      status == 'Awaiting Customer Response' || status == 'Awaiting Dependent Action' || status === 'Ordered')
      && isReadWriteOnQuoteMgmt" (keypress)="openModal()">

        <div class="d-flex align-items-center" (click)="openModal()">
          <img aria-hidden="true" class="withdraw-icon" src="../../../../assets/images/Withdraw-Button-Icon.svg" />
          <span aria-hidden="true" class="withdraw-btn">{{aemLabelAuthoring?.pageHeader?.withdraw}}</span>
          <img aria-hidden="true" alt="Withdraw" src="../../../../assets/images/Info-Withdraw.svg" [ngbTooltip]="dateTipContent"
            [content]="dateTooltipContentModel.content" automationType="anchor" automationId="anchorAddOrderDateInfo"
            placement="top" #tooltipCustRd="ngbTooltip" (focusin)="tooltipCustRd.open()"
            (focusout)="tooltipCustRd.close()" tabindex="0" class="pl-5">
        </div>

          <ng-template #dateTipContent>
            <div class="text-left ">
              <ul class="font-500">
                <li class="help-text-list"><span
                    class="font-600">{{aemLabelAuthoring?.pageHeader?.eligibleStatusToWithdrawn}}</span></li>
                <li class="help-text-list">
                  <img class="help-text-img" src="../../../assets/images/Awaiting-Customer-Response.svg" />
                  <span class="help-text-status">{{aemLabelAuthoring?.pageHeader?.awaitingCustomerResponse}}</span>
                </li>
                <li class="help-text-list">
                  <img class="help-text-img" src="../../../assets/images/Awaiting-Dependent-Action.svg" />
                  <span class="help-text-status">{{aemLabelAuthoring?.pageHeader?.awaitingDependentAction}}</span>
                </li>
                <li class="help-text-list">
                  <img class="help-text-img" src="../../../assets/images/In-Progress.svg" />
                  <span class="help-text-status">{{aemLabelAuthoring?.pageHeader?.inProgress}}</span>
                </li>
                <li class="help-text-list">
                  <img class="help-text-img" src="../../../assets/images/Submitted.svg" />
                  <span class="help-text-status">{{aemLabelAuthoring?.pageHeader?.submitted}}</span>
                </li>
                <li class="help-text-list"> <span
                    class="terms">{{aemLabelAuthoring?.pageHeader?.termsandCondition}}</span></li>
              </ul>
            </div>
          </ng-template>
      </div>

      <!-- <div class="col" style="min-width: 132px !important;">
      <button class="quoteColPref download-btn" tabindex="0" aria-label="download button" type="button"
        (click)="downloadPdf()">
        <img class="downloadIcon" src="../../../../assets/images/icon-download.svg" alt="upload icon" />
        Download
      </button>
    </div> -->

      <div class="col icon-width" (keypress)="downloadPdf()" role="button" tabindex="0">
        <span class="button-text-download" (click)="downloadPdf()">
          <img class="icon-container" src="../../../../assets/images/icon-download.svg" />
          Download</span>
      </div>

    </div>
        </div>
      </div>

    </div>



    <div class="row status-container">
      <!-- <div ngSwitch="{{status | lowercase}}" class="statusCont col-10"> -->
      <div class="statusCont col-10">
        <span>
          <img class="quoteStatus" *ngIf="(status?.toLowerCase() === 'submitted' ||
           status?.toLowerCase() === 'accepted')" src="../../../assets/images/Submitted.svg" />
          <img class="quoteStatus" *ngIf="status?.toLowerCase() === 'cancelled'"
            src="../../../assets/images/Cancelled.svg" />
          <img class="quoteStatus" *ngIf="status?.toLowerCase() === 'expired'"
            src="../../../assets/images/Expired.svg" />
          <img class="quoteStatus" *ngIf="(status?.toLowerCase() === 'in progress' ||
           status?.toLowerCase() === 'appraisal - in progress'
          || status?.toLowerCase() === 'site assessment - in progress' ||
           status?.toLowerCase() === 'design - in progress' ||
           status?.toLowerCase() === 'construction - in progress')" src="../../../assets/images/In-Progress.svg" />
          <img class="quoteStatus" *ngIf="(status?.toLowerCase() === 'draft' ||
          status?.toLowerCase() === 'awaiting commercial review')" src="../../../assets/images/Draft.svg" />
          <img class="quoteStatus" *ngIf="status?.toLowerCase() === 'quote ready' ||
           status?.toLowerCase() === 'completed' ||
           status?.toLowerCase() === 'ordered' ||
           status?.toLowerCase() === 'complete'" src="../../../assets/images/Quote-Ready.svg" />
          <img class="quoteStatus" *ngIf="(status?.toLowerCase() === 'awaiting customer response' ||
           status?.toLowerCase() === 'appraisal - on hold' ||
          status?.toLowerCase() === 'site assessment - on hold' ||
           status?.toLowerCase() === 'design - on hold' ||
           status?.toLowerCase() === 'construction - on hold')"
            src="../../../assets/images/Awaiting-Customer-Response.svg" />
          <img class="quoteStatus" *ngIf="status?.toLowerCase() === 'awaiting dependent action'"
            src="../../../assets/images/Awaiting-Dependent-Action.svg" />
          <img class="quoteStatus" *ngIf="status?.toLowerCase() === 'withdrawn'"
            src="../../../assets/images/Withdrawn.svg" />
          <img class="quoteStatus" *ngIf="status?.toLowerCase() === 'withdraw submitted'"
            src="../../../assets/images/Withdrawn.svg" />
          <img class="quoteStatus" *ngIf="status?.toLowerCase() === 'quote accepted'"
            src="../../../assets/images/quote-accepted-icon.svg" />
          <img class="quoteStatus" *ngIf="status?.toLowerCase() ==='new'" src="../../../assets/images/Submitted.svg" />
          <img class="quoteStatus" *ngIf="status?.toLowerCase() ==='assessment complete'"
            src="../../../assets/images/AssessmentComplete.svg" />
          <h2 class="status row">{{status}}</h2>
        </span>
        <span class="statusDef row">{{statusSummary}}</span>
      </div>
      <div class="col-2 quote-expiry-container" *ngIf="status?.toLowerCase() === 'quote ready'">
        <span class="quote-expiry-heading">Quote expiry</span>
        <span class="quote-expiry-text"><span *ngIf="quoteExpiry; else noValueAvailable">{{quoteExpiry}} days
            left</span></span>
        <label aria-live="assertive" class="visually-hidden" *ngIf="!quoteExpiry">No value</label>
      </div>

    </div>
    <hr class="hrSum">

    <!--ONLY FOR ORDER SECTION  -->
    <div class="Summary" *ngIf="isOrder">
      <span class="summaryHead row">{{aemLabelAuthoring?.pageHeader?.subHeading}}</span>
      <div class="col-12 summaryContent">
        <div class="col-lg-5 col-sm-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.quoteReference}}</label><br>
          <label class="valuesummary" *ngIf="quoteRefId; else noValueAvailable">{{quoteRefId}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!quoteRefId">No value</label>
        </div>
        <div class="col-lg-4 col-sm-5">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.customerReference}}</label><br>
          <label class="valuesummary" *ngIf="custRefId; else noValueAvailable">{{custRefId}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!custRefId">No value</label>
        </div>
        <div class="col-lg-4 col-sm-5">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.customerRequestedDate}}</label><br>
          <label class="valuesummary"
            *ngIf="customerRequestedDate; else noValueAvailable">{{customerRequestedDate}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!customerRequestedDate">No value</label>
        </div>
        <div class="col-lg-4 col-sm-5">

          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.submittedDate}}</label><br>
          <label class="valuesummary" *ngIf="submittedDate; else noValueAvailable">{{submittedDate}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!submittedDate">No value</label>

          <!-- <label class="headersum">{{aemLabelAuthoring?.pageHeader?.lastUpdated}}</label><br>
          <label class="valuesummary" *ngIf="lastSub; else noValueAvailable">{{lastSub}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!lastSub">No value</label> -->
        </div>
      </div>

      <div class="col-12 summaryContent1">
        <div class="col-lg-5 col-sm-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.provisionalDateRange}}</label><br>
          <label class="valuesummary"
            *ngIf="provisionalDateRange; else noValueAvailable">{{provisionalDateRange}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!provisionalDateRange">No value</label>
        </div>
        <div class="col-lg-4 col-sm-5">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.ourCommitmentDate}}</label><br>
          <label class="valuesummary" *ngIf="ourCommitmentDate; else noValueAvailable">{{ourCommitmentDate}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!ourCommitmentDate">No value</label>
        </div>
        <div class="col-lg-4 col-sm-5">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.networkProximity}}</label><br>
          <label class="valuesummary" *ngIf="networkProximity; else noValueAvailable">{{networkProximity}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!networkProximity">No value</label>
        </div>
        <div class="col-lg-4 col-sm-5">

          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.completedDate}}</label><br>
          <label class="valuesummary" *ngIf="completedDate; else noValueAvailable">{{completedDate}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!completedDate">No value</label>

          <!-- <label class="headersum">{{aemLabelAuthoring?.pageHeader?.lastUpdated}}</label><br>
          <label class="valuesummary" *ngIf="lastSub; else noValueAvailable">{{lastSub}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!lastSub">No value</label> -->
        </div>
      </div>

      <div class="col-12 summaryContent1">
        <div class="col-lg-5 col-sm-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.orderType}}</label><br>
          <label class="valuesummary" *ngIf="orderType; else noValueAvailable">{{orderType}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!orderType">No value</label>
        </div>
        <div class="col-lg-4 col-sm-5">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.serviceId}}</label><br>
          <label class="valuesummary" *ngIf="serviceId; else noValueAvailable">{{serviceId}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!serviceId">No value</label>
        </div>

        <div class="col-lg-4 col-sm-5">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.lastUpdated}}</label><br>
          <label class="valuesummary" *ngIf="lastSub; else noValueAvailable">{{lastSub}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!lastSub">No value</label>
        </div>
      </div>


      <div class="col-12 summaryContent1">
        <div class="col-lg-5 col-sm-4 requestor-container">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestor}}</label><br>
          <label class="valuesummary summary-text-wrap" *ngIf="requestor; else noValueAvailable">{{requestor}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!requestor">No value</label>
        </div>
        <div class="col-lg-4 col-sm-5">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestorChannel}}</label><br>
          <label class="valuesummary" *ngIf="requestorChannel; else noValueAvailable">{{requestorChannel}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!requestorChannel">No value</label>
        </div>
        <div class="col-lg-4 col-sm-5" *ngIf="showCustName">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.customerName}}</label><br>
          <label class="valuesummary">{{customerName}}</label>
        </div>
      </div>
      <div class="col-12 summaryContent1" *ngIf="showSerNum && !isOrder">
        <div class="col-lg-5 col-sm-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.serviceNowReference}}</label><br>
          <label class="valuesummary">{{serviceNowReference}}</label>
        </div>
      </div>
    </div>
    <!-- END OF ORDER SECTION -->
    <div class="Summary" *ngIf="!isOrder">
      <span class="summaryHead row">{{aemLabelAuthoring?.pageHeader?.subHeading}}</span>
      <div class="col-12 summaryContent">
        <div class="col-5">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.quoteReference}}</label><br>
          <label class="valuesummary" *ngIf="quoteRefId; else noValueAvailable">{{quoteRefId}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!quoteRefId">No value</label>
        </div>
        <div class="col-4 label-width-cls me-5">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.customerReference}}</label><br>
          <label class="valuesummary" *ngIf="custRefId; else noValueAvailable">{{custRefId}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!custRefId">No value</label>
        </div>
        <div class="col-4 label-width-cls">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.responseDate}}</label><br>
          <label class="valuesummary" *ngIf="responseReq; else noValueAvailable">{{responseReq}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!responseReq">No value</label>
        </div>
        <div class="col-4 label-width-cls">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.lastUpdated}}</label><br>
          <label class="valuesummary" *ngIf="lastSub; else noValueAvailable">{{lastSub}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!lastSub">No value</label>
        </div>
      </div>
      <div class="col-12 summaryContent1">
        <div class="col-5 requestor-container">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestor}}</label><br>
          <label class="valuesummary summary-text-wrap" *ngIf="requestor; else noValueAvailable">{{requestor}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!requestor">No value</label>
        </div>
        <div class="col-4 label-width-cls me-5">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestorChannel}}</label><br>
          <label class="valuesummary" *ngIf="requestorChannel; else noValueAvailable">{{requestorChannel}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!requestorChannel">No value</label>
        </div>
        <div class="col-4 label-width-cls">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.submittedDate}}</label><br>
          <label class="valuesummary" *ngIf="submittedDate; else noValueAvailable">{{submittedDate}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!submittedDate">No value</label>
        </div>
        <div class="col-4 label-width-cls" *ngIf="showCustName">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.customerName}}</label><br>
          <label class="valuesummary">{{customerName}}</label>
        </div>
      </div>
      <div class="col-12 summaryContent1" *ngIf="showSerNum">
        <div class="col-5">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.serviceNowReference}}</label><br>
          <label class="valuesummary">{{serviceNowReference}}</label>
        </div>
      </div>
    </div>

    <div class="expandable">
      <div class="headerCont" tabindex="0" (keypress)="toggleContent()" (click)="toggleContent()"
        [attr.aria-label]="isExpanded ? 'Expanded ' + aemLabelAuthoring?.pageHeader?.darkFiber +' button' : 'Collapsed ' +  aemLabelAuthoring?.pageHeader?.darkFiber + ' button'">
        <i class="icon" [ngClass]="{'expanded': isExpanded}"></i>
        <label class="productLabel" role="link">{{aemLabelAuthoring?.pageHeader?.darkFiber}}</label>
      </div>
      <div class="content productCont" [hidden]="!isExpanded">
        <div class="summaryContent flex-class">
          <div>
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.product}}</label><br>
            <label class="valuesum" *ngIf="productRef; else noValueAvailable">{{productRef}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!productRef && isExpanded">No value</label>
          </div>

          <div>
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.subProduct}}</label><br>
            <label class="valuesum" *ngIf="subProductRef; else noValueAvailable">{{subProductRef}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!subProductRef && isExpanded">No value</label>
          </div>

          <div>
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.fiberRequirement}}</label><br>
            <label class="valuesum" *ngIf="linkReq; else noValueAvailable">{{linkReq}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!linkReq && isExpanded">No value</label>
          </div>


          <!-- START: Section should not be appeared for order -->
          <div *ngIf="!isOrder">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.quantityService}}</label><br>
            <label class="valuesum" *ngIf="quantityServ; else noValueAvailable">{{quantityServ}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!quantityServ && isExpanded">No value</label>
          </div>
          <!-- END: Section should not be appeared for order -->


          <!-- START: Terms should only be displayed in case of quote or in case of rg-->
          <div *ngIf="(serviceType == 'RG')">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.Terms}}</label><br>
            <label class="valuesum">{{terms}} years</label>
          </div>
          <!-- END: Terms should only be displayed in case of quote or in case of rg-->
        </div>
      </div>
    </div>

    <!-- START: Pricing section for order only -->
    <div class="expandable" *ngIf="isOrder">
      <div class="headerCont" tabindex="0" (keypress)="toggleContent5()" (click)="toggleContent5()"
        [attr.aria-label]="isExpanded5 ? 'Expanded ' + aemLabelAuthoring?.pageHeader?.pricing +' button' : 'Collapsed ' +  aemLabelAuthoring?.pageHeader?.pricing + ' button'">
        <i class="icon" [ngClass]="{'expanded': isExpanded5}"></i>
        <label class="productLabel" role="link">{{aemLabelAuthoring?.pageHeader?.pricing}}</label>
      </div>
      <div class="content productCont" [hidden]="!isExpanded5">
        <!--START: ONLY FOR RG-->
        <ng-container *ngIf="serviceType=='RG'">
          <div class="col-12 summaryContent">
            <div class="col-3">
              <label class="headersum">{{aemLabelAuthoring?.pageHeader?.totalCapitalCost}}</label><br>
              <label class="valuesum" *ngIf="totalCapitalCost; else noValueAvailable">{{totalCapitalCost | currency
                }}</label>
              <label aria-live="assertive" class="visually-hidden" *ngIf="!totalCapitalCost && isExpanded5">No value</label>
            </div>
            <div class="col-3">
              <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteAssessmentCost}}</label><br>
              <label class="valuesum" *ngIf="siteAssessmentCost; else noValueAvailable">{{siteAssessmentCost |
                currency}}</label>
              <label aria-live="assertive" class="visually-hidden" *ngIf="!siteAssessmentCost && isExpanded5">No value</label>
            </div>
          </div>
          <div class="col-12 summaryContent">
            <div class="col-3">
              <label class="headersum">{{aemLabelAuthoring?.pageHeader?.monthlyRecurringICACharge}}</label><br>
              <label class="valuesum"
                *ngIf="monthlyRecurringICACharge; else noValueAvailable">{{monthlyRecurringICACharge | currency
                }}</label>
              <label aria-live="assertive" class="visually-hidden" *ngIf="!monthlyRecurringICACharge && isExpanded5">No value</label>
            </div>
            <div class="col-3">

              <label class="headersum">{{aemLabelAuthoring?.pageHeader?.monthlyRecurringCharge}}</label><br>
              <label class="valuesum" *ngIf="monthlyRecurringCharge; else noValueAvailable">{{monthlyRecurringCharge
                |
                currency }}</label>
              <label aria-live="assertive" class="visually-hidden" *ngIf="!monthlyRecurringCharge && isExpanded5">No value</label>


            </div>

            <div class="col-3">
              <label class="headersum">{{aemLabelAuthoring?.pageHeader?.totalMonthlyRecurringCharge}}</label><br>
              <label class="valuesum"
                *ngIf="totalMonthlyRecurringCharge; else noValueAvailable">{{totalMonthlyRecurringCharge | currency
                }}</label>
              <label aria-live="assertive" class="visually-hidden" *ngIf="!totalMonthlyRecurringCharge && isExpanded5">No
                value</label>
            </div>
          </div>
        </ng-container>
        <!--END: ONLY FOR RG-->

        <!-- START: ONLY FOR NON RG -->
        <ng-container *ngIf="serviceType !=='RG'">
          <div class="col-12 summaryContent">
            <div class="col-3">
              <label class="headersum">{{aemLabelAuthoring?.pageHeader?.monthlyRecurringCharge}}</label><br>
              <label class="valuesum">{{getMonthlyRecurringChargeNonRG() | currency
}}</label>
              <label aria-live="assertive" class="visually-hidden" *ngIf="!monthlyRecurringCharge && isExpanded5">No value</label>

            </div>

            <div class="col-3">
              <label class="headersum">{{aemLabelAuthoring?.pageHeader?.ContractTerms}}</label><br>
              <label class="valuesum">{{orderPath.toLowerCase() === 'path1' ? selectedTermForNonRGPath1 :
                selectedTermForNonRGPath2}} years</label>
            </div>

            <div class="col-3">
              <label class="headersum">{{aemLabelAuthoring?.pageHeader?.nonRecurringCharge}}</label><br>
              <label class="valuesum" *ngIf="nonRecurringCharge; else noValueAvailable">{{nonRecurringCharge | currency}}</label>
              <label aria-live="assertive" class="visually-hidden" *ngIf="!nonRecurringCharge && isExpanded5">No value</label>
            </div>
          </div>
        </ng-container>
        <!-- END: ONLY FOR NON RG -->
      </div>
    </div>
    <!--END: Pricing section for order only  -->

    <div class="expandable" *ngIf="showDiversityDetails">
      <div class="headerCont" tabindex="0" (keypress)="toggleContent7()" (click)="toggleContent7()"
        [attr.aria-label]="isExpanded7 ? 'Expanded ' + aemLabelAuthoring?.pageHeader?.diversity + ' button' : 'Collapsed ' +  aemLabelAuthoring?.pageHeader?.diversity + ' button'">
        <i class="icon" [ngClass]="{'expanded': isExpanded7}"></i>
        <label class="productLabel" role="link">{{aemLabelAuthoring?.pageHeader?.diversity}}</label>
      </div>
      <div class="content productCont" [hidden]="!isExpanded7">
        <div class="row summaryContent" style="min-width: 100%;">
          <div [ngClass]="existingInfracoServiceInfo ? 'col-4' : 'col-5'">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.diversityReq}}</label><br>
            <label class="valuesum" [ngClass]="{'existing-info-avlbl': existingInfracoServiceInfo}"
              *ngIf="describeYourDiversity; else noValueAvailable">{{describeYourDiversity}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!describeYourDiversity && isExpanded7">No value</label>
          </div>
          <div class="col-3" *ngIf="existingInfracoServiceInfo;">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.existingInfracoServiceInfo}}</label><br>
            <label class="valuesum"
              *ngIf="existingInfracoServiceInfo; else noValueAvailable">{{existingInfracoServiceInfo}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!existingInfracoServiceInfo && isExpanded7">No value</label>
          </div>
          <div [ngClass]="existingInfracoServiceInfo ? 'col-8' : 'col-7'" *ngIf="thirdPartyPathInfo"
            style="margin-bottom: 1rem;">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.thirdPartyPathInfo}}</label><br>
            <label class="valuesum" *ngIf="thirdPartyPathInfo; else noValueAvailable">{{thirdPartyPathInfo}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!thirdPartyPathInfo && isExpanded7">No value</label>
          </div>
          <div [ngClass]="thirdPartyPathInfo ? 'col-5' : 'col-3'">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteALead}}</label><br>
            <label class="valuesum" *ngIf="aEndLead; else noValueAvailable">{{aEndLead}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndLead && isExpanded7">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteZLead}}</label><br>
            <label class="valuesum" *ngIf="zEndLead; else noValueAvailable">{{zEndLead}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndLead && isExpanded7">No value</label>
          </div>
        </div>
      </div>
    </div>

    <div class="expandable" [ngClass]="{'expandedClass site-details-container': isExpanded1}" *ngIf="!isOrder">
      <div class="headerCont" tabindex="0" (keypress)="toggleContent1()" (click)="toggleContent1()"
        [attr.aria-label]="isExpanded1 ?  showDiversity ? 'Expanded '+ aemLabelAuthoring?.pageHeader?.SiteAPath1Adddetails + ' button' : 'Expanded ' + aemLabelAuthoring?.pageHeader?.SiteAAdddetails + ' button' :  showDiversity ? 'Collapsed '+ aemLabelAuthoring?.pageHeader?.SiteAPath1Adddetails + ' button' : 'Collapsed '+ aemLabelAuthoring?.pageHeader?.SiteAAdddetails + ' button'">
        <i class="icon" [ngClass]="{'expanded': isExpanded1}"></i>
        <label class="productLabel" role="link">{{isdiversity === 'Yes' && quoteDetails.describeYourDiversity === 'All new services with diversity' ?
          aemLabelAuthoring?.pageHeader?.SiteAPath1Adddetails:aemLabelAuthoring?.pageHeader?.SiteAAdddetails}}</label>
      </div>
      <div class="col-11 content siteDet site-details-content" [hidden]="!isExpanded1">
        <div class="row col-12 site-det-row-container">
          <span class="col-2 mb-4 site-map-img ">
            <app-map-item [LatLng]="LatLngSiteA" *ngIf="LatLngSiteA.address" [type]="'A'"></app-map-item>
          </span>
          <div class="col-4 mb-4 site-det-address">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.SiteAAdd}}</label><br>
            <label class="valuesum" *ngIf="aEndAdress; else noValueAvailable">{{aEndAdress}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndAdress && isExpanded1">No value</label>
          </div>
          <div class="col-3 alt-name-container">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternativeName}}</label><br>
            <label class="valuesum"
              *ngIf="aEndAdressSiteAlternativeName; else noValueAvailable">{{aEndAdressSiteAlternativeName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndAdressSiteAlternativeName && isExpanded1">No value</label>
          </div>
          <div class="col-3" *ngIf="definedPropertyLoca?.toLowerCase() == 'yes' || definedPropertyLoca?.toLowerCase() == 'no'">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.propertyLocation}}</label><br>
            <label class="valuesum" *ngIf="definedPropertyLoca; else noValueAvailable">{{definedPropertyLoca}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!definedPropertyLoca && isExpanded1">No value</label>
          </div>
        </div>
        <!-- added new for non RG customer-->
        <div class="row col-12" *ngIf="serviceType !=='RG'">
          <div class="col-2"></div>
          <div class="col-4 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteType}}</label><br>
            <label class="valuesum"
              *ngIf="aEndSiteType; else noValueAvailable">{{aEndSiteType}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndSiteType && isExpanded1">No value</label>
          </div>
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreOperator}}</label><br>
            <label class="valuesum"
              *ngIf="aEndDcOperator; else noValueAvailable">{{aEndDcOperator}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndDcOperator && isExpanded1">No value</label>
          </div>
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreSite}}</label><br>
            <label class="valuesum"
              *ngIf="aEndDcSiteName; else noValueAvailable">{{aEndDcSiteName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndDcSiteName && isExpanded1">No value</label>
          </div>
        </div>
        <div class="col-2"></div>
        <div class="col-9 mb-5 address-info-container">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.addressInformation}}</label><br>
          <label class="valuesum" *ngIf="addInformation; else noValueAvailable">{{addInformation}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!addInformation && isExpanded1">No value</label>
        </div>        
        <div class="row col-12" *ngIf="serviceType=='RG'">
          <div class="col-2"></div>
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactName}}</label><br>
            <label class="valuesum"
              *ngIf="aEndprimaryContactName; else noValueAvailable">{{aEndprimaryContactName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndprimaryContactName && isExpanded1">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactNumber}}</label><br>
            <label class="valuesum"
              *ngIf="aEndprimaryContactNumber; else noValueAvailable">{{aEndprimaryContactNumber}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndprimaryContactNumber && isExpanded1">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactEmail}}</label><br>
            <label class="valuesum"
              *ngIf="aEndprimaryContactEmail; else noValueAvailable">{{aEndprimaryContactEmail}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndprimaryContactEmail && isExpanded1">No value</label>
          </div>
        </div>
        <div class="row col-12" *ngIf="serviceType=='RG'">
          <div class="col-2"></div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactName}}</label><br>
            <label class="valuesum" *ngIf="aEndAltContactName; else noValueAvailable">{{aEndAltContactName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndAltContactName && isExpanded1">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactNumber}}</label><br>
            <label class="valuesum" *ngIf="aEndAltContactNumber; else noValueAvailable">{{aEndAltContactNumber}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndAltContactNumber && isExpanded1">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactEmail}}</label><br>
            <label class="valuesum" *ngIf="aEndAltContactEmail; else noValueAvailable">{{aEndAltContactEmail}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndAltContactEmail && isExpanded1">No value</label>
          </div>
        </div>
      </div>
    </div>
    <!--END: SITE A end address for quote only  -->


    <!-- START: SITE A end address for order only -->
    <div class="expandable" [ngClass]="{'expandedClass site-details-container': isExpanded1}" *ngIf="isOrder">
      <div class="headerCont" tabindex="0" (keypress)="toggleContent1()" (click)="toggleContent1()"
        [attr.aria-label]="isExpanded1 ?  showDiversity ? 'Expanded '+ aemLabelAuthoring?.pageHeader?.SiteAPath1Adddetails + ' button' : 'Expanded ' + aemLabelAuthoring?.pageHeader?.SiteAAdddetails + ' button' :  showDiversity ? 'Collapsed '+ aemLabelAuthoring?.pageHeader?.SiteAPath1Adddetails + ' button' : 'Collapsed '+ aemLabelAuthoring?.pageHeader?.SiteAAdddetails + ' button'">
        <i class="icon" [ngClass]="{'expanded': isExpanded1}"></i>
        <label class="productLabel" role="link">{{showDiversity ?
          aemLabelAuthoring?.pageHeader?.SiteAPath1Adddetails:aemLabelAuthoring?.pageHeader?.SiteAAdddetails}}</label>
      </div>
      <div class="col-11 content siteDet" [hidden]="!isExpanded1">
        <div class="row col-12">
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.SiteAAdd}}</label><br>
            <label class="valuesum" *ngIf="aEndAdress; else noValueAvailable">{{aEndAdress}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndAdress && isExpanded1">No value</label>
          </div>
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternativeName}}</label><br>
            <label class="valuesum"
              *ngIf="aEndAdressSiteAlternativeName; else noValueAvailable">{{aEndAdressSiteAlternativeName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndAdressSiteAlternativeName && isExpanded1">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteType}}</label><br>
            <label class="valuesum"
              *ngIf="aEndSiteType; else noValueAvailable">{{aEndSiteType}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndSiteType && isExpanded1">No value</label>
          </div>
          <!-- <div> commented service delivery point 
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.serviceDeliveryPoint}}</label><br>
            <label class="valuesum" *ngIf="serviceDeliveryPoint; else noValueAvailable">{{serviceDeliveryPoint}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!serviceDeliveryPoint && isExpanded1">No value</label>
          </div> and added sdpType below-->
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.sdpType}}</label><br>
            <label class="valuesum"
              *ngIf="aEndsdpType; else noValueAvailable">{{aEndsdpType}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndsdpType && isExpanded1">No value</label>
          </div>

          <!-- <div class="col-3" *ngIf="serviceType=='RG' && businessDriv == '5G Small Cell'">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.nodeCode}}</label><br>
            <label class="valuesum" *ngIf="nodeCode; else noValueAvailable">{{nodeCode}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!nodeCode && isExpanded1">No value</label>
          </div> -->
        </div>
        <div class="row col-12">
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.connectorType}}</label><br>
            <label class="valuesum"
              *ngIf="aEndConnectorType; else noValueAvailable">{{aEndConnectorType}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndConnectorType && isExpanded1">No value</label>
          </div>
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.demarcationPoint}}</label><br>
            <label class="valuesum"
              *ngIf="aEndDemarcationPoint; else noValueAvailable">{{aEndDemarcationPoint}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndDemarcationPoint && isExpanded1">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreOperator}}</label><br>
            <label class="valuesum"
              *ngIf="aEndDcOperator; else noValueAvailable">{{aEndDcOperator}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndDcOperator && isExpanded1">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreSite}}</label><br>
            <label class="valuesum"
              *ngIf="aEndDcSiteName; else noValueAvailable">{{aEndDcSiteName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndDcSiteName && isExpanded1">No value</label>
          </div>
        </div>
        <div class="row col-12 mb-4">
          <!-- <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.sdpType}}</label><br>
            <label class="valuesum"
              *ngIf="aEndsdpType; else noValueAvailable">aEndsdpType</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndsdpType && isExpanded1">No value</label>
          </div> -->
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.serviceDeliveryPoint}}</label><br>
            <label class="valuesum" *ngIf="serviceDeliveryPoint; else noValueAvailable">{{serviceDeliveryPoint}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!serviceDeliveryPoint && isExpanded1">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.accessType}}</label><br>
            <label class="valuesum"
              *ngIf="aEndAccessType; else noValueAvailable">{{aEndAccessType}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndAccessType && isExpanded1">No value</label>
          </div>
          <div class="col-3" *ngIf="subProductRef && subProductRef === 'mobile smc' && siteName == 'NON-PREMISES'">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.poleId}}</label><br>
            <label class="valuesum" *ngIf="poleId; else noValueAvailable">{{poleId}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!poleId && isExpanded1">No value</label>
          </div>
          <div class="col-3" *ngIf="subProductRef && subProductRef === 'mobile smc' && siteName == 'NON-PREMISES'">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.nodeCode}}</label><br>
            <label class="valuesum" *ngIf="nodeCode; else noValueAvailable">{{nodeCode}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!nodeCode && isExpanded1">No value</label>
          </div>
        </div>
        <div class="row col-12">
          <div class="col-3 mb-4" *ngIf="subProductRef && subProductRef === 'mobile smc' && siteName == 'NON-PREMISES'">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leadInRequired}}</label><br>
            <label class="valuesum" *ngIf="leadInRequired; else noValueAvailable">{{leadInRequired}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!leadInRequired && isExpanded1">No value</label>
          </div>
          <div class="col-3 mb-4" *ngIf="aEndAdditionalCabling">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.additionalCabling}}</label><br>
            <label class="valuesum"
              *ngIf="aEndAdditionalCabling; else noValueAvailable">{{aEndAdditionalCabling}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndAdditionalCabling && isExpanded1">No value</label>
          </div>
          <div class="col-3 mb-4" *ngIf="aEndAdditionalCablingLocation">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.additionalCablingLocation}}</label><br>
            <label class="valuesum"
              *ngIf="aEndAdditionalCablingLocation; else noValueAvailable">{{aEndAdditionalCablingLocation}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndAdditionalCablingLocation && isExpanded1">No value</label>
          </div>
        </div>

        <!-- <div class="row col-12"
          *ngIf="serviceType=='RG' && siteName =='NON-PREMISES' && businessDriv == '5G Small Cell'">
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.poleId}}</label><br>
            <label class="valuesum" *ngIf="poleId; else noValueAvailable">{{poleId}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!poleId && isExpanded1">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leadInRequired}}</label><br>
            <label class="valuesum" *ngIf="leadInRequired; else noValueAvailable">{{leadInRequired}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!leadInRequired && isExpanded1">No value</label>
          </div>
        </div> -->

        <div class="col-9 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.addressInformation}}</label><br>
          <label class="valuesum" *ngIf="addInformation; else noValueAvailable">{{addInformation}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!addInformation && isExpanded1">No value</label>
        </div>
        <div class="row col-12">
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactName}}</label><br>
            <label class="valuesum"
              *ngIf="aEndprimaryContactName; else noValueAvailable">{{aEndprimaryContactName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndprimaryContactName && isExpanded1">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactNumber}}</label><br>
            <label class="valuesum"
              *ngIf="aEndprimaryContactNumber; else noValueAvailable">{{aEndprimaryContactNumber}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndprimaryContactNumber && isExpanded1">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactEmail}}</label><br>
            <label class="valuesum"
              *ngIf="aEndprimaryContactEmail; else noValueAvailable">{{aEndprimaryContactEmail}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!aEndprimaryContactEmail && isExpanded1">No value</label>
          </div>
        </div>
      </div>
    </div>
    <!--END: SITE A end address for order only  -->

    <div class="expandable" [ngClass]="{'expandedClass site-details-container': isExpanded2}" *ngIf="!isOrder">
      <div class="headerCont" tabindex="0" (keypress)="toggleContent2()" (click)="toggleContent2()"
        [attr.aria-label]="isExpanded2 ?  showDiversity ? 'Expanded '+ aemLabelAuthoring?.pageHeader?.SiteZPath1Adddetails + ' button' : 'Expanded ' + aemLabelAuthoring?.pageHeader?.SiteZAdddetails + ' button' :  showDiversity ? 'Collapsed '+ aemLabelAuthoring?.pageHeader?.SiteZPath1Adddetails + ' button' : 'Collapsed '+ aemLabelAuthoring?.pageHeader?.SiteZAdddetails + ' button'">
        <i class="icon" [ngClass]="{'expanded': isExpanded2}"></i>
        <label class="productLabel" role="link">{{ isdiversity === 'Yes' && quoteDetails.describeYourDiversity === 'All new services with diversity' ? aemLabelAuthoring?.pageHeader?.SiteZPath1Adddetails:
          aemLabelAuthoring?.pageHeader?.SiteZAdddetails}}</label>
      </div>
      <div class="col-11 content siteDet site-details-content" [hidden]="!isExpanded2">
        <!-- <div class="col-3">
    <img class="mapImage" src="../../../assets/images/Map Z.svg"/>
    </div> -->
        <div class="row col-12 site-det-row-container">

          <div class="col-2 mb-4 site-map-img ">
            <app-map-item [LatLng]="LatLngSiteZ" *ngIf="LatLngSiteZ.address" [type]="'Z'"></app-map-item>
          </div>
          <div class="col-4 mb-4 site-det-address">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.SiteZaddress}}</label><br>
            <label class="valuesum" *ngIf="zEndAdress; else noValueAvailable">{{zEndAdress}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndAdress && isExpanded2">No value</label>
          </div>
          <div class="col-3 alt-name-container">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternativeName}}</label><br>
            <label class="valuesum"
              *ngIf="zEndAdressSiteAlternativeName; else noValueAvailable">{{zEndAdressSiteAlternativeName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndAdressSiteAlternativeName && isExpanded2">No value</label>
          </div>
          <div class="col-3" *ngIf="zdefinedPropertyLoca?.toLowerCase() == 'yes' || zdefinedPropertyLoca?.toLowerCase() == 'no'">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.propertyLocation}}</label><br>
            <label class="valuesum" *ngIf="zdefinedPropertyLoca; else noValueAvailable">{{zdefinedPropertyLoca}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zdefinedPropertyLoca && isExpanded2">No value</label>
          </div>
        </div>
        <!-- newly added fields for NON RG -->
        <div class="row col-12" *ngIf="serviceType !=='RG'">
          <div class="col-2"></div>
          <div class="col-4 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteType}}</label><br>
            <label class="valuesum"
              *ngIf="zEndSiteType; else noValueAvailable">{{zEndSiteType}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndSiteType && isExpanded1">No value</label>
          </div>
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreOperator}}</label><br>
            <label class="valuesum"
              *ngIf="zEndDcOperator; else noValueAvailable">{{zEndDcOperator}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndDcOperator && isExpanded1">No value</label>
          </div>
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreSite}}</label><br>
            <label class="valuesum"
              *ngIf="zEndDcSiteName; else noValueAvailable">{{zEndDcSiteName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndDcSiteName && isExpanded1">No value</label>
          </div>
        </div>
        <div class="col-2"></div>
        <div class="col-10 mb-5 address-info-container">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.addressInformation}}</label><br>
          <label class="valuesum" *ngIf="zaddInformation; else noValueAvailable">{{zaddInformation}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zaddInformation && isExpanded2">No value</label>
        </div>        
        <div class="row col-12" *ngIf="serviceType=='RG'">
          <div class="col-2"></div>
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactName}}</label><br>
            <label class="valuesum"
              *ngIf="zEndprimaryContactName; else noValueAvailable">{{zEndprimaryContactName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndprimaryContactName && isExpanded2">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactNumber}}</label><br>
            <label class="valuesum"
              *ngIf="zEndprimaryContactNumber; else noValueAvailable">{{zEndprimaryContactNumber}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndprimaryContactNumber && isExpanded2">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactEmail}}</label><br>
            <label class="valuesum"
              *ngIf="zEndprimaryContactEmail; else noValueAvailable">{{zEndprimaryContactEmail}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndprimaryContactEmail && isExpanded2">No value</label>
          </div>
        </div>
        <div class="row col-12" *ngIf="serviceType=='RG'">
          <div class="col-2"></div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactName}}</label><br>
            <label class="valuesum" *ngIf="zEndAltContactName; else noValueAvailable">{{zEndAltContactName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndAltContactName && isExpanded2">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactNumber}}</label><br>
            <label class="valuesum" *ngIf="zEndAltContactNumber; else noValueAvailable">{{zEndAltContactNumber}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndAltContactNumber && isExpanded2">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactEmail}}</label><br>
            <label class="valuesum" *ngIf="zEndAltContactEmail; else noValueAvailable">{{zEndAltContactEmail}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndAltContactEmail && isExpanded2">No value</label>
          </div>
        </div>
      </div>
    </div>
    <!--END: SITE z end address for quote only  -->

    <!-- START: SITE z end address for order only -->
    <div class="expandable" [ngClass]="{'expandedClass site-details-container': isExpanded2}" *ngIf="isOrder">
      <div class="headerCont" tabindex="0" (keypress)="toggleContent2()" (click)="toggleContent2()"
        [attr.aria-label]="isExpanded2 ?  showDiversity ? 'Expanded '+ aemLabelAuthoring?.pageHeader?.SiteZPath1Adddetails + ' button' : 'Expanded ' + aemLabelAuthoring?.pageHeader?.SiteZAdddetails + ' button' :  showDiversity ? 'Collapsed '+ aemLabelAuthoring?.pageHeader?.SiteZPath1Adddetails + ' button' : 'Collapsed '+ aemLabelAuthoring?.pageHeader?.SiteZAdddetails + ' button'">
        <i class="icon" [ngClass]="{'expanded': isExpanded2}"></i>
        <label class="productLabel" role="link">{{showDiversity ?
          aemLabelAuthoring?.pageHeader?.SiteZPath1Adddetails:aemLabelAuthoring?.pageHeader?.SiteZAdddetails}}</label>
      </div>
      <div class="col-11 content siteDet" [hidden]="!isExpanded2">
        <div class="row col-12">
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.SiteZaddress}}</label><br>
            <label class="valuesum" *ngIf="zEndAdress; else noValueAvailable">{{zEndAdress}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndAdress && isExpanded2">No value</label>
          </div>
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternativeName}}</label><br>
            <label class="valuesum"
              *ngIf="zEndAdressSiteAlternativeName; else noValueAvailable">{{zEndAdressSiteAlternativeName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndAdressSiteAlternativeName && isExpanded2">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteType}}</label><br>
            <label class="valuesum"
              *ngIf="zEndSiteType; else noValueAvailable">{{zEndSiteType}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndSiteType && isExpanded1">No value</label>
          </div>
          <!-- <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.serviceDeliveryPoint}}</label><br>
            <label class="valuesum"
              *ngIf="zEndServiceDeliveryPoint; else noValueAvailable">{{zEndServiceDeliveryPoint}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndServiceDeliveryPoint && isExpanded2">No value</label>
          </div> -->
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.sdpType}}</label><br>
            <label class="valuesum"
              *ngIf="zEndsdpType; else noValueAvailable">{{zEndsdpType}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndsdpType && isExpanded1">No value</label>
          </div>
          <!-- <div class="col-3" *ngIf="serviceType == 'RG' &&  businessDriv == '5G Small Cell'">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.nodeCode}}</label><br>
            <label class="valuesum" *ngIf="zNodeCode; else noValueAvailable">{{zNodeCode}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zNodeCode && isExpanded2">No value</label>
          </div> -->
        </div>
   
        <div class="row col-12">
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.connectorType}}</label><br>
            <label class="valuesum"
              *ngIf="zEndConnectorType; else noValueAvailable">{{zEndConnectorType}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndConnectorType && isExpanded1">No value</label>
          </div>
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.demarcationPoint}}</label><br>
            <label class="valuesum"
              *ngIf="zEndDemarcationPoint; else noValueAvailable">{{zEndDemarcationPoint}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndDemarcationPoint && isExpanded1">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreOperator}}</label><br>
            <label class="valuesum"
              *ngIf="zEndDcOperator; else noValueAvailable">{{zEndDcOperator}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndDcOperator && isExpanded1">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreSite}}</label><br>
            <label class="valuesum"
              *ngIf="zEndDcSiteName; else noValueAvailable">{{zEndDcSiteName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndDcSiteName && isExpanded1">No value</label>
          </div>
        </div>
        <div class="row col-12 mb-4">
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.serviceDeliveryPoint}}</label><br>
            <label class="valuesum"
              *ngIf="zEndServiceDeliveryPoint; else noValueAvailable">{{zEndServiceDeliveryPoint}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndServiceDeliveryPoint && isExpanded2">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.accessType}}</label><br>
            <label class="valuesum"
              *ngIf="zEndAccessType; else noValueAvailable">{{zEndAccessType}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndAccessType && isExpanded1">No value</label>
          </div>
          <div class="col-3" *ngIf="subProductRef && subProductRef === 'mobile smc' && siteNameZ == 'NON-PREMISES'">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.poleId}}</label><br>
            <label class="valuesum" *ngIf="zPoleId; else noValueAvailable">{{zPoleId}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zPoleId && isExpanded2">No value</label>
          </div>
          <div class="col-3" *ngIf="subProductRef && subProductRef === 'mobile smc' && siteNameZ == 'NON-PREMISES'">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.nodeCode}}</label><br>
            <label class="valuesum" *ngIf="zNodeCode; else noValueAvailable">{{zNodeCode}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zNodeCode && isExpanded2">No value</label>
          </div>
        </div>
        <div class="row col-12">
          <div class="col-3 mb-4" *ngIf="subProductRef && subProductRef === 'mobile smc' && siteNameZ == 'NON-PREMISES'">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leadInRequired}}</label><br>
            <label class="valuesum" *ngIf="zLeadInRequired; else noValueAvailable">{{zLeadInRequired}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zLeadInRequired && isExpanded2">No value</label>
          </div>
          <div class="col-3 mb-4" *ngIf="zEndAdditionalCabling">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.additionalCabling}}</label><br>
            <label class="valuesum"
              *ngIf="zEndAdditionalCabling; else noValueAvailable">{{zEndAdditionalCabling}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndAdditionalCabling && isExpanded1">No value</label>
          </div>
          <div class="col-3 mb-4" *ngIf="zEndAdditionalCablingLocation">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.additionalCablingLocation}}</label><br>
            <label class="valuesum"
              *ngIf="zEndAdditionalCablingLocation; else noValueAvailable">{{zEndAdditionalCablingLocation}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndAdditionalCablingLocation && isExpanded1">No value</label>
          </div>
        </div>
        <!-- <div class="row col-12"
          *ngIf="serviceType == 'RG' &&  siteNameZ =='NON-PREMISES' && businessDriv == '5G Small Cell'">
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.poleId}}</label><br>
            <label class="valuesum" *ngIf="zPoleId; else noValueAvailable">{{zPoleId}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zPoleId && isExpanded2">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leadInRequired}}</label><br>
            <label class="valuesum" *ngIf="zLeadInRequired; else noValueAvailable">{{zLeadInRequired}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zLeadInRequired && isExpanded2">No value</label>
          </div>
        </div> -->

        <div class="col-9 mb-4">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.addressInformation}}</label><br>
          <label class="valuesum" *ngIf="zaddInformation; else noValueAvailable">{{zaddInformation}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!zaddInformation && isExpanded2">No value</label>
        </div>
        <div class="row col-12">
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactName}}</label><br>
            <label class="valuesum"
              *ngIf="zEndprimaryContactName; else noValueAvailable">{{zEndprimaryContactName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndprimaryContactName && isExpanded2">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactNumber}}</label><br>
            <label class="valuesum"
              *ngIf="zEndprimaryContactNumber; else noValueAvailable">{{zEndprimaryContactNumber}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndprimaryContactNumber && isExpanded2">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactEmail}}</label><br>
            <label class="valuesum"
              *ngIf="zEndprimaryContactEmail; else noValueAvailable">{{zEndprimaryContactEmail}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndprimaryContactEmail && isExpanded2">No value</label>
          </div>
        </div>
      </div>
    </div>
    <!--END: SITE z end address for order only  -->


    <!-- START: Contact section for order only -->
    <div class="expandable" *ngIf="isOrder">
      <div class="headerCont" tabindex="0" (keypress)="toggleContent4()" (click)="toggleContent4()"
        [attr.aria-label]="isExpanded4 ? 'Expanded ' + aemLabelAuthoring?.pageHeader?.customerContactDetails + ' button' : 'Collapsed ' +  aemLabelAuthoring?.pageHeader?.customerContactDetails + ' button'">
        <i class="icon" [ngClass]="{'expanded': isExpanded4}"></i>
        <label class="productLabel" role="link">{{aemLabelAuthoring?.pageHeader?.customerContactDetails}}</label>
      </div>
      <div class="content productCont" [hidden]="!isExpanded4">
        <div class="col-12 summaryContent">
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.contactName}}</label><br>
            <label class="valuesum" *ngIf="customerContactName; else noValueAvailable">{{customerContactName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!customerContactName && isExpanded4">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.contactNumber}}</label><br>
            <label class="valuesum"
              *ngIf="customerContactNumber; else noValueAvailable">{{customerContactNumber}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!customerContactNumber && isExpanded4">No value</label>
          </div>
          <div class="col-6">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.contactEmailAddress}}</label><br>
            <label class="valuesum" *ngIf="customerContactEmail; else noValueAvailable">{{customerContactEmail}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!customerContactEmail && isExpanded4">No value</label>
          </div>
        </div>
      </div>
    </div>
    <!--END: Contact section for order only  -->


    <div class="expandable" *ngIf="isdiversity === 'Yes' && quoteDetails.describeYourDiversity === 'All new services with diversity'">
      <div class="headerCont" tabindex="0" (keypress)="toggleContent8()" (click)="toggleContent8()"
        [attr.aria-label]="isExpanded8 ? 'Expanded ' + aemLabelAuthoring?.pageHeader?.SiteAPath2 + ' button' : 'Collapsed ' +  aemLabelAuthoring?.pageHeader?.SiteAPath2 + ' button'">
        <i class="icon" [ngClass]="{'expanded': isExpanded8}"></i>
        <label class="productLabel" role="link">{{aemLabelAuthoring?.pageHeader?.SiteAPath2}}</label>
      </div>
      <div class="col-11 content siteDet site-details-content" [hidden]="!isExpanded8">
        <!-- <div class="col-3">
    <img class="mapImage" src="../../../assets/images/Map Z.svg"/>
    </div> -->
        <div class="row col-12 site-det-row-container">
          <span class="col-2 mb-4 site-map-img ">
            <app-map-item [LatLng]="LatLngSiteA2" *ngIf="LatLngSiteA2.address" [type]="'A'"></app-map-item>
          </span>
          <div class="col-4 mb-4 site-det-address">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.SiteAAdd}}</label><br>
            <label class="valuesum" *ngIf="a2EndAdress; else noValueAvailable">{{a2EndAdress}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!a2EndAdress && isExpanded8">No value</label>
          </div>
          <div class="col-3 alt-name-container">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternativeName}}</label><br>
            <label class="valuesum"
              *ngIf="a2EndAdressAlternativeCot; else noValueAvailable">{{a2EndAdressAlternativeCot}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!a2EndAdressAlternativeCot && isExpanded8">No value</label>
          </div>
          <div class="col-3" *ngIf="a2definedPropertyLoca?.toLowerCase() == 'yes' ||  a2definedPropertyLoca?.toLowerCase() == 'no'">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.propertyLocation}}</label><br>
            <label class="valuesum"
              *ngIf="a2definedPropertyLoca; else noValueAvailable">{{a2definedPropertyLoca}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!a2definedPropertyLoca && isExpanded8">No value</label>
          </div>
        </div>
        <!-- newly added section for non RG customer -->
        <div class="row col-12" *ngIf="serviceType !=='RG'">
          <div class="col-2"></div>
          <div class="col-4 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteType}}</label><br>
            <label class="valuesum"
              *ngIf="a2EndSiteType; else noValueAvailable">{{a2EndSiteType}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!a2EndSiteType && isExpanded1">No value</label>
          </div>
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreOperator}}</label><br>
            <label class="valuesum"
              *ngIf="a2EndDcOperator; else noValueAvailable">{{a2EndDcOperator}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!a2EndDcOperator && isExpanded1">No value</label>
          </div>
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreSite}}</label><br>
            <label class="valuesum"
              *ngIf="a2EndDcSiteName; else noValueAvailable">{{a2EndDcSiteName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!a2EndDcSiteName && isExpanded1">No value</label>
          </div>
        </div>
        <div class="col-2"></div>
        <div class="col-9 mb-5 address-info-container">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.addressInformation}}</label><br>
          <label class="valuesum" *ngIf="a2addInformation; else noValueAvailable">{{a2addInformation}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!a2addInformation && isExpanded8">No value</label>
        </div>
        
        <div class="row col-12" *ngIf="serviceType=='RG'">
          <div class="col-2"></div>
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactName}}</label><br>
            <label class="valuesum"
              *ngIf="a2EndprimaryContactName; else noValueAvailable">{{a2EndprimaryContactName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!a2EndprimaryContactName && isExpanded8">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactNumber}}</label><br>
            <label class="valuesum"
              *ngIf="a2EndprimaryContactNumber; else noValueAvailable">{{a2EndprimaryContactNumber}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!a2EndprimaryContactNumber && isExpanded8">No value</label>
          </div>
          <div class="col-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactEmail}}</label><br>
            <label class="valuesum"
              *ngIf="a2EndprimaryContactEmail; else noValueAvailable">{{a2EndprimaryContactEmail}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!a2EndprimaryContactEmail && isExpanded8">No value</label>
          </div>
        </div>
        <div class="row col-12" *ngIf="serviceType=='RG'">
          <div class="col-2"></div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactName}}</label><br>
            <label class="valuesum" *ngIf="a2EndAltContactName; else noValueAvailable">{{a2EndAltContactName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!a2EndAltContactName && isExpanded8">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactNumber}}</label><br>
            <label class="valuesum"
              *ngIf="a2EndAltContactNumber; else noValueAvailable">{{a2EndAltContactNumber}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!a2EndAltContactNumber && isExpanded8">No value</label>
          </div>
          <div class="col-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactEmail}}</label><br>
            <label class="valuesum" *ngIf="a2EndAltContactEmail; else noValueAvailable">{{a2EndAltContactEmail}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!a2EndAltContactEmail && isExpanded8">No value</label>
          </div>
        </div>
      </div>
    </div>


    <div class="expandable" *ngIf="isdiversity === 'Yes' && quoteDetails.describeYourDiversity === 'All new services with diversity'">
      <div class="headerCont" tabindex="0" (keypress)="toggleContent9()" (click)="toggleContent9()"
        [attr.aria-label]="isExpanded9 ? 'Expanded ' + aemLabelAuthoring?.pageHeader?.SiteZPath2 + ' button' : 'Collapsed ' +  aemLabelAuthoring?.pageHeader?.SiteZPath2 + ' button'">
        <i class="icon" [ngClass]="{'expanded': isExpanded9}"></i>
        <label class="productLabel" role="link">{{aemLabelAuthoring?.pageHeader?.SiteZPath2}}</label>
      </div>
      <div class="col-11 content siteDet site-details-content" [hidden]="!isExpanded9">
        <!-- <div class="col-3">
    <img class="mapImage" src="../../../assets/images/Map Z.svg"/>
    </div> -->
        <div class="row col-12 site-det-row-container">
          <span class="col-2 mb-4 site-map-img ">
            <app-map-item [LatLng]="LatLngSiteZ2" *ngIf="LatLngSiteZ2.address" [type]="'Z'"></app-map-item>
          </span>
          <div class="col-4 mb-4 site-det-address">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.SiteZaddress}}</label><br>
            <label class="valuesum" *ngIf="zEndAdress; else noValueAvailable">{{z2EndAdress}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!zEndAdress && isExpanded9">No value</label>
          </div>
          <div class="col-3 alt-name-container">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternativeName}}</label><br>
            <label class="valuesum"
              *ngIf="z2EndAdressAlternativeCot; else noValueAvailable">{{z2EndAdressAlternativeCot}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!z2EndAdressAlternativeCot && isExpanded9">No value</label>
          </div>
          <div class="col-3" *ngIf="z2definedPropertyLoca?.toLowerCase() == 'yes' || z2definedPropertyLoca?.toLowerCase() == 'no'">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.propertyLocation}}</label><br>
            <label class="valuesum"
              *ngIf="z2definedPropertyLoca; else noValueAvailable">{{z2definedPropertyLoca}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!z2definedPropertyLoca && isExpanded9">No value</label>
          </div>
        </div>
        <!-- newly added section for non RG customer -->
        <div class="row col-12" *ngIf="serviceType !=='RG'">
          <div class="col-2"></div>
          <div class="col-4 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteType}}</label><br>
            <label class="valuesum"
              *ngIf="z2EndSiteType; else noValueAvailable">{{z2EndSiteType}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!z2EndSiteType && isExpanded1">No value</label>
          </div>
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreOperator}}</label><br>
            <label class="valuesum"
              *ngIf="z2EndDcOperator; else noValueAvailable">{{z2EndDcOperator}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!z2EndDcOperator && isExpanded1">No value</label>
          </div>
          <div class="col-3 mb-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.dataCentreSite}}</label><br>
            <label class="valuesum"
              *ngIf="z2EndDcSiteName; else noValueAvailable">{{z2EndDcSiteName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!z2EndDcSiteName && isExpanded1">No value</label>
          </div>
        </div>
        <div class="col-2"></div>
        <div class="col-9 mb-5 address-info-container">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.addressInformation}}</label><br>
          <label class="valuesum" *ngIf="z2addInformation; else noValueAvailable">{{z2addInformation}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!z2addInformation && isExpanded9">No value</label>
        </div>
        <div class="row col-12" *ngIf="serviceType=='RG'">
          <div class="col-2"></div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactName}}</label><br>
            <label class="valuesum"
              *ngIf="z2EndprimaryContactName; else noValueAvailable">{{z2EndprimaryContactName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!z2EndprimaryContactName && isExpanded9">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactNumber}}</label><br>
            <label class="valuesum"
              *ngIf="z2EndprimaryContactNumber; else noValueAvailable">{{z2EndprimaryContactNumber}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!z2EndprimaryContactNumber && isExpanded9">No value</label>
          </div>
          <div class="col-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteContactEmail}}</label><br>
            <label class="valuesum"
              *ngIf="z2EndprimaryContactEmail; else noValueAvailable">{{z2EndprimaryContactEmail}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!z2EndprimaryContactEmail && isExpanded9">No value</label>
          </div>
        </div>
        <div class="row col-12" *ngIf="serviceType=='RG'">
          <div class="col-2"></div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactName}}</label><br>
            <label class="valuesum" *ngIf="z2EndAltContactName; else noValueAvailable">{{z2EndAltContactName}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!z2EndAltContactName && isExpanded9">No value</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactNumber}}</label><br>
            <label class="valuesum"
              *ngIf="z2EndAltContactNumber; else noValueAvailable">{{z2EndAltContactNumber}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!z2EndAltContactNumber && isExpanded9">No value</label>
          </div>
          <div class="col-4">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.alternateContactEmail}}</label><br>
            <label class="valuesum" *ngIf="z2EndAltContactEmail; else noValueAvailable">{{z2EndAltContactEmail}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!z2EndAltContactEmail && isExpanded9">No value</label>
          </div>
        </div>
      </div>
    </div>
    <div class="expandable" *ngIf="(serviceType == 'RG')">
      <div class="headerCont" tabindex="0" (keypress)="toggleContent3()" (click)="toggleContent3()"
        [attr.aria-label]="isExpanded3 ? 'Expanded ' + aemLabelAuthoring?.pageHeader?.References + ' button' : 'Collapsed ' +  aemLabelAuthoring?.pageHeader?.References + ' button'">
        <i class="icon" [ngClass]="{'expanded': isExpanded3}"></i>
        <label class="productLabel" role="link">{{aemLabelAuthoring?.pageHeader?.References}}</label>
      </div>
      <div class="content exterRef" [hidden]="!isExpanded3">
        <div class="row col-12">
          <div class='col-3'>
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.businessDriver}}</label><br>
            <label class="valuesum" *ngIf="businessDriv; else noValueAvailable">{{businessDriv}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!businessDriv && isExpanded3">No value</label>
          </div>
          <div class='col-3' *ngIf='isOrder'>
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.projectId}}</label><br>
            <label class="valuesum" *ngIf="projectReferenceId; else noValueAvailable">{{projectReferenceId}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!projectReferenceId && isExpanded3">No value</label>
          </div>
          <div class='col-3' *ngIf='isOrder'>
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.opportunityNo}}</label><br>
            <label class="valuesum" *ngIf="opportunityNo; else noValueAvailable">{{opportunityNo}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!opportunityNo && isExpanded3">No value</label>
          </div>
          <div class='col-3' *ngIf='isOrder'>
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.clusterId}}</label><br>
            <label class="valuesum" *ngIf="clusterId; else noValueAvailable">{{clusterId}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!clusterId && isExpanded3">No value</label>
          </div>
        </div>
        <div class="row col-12 external" *ngIf='isOrder'>
          <div class='col-3'>
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.miragepclId}}</label><br>
            <label class="valuesum" *ngIf="miragepclId; else noValueAvailable">{{miragepclId}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!miragepclId && isExpanded3">No value</label>
          </div>
        </div>
        <div class="row col-12 external">
          <div class="col-5" *ngIf="isOrder">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="headersum">{{aemLabelAuthoring?.pageHeader?.opticalLoss}}</th>
                  <th class="headersum">{{aemLabelAuthoring?.pageHeader?.valueDb}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ref of maxSignalLoss">
                  <th class="valuesum">{{ref.wavelength}}</th>
                  <td class="valuesum">{{ref.dbValue}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-1" *ngIf="isOrder"></div>
          <div class="col-5">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="headersum">{{aemLabelAuthoring?.pageHeader?.externalReference}}</th>
                  <th class="headersum">{{aemLabelAuthoring?.pageHeader?.value}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ref of externalReferences">
                  <th class="valuesum">{{ref.externalReferenceType}}</th>
                  <td class="valuesum">{{ref.externalReferenceValue}}</td>
                </tr>
              </tbody>
            </table>
            <!-- <div class="col-3">
            <div class="valuesum ml-7" *ngFor="let val of externalReferenceVal">{{val}}</div><br> -->
          </div>
        </div>
      </div>
    </div>
    <div class="expandable" *ngIf="!isOrder && !isPredefinedQuote">
      <div class="headerCont" tabindex="0" (keypress)="toggleContent4()" (click)="toggleContent4()"
        [attr.aria-label]="isExpanded4 ? 'Expanded ' + aemLabelAuthoring?.pageHeader?.considerations + ' button' : 'Collapsed ' +  aemLabelAuthoring?.pageHeader?.considerations + ' button'">
        <i class="icon" [ngClass]="{'expanded': isExpanded4}"></i>
        <label class="productLabel" role="link">{{aemLabelAuthoring?.pageHeader?.considerations}}</label>
      </div>
      <div class="content exterRef" [hidden]="!isExpanded4">
        <div class="col-12">
          <label class="headersum">{{aemLabelAuthoring?.pageHeader?.maximumDistance}}</label><br>
          <label class="valuesum" *ngIf="preferredMaxDistance; else noValueAvailable">{{preferredMaxDistance}}</label>
          <label aria-live="assertive" class="visually-hidden" *ngIf="!preferredMaxDistance && isExpanded4">No value</label>
        </div>
        <div class="col-12 external">
          <label class="headersum col-5"
            aria-label="Maximum allowable Dark Fibre optical loss">{{aemLabelAuthoring?.pageHeader?.opticalLoss}}</label><br>
        </div>
        <div class="col-10 mt-2">
          <label class="headersum col-5">{{aemLabelAuthoring?.pageHeader?.waveLength}}</label>
          <label class="headersum col-5">{{aemLabelAuthoring?.pageHeader?.valueDb}}</label>
        </div>


        <!-- <div class="col-6">
        <label class="headersum">Value</label>

      </div> -->
        <hr class="col-7 hrLineFirst">
        <!-- <div class="row col-10 hrPadd">
        <label class="col-5 valuesum" *ngFor="let ref of fiberOpticalLoss">{{ref}}</label>
        <label class="col-5 valuesum" *ngFor="let val of dbVal">{{val}}</label>

      </div> -->
        <div class="col-10 hrPadd">
          <ul class="col-5 listStyle" *ngIf="fiberOpticalLoss?.length; else noListValue">
            <li *ngFor="let ref1 of fiberOpticalLoss">{{ref1}}</li>


          </ul>
          <ul class="col-5 listStyle" *ngIf="dbVal?.length; else noListValue">
            <li *ngFor="let val1 of dbVal">{{val1}}</li>

          </ul>
        </div>
        <hr class="col-7 hrLine">
      </div>

    </div>

    <div class="expandable" *ngIf="!isOrder && serviceType == 'RG'">
      <div class="headerCont" tabindex="0" id="fundingDetails" (keypress)="toggleContent5()" (click)="toggleContent5()"
        [attr.aria-label]="isExpanded5 ? 'Expanded ' + aemLabelAuthoring?.pageHeader?.fundingDetails + ' button' : 'Collapsed ' +  aemLabelAuthoring?.pageHeader?.fundingDetails + ' button'">
        <i class="icon" [ngClass]="{'expanded': isExpanded5}"></i>
        <label class="productLabel" role="link">{{aemLabelAuthoring?.pageHeader?.fundingDetails}}</label>
      </div>
      <div class="content exterRef" [hidden]="!isExpanded5">
        <div class="row col-12 ">
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.fundingSource}}</label><br>
            <label class="valuesummary" *ngIf="fundingSource; else noValueAvailable">{{fundingSource}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!fundingSource && isExpanded5">No value</label>
          </div>
          <div class="col-3" *ngIf="showFundingSourceCodes()">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.l3Capex}}</label><br>
            <label class="valuesummary" *ngIf="requestorChannel; else noValueAvailable">{{l3WBSCodeCapex}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!requestorChannel && isExpanded5">No value</label>
          </div>
          <div class="col-3" *ngIf="showFundingSourceCodes()">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.l3Opex}}</label><br>
            <label class="valuesummary" *ngIf="submittedDate; else noValueAvailable">{{l3WBSCodeOpex}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!submittedDate && isExpanded5">No value</label>
          </div>
          <div class="col-3" *ngIf="fundingSource==='Other'">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.otherPOW}}</label><br>
            <label class="valuesummary">{{otherPowImc}}</label>
          </div>
          <div class="col-3">
            <label class="headersum">{{aemLabelAuthoring?.pageHeader?.preApprovalCost}}</label><br>
            <label class="valuesummary" *ngIf="fundingSource; else noValueAvailable">{{preApprovalCost |
              currency}}</label>
            <label aria-live="assertive" class="visually-hidden" *ngIf="!fundingSource && isExpanded5">No value</label>
          </div>
        </div>
      </div>

    </div>
    <div class="tabContainer" #assessmentOutcome>
      <mat-tab-group #assessmentHistoryTabs [disableRipple]="true" [(selectedIndex)]=selectedIndexV>
        <mat-tab tabindex="0" id="path1AssessmentOutcome"
          [label]="isdiversity === 'Yes' && quoteDetails.describeYourDiversity === 'All new services with diversity' ?
          'Path 1: Assessment outcome' : 'Assessment Outcome'" class="my-custom-class"
          *ngIf="showAssessmentOutcome(status)">

          <!--START: Show outcome section for quote and RG only-->

          <div class="expandable assessment-outcome-tab mt-25" *ngIf="(serviceType == 'RG')">
            <div class="headerCont">
              <label class="productLabel">{{aemLabelAuthoring?.pageHeader?.outcome}}</label>
            </div>
            <div class="assessment-outcome-content">
              <div class="col-12 summaryContent">
                <div class="col-3">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestMet}}</label><br>
                  <label class="valuesum" *ngIf="requestMet; else noValueAvailable">{{requestMet}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!requestMet">No value</label>
                </div>
                <div class="col-3" *ngIf="showForQuoteOrderBasedOnRequestMet('Yes (with concession)')">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.concessionItems}}</label><br>
                  <label class="valuesum" *ngIf="concessionItems; else noValueAvailable">{{concessionItems}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!concessionItems">No value</label>
                </div>
                <div class="col-3" *ngIf="showForQuoteOrderBasedOnRequestMet('No')">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.notMetItems}}</label><br>
                  <label class="valuesum" *ngIf="notMetItems; else noValueAvailable">{{notMetItems}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!notMetItems">No value</label>

                </div>
                <div class="col-3">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.networkProximity}}</label><br>
                  <label class="valuesum" *ngIf="networkProx; else noValueAvailable">{{networkProx}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!networkProx">No
                    value</label>
                </div>
                <div class="col-3">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.indicativeLinkdeliveryDays}}</label><br>
                  <label class="valuesum"
                    *ngIf="indicativeLinkdeliveryDays; else noValueAvailable">{{indicativeLinkdeliveryDays}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!indicativeLinkdeliveryDays">No
                    value</label>
                </div>
              </div>
            </div>
          </div>
          <!--END: Show outcome section for quote and RG only-->

          <!-- START: show pricing section for quote only -->
          <div class="expandable assessment-outcome-tab mt-25" *ngIf="(!isOrder && serviceType == 'RG')">
            <div class="headerCont">
              <label class="productLabel">{{aemLabelAuthoring?.pageHeader?.pricing}}</label>
            </div>
            <div class="assessment-outcome-content">
              <div class="col-12 summaryContent">
                <div class="col-3">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.totalCapitalCost}}</label><br>
                  <label class="valuesum" *ngIf="totalCapitalCost; else noValueAvailable">{{totalCapitalCost | currency
                    }}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!totalCapitalCost">No value</label>
                </div>
                <div class="col-3">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.lateralBenefitValue}}</label><br>
                  <label class="valuesum" *ngIf="lateralBenefitValue; else noValueAvailable">{{lateralBenefitValue |
                    currency }}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!lateralBenefitValue">No value</label>
                </div>
                <div class="col-3">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteAssessmentCost}}</label><br>
                  <label class="valuesum" *ngIf="siteAssessmentCost; else noValueAvailable">{{siteAssessmentCost |
                    currency }}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!siteAssessmentCost">No value</label>
                </div>
              </div>
              <div class="col-12 summaryContent">
                <div class="col-3">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.monthlyRecurringICACharge}}</label><br>
                  <label class="valuesum"
                    *ngIf="monthlyRecurringICACharge; else noValueAvailable">{{monthlyRecurringICACharge | currency
                    }}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!monthlyRecurringICACharge">No
                    value</label>
                </div>
                <div class="col-3">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.monthlyRecurringCharge}}</label><br>
                  <label class="valuesum"
                    *ngIf="monthlyRecurringCharge; else noValueAvailable">{{monthlyRecurringCharge|currency }}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!monthlyRecurringCharge">No value</label>
                </div>
                <div class="col-3">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.totalMonthlyRecurringCharge}}</label><br>
                  <label class="valuesum"
                    *ngIf="totalMonthlyRecurringCharge; else noValueAvailable">{{totalMonthlyRecurringCharge | currency
                    }}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!totalMonthlyRecurringCharge">No
                    value</label>
                </div>
              </div>
            </div>
          </div>
          <!-- END: show pricing section for quote only -->

          <!-- START: Solution section for order and  Non RG -->
          <div class="expandable assessment-outcome-tab mt-25" *ngIf="isOrder && serviceType !== 'RG'"
            style="margin-top:25px;  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);">
            <div class="headerCont">
              <label class="productLabel">{{aemLabelAuthoring?.pageHeader?.solution}}</label>
            </div>
            <div class="assessment-outcome-content">
              <div class="col-12 summaryContent">
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.estimatedDistanceRange}}</label><br>
                  <label class="valuesum"
                    *ngIf="estimatedDistanceRange; else noValueAvailable">{{estimatedDistanceRange}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!estimatedDistanceRange">No value</label>
                </div>
              </div>
              <div class="row col-12 external">
                <div class="col-12 external pl-0">
                  <label class="col-5 pl-0 ml-0 nonrgOpticalloss"
                    aria-label="Maximum allowable Dark Fibre optical loss">{{aemLabelAuthoring?.pageHeader?.opticalLoss}}</label><br>
                </div>
                <div class="col-5">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th class="headersum pl-0">{{aemLabelAuthoring?.pageHeader?.waveLength}}</th>
                        <th class="headersum">{{aemLabelAuthoring?.pageHeader?.valueDb}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let ref of estimatedLoss">
                        <th class="valuesum pl-0">{{ref.wavelength}}</th>
                        <td class="valuesum">{{ref.dbValue}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Solution section for Non RG -->

          <!-- START: Solution section for quote & order for RG -->
          <div class="expandable assessment-outcome-tab mt-25" *ngIf="(serviceType == 'RG')">
            <div class="headerCont">
              <label class="productLabel">{{aemLabelAuthoring?.pageHeader?.solution}}</label>
            </div>
            <div class="assessment-outcome-content">
              <div class="col-12 summaryContent">
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofr}}</label><br>
                  <label class="valuesum" *ngIf="rofr; else noValueAvailable">{{rofr}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!rofr">No value</label>
                </div>
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFibreSize}}</label><br>
                  <label class="valuesum" *ngIf="rofrFibreSize; else noValueAvailable">{{rofrFibreSize}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!rofrFibreSize">No value</label>
                </div>
                <div class="col-2 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFAPId}}</label><br>
                  <label class="valuesum" *ngIf="rofrFAPId; else noValueAvailable">{{rofrFAPId}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!rofrFAPId">No value</label>
                </div>
                <div class="col-2 solution-custom-width rofr-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFAPLatitude}}</label><br>
                  <label class="valuesum" *ngIf="rofrFAPLatitude; else noValueAvailable">{{rofrFAPLatitude}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!rofrFAPLatitude">No value</label>
                </div>
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFAPLongitude}}</label><br>
                  <label class="valuesum" *ngIf="rofrFAPLongitude; else noValueAvailable">{{rofrFAPLongitude}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!rofrFAPLongitude">No value</label>
                </div>
              </div>
              <div class="col-12 summaryContent">
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.totalFibreLength}}</label><br>
                  <label class="valuesum" *ngIf="totalFibreLength; else noValueAvailable">{{totalFibreLength}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!totalFibreLength">No value</label>
                </div>
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.newFibreHaulLength}}</label><br>
                  <label class="valuesum"
                    *ngIf="newFibreHaulLength; else noValueAvailable">{{newFibreHaulLength}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!newFibreHaulLength">No value</label>
                </div>
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.newFibreBuriedLength}}</label><br>
                  <label class="valuesum"
                    *ngIf="newFibreBuriedLength; else noValueAvailable">{{newFibreBuriedLength}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!newFibreBuriedLength">No value</label>
                </div>
              </div>
              <div class="col-12 summaryContent">
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.newConduitLength}}</label><br>
                  <label class="valuesum" *ngIf="newConduitLength; else noValueAvailable">{{newConduitLength}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!newConduitLength">No value</label>
                </div>
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leasedConduitLength}}</label><br>
                  <label class="valuesum"
                    *ngIf="leasedConduitLength; else noValueAvailable">{{leasedConduitLength}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!leasedConduitLength">No value</label>
                </div>
                <div class="solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leasedConduitTerm}}</label><br>
                  <label class="valuesum" *ngIf="leasedConduitTerm; else noValueAvailable">{{leasedConduitTerm}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!leasedConduitTerm">No value</label>
                </div>
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leasedConduitOwner}}</label><br>
                  <label class="valuesum"
                    *ngIf="leasedConduitOwner; else noValueAvailable">{{leasedConduitOwner}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!leasedConduitOwner">No value</label>
                </div>
              </div>
              <div class="col-12 summaryContent">
                <div class="col-3 solution-custom-width" *ngFor="let val of estimatedLoss">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.estimatedLoss}} {{val.wavelength}}
                    (dB)</label><br>
                  <label class="valuesum" *ngIf="val.dbValue; else noValueAvailable">{{val.dbValue}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!val.dbValue">No value</label>
                </div>
              </div>
              <div class="col-12 summaryContent">
                <div class="col-12">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestorSolutionSummary}}</label><br>
                  <label class="valuesum summary-text-wrap"
                    *ngIf="requestorSolutionSummary; else noValueAvailable">{{requestorSolutionSummary}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!requestorSolutionSummary">No
                    value</label>
                </div>
              </div>
              <div class="col-12 summaryContent">
                <div class="col-12">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.solutionSummary}}</label><br>
                  <label class="valuesum summary-text-wrap"
                    *ngIf="solutionSummary; else noValueAvailable">{{solutionSummary}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!solutionSummary">No value</label>
                </div>
              </div>
            </div>
          </div>

          <!-- END: Solution section for quote & RG -->


          <!-- START: Cable details show only for quote & RG -->
          <div class="expandable" *ngIf="serviceType == 'RG'">
            <div class="headerCont" tabindex="0" (keypress)="toggleContent6()" (click)="toggleContent6()"
              [attr.aria-label]="isExpanded6 ? 'Expanded ' + aemLabelAuthoring?.pageHeader?.CableDetails + ' button' : 'Collapsed ' +  aemLabelAuthoring?.pageHeader?.CableDetails + ' button'">
              <i class="icon" [ngClass]="{'expanded': isExpanded6}"></i>
              <label class="productLabel" role="link">Cable details</label>
            </div>
            <div class="grid-content" [hidden]="!isExpanded6">
              <div class="grid-container cable-detail-grid" *ngIf="gridConfig && gridInitialized">
                <app-cui-ag-grid [model]="gridModel">
                </app-cui-ag-grid>
              </div>
            </div>
          </div>
          <!-- END: Cable details show only for quote & RG -->
          <!-- START: Proceed to Order section Path 1 for RG quote-->
          <div class="orderBtn" *ngIf="(!isOrder && serviceType == 'RG')">
            <button type="button" id="newQuotebtn" class="primary-globalbtn-cls orderBtnStl"
              (click)="proceedToOrder('Path1')"
              [attr.aria-label]="showDiversity ? aemLabelAuthoring?.pageHeader?.assessmentoutcomePath1 +' Proceed to Order' : ' Proceed to Order'"
              *ngIf="isReadWriteOnOrderManagement && (status?.toLowerCase() === 'quote ready' || status?.toLowerCase() === 'quote accepted'
              || status?.toLowerCase() == 'cancelled' || status?.toLowerCase() === 'ordered' || status?.toLowerCase() === 'expired')"
              [disabled]="status?.toLowerCase() == 'cancelled' || status?.toLowerCase() === 'ordered' || status?.toLowerCase() === 'quote accepted'
              || status?.toLowerCase() === 'expired'
            || quoteDetails?.quoteAcceptedFrom?.toLowerCase() === 'path1' || quoteDetails?.quoteAcceptedFrom?.toLowerCase() === 'both'">
              {{aemLabelAuthoring?.pageHeader?.proceedToOrder}}
            </button>
          </div>
          <!-- END: Proceed to Order section Path 1 for RG quote-->
          <!-- START: Non-RG Quote Ready Predefined -->

          <div class="col-12 summaryContent" *ngIf="(!isOrder && serviceType !== 'RG')">
            <div class="col-3 solution-custom-width estimatedDisnonRg">
              <label class="headersum">{{aemLabelAuthoring?.pageHeader?.estimatedDistance}}</label><br>
              <label class="valuesum" *ngIf="preferredDistance; else noValueAvailable">{{preferredDistance}}</label>
              <label aria-live="assertive" class="visually-hidden" *ngIf="!totalFibreLength">No value</label>
            </div>
            <div class="col-3 solution-custom-width">
              <label class="headersum">{{aemLabelAuthoring?.pageHeader?.networkProximity}}</label><br>
              <label class="valuesum" *ngIf="networkProx; else noValueAvailable">{{networkProx}}</label>
              <label aria-live="assertive" class="visually-hidden" *ngIf="!newFibreHaulLength">No value</label>
            </div>


          </div>
          <div class="col-5" *ngIf="(!isOrder && serviceType !== 'RG' && estimatedLoss && estimatedLoss.length > 0)">
            <label class="nonrgOpticalloss">{{aemLabelAuthoring?.pageHeader?.estimatedOpticalLoss}}</label><br>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="headersum">{{aemLabelAuthoring?.pageHeader?.waveLength}}</th>
                  <th class="headersum">{{aemLabelAuthoring?.pageHeader?.valueDb}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ref of estimatedLoss">
                  <th class="valuesum">{{ref.wavelength}}</th>
                  <td class="valuesum">{{ref.dbValue}}</td>
                </tr>
              </tbody>
            </table>
            <!-- <div class="col-3">
            <div class="valuesum ml-7" *ngFor="let val of externalReferenceVal">{{val}}</div><br> -->
          </div>
          <form [formGroup]="quoteTermFormPath1" class="row product-details-form-container summaryTerm" *ngIf="(!isOrder && serviceType !== 'RG')
          && !(status?.toLowerCase() === 'assessment complete')">
            <ng-container>
              <div class="radio-btn-container">
                <label class="nonrgOpticalloss">{{aemLabelAuthoring?.pageHeader?.Selectterm}}</label><br>
                <mat-radio-group role="radiogroup" class="smart-ui-radio-group terms-radio-group display-radio-grp"
                formControlName="terms"
                [disabled]="(status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'ordered' || status?.toLowerCase() === 'expired')">

                  <mat-radio-button [ngClass]="{'disabledRadio':(radioValuePath1 !== 3 && (status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'ordered'
        || status?.toLowerCase() === 'expired' || !isReadWriteOnOrderManagement || sentEnqPath1)),
         'checkedClass' : radioValuePath1 === 3,
        'mat-radio-disabled-checked': (radioValuePath1 === 3 && (status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'ordered'
        || status?.toLowerCase() === 'expired' || !isReadWriteOnOrderManagement || sentEnqPath1))}"
                    (click)="termSelect('path1',3)" (keypress)="termSelect('path1',3)" id="termsRadio1"

                    class="col-2 smart-ui-button-group terms-radio-btn align-items-center" [value]="3"
                    [disabled]="!isReadWriteOnOrderManagement || sentEnqPath1" (change)="radioChange($event)">
                    <div  role="radio"  class="mat-custom-label" tabindex="0" [value]="3" (click)="termSelect('path1',3)" (change)="radioChange($event)"
                     (keypress)="termSelect('path1',3)" [attr.aria-checked]="(radioValuePath1 === 3) ? 'true' : 'false'">
                      <span attr.aria-label="{{aemLabelAuthoring?.pageHeader?.Selectterm}}"></span>
                      <div class="charge">{{threeYearPriceMonth}}<label class="month"> / month</label></div>
                      <div class="yearsSlc" aria-label="Terms required 3 years">3 years</div>
                      <div class="nonrecurCharge">{{aemLabelAuthoring?.pageHeader?.nonRecurringCharge}}: {{nonRecurringCharge}}</div>
                    </div>
                  </mat-radio-button>

                  <mat-radio-button [ngClass]="{'disabledRadio':(radioValuePath1 !== 5 && (status?.toLowerCase() === 'quote accepted'
        || status?.toLowerCase() === 'ordered' || status?.toLowerCase() === 'expired' || !isReadWriteOnOrderManagement || sentEnqPath1)),
        'checkedClass' : radioValuePath1 === 5,
       'mat-radio-disabled-checked': (radioValuePath1 === 5 && (status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'ordered'
       || status?.toLowerCase() === 'expired' || !isReadWriteOnOrderManagement || sentEnqPath1))}"
                    (click)="termSelect('path1',5)" (keypress)="termSelect('path1',5)" id="termsRadio2"

                    class="col-2 smart-ui-button-group terms-radio-btn align-items-center" [value]="5"
                    [disabled]="!isReadWriteOnOrderManagement || sentEnqPath1" (change)="radioChange($event)">
                    <div role="radio" tabindex="0" id="termsRadio2" [value]="5" (click)="termSelect('path1',5)" class="mat-custom-label"
                    (change)="radioChange($event)"  (keypress)="termSelect('path1',5)" [attr.aria-checked]="(radioValuePath1 === 5) ? 'true' : 'false'">
                      <span attr.aria-label="{{aemLabelAuthoring?.pageHeader?.Selectterm}}"></span>
                      <div class="charge">{{fiveYearPriceMonth}}<label class="month"> / month</label></div>
                      <div class="yearsSlc">5 years</div>
                      <div class="nonrecurCharge">{{aemLabelAuthoring?.pageHeader?.nonRecurringCharge}}: {{nonRecurringCharge}}</div>
                    </div>
                  </mat-radio-button>

                  <mat-radio-button tabindex="0" *ngIf="custTerm" role="radio" [ngClass]="{'disabledRadio':(radioValuePath1 !== custTerm &&
         (status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'ordered' || status?.toLowerCase() === 'expired' ||
          !isReadWriteOnOrderManagement || sentEnqPath1)),
        'checkedClass' : radioValuePath1 === custTerm,
       'mat-radio-disabled-checked': (radioValuePath1 === custTerm && (status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'ordered'
       || status?.toLowerCase() === 'expired' || !isReadWriteOnOrderManagement || sentEnqPath1 ))}"
                    (click)="termSelect('path1',custTerm)" (keypress)="termSelect('path1',custTerm)" id="termsRadio4"
                    [attr.aria-checked]="(radioValuePath1 === custTerm) ? 'true' : 'false'"
                    class="col-2 smart-ui-button-group terms-radio-btn align-items-center" [value]="custTerm"
                    [disabled]="!isReadWriteOnOrderManagement || sentEnqPath1" (change)="radioChange($event)">
                    <div role="radio" tabindex="0" id="termsRadio4"  (click)="termSelect('path1',custTerm)"  class="mat-custom-label"
                    (change)="radioChange($event)" (keypress)="termSelect('path1',custTerm)" >
                      <span attr.aria-label="{{aemLabelAuthoring?.pageHeader?.Selectterm}}"></span>
                      <div class="charge">{{customYearMonth}}<label class="month"> / month</label></div>
                      <div class="yearsSlc">{{custTerm}} years</div>
                      <div class="nonrecurCharge">{{aemLabelAuthoring?.pageHeader?.nonRecurringCharge}}:
                        {{nonRecurringCharge}}</div>
                    </div>
                  </mat-radio-button>

                  <mat-radio-button tabindex="0" *ngIf="!custTerm || custTerm === ''" [ngClass]="{'disabledRadio':(status?.toLowerCase() === 'quote accepted'
        || status?.toLowerCase() === 'expired' || !isReadWriteOnOrderManagement || sentEnqPath1)}"
                    (click)="checkRadioButton('path1', 'customRadio')"
                    (keypress)="checkRadioButton('path1', 'customRadio')" id="termsRadio3"
                    class="col-2 smart-ui-button-group terms-radio-btn custom-term-radio" [value]="10"
                    [disabled]="true">
                    <div id="termsRadio3" class="mat-custom-label cusTermRadio"  [value]="10"  (click)="checkRadioButton('path1', 'customRadio')" (keypress)="checkRadioButton('path1', 'customRadio')" >
                      <span>{{aemLabelAuthoring?.pageHeader?.Customcontract}}</span><br>
                      <input [ngClass]="{'inputTermDis': sentEnqPath1 || (status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'ordered' ||
             status?.toLowerCase() === 'expired'),
             'error': quoteTermFormPath1.get('selectedValue').invalid}"
                        class="contractTerm" type="text" formControlName="selectedValue"
                        (input)="getCustomTermPath1()" aria-label="custom contract term"  placeholder="Enter term" />
                      <label [ngClass]="{'inputTermDis': sentEnqPath1 || (status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'expired'),
             'error': quoteTermFormPath1.get('selectedValue').invalid}" class="contractyearsLabel"
                        aria-describedby="errMsg_ContractTerm">year(s)</label><br>
                      <ng-container *ngIf="quoteTermFormPath1.controls.selectedValue.invalid">
                        <div *ngIf="quoteTermFormPath1.controls.selectedValue.invalid" class="text-danger errorMsg"
                          role="alert" id="errMsg_ContractTerm">
                          <img class="error-icon alignErrTrm" alt="error icon"
                            src="../../../../assets/images/icon-error.svg" /><span>{{aemLabelAuthoring?.pageHeader?.errTerm}}</span>
                        </div>
                      </ng-container>
                      <button [disabled]="quoteTermFormPath1.controls.selectedValue.invalid || sentEnqPath1 ||  status?.toLowerCase() === 'ordered' ||
           status?.toLowerCase() === 'expired' || quoteTermFormPath1.get('selectedValue').value.trim() === ''"
                        style="margin-top: 10px;" class="primary-globalbtn-cls"
                        (click)="sendEnq('path1')">{{aemLabelAuthoring.pageHeader?.sendEnquiry}}</button>
                    </div>
                  </mat-radio-button>
              </mat-radio-group>
              </div>
            </ng-container>
            <!-- START: Proceed to Order section Path 1 for non-RG quote-->
            <div class="orderBtn">
              <button type="button" id="newQuotebtn" style="float:right" class="primary-globalbtn-cls"
                (click)="proceedToOrder('Path1')"
                *ngIf="(status?.toLowerCase() === 'quote ready' || status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() == 'expired'
                || status?.toLowerCase() === 'ordered') && isReadWriteOnOrderManagement"
                [disabled]="isPath1TermSelected || !enableOrderPath1 || status?.toLowerCase() === 'ordered' || status?.toLowerCase() == 'expired'
                || status?.toLowerCase() === 'quote accepted'
                || quoteDetails?.quoteAcceptedFrom?.toLowerCase() === 'path1' || quoteDetails?.quoteAcceptedFrom?.toLowerCase() === 'both' || sentEnqPath1 ">
                {{aemLabelAuthoring?.pageHeader?.proceedToOrder}}
              </button>
            </div>
            <!-- END: Proceed to Order section Path 1 for non-RG quote-->
          </form>
          <!-- END: Non-RG Quote Ready Predefined -->
        </mat-tab>

        <!-- assessment path2 starts-->
        <mat-tab id="path2AssessmentOutcome"
        [label]="!isOrder ? 'Path 2: Assessment outcome' : 'Assessment outcome'"
         class="my-custom-class"
        *ngIf="(!this.isOrder &&
        isdiversity === 'Yes' && quoteDetails.describeYourDiversity === 'All new services with diversity'
        && (status?.toLowerCase() === 'quote ready' || status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'expired'
          || status?.toLowerCase() === 'assessment complete')) || (this.isOrder && orderPath.toLowerCase() === 'path2')">
          <div class="expandable assessment-outcome-tab mt-25" *ngIf="(serviceType == 'RG')">
           <div class="headerCont">
              <label class="productLabel">{{aemLabelAuthoring?.pageHeader?.outcome}}</label>
            </div>
            <div class="assessment-outcome-content">
              <div class="col-12 summaryContent">
                <div class="col-3">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestMet}}</label><br>
                  <label class="valuesum"
                    *ngIf="requestMetDiversity; else noValueAvailable">{{requestMetDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!requestMetDiversity">No value</label>
                </div>
                <div class="col-3" *ngIf="showForQuoteOrderBasedOnRequestMet('Yes (with concession)' , true)">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.concessionItems}}</label><br>
                  <label class="valuesum"
                    *ngIf="concessionItemsDiversity; else noValueAvailable">{{concessionItemsDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!concessionItemsDiversity">No
                    value</label>
                </div>
                <div class="col-3" *ngIf="showForQuoteOrderBasedOnRequestMet('No', true)">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.notMetItems}}</label><br>
                  <label class="valuesum"
                    *ngIf="notMetItemsDiversity; else noValueAvailable">{{notMetItemsDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!notMetItemsDiversity">No value</label>

                </div>
                <div class="col-3">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.networkProximity}}</label><br>
                  <label class="valuesum"
                    *ngIf="networkProxDiversity; else noValueAvailable">{{networkProxDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!networkProxDiversity">No value</label>
                </div>
                <div class="col-3">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.indicativeLinkdeliveryDays}}</label><br>
                  <label class="valuesum"
                    *ngIf="indicativeLinkdeliveryDaysDiversity; else noValueAvailable">{{indicativeLinkdeliveryDaysDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!indicativeLinkdeliveryDaysDiversity">No
                    value</label>
                </div>
              </div>
            </div>
          </div>

          <div class="expandable assessment-outcome-tab mt-25" *ngIf="(!isOrder && serviceType == 'RG')">
            <div class="headerCont">
              <label class="productLabel">{{aemLabelAuthoring?.pageHeader?.pricing}}</label>
            </div>
            <div class="assessment-outcome-content">
              <div class="col-12 summaryContent">
                <div class="col-3">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.totalCapitalCost}}</label><br>
                  <label class="valuesum"
                    *ngIf="totalCapitalCostDiversity; else noValueAvailable">{{totalCapitalCostDiversity | currency
                    }}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!totalCapitalCostDiversity">No
                    value</label>
                </div>
                <div class="col-3">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.lateralBenefitValue}}</label><br>
                  <label class="valuesum"
                    *ngIf="lateralBenefitValueDiversity; else noValueAvailable">{{lateralBenefitValueDiversity |
                    currency }}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!lateralBenefitValueDiversity">No
                    value</label>
                </div>
                <div class="col-3">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.siteAssessmentCost}}</label><br>
                  <label class="valuesum"
                    *ngIf="siteAssessmentCostDiversity; else noValueAvailable">{{siteAssessmentCostDiversity |
                    currency }}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!siteAssessmentCostDiversity">No
                    value</label>
                </div>
              </div>
              <div class="col-12 summaryContent">
                <div class="col-3">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.monthlyRecurringICACharge}}</label><br>
                  <label class="valuesum"
                    *ngIf="monthlyRecurringICAChargeDiversity; else noValueAvailable">{{monthlyRecurringICAChargeDiversity

                    }}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!monthlyRecurringICAChargeDiversity">No
                    value</label>
                </div>
                <div class="col-3">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.monthlyRecurringCharge}}</label><br>
                  <label class="valuesum"
                    *ngIf="monthlyRecurringChargeDiversity; else noValueAvailable">{{monthlyRecurringChargeDiversity |
                    currency }}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!monthlyRecurringChargeDiversity">No
                    value</label>
                </div>
                <div class="col-3">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.totalMonthlyRecurringCharge}}</label><br>
                  <label class="valuesum"
                    *ngIf="totalMonthlyRecurringChargeDiversity; else noValueAvailable">{{totalMonthlyRecurringChargeDiversity

                    }}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!totalMonthlyRecurringChargeDiversity">No
                    value</label>
                </div>
              </div>
            </div>
          </div>

          <div class="expandable assessment-outcome-tab mt-25" *ngIf="isOrder && serviceType !== 'RG'">
            <div class="headerCont">
              <label class="productLabel">{{aemLabelAuthoring?.pageHeader?.solution}}</label>
            </div>
            <div class="assessment-outcome-content">
              <div class="col-12 summaryContent">
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofr}}</label><br>
                  <label class="valuesum" *ngIf="rofrDiversity; else noValueAvailable">{{rofrDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!rofrDiversity">No value</label>
                </div>
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFibreSize}}</label><br>
                  <label class="valuesum"
                    *ngIf="rofrFibreSizeDiversity; else noValueAvailable">{{rofrFibreSizeDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!rofrFibreSizeDiversity">No value</label>
                </div>
                <div class="col-2 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFAPId}}</label><br>
                  <label class="valuesum"
                    *ngIf="rofrFAPIdDiversity; else noValueAvailable">{{rofrFAPIdDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!rofrFAPIdDiversity">No value</label>
                </div>
                <div class="col-2 solution-custom-width rofr-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFAPLatitude}}</label><br>
                  <label class="valuesum"
                    *ngIf="rofrFAPLatitudeDiversity; else noValueAvailable">{{rofrFAPLatitudeDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!rofrFAPLatitudeDiversity">No
                    value</label>
                </div>
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFAPLongitude}}</label><br>
                  <label class="valuesum"
                    *ngIf="rofrFAPLongitudeDiversity; else noValueAvailable">{{rofrFAPLongitudeDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!rofrFAPLongitudeDiversity">No
                    value</label>
                </div>
              </div>
              <div class="col-12 summaryContent">
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.totalFibreLength}}</label><br>
                  <label class="valuesum"
                    *ngIf="totalFibreLengthDiversity; else noValueAvailable">{{totalFibreLengthDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!totalFibreLengthDiversity">No
                    value</label>
                </div>
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.newFibreHaulLength}}</label><br>
                  <label class="valuesum"
                    *ngIf="newFibreHaulLengthDiversity; else noValueAvailable">{{newFibreHaulLengthDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!newFibreHaulLengthDiversity">No
                    value</label>
                </div>
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.newFibreBuriedLength}}</label><br>
                  <label class="valuesum"
                    *ngIf="newFibreBuriedLengthDiversity; else noValueAvailable">{{newFibreBuriedLengthDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!newFibreBuriedLengthDiversity">No
                    value</label>
                </div>
              </div>
              <div class="col-12 summaryContent">
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.newConduitLength}}</label><br>
                  <label class="valuesum"
                    *ngIf="newConduitLengthDiversity; else noValueAvailable">{{newConduitLengthDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!newConduitLengthDiversity">No
                    value</label>
                </div>
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leasedConduitLength}}</label><br>
                  <label class="valuesum"
                    *ngIf="leasedConduitLengthDiversity; else noValueAvailable">{{leasedConduitLengthDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!leasedConduitLengthDiversity">No
                    value</label>
                </div>
                <div class="solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leasedConduitTerm}}</label><br>
                  <label class="valuesum"
                    *ngIf="leasedConduitTermDiversity; else noValueAvailable">{{leasedConduitTermDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!leasedConduitTermDiversity">No
                    value</label>
                </div>
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leasedConduitOwner}}</label><br>
                  <label class="valuesum"
                    *ngIf="leasedConduitOwnerDiversity; else noValueAvailable">{{leasedConduitOwnerDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!leasedConduitOwnerDiversity">No
                    value</label>
                </div>
              </div>
              <div class="col-12 summaryContent">
                <div class="col-3 solution-custom-width" *ngFor="let val of estimatedLoss">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.estimatedLoss}} {{val.wavelength}}
                    (dB)</label><br>
                  <label class="valuesum" *ngIf="val.dbValue; else noValueAvailable">{{val.dbValue}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!val.dbValue">No value</label>
                </div>
              </div>
              <div class="col-12 summaryContent">
                <div class="col-12">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestorSolutionSummary}}</label><br>
                  <label class="valuesum summary-text-wrap"
                    *ngIf="requestorSolutionSummaryDiversity; else noValueAvailable">{{requestorSolutionSummaryDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!requestorSolutionSummaryDiversity">No
                    value</label>
                </div>
              </div>
              <div class="col-12 summaryContent">
                <div class="col-12">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.solutionSummary}}</label><br>
                  <label class="valuesum summary-text-wrap"
                    *ngIf="solutionSummaryDiversity; else noValueAvailable">{{solutionSummaryDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!solutionSummaryDiversity">No
                    value</label>
                </div>
              </div>
            </div>
            <!-- START: Proceed to Order section Path 2 for RG quote-->
            <div class="orderBtn" *ngIf="(!isOrder && serviceType == 'RG')">
              <button type="button" id="newQuotebtn" class="primary-globalbtn-cls orderBtnStl"
                [attr.aria-label]="aemLabelAuthoring.pageHeader.assessmentoutcomePath2 + ' Proceed to Order'"
                (click)="proceedToOrder('Path2')"
                *ngIf="isReadWriteOnOrderManagement && (status?.toLowerCase() === 'quote ready' || status?.toLowerCase() === 'quote accepted' ||
                status?.toLowerCase() == 'cancelled' || status?.toLowerCase() === 'ordered' || status?.toLowerCase() === 'expired')"
                [disabled]="status?.toLowerCase() === 'cancelled' || status?.toLowerCase() === 'ordered'
                 || status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'expired' ||
                quoteDetails?.quoteAcceptedFrom?.toLowerCase() === 'path2' || quoteDetails?.quoteAcceptedFrom?.toLowerCase() === 'both' || sentEnqPath1">
                {{aemLabelAuthoring?.pageHeader?.proceedToOrder}}
              </button>
            </div>
            <!-- END: Proceed to Order section Path 2 for RG quote-->
          </div>
          <!-- END: Solution section for Non RG -->

          <!-- START: Solution section for quote & RG -->
          <div class="expandable assessment-outcome-tab mt-25" *ngIf="(serviceType == 'RG')">
            <div class="headerCont">
              <label class="productLabel">{{aemLabelAuthoring?.pageHeader?.solution}}</label>
            </div>
            <div class="assessment-outcome-content">
              <div class="col-12 summaryContent">
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofr}}</label><br>
                  <label class="valuesum" *ngIf="rofrDiversity; else noValueAvailable">{{rofrDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!rofrDiversity">No value</label>
                </div>
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFibreSize}}</label><br>
                  <label class="valuesum"
                    *ngIf="rofrFibreSizeDiversity; else noValueAvailable">{{rofrFibreSizeDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!rofrFibreSizeDiversity">No value</label>
                </div>
                <div class="col-2 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFAPId}}</label><br>
                  <label class="valuesum"
                    *ngIf="rofrFAPIdDiversity; else noValueAvailable">{{rofrFAPIdDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!rofrFAPIdDiversity">No value</label>
                </div>
                <div class="col-2 solution-custom-width rofr-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFAPLatitude}}</label><br>
                  <label class="valuesum"
                    *ngIf="rofrFAPLatitudeDiversity; else noValueAvailable">{{rofrFAPLatitudeDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!rofrFAPLatitudeDiversity">No
                    value</label>
                </div>
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.rofrFAPLongitude}}</label><br>
                  <label class="valuesum"
                    *ngIf="rofrFAPLongitudeDiversity; else noValueAvailable">{{rofrFAPLongitudeDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!rofrFAPLongitudeDiversity">No
                    value</label>
                </div>
              </div>
              <div class="col-12 summaryContent">
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.totalFibreLength}}</label><br>
                  <label class="valuesum"
                    *ngIf="totalFibreLengthDiversity; else noValueAvailable">{{totalFibreLengthDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!totalFibreLengthDiversity">No
                    value</label>
                </div>
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.newFibreHaulLength}}</label><br>
                  <label class="valuesum"
                    *ngIf="newFibreHaulLengthDiversity; else noValueAvailable">{{newFibreHaulLengthDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!newFibreHaulLengthDiversity">No
                    value</label>
                </div>
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.newFibreBuriedLength}}</label><br>
                  <label class="valuesum"
                    *ngIf="newFibreBuriedLengthDiversity; else noValueAvailable">{{newFibreBuriedLengthDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!newFibreBuriedLengthDiversity">No
                    value</label>
                </div>
              </div>
              <div class="col-12 summaryContent">
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.newConduitLength}}</label><br>
                  <label class="valuesum"
                    *ngIf="newConduitLengthDiversity; else noValueAvailable">{{newConduitLengthDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!newConduitLengthDiversity">No
                    value</label>
                </div>
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leasedConduitLength}}</label><br>
                  <label class="valuesum"
                    *ngIf="leasedConduitLengthDiversity; else noValueAvailable">{{leasedConduitLengthDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!leasedConduitLengthDiversity">No
                    value</label>
                </div>
                <div class="solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leasedConduitTerm}}</label><br>
                  <label class="valuesum"
                    *ngIf="leasedConduitTerm; else noValueAvailable">{{leasedConduitTermDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!leasedConduitTermDiversity">No
                    value</label>
                </div>
                <div class="col-3 solution-custom-width">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.leasedConduitOwner}}</label><br>
                  <label class="valuesum"
                    *ngIf="leasedConduitOwner; else noValueAvailable">{{leasedConduitOwnerDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!leasedConduitOwnerDiversity">No
                    value</label>
                </div>
              </div>
              <div class="col-12 summaryContent">
                <div class="col-3 solution-custom-width" *ngFor="let val of estimatedLossDiversity">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.estimatedLoss}} {{val.wavelength}}
                    (dB)</label><br>
                  <label class="valuesum" *ngIf="val.dbValue; else noValueAvailable">{{val.dbValue}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!val.dbValue">No value</label>
                </div>
              </div>
              <div class="col-12 summaryContent">
                <div class="col-12">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.requestorSolutionSummary}}</label><br>
                  <label class="valuesum summary-text-wrap"
                    *ngIf="requestorSolutionSummaryDiversity; else noValueAvailable">{{requestorSolutionSummaryDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!requestorSolutionSummaryDiversity">No
                    value</label>
                </div>
              </div>
              <div class="col-12 summaryContent">
                <div class="col-12">
                  <label class="headersum">{{aemLabelAuthoring?.pageHeader?.solutionSummary}}</label><br>
                  <label class="valuesum summary-text-wrap"
                    *ngIf="solutionSummaryDiversity; else noValueAvailable">{{solutionSummaryDiversity}}</label>
                  <label aria-live="assertive" class="visually-hidden" *ngIf="!solutionSummaryDiversity">No
                    value</label>
                </div>
              </div>
            </div>
          </div>
            <!-- END: Solution section for quote & RG -->

             <!-- START: Cable details show only for quote & RG -->
          <div class="expandable" role="button"  *ngIf="(serviceType == 'RG')">
            <div class="headerCont" tabindex="0" (keypress)="toggleContent10()" (click)="toggleContent10()"
              [attr.aria-label]="isExpanded10 ? 'Expanded ' + aemLabelAuthoring?.pageHeader?.CableDetails + ' button' : 'Collapsed ' +  aemLabelAuthoring?.pageHeader?.CableDetails + ' button'">
              <i class="icon" [ngClass]="{'expanded': isExpanded10}"></i>
              <label class="productLabel" role="link">{{aemLabelAuthoring?.pageHeader?.CableDetails}}</label>
            </div>
            <div class="grid-content" [hidden]="!isExpanded10">
              <div class="grid-container cable-detail-grid" *ngIf="gridInitialized1">
                <app-cui-ag-grid [model]="gridModel1">
                </app-cui-ag-grid>
              </div>
            </div>
          </div>
          <!-- END: Cable details show only for quote & RG -->

          <!-- START: Proceed to Order section Path 2 for non-RG quote-->
          <div class="orderBtn" *ngIf="(!isOrder && serviceType == 'RG')">
            <button type="button" id="newQuotebtn" class="primary-globalbtn-cls orderBtnStl"
              [attr.aria-label]="showDiversity ? aemLabelAuthoring?.pageHeader?.assessmentoutcomePath1 +' Proceed to Order' : ' Proceed to Order'"
              (click)="proceedToOrder('Path2')"
              *ngIf="isReadWriteOnOrderManagement && (status?.toLowerCase() === 'quote ready' || status?.toLowerCase() === 'quote accepted'
              || status?.toLowerCase() == 'cancelled' || status?.toLowerCase() === 'ordered' || status?.toLowerCase() === 'expired')"
              [disabled]="status?.toLowerCase() == 'cancelled' || status?.toLowerCase() === 'ordered' || status?.toLowerCase() === 'expired'
               || status?.toLowerCase() === 'quote accepted' || quoteDetails?.quoteAcceptedFrom?.toLowerCase() === 'path2' ||
                quoteDetails?.quoteAcceptedFrom?.toLowerCase() === 'both'">
              {{aemLabelAuthoring?.pageHeader?.proceedToOrder}}
            </button>
          </div>
          <!-- END: Proceed to Order section Path 2 for non-RG quote-->
          <!-- START: Non-RG Quote Ready Predefined -->

          <div class="col-12 summaryContent" *ngIf="(!isOrder && serviceType !== 'RG')">
            <div class="col-3 solution-custom-width estimatedDisnonRg">
              <label class="headersum">{{aemLabelAuthoring?.pageHeader?.estimatedDistance}}</label><br>
              <label class="valuesum"
                *ngIf="preferredDistanceDiversity; else noValueAvailable">{{preferredDistanceDiversity}}</label>
              <label aria-live="assertive" class="visually-hidden" *ngIf="!preferredDistanceDiversity">No value</label>
            </div>
            <div class="col-3 solution-custom-width">
              <label class="headersum">{{aemLabelAuthoring?.pageHeader?.networkProximity}}</label><br>
              <label class="valuesum"
                *ngIf="networkProxDiversity; else noValueAvailable">{{networkProxDiversity}}</label>
              <label aria-live="assertive" class="visually-hidden" *ngIf="!networkProxDiversity">No value</label>
            </div>


          </div>
          <div class="col-5" *ngIf="(!isOrder && serviceType !== 'RG' && estimatedLossDiversity && estimatedLossDiversity.length > 0)">
            <label class="nonrgOpticalloss">{{aemLabelAuthoring?.pageHeader?.estimatedOpticalLoss}}</label><br>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="headersum">{{aemLabelAuthoring?.pageHeader?.waveLength}}</th>
                  <th class="headersum">{{aemLabelAuthoring?.pageHeader?.valueDb}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ref of estimatedLossDiversity">
                  <th class="valuesum">{{ref.wavelength}}</th>
                  <td class="valuesum">{{ref.dbValue}}</td>
                </tr>
              </tbody>
            </table>
            <!-- <div class="col-3">
            <div class="valuesum ml-7" *ngFor="let val of externalReferenceVal">{{val}}</div><br> -->
          </div>
          <form [formGroup]="quoteTermFormPath2" class="row product-details-form-container summaryTerm" *ngIf="(!isOrder && serviceType !== 'RG') &&
           !(status?.toLowerCase() === 'assessment complete')">
           <ng-container>
            <div class="radio-btn-container">
              <label class="nonrgOpticalloss">{{aemLabelAuthoring?.pageHeader?.Selectterm}}</label><br>
              <mat-radio-group role="radiogroup" class="smart-ui-radio-group terms-radio-group display-radio-grp"
                formControlName="terms"
                [disabled]="(status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'ordered')">
                <mat-radio-button role="radio" [ngClass]="{'disabledRadio': (radioValuePath2 !== 3 && (status?.toLowerCase() === 'quote accepted' ||
              status?.toLowerCase() === 'ordered' || !isReadWriteOnOrderManagement || sentEnqPath1)),
               'checkedClass' : radioValuePath2 === 3,
                'mat-radio-disabled-checked': (radioValuePath2 === 3 && (status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'ordered'  ||
                 status?.toLowerCase() === 'expired' || !isReadWriteOnOrderManagement || sentEnqPath1))}"
                   (click)="termSelect('path2',3)" (keypress)="termSelect('path2',3)" id="termsRadio1"
                  class="col-2 smart-ui-button-group terms-radio-btn align-items-center" [value]="3"
                  [disabled]="!isReadWriteOnOrderManagement || sentEnqPath1" (change)="radioChangePath2($event)"
                  [attr.aria-checked]="(radioValuePath2 === 3) ? 'true' : 'false'">
                  <div class="mat-custom-label" tabindex="0" (keypress)="termSelect('path2',3)" >
                    <div class="charge">{{threeYearPriceMonthDiversity}}<label class="month"> / month</label></div>
                    <div class="yearsSlc" aria-label="Terms required 3 years">3 years</div>
                    <div class="nonrecurCharge">{{aemLabelAuthoring?.pageHeader?.nonRecurringCharge}}:
                      {{nonRecurringChargeDiversity}}</div>
                  </div>
                </mat-radio-button>
                <mat-radio-button role="radio" [ngClass]="{'disabledRadio': (radioValuePath2 !== 5 && (status?.toLowerCase() === 'quote accepted'
              || status?.toLowerCase() === 'expired' || status?.toLowerCase() === 'ordered' || !isReadWriteOnOrderManagement || sentEnqPath1)),
              'checkedClass' : radioValuePath2 === 5,
               'mat-radio-disabled-checked': (radioValuePath2 === 5 && (status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'ordered'
               || status?.toLowerCase() === 'expired' || !isReadWriteOnOrderManagement || sentEnqPath1))}"
                  (click)="termSelect('path2',5)" (keypress)="termSelect('path2',5)" id="termsRadio2"
                  class="col-2 smart-ui-button-group terms-radio-btn align-items-center" [value]="5"
                  [attr.aria-checked]="(radioValuePath2 === 5) ? 'true' : 'false'"
                  [disabled]="!isReadWriteOnOrderManagement || sentEnqPath1" (change)="radioChangePath2($event)">
                  <div class="mat-custom-label" tabindex="0" (keypress)="termSelect('path2',5)">
                    <div class="charge">{{fiveYearPriceMonthDiversity}}<label class="month"> / month</label></div>
                    <div class="yearsSlc">5 years</div>
                    <div class="nonrecurCharge">{{aemLabelAuthoring?.pageHeader?.nonRecurringCharge}}: {{nonRecurringChargeDiversity}}</div>
                  </div>
                </mat-radio-button>
                <mat-radio-button  role="radio" *ngIf="custTerm"
                  [ngClass]="{'disabledRadio': (radioValuePath2 !== custTerm &&
               (status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'ordered'|| status?.toLowerCase() === 'expired'
                || !isReadWriteOnOrderManagement || sentEnqPath1)),
              'checkedClass' : radioValuePath2 === custTerm,
               'mat-radio-disabled-checked': (radioValuePath2 === custTerm && (status?.toLowerCase() === 'quote accepted' ||
               status?.toLowerCase() === 'ordered' || status?.toLowerCase() === 'expired' || !isReadWriteOnOrderManagement || sentEnqPath1))}"
                  (click)="termSelect('path2',custTerm)" (keypress)="termSelect('path2',custTerm)"
                  id="termsRadio3" class="col-2 smart-ui-button-group terms-radio-btn align-items-center"
                  [value]="custTerm" [disabled]="!isReadWriteOnOrderManagement || sentEnqPath1"
                  (change)="radioChangePath2($event)"
                  [attr.aria-checked]="(radioValuePath2 === custTerm) ? 'true' : 'false'">
                  <div class="mat-custom-label" tabindex="0" (keypress)="termSelect('path2',custTerm)" >
                    <div class="charge">{{customYearMonthDiversity}}<label class="month"> / month</label></div>
                    <div class="yearsSlc">{{custTerm}} years</div>
                    <div class="nonrecurCharge">{{aemLabelAuthoring?.pageHeader?.nonRecurringCharge}}: {{nonRecurringChargeDiversity}}</div>
                  </div>
                </mat-radio-button>
                <mat-radio-button *ngIf="!custTerm || custTerm === ''" [ngClass]="{'disabledRadio':(status?.toLowerCase() === 'quote accepted' ||
               status?.toLowerCase() === 'expired' || !isReadWriteOnOrderManagement || sentEnqPath1 )}"
                  (click)="checkRadioButton('path2', 'customRadio')"
                  (keypress)="checkRadioButton('path2', 'customRadio')" id="termsRadio3"
                  class="col-2 smart-ui-button-group terms-radio-btn custom-term-radio" [value]="10"
                  [disabled]="true">
                  <div class="mat-custom-label cusTermRadio" tabindex="0" (keypress)="checkRadioButton('path2', 'customRadio')" >
                    <span>{{aemLabelAuthoring?.pageHeader?.Customcontract}}</span><br>
                    <input [ngClass]="{'inputTermDis': sentEnqPath1 || (status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'ordered'
                  || status?.toLowerCase() === 'expired'),
                  'error': quoteTermFormPath2.get('selectedValue').invalid}"
                     aria-label="custom contract term" class="contractTerm" type="text" formControlName="selectedValue" (input)="getCustomTermPath2()"
                      placeholder="Enter term" /><label [ngClass]="{'inputTermDis': sentEnqPath1 || (status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() === 'expired'),
                  'error': quoteTermFormPath2.get('selectedValue').invalid}"
                      class="contractyearsLabel">year(s)</label><br>
                    <ng-container *ngIf="quoteTermFormPath2.controls.selectedValue.invalid">
                      <div *ngIf="quoteTermFormPath2.controls.selectedValue.invalid" class="text-danger errorMsg">
                        <img class="error-icon alignErrTrm" alt="error icon"
                          src="../../../../assets/images/icon-error.svg" /><span>{{aemLabelAuthoring?.pageHeader?.errTerm}}</span>
                      </div>
                    </ng-container>
                    <span><button
                        [disabled]="quoteTermFormPath2.controls.selectedValue.invalid || sentEnqPath1 || status?.toLowerCase() === 'ordered'
                  || status?.toLowerCase() === 'expired' ||  quoteTermFormPath2.get('selectedValue').value.trim() === ''"
                        style="margin-top: 10px;" class="primary-globalbtn-cls" (click)="sendEnq('path2')">
                        {{aemLabelAuthoring.pageHeader?.sendEnquiry}}</button></span>
                  </div>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </ng-container>
            <div class="orderBtn">
              <button type="button" id="newQuotebtn" style="float:right" class="primary-globalbtn-cls"
                (click)="proceedToOrder('Path2')" *ngIf="isReadWriteOnOrderManagement && (status?.toLowerCase() === 'quote ready' ||
                 status?.toLowerCase() === 'quote accepted' || status?.toLowerCase() == 'expired' || status?.toLowerCase() === 'ordered')"
                [disabled]="isPath2TermSelected || !enableOrderPath2 || disableOrder || status?.toLowerCase() === 'ordered' || status?.toLowerCase() == 'expired' ||
                status?.toLowerCase() === 'quote accepted'
                || quoteDetails?.quoteAcceptedFrom?.toLowerCase() === 'path2' || quoteDetails?.quoteAcceptedFrom?.toLowerCase() === 'both' || sentEnqPath1">
                {{aemLabelAuthoring?.pageHeader?.proceedToOrder}}
              </button>
            </div>
          </form>
          <!-- END: Non-RG Quote Ready Predefined -->
        </mat-tab>
        <!--assesment path2 ends-->
        <mat-tab tabindex="-1" label="History" class="my-custom-class">
          <!-- <ng-template mat-tab-label class="my-custom-class" aria-label="none">
                        <span tabindex="0" aria-label="history" class="my-custom-class">History</span>
                    </ng-template> -->
          <ul id="hisdisplay">
            <li *ngFor="let item of notes; let i = index">
              <div class="row historydetails">
                <div aria-live="assertive" class="visually-hidden">
                  <span role="presentation" style="position:absolute;left:-9999px">list {{i+1}} of
                    {{notes?.length}}</span>
                </div>
                <div class="col-1 dateTime"><span>{{item.date}}</span></div>
                <div class="col-9 textContent">
            <li class="histHeader">{{item.header}}</li>
            <li class="listCol">{{item.text}}</li>
            <br>
            <li class="histHeader">{{item.subHeader}}</li>
            <li class="listCol">{{item.subText}}</li>
    </div>
  </div>
  </li>
  </ul>
  </mat-tab>
  </mat-tab-group>

</div>
<!-- </main> -->
</div>
<ng-template #noValueAvailable><i class="icon-icon-ui-minus"></i></ng-template>

<ng-template #noListValue>
  <ul class="col-5 listStyle">
    <li><i class="icon-icon-ui-minus"></i></li>
  </ul>
</ng-template>
<!-- <div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div> -->
  <div [ngClass]="toastIcon === 'download' ? 'download-toast-icon':'success-toast-icon'">
    <div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div>
  </div>
</div>
<app-html-to-pdf [quoteExpiry]="quoteExpiry" [status]="status" [statusSummary]="statusSummary"
  [aemLabelAuthoring]="aemLabelAuthoring" [quoteRefId]="quoteRefId" [noValueAvailable]="noValueAvailable"
  [custRefId]="custRefId" [responseReq]="responseReq" [lastSub]="lastSub" [requestor]="requestor"
  [requestorChannel]="requestorChannel" [submittedDate]="submittedDate" [showCustName]="showCustName"
  [showSerNum]="showCustName" [requestMet]="requestMet" [requestorSolutionSummary]="requestorSolutionSummary"
  [productRef]="productRef" [subProductRef]="subProductRef" [linkReq]="linkReq" [quantityServ]="quantityServ" [terms]="terms"
  [showDiversity]="showDiversity" [isDiversity]="isdiversity" [requestMetDiversity]="requestMetDiversity"
  [requestorSolutionSummaryDiversity]="requestorSolutionSummaryDiversity"
  [describeYourDiversity]="describeYourDiversity" [aEndLead]="aEndLead" [zEndLead]="zEndLead" [aEndAdress]="aEndAdress"
  [aEndSiteType]="aEndSiteType" [aEndDcOperator]="aEndDcOperator" [aEndDcSiteName]="aEndDcSiteName"
  [aEndConnectorType]="aEndConnectorType" [aEndDemarcationPoint]="aEndDemarcationPoint" [aEndsdpType]="aEndsdpType"
  [aEndAccessType]="aEndAccessType" [aEndAdditionalCabling]="aEndAdditionalCabling" [aEndAdditionalCablingLocation]="aEndAdditionalCablingLocation"
  [aEndAdressSiteAlternativeName]="aEndAdressSiteAlternativeName" [definedPropertyLoca]="definedPropertyLoca"
  [addInformation]="addInformation" [aEndprimaryContactName]="aEndprimaryContactName"
  [aEndprimaryContactNumber]="aEndprimaryContactNumber" [aEndprimaryContactEmail]="aEndprimaryContactEmail"
  [aEndAltContactName]="aEndAltContactName" [aEndAltContactNumber]="aEndAltContactNumber"
  [aEndAltContactEmail]="aEndAltContactEmail" [LatLngSiteZ]="LatLngSiteZ" [zEndAdress]="zEndAdress"
  [zEndSiteType]="zEndSiteType" [zEndDcOperator]="zEndDcOperator" [zEndDcSiteName]="zEndDcSiteName"
  [zEndConnectorType]="zEndConnectorType" [zEndDemarcationPoint]="zEndDemarcationPoint" [zEndsdpType]="zEndsdpType"
  [zEndAccessType]="zEndAccessType" [zEndAdditionalCabling]="zEndAdditionalCabling" [zEndAdditionalCablingLocation]="zEndAdditionalCablingLocation"
  [zEndAdressSiteAlternativeName]="zEndAdressSiteAlternativeName" [zdefinedPropertyLoca]="zdefinedPropertyLoca"
  [zaddInformation]="zaddInformation" [zEndprimaryContactName]="zEndprimaryContactName"
  [zEndprimaryContactNumber]="zEndprimaryContactNumber" [zEndprimaryContactEmail]="zEndprimaryContactEmail"
  [zEndAltContactName]="zEndAltContactName" [zEndAltContactNumber]="zEndAltContactNumber"
  [zEndAltContactEmail]="zEndAltContactEmail" [LatLngSiteA2]="LatLngSiteA2" [a2EndAdress]="a2EndAdress"
  [a2EndSiteType]="a2EndSiteType" [a2EndDcOperator]="a2EndDcOperator" [a2EndDcSiteName]="a2EndDcSiteName"
  [a2EndAdressAlternativeCot]="a2EndAdressAlternativeCot" [a2definedPropertyLoca]="a2definedPropertyLoca"
  [a2addInformation]="a2addInformation" [a2EndprimaryContactName]="a2EndprimaryContactName"
  [a2EndprimaryContactNumber]="a2EndprimaryContactNumber" [a2EndprimaryContactEmail]="a2EndprimaryContactEmail"
  [a2EndAltContactName]="a2EndAltContactName" [a2EndAltContactNumber]="a2EndAltContactNumber"
  [a2EndAltContactEmail]="a2EndAltContactEmail" [LatLngSiteZ2]="LatLngSiteZ2" [z2EndAdress]="z2EndAdress"
  [z2EndSiteType]="z2EndSiteType" [z2EndDcOperator]="z2EndDcOperator" [z2EndDcSiteName]="z2EndDcSiteName"
  [z2EndAdressAlternativeCot]="z2EndAdressAlternativeCot" [z2definedPropertyLoca]="z2definedPropertyLoca"
  [z2addInformation]="z2addInformation" [z2EndprimaryContactName]="z2EndprimaryContactName"
  [z2EndprimaryContactNumber]="z2EndprimaryContactNumber" [z2EndprimaryContactEmail]="z2EndprimaryContactEmail"
  [z2EndAltContactName]="z2EndAltContactName" [z2EndAltContactNumber]="z2EndAltContactNumber"
  [z2EndAltContactEmail]="z2EndAltContactEmail" [businessDriv]="businessDriv" [externalReferences]="externalReferences"
  [maxSignalLoss]="maxSignalLoss" [preferredMaxDistance]="preferredMaxDistance" [fiberOpticalLoss]="fiberOpticalLoss"
  [dbVal]="dbVal" [noListValue]="noListValue" [fundingSource]="fundingSource" [l3WBSCodeCapex]="l3WBSCodeCapex"
  [l3WBSCodeOpex]="l3WBSCodeOpex" [otherPowImc]="otherPowImc" [preApprovalCost]="preApprovalCost"
  [concessionItems]="concessionItems" [notMetItems]="notMetItems"
  [indicativeLinkDeliveryTimingClass]="indicativeLinkDeliveryTimingClass"
  [indicativeLinkdeliveryDays]="indicativeLinkdeliveryDays" [totalCapitalCost]="totalCapitalCost"
  [lateralBenefitValue]="lateralBenefitValue" [siteAssessmentCost]="siteAssessmentCost"
  [monthlyRecurringICACharge]="monthlyRecurringICACharge" [monthlyRecurringCharge]="monthlyRecurringCharge"
  [totalMonthlyRecurringCharge]="totalMonthlyRecurringCharge" [rofr]="rofr" [rofrFibreSize]="rofrFibreSize"
  [rofrFAPId]="rofrFAPId" [rofrFAPLatitude]="rofrFAPLatitude" [rofrFAPLongitude]="rofrFAPLongitude"
  [totalFibreLength]="totalFibreLength" [newFibreHaulLength]="newFibreHaulLength"
  [newFibreBuriedLength]="newFibreBuriedLength" [newConduitLength]="newConduitLength"
  [leasedConduitLength]="leasedConduitLength" [leasedConduitTerm]="leasedConduitTerm"
  [leasedConduitOwner]="leasedConduitOwner" [estimatedLoss]="estimatedLoss" [solutionSummary]="solutionSummary"
  [gridConfig]="gridConfig" [gridInitialized]="gridInitialized" [gridModel]="gridModel" [customerName]="customerName"
  [serviceNowReference]="serviceNowReference" [concessionItemsDiversity]="concessionItemsDiversity"
  [notMetItemsDiversity]="notMetItemsDiversity"
  [indicativeLinkDeliveryTimingClassDiversity]="indicativeLinkDeliveryTimingClassDiversity"
  [indicativeLinkdeliveryDaysDiversity]="indicativeLinkdeliveryDaysDiversity"
  [totalCapitalCostDiversity]="totalCapitalCostDiversity" [lateralBenefitValueDiversity]="lateralBenefitValueDiversity"
  [siteAssessmentCostDiversity]="siteAssessmentCostDiversity"
  [monthlyRecurringICAChargeDiversity]="monthlyRecurringICAChargeDiversity"
  [monthlyRecurringChargeDiversity]="monthlyRecurringChargeDiversity"
  [totalMonthlyRecurringChargeDiversity]="totalMonthlyRecurringChargeDiversity" [rofrDiversity]="rofrDiversity"
  [rofrFibreSizeDiversity]="rofrFibreSizeDiversity" [rofrFAPIdDiversity]="rofrFAPIdDiversity"
  [rofrFAPLatitudeDiversity]="rofrFAPLatitudeDiversity" [rofrFAPLongitudeDiversity]="rofrFAPLongitudeDiversity"
  [totalFibreLengthDiversity]="totalFibreLengthDiversity" [newFibreHaulLengthDiversity]="newFibreHaulLengthDiversity"
  [newFibreBuriedLengthDiversity]="newFibreBuriedLengthDiversity"
  [existingInfracoServiceInfo]="existingInfracoServiceInfo" [newConduitLengthDiversity]="newConduitLengthDiversity"
  [leasedConduitLengthDiversity]="leasedConduitLengthDiversity"
  [leasedConduitTermDiversity]="leasedConduitTermDiversity" [leasedConduitOwnerDiversity]="leasedConduitOwnerDiversity"
  [solutionSummaryDiversity]="solutionSummaryDiversity" [gridInitialized1]="gridInitialized1" [gridModel1]="gridModel1"
  [thirdPartyPathInfo]="thirdPartyPathInfo" [customerRequestedDate]="customerRequestedDate"
  [provisionalDateRange]="provisionalDateRange" [ourCommitmentDate]="ourCommitmentDate"
  [networkProximity]="networkProximity" [completedDate]="completedDate" [orderType]="orderType" [serviceId]="serviceId"
  [serviceDeliveryPoint]="serviceDeliveryPoint" [nodeCode]="nodeCode" [poleId]="poleId"
  [leadInRequired]="leadInRequired" [zEndServiceDeliveryPoint]="zEndServiceDeliveryPoint" [zNodeCode]="zNodeCode"
  [zPoleId]="zPoleId" [zLeadInRequired]="zLeadInRequired" [projectReferenceId]="projectReferenceId"
  [opportunityNo]="opportunityNo" [customerContactName]="customerContactName"
  [customerContactNumber]="customerContactNumber" [customerContactEmail]="customerContactEmail" [isOrder]="isOrder"
  [serviceType]='serviceType' [estimatedDistanceRange]='estimatedDistanceRange'
  [nonRecurringCharge]="nonRecurringCharge" [siteName]='siteName' [siteNameZ]='siteNameZ' [clusterId]="clusterId"
  [miragepclId]="miragepclId" [networkProx]="networkProx" [networkProxDiversity]="networkProxDiversity" [preferredDistance]="preferredDistance"
  [threeYearPriceMonth]="threeYearPriceMonth" [fiveYearPriceMonth]="fiveYearPriceMonth"
  [customYearMonth]="customYearMonth" [custTerm]="custTerm" [customYearMonthDiversity]="customYearMonthDiversity"
  [preferredDistanceDiversity]="preferredDistanceDiversity"
  [threeYearPriceMonthDiversity]="threeYearPriceMonthDiversity"
  [fiveYearPriceMonthDiversity]="fiveYearPriceMonthDiversity" [custTermDiversity]="custTerm"
  [estimatedLossDiversity]="estimatedLossDiversity" [selectedTermForNonRGPath1]="radioValuePath1"
  [selectedTermForNonRGPath2]="radioValuePath2" [isPredefinedQuote]="isPredefinedQuote"
  [isReadWriteOnOrderManagement]="isReadWriteOnOrderManagement" [sentEnqPath1]="sentEnqPath1" [orderPath]="orderPath"
  style="display: none;"></app-html-to-pdf>

<app-terms-conditions-modal [type]="proccedType" [quoteId]="quoteRefId" [quoteDetails]="quoteDetails" [sysID]="sysID"
  [tAndcText]="tAndcText" *ngIf="isTermsCondition && proccedType" [showDiversity]="showDiversity"
  [selectedTermForNonRGPath1]="radioValuePath1" [selectedTermForNonRGPath2]="radioValuePath2"
  (onClickClose)="onClickClose($event)">
</app-terms-conditions-modal>


<div class="container-fluid order-details-container">
  <div class="row">
    <h1 class="col-12 page-heading" id="upload" *ngIf="aemLabelAuthoring">
      {{ aemLabelAuthoring.pageHeader.pageHeaderLabel }}
    </h1>
  </div>
  <div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li (keypress)="goToCabinetNav()" tabindex="0" class="breadcrumb-item">
        <a (click)="goToCabinetNav()" role="button"> Cabinets </a>
      </li>
      <i class="icon-icon-ui-right-arrow breadcrumb-icon"></i>
      <li tabindex="0" *ngIf="cabinetName" class="breadcrumb-item" (keypress)="gotoListPage()" (click)="gotoListPage()">
        <a role="button"> {{cabinetName}} </a>
      </li>
      <i class="icon-icon-ui-right-arrow breadcrumb-icon"></i>
      <li class="breadcrumb-item active" aria-current="page">
        <span> Upload new document</span>
      </li>
    </ol>
  </nav>
  <div class="row">
    <div class="col-12">
      <h2 class="headings" *ngIf="aemLabelAuthoring">
        {{ aemLabelAuthoring.pageHeader.pageSubHeaderLabel }}
      </h2>
    </div>
  </div>
  <hr>
  <p class="desc">Please select the document you wish to upload, then complete the fields before clicking the "Upload
    document"
    button below</p>
  <div class="form-group">
    <div class="row upload-document-list pt-2" *ngIf="aemLabelAuthoring">
      <div class="col-sm-4 col-lg-4 col-md-4 pb-2">
        <div class="row">
          <div class="col-12 pb-3">
            <label class="user-details-label-top required-field" automationType="label" automationId="lblrole"
              for="documentTypeId" attr.aria-label="{{aemLabelAuthoring.form.documentTypeModel}}">
              {{ aemLabelAuthoring.form.documentTypeModel }}
            </label>
            <app-cui-searchable-dropdown #businessTypes [(ngModel)]="selectedDocumentType" name="selectedDocumentType"
              [items]="documentTypeList" [isRequired]=true aria-required="true" [model]="documentTypeModel"
              [placeholder]="aemLabelAuthoring.form.documentTypePlaceholder" automationType="dropdown"
              automationId="drpdwnroleType" [labelName]="aemLabelAuthoring.form.documentTypeModel"
              [noAutoComplete]=false id="documentTypeId" class="user-dropDown" required></app-cui-searchable-dropdown>
          </div>
          <div class="col-12 pb-2 pt-3">
            <label class="user-details-label-top" for="keyword">Enter keywords</label>
            <ng-template #dateTipContent>
              <app-cui-tooltip-content [model]="dateTooltipContentModel"></app-cui-tooltip-content>
            </ng-template>
            <app-cui-info-icon [ngbTooltip]="dateTipContent" [content]="dateTooltipContentModel.content"
              automationType="anchor" automationId="anchorAddOrderDateInfo" placement="right"
              #tooltipCustRd="ngbTooltip" (focusin)="tooltipCustRd.open()" (focusout)="tooltipCustRd.close()"
              class="infoIcon">
            </app-cui-info-icon>
            <input type="text" class="field-input" maxlength="250" [(ngModel)]="documentKeyword" id="keyword"
              name="keyword" placeholder="Enter keywords" attr.aria-label="document title">
            <div class="text-right">
              <span class="text-right-blue">{{documentKeyword ? documentKeyword.length : 0}}/250</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4 col-lg-4 col-md-4 pb-2">
        <label class="user-details-label-top" for="DocDesc1">Comments</label>
        <textarea class="form-control textarea" maxlength="100" [(ngModel)]="comments" rows="6" id="DocDesc1"
          name="DocDesc1" attr.aria-label="document description" placeholder="Part of the CRM on demand"></textarea>
        <div class="text-right">
          <span class="text-right-blue">{{comments ? comments.length : 0}}/100</span>
        </div>
      </div>
    </div>
  </div>
  <div class="upload-document" *ngIf="aemLabelAuthoring">
    <div class="col-12 pb-3">
      <label class="user-details-label-top required-field" for="DocTitle1" aria-required="true" required>Document
        title</label>
      <input type="text" class="field-input" maxlength="100" [(ngModel)]="documentTitle" id="DocTitle1" name="DocTitle1"
        placeholder="Document title" attr.aria-label="document title" [disabled]="true">
      <div class="text-right">
        <span class="text-right-blue">{{documentTitle ? documentTitle.length : 0}}/100</span>
      </div>
    </div>



    <div class="col-12 pb-2 pt-3">
      <label aria-label="File" aria-required="true" class="user-details-label-top required-field" required>File</label>
      




      <div class="uploadfilecontainer" appFileUpload [allowSingle]="true" (onFileDropped)="uploadFile($event)">
        <input hidden type="file" #fileInput (change)="uploadFile($event.target.files)" (click)="fileInput.value=null"
          required>
        <div class="center-content">
          <img id="upload_to_cloud" src="../../../assets/images/Icon-Import-btn.svg" alt="upload_to_cloud">
          <br />
          <h3 class="uploadHeader">Drag and drop file or</h3>
          <p class="uploadHeader text">( xls, xlsx, csv, doc, docx, pdf, jpeg, jpg, png, ico, bmp, pptx, zip, txt, eml,
            msg, json, xml )</p>
          <button tabindex="0" [disabled]="uploadedFile.fileName" aria-label="File browse button" class="browse-btn"
            (click)="fileInput.click()">Browse</button>
          <p class="mb-0 mt-1 uploadHeader size">Maximum file size 25MB</p>
          <p class="uploadHeader waitWarning">Uploading may take a few minutes. Please do not move away from this screen
            whilst this
            action is underway.</p>
        </div>
      </div>

      <div >
       <p class="upload-validation-error" *ngIf="uploadedFile.validationError"> 
          <img class="error-icon" src="../../../assets/images/icon-error.svg" alt="error icon" />
          {{uploadedFile.validationError}}
        </p>
        <div class="files-list mb-2" *ngIf="uploadedFile.fileName">
          <p class="mb-0"> {{uploadedFile.fileName}} </p>
        
          <!--       <div class="file-progress" *ngIf="uploadedFile.canShowProgressBar">
                    <app-cui-progress-bar [min]="0" [max]="100" [model]="progressBarModel" [isDone]="uploadedFile.isUploadDone">
                    </app-cui-progress-bar>
                  </div> -->
        
          <div class="file-progress" *ngIf="uploadedFile.canShowProgressBar">
            <!-- *ngIf="!error && uploadProgress" -->
            <ng-conatiner *ngIf="!error && uploadProgress">
              <progress id="file" [value]="uploadProgress" [max]="100"> </progress>
            </ng-conatiner>
          </div>

          <button role="button" aria-label="delete file" class="delete-file" (click)="deleteAttachment()">
            <i class="icon-icon-ui-cross icon-sm file-cross-btn"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="btnRow" *ngIf="aemLabelAuthoring">
    <div class="button-container">
      <div class="submitCancelBtn">
        <button role="button" aria-label="cancel" type="button" class="app-cancel-btn" (click)="onClickCancel()">
          <i class="icon-icon-ui-cross"></i>
          {{aemLabelAuthoring.form.cancel}}
        </button>
        <!-- <button type="button" class="app-save-btn" [disabled]="checkDisabled()" (click)="onClickSave()" -->
          <button mat-button class="app-btn-green save-cancel-btn pull-right" [disabled]="checkDisabled()" (click)="onClickSave()"
          [ngClass]="{'enableSaveBtn': !checkDisabled()}">
          {{aemLabelAuthoring.form.save}}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" role="dialog" aria-modal="true" aria-haspopup="dialog" *ngIf="aemLabelAuthoring"
  [ngClass]="{'cancelUploadModal':isModalOpen}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h3 class="head">{{'Replace?'}}</h3>
            <div class="closeBtn">
              <span (click)="closeModal()" class="cancelBtn close" id="cls" tabindex="0" aria-label="Close"
                role="button">
                <i class="icon-icon-ui-cross"></i>
              </span>
            </div>
          </div>
          <div class="col-12">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
              <p class="user-details-label-top" automationType="label" automationId="lblrole">
                A file with this name is already exists. Would you like to replace?
              </p>
              <div class="btnRow">
                <div class="submitCancelBtn">
                  <button type="button" class="cancelBtn" (click)="onClickReplace()">{{'Replace'}}</button>
                  <button type="button" tabindex="0" id="cancel" class="app-save-btn"
                    (click)="closeModal()">{{'Cancel'}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" role="dialog" aria-label="cancel upload" aria-modal="true"
  [ngClass]="{'cancelUploadModal':isCancelModalOpen}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="container">
        <div class="row" *ngIf="isCancelModalOpen">
          <div class="closeBtn">
            <span (click)="closeUploadModal()" class="cancelBtn close" id="abc" tabindex="0" aria-label="Close"
              role="button">
              <i class="icon-icon-ui-cross"></i>
            </span>
          </div>
          <div class="col-12">
            <h1 class="head">Cancel Upload?</h1>

          </div>
          <div class="col-12">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
              <p class="user-details-label-top" automationType="label" automationId="lblrole">
                Are you sure you want to cancel your upload?
              </p>
            </div>
            <div class="btnRow">
              <div class="submitCancelBtn">
                <button type="button" class="cancelBtn" (click)="closeUploadModal()">
                  Don't cancel
                </button>
                <button type="button" tabindex="0" id="yes" class="app-save-btn" (click)="onSubmitDomain()">
                  Yes, cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit, ViewChild, HostListener, OnDestroy, Inject, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { forkJoin, Subscription } from 'rxjs';
import * as moment_ from 'moment-timezone';
import { CheckboxFilterComponent } from 'src/app/grid-filter/checkbox-filter/checkbox-filter.component';
import { CustomDateFilterComponent } from 'src/app/grid-filter/custom-date-filter/custom-date-filter.component';
import { CustomTextFilterComponent } from 'src/app/grid-filter/custom-text-filter/custom-text-filter.component';
import { CommonService } from 'src/app/shared/services/common.service';
import { myQuotesService } from './myquotes.service';
import { WorklistStatusRendererComponent } from './worklist-status-renderer/worklist-status-renderer.component';
import { CreateQuoteService } from '../create-new-quote/create-quote.service';
import { CustomerService } from 'src/app/admin-settings/customer-list/customer.service';
import { HomePageService } from 'src/app/shared/services/homePage.service';
import { AuthService } from 'tw-core-ui';
import { TitleCasePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { MapsService } from 'src/app/maps.service';

@Component({
  selector: 'app-my-quotes',
  templateUrl: './my-quotes.component.html',
  styleUrls: ['./my-quotes.component.less'],
  providers: [myQuotesService, TitleCasePipe]
})
export class MyQuotesComponent implements OnInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  public currentlyCheckedCount = 0;
  showFilterPop: boolean = false;
  myLinkedCustomerList: any;
  currentMoment = moment_;
  show: boolean = false;
  moment = moment_;
  placeholderFontSize = 8;
  colDefsAthleteEXcluded: {
  }[];
  currentSelected: { field: String; colId: string; tooltipField: string; filter: string; headerName: string; cellRendererFramework: any, fullHeaderName: string; customFilter: any, width: any, minWidth: any, maxWidth: any };
  checkedList: any[];
  public headerSubs: Subscription;
  isShowAwait: boolean = false;
  stopSelect: boolean = false;
  public colDefsAthleteIncluded;
  functionList: any;
  public searchvalue;
  public gridConfig: GridOptions;
  public businessList = [];
  public rowData: any;
  public columnDefs: any;
  // infraCoCIDN = '9999999999';
  infraCoCIDN = window['environment'].InfraCoCIDN;
  public gridInitialized: Boolean;
  public aemLabelAuthoring: any;
  subscription: any;
  subscribedflag: Boolean = false;
  public options: GlobalConfig;
  public gridModel: any = {
    // CustomAgGrid = {
    gridOptions: {},
    keysToSuppress: [13], // Supress Enter key row selection
    labelAuthoring: {
      headerPageCount: '',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: '',
      noDataOnSearch: '',
      toTop: ''
    }
  };
  public filteredRowData: any = [];
  timeoutId: any;
  quoteStatus: number;
  columnHeaderId = [];
  cidnSelected: string;
  columnsPresent: boolean = true;
  companyName: any;
  linkedCIDNs: any;
  isReadWriteOnQuoteMgmt: boolean = false;
  cidn: any;
  disableDwn: Boolean = false;
  selectedRows: any[] = [];
  userName: any;
  isReadOnlyRole: boolean = false;
  userTimeZone: string;
  focusableElement: HTMLElement;
  public serviceType: string;

  constructor(private dataSvc: myQuotesService, private router: Router, private commonService: CommonService,
    private createQuoteService: CreateQuoteService, private customerService: CustomerService, private homepageservice: HomePageService,
    private toastr: ToastrService, private mapService: MapsService, private authService: AuthService, @Inject(LOCALE_ID) private locale: string, public titleCasePipe: TitleCasePipe, private titleService: Title) {

    // Get Google maps key from API before going to site details page
    this.mapService.fetchGoogleMapsApiKey();
    this.titleService.setTitle('InfraCo Customer Portal -MyQuotes page');

    localStorage.removeItem('patchDataAEndAdress');
    localStorage.removeItem('patchDataZEndAdress');
    localStorage.removeItem('patchDataA2EndAdress');
    localStorage.removeItem('patchDataZ2EndAdress');

    this.getUserTimeZone();
    if (localStorage.getItem('selectedHeaderCIDN')) {
      this.cidnSelected = localStorage.getItem('selectedHeaderCIDN')
    } else {
      this.getCommonServices();
      //this.cidnSelected = '9999999998'
    }
    this.options = this.toastr.toastrConfig;
    this.checkedList = [];
    if (this.cidnSelected?.length) {
      this.getProductCatalogDataFromAPI();
    }
    // will be called inside productCatalog api
    // this.createGrid(null); //initialization call
    // this.cidnSelected = this.commonService.selectedCidn;
    // this.cidnSelected = '9999999998';

    // code for header change in myquotes
    this.customerService.getLinkedCIDNs().then(res => {
      if (res && res.data && res.data.businessNameDetails && res.data.businessNameDetails.length > 0) {
        // let businessList = [];
        const iteratedArr = res.data.businessNameDetails.map(({
          bussinessName,
          cidn
        }) => [bussinessName, cidn]);

        for (var i = 0; i < iteratedArr.length; i++) {
          const businessName = iteratedArr[i][0];
          const cidn = iteratedArr[i][1];
          if (businessName || cidn) {
            this.businessList.push({
              businessName: businessName,
              cidn: cidn
            });
          }
        }
      }
      else {
        this.userName = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail']
        this.homepageservice
          .getUserProfile(this.userName)
          .then((response) => {
            this.myLinkedCustomerList = response?.data?.customerDetails;
            if (this.myLinkedCustomerList) {
              this.myLinkedCustomerList.forEach((option) => {
                this.businessList.push({
                  businessName: option.customerName,
                  cidn: option.cidn
                });
              });
            }
          });
      }

    }).catch((err) => {

    });
    if (this.commonService.getDefaultCustomer()) {
      this.headerSubs = this.commonService.getDefaultCustomer().pipe().subscribe(resp => {

        this.businessList.forEach(item => {
          if (item.businessName === resp) {
            this.cidnSelected = item.cidn;
            // if (this.cidnSelected?.length) {
            //   this.getProductCatalogDataFromAPI();
            // }
            localStorage.setItem('selectedHeaderCIDN', this.cidnSelected)
            const roles = this.commonService.getroleDetails().customerList;
            this.isReadOnlyRole = false;
            this.isReadWriteOnQuoteMgmt = false;
            this.selectedRows = [];
            roles.map((r) => {
              if (r.cidn === this.cidnSelected && r.role.includes('Read & Write') && r.function.includes('Quote Request Management')) {
                this.isReadWriteOnQuoteMgmt = true;
                return;
              }
              if (r.cidn === this.cidnSelected && r.role.includes('Read Only') && r.function.includes('Quote Request Management')) {
                this.isReadOnlyRole = true;
                return;
              }
            });
            // const isReadWriteOnQuoteMgmt = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.role === 'Read & Write' && obj?.function === 'Quote Request Management'));
            // const isReadOnlyRole = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.role === 'Read Only' && obj?.function === 'Quote Request Management'));
            if (this.isReadWriteOnQuoteMgmt || this.isReadOnlyRole) {
              this.gridInitialized = false;
              if (this.cidnSelected?.length) {
                this.getProductCatalogDataFromAPI();
              }
              // this.createGrid(null);
              this.checkedList = [];
            }
            else {
              this.router.navigate(['']);
            }
          }
        });
      });
    }
  }


  getUserTimeZone() {
    const userTimeZone = Intl.DateTimeFormat(this.locale, {
      timeZoneName: 'long'
    }).resolvedOptions().timeZone;

    this.userTimeZone = userTimeZone;
  }

  ngOnInit(): void {
    this.commonService.showTopButtonInFooter(true);
    const roles = this.commonService.getroleDetails().customerList;
    if (this.cidnSelected && roles.some((obj) => obj.role.includes('Read & Write') && obj.function.includes('Quote Request Management'))) {
      roles.map((r) => {
        if (r.cidn === this.cidnSelected && r.role.includes('Read & Write') && r.function.includes('Quote Request Management')) {
          this.isReadWriteOnQuoteMgmt = true;
          return;
        }
      });
    }
    //this.isReadWriteOnQuoteMgmt = roles.some((obj) => (obj?.role === 'Read & Write' && obj?.function === 'Quote Request Management'));
    this.getLabels();

    this.colDefsAthleteIncluded = [
      {
        headerName: "",
        fullHeaderName: "",
        filter: "",
        field: "",
        colId: "a",
        tooltipField: "select",
        minWidth: 50,
        maxWidth: 50,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        headerCheckboxSelectionCurrentPageOnly: true
      },
      {
        headerName: "Quote reference",
        fullHeaderName: "Quote reference",
        filter: "customTextFilter",
        field: "quoteReferenceId",
        colId: "aaa",
        tooltipField: "quoteReferenceId",
        minWidth: 155
      },
      {
        headerName: "Bulk quote reference",
        fullHeaderName: "Bulk quote reference",
        filter: "customTextFilter",
        field: "bulkQuoteReferenceId",
        colId: "aaaa",
        tooltipField: "bulkQuoteReferenceId",
        minWidth: 155,
      },
      {
        headerName: "Customer reference",
        fullHeaderName: "Customer reference",
        field: "customerReference",
        colId: "b",
        tooltipField: "customerReference",
        filter: "customTextFilter",
        minWidth: 160
      },
      {
        headerName: "Product",
        fullHeaderName: "Product",
        field: "productName",
        colId: "c",
        tooltipField: "productName",
        filter: "customTextFilter",
        minWidth: 155
      },
      {
        headerName: "Sub-product",
        fullHeaderName: "Sub-product",
        field: "subProduct",
        colId: "cc",
        tooltipField: "subProduct",
        filter: "customTextFilter",
        minWidth: 155
      },
      {
        headerName: "Status",
        fullHeaderName: "Status",
        field: "status",
        colId: "d",
        tooltipField: "status",
        filter: "CheckboxFilterComponent",
        cellRendererFramework: "WorklistStatusRendererComponent",
        minWidth: 250,
        customFilter: [
          {
            "elementData": "Awaiting Customer Response"
          },
          {
            "elementData": "Awaiting Dependent Action"
          },
          {
            "elementData": "Assessment Complete"
          },
          {
            "elementData": "Cancelled"
          },
          {
            "elementData": "Draft"
          },
          {
            "elementData": "Expired"
          },
          {
            "elementData": "In Progress"
          },
          {
            "elementData": "Ordered"
          },
          {
            "elementData": "Quote Ready"
          },
          {
            "elementData": "Quote Accepted"
          },
          {
            "elementData": "Submitted"
          }, {
            "elementData": "Withdrawn"
          }, {
            "elementData": "Withdraw Submitted"
          }
        ]
      },

      {
        headerName: "Site A end address",
        fullHeaderName: "Site A end address",
        field: "aEndAdress",
        colId: "e",
        tooltipField: "aEndAdress",
        filter: "customTextFilter",
        minWidth: 200
      },
      {
        headerName: "Site Z end address",
        fullHeaderName: "Site Z end address",
        field: "zEndAdress",
        colId: "g",
        tooltipField: "zEndAdress",
        filter: "customTextFilter",
        minWidth: 200
      },
      {
        headerName: "Last updated",
        fullHeaderName: "Last updated",
        field: "lastUpdated",
        colId: "i",
        tooltipField: "lastUpdated",
        filter: "customDateFilter",
        minWidth: 150,
        sortable: true,
        sort: 'desc',
        comparator: (date1: string, date2: string) => this.customDateSorter(date1, date2),
        getQuickFilterText: params => {
          return '';
        }
      },
      {
        headerName: "Quote expiry",
        fullHeaderName: "Quote expiry",
        field: "quoteExpiry",
        colId: "j",
        tooltipField: "quoteExpiry",
        filter: "customDateFilter",
        minWidth: 150,
        getQuickFilterText: params => {
          return '';
        }
      }
    ]
    this.functionList =
      [
        {
          name: 'Customer name', value: 'customerName', checked: false, colId: "aa",
          tooltipField: "customerName", filter: "customTextFilter", headerName: "Customer name",
          fullHeaderName: "Customer name",
          getQuickFilterText: params => {
            return '';
          }
        },
        {
          name: 'Quote reference', value: 'quoteReferenceId', checked: false, colId: "aaa",
          tooltipField: "quoteReferenceId", filter: "customTextFilter", headerName: "Quote reference",
          fullHeaderName: "Quote reference"
        },
        {
          name: 'Bulk quote reference', value: 'bulkQuoteReferenceId', checked: false, colId: "aaaa",
          tooltipField: "bulkQuoteReferenceId", filter: "customTextFilter", headerName: "Bulk quote reference",
          fullHeaderName: "Bulk quote reference"
        },
        {
          name: 'Customer reference', value: 'customerReference', checked: false, colId: "b",
          tooltipField: "customerReference",
          filter: "customTextFilter",
          headerName: "Customer reference",
          fullHeaderName: "Customer reference"
        },
        {
          name: 'Product', value: 'productName', checked: false, colId: "c",
          tooltipField: "productName",
          filter: "customTextFilter", headerName: "Product",
          fullHeaderName: "Product",
          getQuickFilterText: params => {
            return '';
          }
        },
        {
          name: 'Sub-product', value: 'subProduct', checked: false, colId: "cc",
          tooltipField: "subProduct",
          filter: "customTextFilter", headerName: "Sub-product",
          fullHeaderName: "Sub-product",
        },
        {
          name: 'Status', value: 'status', checked: false, colId: "d",
          tooltipField: "status",
          filter: "CheckboxFilterComponent",
          cellRendererFramework: "WorklistStatusRendererComponent",
          headerName: "Status",
          fullHeaderName: "Status",

          customFilter: [
            {
              "elementData": "Awaiting Customer Response"
            },
            {
              "elementData": "Awaiting Dependent Action"
            },
            {
              "elementData": "Cancelled"
            },
            {
              "elementData": "Draft"
            },
            {
              "elementData": "Expired"
            },
            {
              "elementData": "In Progress"
            },
            {
              "elementData": "Quote Ready"
            },
            {
              "elementData": "Submitted"
            }, {
              "elementData": "Withdrawn"
            }, {
              "elementData": "Withdraw Submitted"
            },
            {
              "elementData": "Assessment Complete"
            }
          ]
        },
        {
          name: 'Site A end address', value: 'aEndAdress', checked: false, colId: "e",
          tooltipField: "aEndAdress",
          filter: "customTextFilter", headerName: "Site A end address",
          fullHeaderName: "Site A end address"
        },
        {
          name: "Site A alternative name",
          value: "aEndAdressSiteAlternativeName",
          checked: false,
          colId: "f",
          tooltipField: "aEndAdressSiteAlternativeName",
          filter: "customTextFilter",
          headerName: "Site A alternative name",
          fullHeaderName: "Site A alternative name",
          getQuickFilterText: params => {
            return '';
          }
        },
        {
          name: 'Site Z end address', value: 'zEndAdress', checked: false, colId: "g",
          tooltipField: "zEndAdress",
          filter: "customTextFilter", headerName: "Site Z end address",
          fullHeaderName: "Site Z end address"
        },
        {
          name: "Site Z alternative name",
          value: "zEndAdressSiteAlternativeName",
          checked: false,
          colId: "h",
          tooltipField: "zEndAdressSiteAlternativeName",
          filter: "customTextFilter",
          headerName: "Site Z alternative name",
          fullHeaderName: "Site Z alternative name",
          getQuickFilterText: params => {
            return '';
          }
        },
        {
          name: 'Last updated', value: 'lastUpdated', checked: false, colId: "i",
          tooltipField: "lastUpdated",
          filter: "customDateFilter", headerName: "Last updated",
          fullHeaderName: "Last updated",
          sortable: true,
          sort: 'desc',
          comparator: (date1: string, date2: string) => this.customDateSorter(date1, date2),
          getQuickFilterText: params => {
            return '';
          }
        },
        {
          name: 'Quote expiry', value: 'quoteExpiry', checked: false, colId: "j",
          tooltipField: "quoteExpiry",
          filter: "customDateFilter", headerName: "Quote expiry",
          fullHeaderName: "Quote expiry",
          getQuickFilterText: params => {
            return '';
          }
        },
        {
          name: "Submitted date",
          value: "submittedDate",
          checked: false,
          colId: "k",
          sortable: true,
          sort: 'desc',
          comparator: (date1: string, date2: string) => this.customDateSorter(date1, date2),
          tooltipField: "submittedDate",
          filter: "customDateFilter",
          headerName: "Submitted date",
          fullHeaderName: "Submitted date",
          getQuickFilterText: params => {
            return '';
          }
        },
        {
          name: "Requestor",
          value: "requestorUser",
          checked: false,
          colId: "l",
          tooltipField: "requestorUser",
          filter: "customTextFilter",
          headerName: "Requestor",
          fullHeaderName: "Requestor",
          getQuickFilterText: params => {
            return '';
          }
        },
        {
          name: "Requestor channel",
          value: "requestorChannel",
          checked: false,
          colId: "m",
          tooltipField: "requestorChannel",
          filter: "customTextFilter",
          headerName: "Requestor channel",
          fullHeaderName: "Requestor channel",
          getQuickFilterText: params => {
            return '';
          }
        }
      ];
    // this.deleteCustomerNameIfNotInfraco();
    this.customerService.getLinkedCIDNs().then(res => {
      this.linkedCIDNs = res.data.businessNameDetails;
      this.checkCompanyName();
    }).catch((err) => {
    });
  }

  handleKeyPress(event, a) {
    if (event.key === 'Enter' || event.key === ' ') {
      a.checked = !a.checked;
    }
  }

  filterOnQuoteRefId(quoteRefId) {
    let dashboardCardFilter = {
      quoteReferenceId: quoteRefId
    };
    setTimeout(() => {
      if (this.rowData && this.rowData.length && dashboardCardFilter && dashboardCardFilter.quoteReferenceId) {
        var filterComponent = this.gridModel.gridOptions.api.getFilterInstance('aaa');
        if (filterComponent) {
          filterComponent.setModel({ value: dashboardCardFilter.quoteReferenceId });
        }
        this.gridModel.gridOptions.api.onFilterChanged();
      }
    }, 800);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // Check if the pressed key is "Esc"
    if (event.key === 'Escape' || event.key === 'Esc') {
      // Add your logic to close or dismiss the dropdown here
      this.showFilterPop = false; // Example: Closing the dropdown
    }
  }
  getCommonServices() {
    this.commonService.getDefaultCustomer().pipe().subscribe(resp => {
      if (resp) {
        this.setCustomerDropdown(resp);
      }
    });
  }

  setCustomerDropdown = (selectedHeaderValue?: string) => {
    const profileDetails = this.homepageservice.userProfileDetails;
    if (profileDetails) {
      const custDetails = profileDetails?.data?.customerDetails;
      if (selectedHeaderValue) {
        const findCIDNobject = this.searchCidn(custDetails, selectedHeaderValue)
        this.cidnSelected = findCIDNobject?.cidn;
        localStorage.setItem('selectedHeaderCIDN', this.cidnSelected)
      }
    }
  }

  searchCidn(myArray, nameKey) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].customerName === nameKey) {
        return myArray[i];
      }
    }
  }

  customDateSorter(date1: string, date2: string): number {
    if (!date1 && !date2) {
      return 0; // Treat both empty dates as equal
    }
    if (!date1) {
      return -1; // Treat empty date1 as smaller (earlier) for descending order
    }
    if (!date2) {
      return 1; // Treat empty date2 as greater (latest) for descending order
    }

    const dateObj1 = this.parseCustomDate(date1);
    const dateObj2 = this.parseCustomDate(date2);

    if (!isNaN(dateObj1) && !isNaN(dateObj2)) {
      // If both dates are valid, perform the comparison in descending order
      return dateObj2 - dateObj1;
    } else if (!isNaN(dateObj1) && isNaN(dateObj2)) {
      return 1; // date1 is valid, date2 is empty, so date1 should come after date2
    } else if (isNaN(dateObj1) && !isNaN(dateObj2)) {
      return -1; // date1 is empty, date2 is valid, so date2 should come before date1
    } else {
      return 0; // Both dates are empty or invalid
    }
  }

  parseCustomDate(dateString: string): number {
    if (!dateString) {
      return Number.MAX_SAFE_INTEGER; // Return a very large value for empty dates
    }

    const parts = dateString.split(' ');
    const day = parseInt(parts[0], 10);
    const month = this.getMonthNumber(parts[1]);
    const year = parseInt(parts[2], 10);
    const timeParts = parts[3].split(':');
    const hour = parseInt(timeParts[0], 10);
    const minute = parseInt(timeParts[1], 10);

    return -Date.UTC(year, month, day, hour, minute); // Negative sign for descending order
  }

  getMonthNumber(monthAbbreviation: string): number {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return months.indexOf(monthAbbreviation);
  }

  checkCompanyName() {
    this.linkedCIDNs.forEach(item => {
      if (item.cidn === this.cidnSelected) {
        this.companyName = item.bussinessName;
      }
    })

  }

  // deleteCustomerNameIfNotInfraco() {
  //   if (this.cidnSelected !== this.infraCoCIDN) {
  //     const index = this.functionList.findIndex((func) => func.name === 'Customer name');
  //     if (index !== -1) {
  //       this.functionList.splice(index, 1);
  //     }
  //   }
  //   else {
  //     return this.functionList;
  //   }
  // }
  showPop() {
    this.showFilterPop = true;
    setTimeout(() => {
      document.getElementById('colPrefTabUsr').style.display = 'block';
      this.focusableElement = document.getElementById('fir');
      this.focusableElement.focus();
    }, 1);
    this.commonService.trapFocusInModal('fir', 'rmv');
    // this.deleteCustomerNameIfNotInfraco();
  }

  closePopup() {
    this.showFilterPop = false;
  }

  getLabels() {
    return new Promise((resolve, reject) => {
      this.dataSvc.getQuoteListModel().then(() => {
        this.aemLabelAuthoring = this.dataSvc.gridLabelAuthoringDataObj;
        this.gridModel.labelAuthoring = this.aemLabelAuthoring.form.grid;
        // this.successMsg();
        resolve(true);
      }).catch(() => {
        // reject(false);
      });
    });
  }

  createGrid(payload: any) {
    if (this.subscribedflag) {
      this.subscription.unsubscribe();
    } else {
      this.subscribedflag = true;
    }
    const promise1 = this.createColumnDefs();
    const promise2 = this.createRowData(payload);
    this.subscription = forkJoin([promise1, promise2]).subscribe(result => {
      this.createGridConfig();
    }, error => {
      this.createGridConfig();
      // this.router.navigateByUrl('/error');
    });
  }

  private createGridConfig() {
    this.gridConfig = <GridOptions>{};
    this.gridConfig.rowData = this.rowData;
    this.gridConfig.paginationPageSize = 10;
    this.gridConfig.columnDefs = this.columnDefs;
    this.gridConfig.suppressHorizontalScroll = true;
    this.gridConfig.pagination = true;
    this.gridConfig.rowSelection = 'single';
    this.gridInitialized = true;
    this.gridConfig.frameworkComponents = {
      CheckboxFilterComponent: <any>CheckboxFilterComponent,
      customTextFilter: <any>CustomTextFilterComponent,
      customDateFilter: <any>CustomDateFilterComponent
      //  FullwidthRowRenderer: <any>GridTilesCustomerViewComponent
    };
    this.gridModel.gridOptions = this.gridConfig;
    if (this.gridModel.gridOptions.api) {
      this.gridModel.gridOptions.api.sizeColumnsToFit();
    }
  }

  private createColumnDefs() {
    return new Promise((resolve, reject) => {
      this.dataSvc.getGridHeader().then(() => {
        this.columnDefs = this.colDefsAthleteIncluded;
        this.columnHeaderId = []; // Resetting columnHeaderId array

        this.columnDefs.forEach((x: any) => {
          this.columnHeaderId.push(x.field);
          if (x.cellRendererFramework && x.headerName === 'Status') {
            x.cellRendererFramework = WorklistStatusRendererComponent;
          }
        });
        const index = this.columnDefs.findIndex(ele => ele.field == 'customerName');
        const index2 = this.functionList.findIndex(ele => ele.value == 'customerName');
        if (this.cidnSelected !== this.infraCoCIDN) {
          if (index !== -1) {
            this.columnDefs[index].hide = true;
          }
          if (index2 !== -1) {
            this.functionList.splice(0, 1);
          }
        } else {
          const customerNames = {
            headerName: "Customer name",
            fullHeaderName: "Customer name",
            filter: "customTextFilter",
            field: "customerName",
            colId: "aa",
            tooltipField: "customerName",
            minWidth: 155,
            getQuickFilterText: params => {
              return '';
            }
          };
          const custName = {
            name: 'Customer name', value: 'customerName', checked: false, colId: "aa",
            tooltipField: "customerName", filter: "customTextFilter", headerName: "Customer name",
            fullHeaderName: "Customer name",
          }
          // Check if the column is already present
          const isCustomerNameAdded = this.columnDefs.some(col => col.field === 'customerName');
          // Add the column only if it's not already present
          if (!isCustomerNameAdded) {
            this.columnDefs.splice(1, 0, customerNames); // Add as the second element (index 1)
          } else {
            const index = this.columnDefs.findIndex(ele => ele.field == 'customerName');
            if (index !== -1) {
              this.columnDefs[index].hide = false; // Reset hide property to show the column
            }
          }
          if (index2 === -1) {
            this.functionList.splice(0, 0, custName);
          }


        }
        this.columnDefs.forEach((x: any) => {
          this.columnHeaderId.push(x.field);
        });
        if (this.functionList?.length) {
          this.functionList.map(f => {
            if (this.columnHeaderId.includes(f.value)) {
              f.checked = true;
            }
          });
        }
        this.checkedList = this.deepCopy(this.colDefsAthleteIncluded);
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });
  }


  deepCopy(element) {
    return JSON.parse(JSON.stringify(element));
  }

  downloadSummary() {
    const data = [];
    const msg = 'Please wait, preparing your download.'
    this.showToasterMessage(msg, '', this.options, 'success');
    const dataHeaders = ['Quote reference', 'Bulk quote reference', 'Customer reference', 'Product', 'Sub-product', 'Status', 'Site A end address', 'Site A alternative name','Site Z end address', 'Site Z alternative name', 'Last updated', 'Quote expiry', 'Submitted date', 'Requestor', 'Requestor channel'];

    if (this.cidnSelected === this.infraCoCIDN) {
      dataHeaders.unshift('Customer name'); // Add 'Customer name' at the beginning of headers
    }
    // 'Site Type','DC Operator','DC Site Name'
    const index = dataHeaders.findIndex(val => val ==='Site A alternative name');
    if (this.serviceType && this.serviceType !== 'RG') {
      dataHeaders.splice(index+1,0,'Path 1: Site A end site type','Path 1: Site A end data centre operator','Path 1: Site A end data centre site name',
       'Path 2: Site A end site type','Path 2: Site A end data centre operator','Path 2: Site A end data centre site name');
    }
    const index2 = dataHeaders.findIndex(val => val ==='Site Z alternative name');
    if (this.serviceType && this.serviceType !== 'RG') {
      dataHeaders.splice(index2+1,0,'Path 1: Site Z end site type','Path 1: Site Z end data centre operator','Path 1: Site Z end data centre site name',
        'Path 2: Site Z end site type','Path 2: Site Z end data centre operator','Path 2: Site Z end data centre site name');
    }

    let dataToBeDownloaded = [];

    if (this.selectedRows.length) {
      dataToBeDownloaded = this.selectedRows;
    } else {
      if (this.filteredRowData && this.filteredRowData.length > 0) {
        dataToBeDownloaded = this.filteredRowData;
      } else {
        if (this.rowData && this.rowData.length) {
          dataToBeDownloaded = this.rowData;
        }
      }
    }

    dataToBeDownloaded.forEach((selectedRow) => {
      const rowData = {};

      if (this.cidnSelected === this.infraCoCIDN) {
        rowData['Customer name'] = selectedRow.customerName ? selectedRow.customerName : '';
      }
      rowData['Quote reference'] = selectedRow.quoteReferenceId ? selectedRow.quoteReferenceId : '';
      rowData['Bulk quote reference'] = selectedRow.bulkQuoteReferenceId ? selectedRow.bulkQuoteReferenceId : '';
      rowData['Customer reference'] = selectedRow.customerReference ? selectedRow.customerReference : '';
      rowData['Product'] = selectedRow.productName ? selectedRow.productName : '';
      rowData['Sub-product'] = selectedRow.subProduct ? selectedRow.subProduct : '';
      rowData['Status'] = selectedRow.status ? selectedRow.status : '';
      rowData['Site A end address'] = selectedRow.aEndAdress ? selectedRow.aEndAdress : '';
      rowData['Site A alternative name'] = selectedRow.aEndAdressSiteAlternativeName ? selectedRow.aEndAdressSiteAlternativeName : '';       
      if (this.serviceType && this.serviceType !=='RG') {
        rowData['Path 1: Site A end site type'] = selectedRow.aEndAdresssiteDeliveryType ? selectedRow.aEndAdresssiteDeliveryType : '';
        rowData['Path 1: Site A end data centre operator'] = selectedRow.aEndAdressDCOperator ? selectedRow.aEndAdressDCOperator : '';
        rowData['Path 1: Site A end data centre site name'] = selectedRow.aEndAdressDCSiteName ? selectedRow.aEndAdressDCSiteName : '';   
        rowData['Path 2: Site A end site type'] = selectedRow.a2EndAdresssiteDeliveryType ? selectedRow.a2EndAdresssiteDeliveryType : '';
        rowData['Path 2: Site A end data centre operator'] = selectedRow.a2EndAdressDCOperator ? selectedRow.a2EndAdressDCOperator : '';
        rowData['Path 2: Site A end data centre site name'] = selectedRow.a2EndAdressDCSiteName ? selectedRow.a2EndAdressDCSiteName : ''; 
      }
      rowData['Site Z end address'] = selectedRow.zEndAdress ? selectedRow.zEndAdress : '';
      rowData['Site Z alternative name'] = selectedRow.zEndAdressSiteAlternativeName ? selectedRow.zEndAdressSiteAlternativeName : '';
      if (this.serviceType && this.serviceType !=='RG') {
        rowData['Path 1: Site Z end site type'] = selectedRow.zEndAdresssiteDeliveryType ? selectedRow.zEndAdresssiteDeliveryType : '';
        rowData['Path 1: Site Z end data centre operator'] = selectedRow.zEndAdressDCOperator ? selectedRow.zEndAdressDCOperator : '';
        rowData['Path 1: Site Z end data centre site name'] = selectedRow.zEndAdressDCSiteName ? selectedRow.zEndAdressDCSiteName : '';   
        rowData['Path 2: Site Z end site type'] = selectedRow.z2EndAdresssiteDeliveryType ? selectedRow.z2EndAdresssiteDeliveryType : '';
        rowData['Path 2: Site Z end data centre operator'] = selectedRow.z2EndAdressDCOperator ? selectedRow.z2EndAdressDCOperator : '';
        rowData['Path 2: Site Z end data centre site name'] = selectedRow.z2EndAdressDCSiteName ? selectedRow.z2EndAdressDCSiteName : '';   
      }
      rowData['Last updated'] = selectedRow.lastUpdated ? selectedRow.lastUpdated : '';
      rowData['Quote expiry'] = selectedRow.quoteExpiry ? selectedRow.quoteExpiry : '';
      rowData['Submitted date'] = selectedRow.submittedDate ? selectedRow.submittedDate : '';
      rowData['Requestor'] = selectedRow.requestorUser ? selectedRow.requestorUser : '';
      rowData['Requestor channel'] = selectedRow.requestorChannel ? selectedRow.requestorChannel : '';

      data.push(rowData);
    });
    if(sessionStorage.getItem('setAllPageheaderVal')) {
      this.companyName = sessionStorage.getItem('setAllPageheaderVal');
    }
    const now = this.currentMoment().format('DDMMYYYYHHmmss');
    const timeInFileName = this.currentMoment().format('DDMMYYYYHHmm').toString();
    let fileName = 'InfraCoQuotesSummary-' + (this.companyName ? this.companyName : sessionStorage.getItem('defaultCustomerValue')) + '-' + timeInFileName;

    return new Angular5Csv(data, fileName, { headers: dataHeaders });
  }


  getSelectedValue(status: Boolean, value: String, colId: string, tooltipField: string, filter: string, headerName: string, fullHeaderName: string, customFilter: any, cellRendererFramework: any) {

    let index = 0;
    if (status) {
      if (headerName === 'Status') {
        this.currentSelected = { field: value, colId: colId, tooltipField: tooltipField, filter: filter, headerName: headerName, fullHeaderName: fullHeaderName, customFilter: customFilter, width: 250, minWidth: 250, maxWidth: 300, cellRendererFramework: WorklistStatusRendererComponent }
      }
      else {
        this.currentSelected = { field: value, colId: colId, tooltipField: tooltipField, filter: filter, headerName: headerName, fullHeaderName: fullHeaderName, customFilter: customFilter, cellRendererFramework: cellRendererFramework, width: 150, minWidth: 150, maxWidth: 300 };
      }
      this.checkedList.push(this.currentSelected);
    } else {
      const index = this.checkedList.findIndex(obj => obj.field === value); // Find the index of the object with id=2
      if (index !== -1) {
        this.checkedList.splice(index, 1); // Remove the object at the found index
      }
    }
    this.checkedList.sort((a, b) => a.colId.localeCompare(b.colId))
    this.checkedList.map(c => {
      if (c.headerName === 'Status') {
        c.cellRendererFramework = WorklistStatusRendererComponent
      }
      if (c.headerName === 'Last updated') {
        c.sortable = true;
        c.sort = 'desc';
        c.comparator = (date1: string, date2: string) => this.customDateSorter(date1, date2),
          c.getQuickFilterText = function (params) {
            return '';
          }
      }

      if (c.headerName === 'Submitted date') {
        c.sortable = true;
        c.comparator = (date1: string, date2: string) => this.customDateSorter(date1, date2),
          c.getQuickFilterText = function (params) {
            return '';
          }
      }

      if (c.headerName === 'Customer name' || c.headerName === 'Product' || c.headerName === 'Site A alternative name' || c.headerName ==='Path 1: Site A end site type' || 
        c.headerName === 'Path 1: Site A end data centre operator' || c.headerName === 'Path 1: Site A end data centre site name' || c.headerName ==='Path 2: Site A end site type' ||
        c.headerName === 'Path 2: Site A end data centre operator' || c.headerName === 'Path 2: Site A end data centre site name' || c.headerName === 'Site Z alternative name' ||
        c.headerName ==='Path 1: Site Z end site type' || c.headerName === 'Path 1: Site Z end data centre operator' || c.headerName === 'Path 1: Site Z end data centre site name' || 
        c.headerName ==='Path 2: Site Z end site type' || c.headerName === 'Path 2: Site Z end data centre operator' || c.headerName === 'Path 2: Site Z end data centre site name' ||
        c.headerName === 'Quote expiry' || c.headerName === 'Requestor' || c.headerName === 'Requestor channel') {
        c.getQuickFilterText = function (params) {
          return '';
        }
      }

    })
    if (this.checkedList.length === 0) {
      this.columnsPresent = false;
    } else {
      this.columnsPresent = true;
    }
    setTimeout(() => {
      if (this.gridModel.gridOptions.api) {
        this.gridModel.gridOptions.api.setColumnDefs([]);
        this.gridModel.gridOptions.api.setColumnDefs(this.checkedList);
        this.gridModel.gridOptions.api.sizeColumnsToFit();
      }
      //   this.createGrid(null);

      setTimeout(() => {
        this.gridModel.gridOptions.api.setQuickFilter('');
        this.gridModel.gridOptions.api.setQuickFilter(this.searchvalue);
      }, 0);
    }, 500);
  }
  onInputChange() {
    if (!this.searchvalue || this.searchvalue.trim() === '') {
      this.gridModel.gridOptions.api.setQuickFilter('');
    }
  }
  notify() {
    let dashboardCardFilter = {
      status: 'Awaiting Customer Response'
    };
    setTimeout(() => {
      if (this.rowData && this.rowData.length && dashboardCardFilter && dashboardCardFilter.status) {
        var filterComponent = this.gridModel.gridOptions.api.getFilterInstance('d');
        if (filterComponent) {
          filterComponent.setModel({ value: dashboardCardFilter.status });
        }
        this.gridModel.gridOptions.api.onFilterChanged();
      }
    }, 800);
  }
  reset() {
    this.gridModel.gridOptions.api.setQuickFilter('');
    this.show = false;
  }

  private createRowData(params?: any) {
    const param = params || null;
    this.rowData = [];
    return new Promise((resolve, reject) => {
      //const cidn = localStorage.getItem('selectedCidn');
      //const cidn = '9999999999';
      // this.cidnSelected = this.commonService.selectedCidn;
      this.createQuoteService.getQuoteList(this.cidnSelected, this.userTimeZone).then((res) => {
        resolve(true);
        this.getRowData(res.data);
      }).catch((err) => {
        this.rowData = [];
        reject(err);
      });
    });

  }

  onCheckboxChange(newValue: boolean, item: any) {
    item.checked = newValue;
  }


  onCheckboxKeydown(event: KeyboardEvent, item: any) {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault(); // Prevent scrolling
      item.checked = !item.checked; // Toggle checkbox state
      this.getSelectedValue(
        item.checked,
        item.value,
        item.colId,
        item.tooltipField,
        item.filter,
        item.headerName,
        item.fullHeaderName,
        item.customFilter,
        item.cellRendererFramework
      );
    }
  }
  navigateToCreateQuote() {
    this.createQuoteService.setProductDetailsFormValues(null);
    const navigationExtras = {
      state: {
        formData: null
      }
    }
    this.router.navigateByUrl('/product-details', navigationExtras);
  }
  navigateToCreateBulkQuote() {
    this.router.navigateByUrl('/bulk-quote-request-details');
  }

  quickSearch() {
    this.gridModel.gridOptions.api.setQuickFilter(this.searchvalue);
  }
  clearsearch() {
    (<HTMLInputElement>document.getElementById("text")).value = '';
    this.gridModel.gridOptions.api.setQuickFilter('');
    this.gridModel.gridOptions.api.deselectAll();
    this.searchvalue = '';
  }

  getRowData(data) {
    this.rowData = data;
    this.functionList.forEach(ele=>{
      if (ele.value ==='aEndAdressSiteAlternativeName' || ele.value ==='zEndAdressSiteAlternativeName' 
        || ele.value ==='submittedDate' || ele.value ==='requestorUser' || ele.value ==='requestorChannel') {
        ele.checked =  false;
      }
    });
    if (this.serviceType && this.serviceType === 'RG') {
      this.functionList.forEach((ele,index) => {
        if (ele.value ==='aEndAdresssiteDeliveryType') {
          this.functionList.splice(index, 6);
        }
        if (ele.value ==='zEndAdresssiteDeliveryType') {
          this.functionList.splice(index, 6);
        }
      });
    } else {
      const index = this.functionList.findIndex(ele => ele.value === 'aEndAdressSiteAlternativeName');
      this.functionList.splice(index+1,0,
      {
        name: 'Path 1: Site A end site type', value: 'aEndAdresssiteDeliveryType', checked: false, colId: "fa",
        tooltipField: "aEndAdresssiteDeliveryType",
        filter: "customTextFilter", headerName: "Path 1: Site A end site type",
        fullHeaderName: "Path 1: Site A end site type"
      },
      {
        name: 'Path 1: Site A end data centre operator', value: 'aEndAdressDCOperator', checked: false, colId: "fb",
        tooltipField: "aEndAdressDCOperator",
        filter: "customTextFilter", headerName: "Path 1: Site A end data centre operator",
        fullHeaderName: "Path 1: Site A end data centre operator"
      },
      {
        name: 'Path 1: Site A end data centre site name', value: 'aEndAdressDCSiteName', checked: false, colId: "fc",
        tooltipField: "aEndAdressDCSiteName",
        filter: "customTextFilter", headerName: "Path 1: Site A end data centre site name",
        fullHeaderName: "Path 1: Site A end data centre site name"
      },
      {
        name: 'Path 2: Site A end site type', value: 'a2EndAdresssiteDeliveryType', checked: false, colId: "fd",
        tooltipField: "a2EndAdresssiteDeliveryType",
        filter: "customTextFilter", headerName: "Path 2: Site A end site type",
        fullHeaderName: "Path 2: Site A end site type"
      },
      {
        name: 'Path 2: Site A end data centre operator', value: 'a2EndAdressDCOperator', checked: false, colId: "fe",
        tooltipField: "a2EndAdressDCOperator",
        filter: "customTextFilter", headerName: "Path 2: Site A end data centre operator",
        fullHeaderName: "Path 2: Site A end data centre operator"
      },
      {
        name: 'Path 2: Site A end data centre site name', value: 'a2EndAdressDCSiteName', checked: false, colId: "ff",
        tooltipField: "a2EndAdressDCSiteName",
        filter: "customTextFilter", headerName: "Path 2: Site A end data centre site name",
        fullHeaderName: "Path 2: Site A end data centre site name"
      }
    );
      const index2 = this.functionList.findIndex(ele => ele.value === 'zEndAdressSiteAlternativeName');
      this.functionList.splice(index2+1,0,
        {
          name: 'Path 1: Site Z end site type', value: 'zEndAdresssiteDeliveryType', checked: false, colId: "ha",
          tooltipField: "zEndAdresssiteDeliveryType",
          filter: "customTextFilter", headerName: "Path 1: Site Z end site type",
          fullHeaderName: "Path 1: Site Z end site type"
        },
        {
          name: 'Path 1: Site Z end data centre operator', value: 'zEndAdressDCOperator', checked: false, colId: "hb",
          tooltipField: "zEndAdressDCOperator",
          filter: "customTextFilter", headerName: "Path 1: Site Z end data centre operator",
          fullHeaderName: "Path 1: Site Z end data centre operator"
        },
        {
          name: 'Path 1: Site Z end data centre site name', value: 'zEndAdressDCSiteName', checked: false, colId: "hc",
          tooltipField: "zEndAdressDCSiteName",
          filter: "customTextFilter", headerName: "Path 1: Site Z end data centre site name",
          fullHeaderName: "Path 1: Site Z end data centre site name"
        },
        {
          name: 'Path 2: Site Z end site type', value: 'z2EndAdresssiteDeliveryType', checked: false, colId: "hd",
          tooltipField: "z2EndAdresssiteDeliveryType",
          filter: "customTextFilter", headerName: "Path 2: Site Z end site type",
          fullHeaderName: "Path 2: Site Z end site type"
        },
        {
          name: 'Path 2: Site Z end data centre operator', value: 'z2EndAdressDCOperator', checked: false, colId: "he",
          tooltipField: "z2EndAdressDCOperator",
          filter: "customTextFilter", headerName: "Path 2: Site Z end data centre operator",
          fullHeaderName: "Path 2: Site Z end data centre operator"
        },
        {
          name: 'Path 2: Site Z end data centre site name', value: 'z2EndAdressDCSiteName', checked: false, colId: "hf",
          tooltipField: "z2EndAdressDCSiteName",
          filter: "customTextFilter", headerName: "Path 2: Site Z end data centre site name",
          fullHeaderName: "Path 2: Site Z end data centre site name"
        }
    );      
    }
    let count = 0;

    this.rowData.forEach(item => {
      if (item.status === 'Awaiting Customer Response') {
        this.isShowAwait = true;
        count++;
        this.quoteStatus = count;
      }
    })

    const navigationState = window.history.state;
    if (navigationState?.quoteId) {
      this.filterOnQuoteRefId(navigationState.quoteId);
    }
  }

  onRowClicked(ev: any) {
    this.navigateToModify(ev.data);
  }

  onSelectionChanged(arg: any) {
    this.selectedRows = arg.gridApi.getSelectedRows();
  }
  navigateToModify(data) {
    this.dataSvc.saveSelectedID(data.quoteReferenceId);
    this.dataSvc.saveSnowID(data.snowOrderId);
    this.dataSvc.saveSubmittedDate(data.submittedDate);
    localStorage.setItem('selectedHeaderCIDN', this.cidnSelected)
    // this.cidn = this.commonService.selectedCidn;
    if (data.status === 'Draft') {
      if (this.isReadWriteOnQuoteMgmt && (this.cidnSelected !== this.infraCoCIDN)) {
        if (data.quoteReferenceId) {
          this.dataSvc.getSummaryDetails(data.quoteReferenceId, '', this.cidnSelected, this.userTimeZone).then((res) => {
            this.createQuoteService.setProductDetailsFormValues(res.data);
            //  this.router.navigateByUrl('/product-details');
            const productDetailsFormData = res.data;
            const navigationExtras = {
              state: {
                formData: productDetailsFormData
              }
            }
            this.createQuoteService.isModifyExistingQuote(false);
            this.router.navigateByUrl('/product-details', navigationExtras);
          }).catch((err) => {
            this.rowData = [];
          });
        } else if (data.bulkQuoteReferenceId) {
          this.router.navigate([`/bulk-quote-request-details/${data.bulkQuoteReferenceId}`]);
        }

      }
    } else {
      if (data.quoteReferenceId) {
        this.router.navigate([`quoteSummary/${data.quoteReferenceId}`]);
      } else if (data.bulkQuoteReferenceId) {
        if (data.status?.toLowerCase() === 'in progress') {
          const navigationExtras = {
            state: {
              isModifyBulkQuote: true
            }
          }
          this.router.navigateByUrl(`/bulk-quote-request-details/${data.bulkQuoteReferenceId}`, navigationExtras);
        }
      }
    }
  }
  onFilterSelected(filteredData: any) {
    this.disableDwn = true;
    this.filteredRowData = [];
    if (filteredData.filteredRowData && filteredData.filteredRowData.length > 0) {
      filteredData.filteredRowData.forEach(row => {
        if (row.data) {
          this.filteredRowData.push(row.data);
        }
      });
    }
    if (this.filteredRowData.some(obj => obj.status.includes('Awaiting Customer Response'))) {
      let awaitingCustomerData = [];
      awaitingCustomerData = this.filteredRowData.filter(val => val.status === 'Awaiting Customer Response')
      this.isShowAwait = true;
      this.quoteStatus = awaitingCustomerData.length;
    } else {
      this.isShowAwait = false;
    }

    if (this.rowData.length === this.filteredRowData.length) {
      this.gridModel.gridOptions.api.deselectAll();
    }
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
  }

  async getProductCatalogDataFromAPI(params?: any) {
    const param = params || null;
    try {
      const res = await this.createQuoteService.getProductCatalogDataFromAPI(param, this.cidnSelected);
      if (res && res.serviceType) {
        this.serviceType = res.serviceType;
      }
      this.createGrid(null);
    } catch (err) {
      const msg = err?.error?.message ? err.error.message : 'Something went wrong';
      this.showToasterMessage(msg, '', this.options, 'error');
    }
    // return new Promise((resolve, reject) => {
    //   this.createQuoteService.getProductCatalogDataFromAPI(param, this.cidnSelected).then((res) => {
    //     if (res && res.serviceType) {
    //       this.serviceType = res.serviceType;
    //     }
    //     resolve(true);
    //   }).catch((err) => {
    //     const msg = err?.error?.message ? err.error.message : 'Something went wrong';
    //     this.showToasterMessage(msg, '', this.options, 'error');
    //     reject(err);
    //   });

    // });
  }

  ngOnDestroy() {
    this.commonService.showTopButtonInFooter(false);
    if (this.headerSubs) {
      this.headerSubs.unsubscribe();
    }
  }
}

import { Component, OnInit, ElementRef, ViewChild, Inject, LOCALE_ID, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridOptions } from 'ag-grid-community';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { forkJoin, Subscription } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { myQuotesService } from '../my-quotes/myquotes.service';
import { CustomAgGrid, TooltipContent } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
// import { myQuotesService } from './myquotes.service';
// import { WorklistStatusRendererComponent } from './worklist-status-renderer/worklist-status-renderer.component';
import * as moment_ from 'moment-timezone';
import { HomePageService } from 'src/app/shared/services/homePage.service';
import { MatTabGroup } from '@angular/material/tabs';
import { CreateQuoteService } from '../create-new-quote/create-quote.service';
import { WithdrawModalComponent } from './withdraw/withdraw-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'src/app/admin-settings/customer-list/customer.service';
import { AuthService } from 'tw-core-ui';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as html2pdf from 'html2pdf.js';
import { MatRadioButton, MatRadioChange } from '@angular/material/radio';
@Component({
  selector: 'app-quote-summary',
  templateUrl: './quote-summary.component.html',
  styleUrls: ['./quote-summary.component.less']
})
export class QuoteSummaryComponent implements OnInit {
  @ViewChild('assessmentHistoryTabs') tabGroup: MatTabGroup;
  @ViewChild('assessmentOutcome') assessmentOutcome: ElementRef;
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;

  selectedIndexV = 0;
  infraCoCIDN = window['environment'].InfraCoCIDN;
  moment = moment_;
  showCustName: Boolean = false;
  sentEnqPath1: Boolean = false;
  showSerNum: Boolean = false;
  refVal = [];
  enteredVal: any;
  showDiversity: Boolean = false;
  currentMoment = moment_;
  public aemLabelAuthoring: any;
  public customerChange: Subscription;
  isExpanded = false;
  isExpanded1 = false;
  isExpanded2 = false;
  isExpanded3 = false;
  userTimeZone: string;
  isExpanded4 = false;
  isExpanded5 = false;
  isExpanded6 = false;
  isExpanded7 = false;
  isExpanded8 = false;
  isExpanded9 = false;
  isExpanded10 = false;
  getRowData: any;
  rowData: any;
  rowData1: any;
  quoteList = [];
  submittedDate: any;
  requestorChannel: any;
  requestor: any;
  responseReq: any;
  custRefId: any;
  quoteRefId: any;
  lastSub: any;
  terms: string;
  quantityServ: any;
  product: any;
  linkReq: any;
  productRef: any;
  subProductRef: any;
  aEndAdress: any;
  address: any;
  aEndAdressAlternativeCot: any;
  definedPropertyLoca: any;
  addInformation: any;
  zEndAdress: any;
  zEndAdressAlternativeCot: any;
  zdefinedPropertyLoca: any;
  zaddInformation: any;
  businessDriv: any;
  externalReferences: any;
  preferredMaxDistance: any;
  fiberOpticalLoss: any;
  dbVal: any;
  notes: any;
  preApprovalCost: any;
  fundingSource: any;
  public historyDet = [];
  status: any;
  isTermsCondition: boolean;
  proccedType;
  quoteID: any;
  sysID: any;
  cidn: any;
  responsedate: string;
  customerRequestedDate
  requestMet: string;
  notMetItems: string;
  requestorSolutionSummary: string;
  solutionSummary: string;
  concessionItems: string;
  quoteTermFormPath1: FormGroup;
  quoteTermFormPath2: FormGroup;
  indicativeLinkDeliveryTimingClass: string;
  indicativeLinkdeliveryDays: string;
  totalCapitalCost: string;
  totalFibreLength: string;
  totalMonthlyRecurringCharge: string;
  lateralBenefitValue: string;
  siteAssessmentCost: string;
  monthlyRecurringICACharge: string;
  monthlyRecurringCharge: string;
  rofr: string;
  rofrFibreSize: number;
  rofrFAPId: string;
  rofrFAPLatitude: string;
  rofrFAPLongitude: string;
  newFibreHaulLength: number;
  newFibreBuriedLength: number;
  newConduitLength: number;
  leasedConduitLength: number;
  leasedConduitTerm: number;
  leasedConduitOwner: number;
  cableSections: any[] = [];
  estimatedLoss: any;
  private columnDefs;
  requestMetDiversity: any;
  requestorSolutionSummaryDiversity: any;
  solutionSummaryDiversity: any;
  concessionItemsDiversity: any;
  indicativeLinkDeliveryTimingClassDiversity: any;
  indicativeLinkdeliveryDaysDiversity: any;
  totalCapitalCostDiversity: any;
  totalFibreLengthDiversity: any;
  totalMonthlyRecurringChargeDiversity: any;
  subscription: any;
  subscribedflag: Boolean = false;
  subscription2: any;
  subscribedflag2: Boolean = false;
  public gridConfig: GridOptions;
  gridInitialized: boolean = false;
  gridInitialized1: boolean = false;
  pathAssessmentOutcome: any[] = [];
  aEndAdressSiteAlternativeName: string = '';
  zEndAdressSiteAlternativeName: string = '';
  l3WBSCodeCapex: string = '';
  l3WBSCodeOpex: string = '';
  otherPowImc: string = '';
  provisionalDateRange;
  aEndprimaryContactName: string = '';
  aEndprimaryContactNumber: number;
  aEndprimaryContactEmail: string = '';
  aEndAltContactName: string = '';
  aEndAltContactNumber: number;
  aEndAltContactEmail: string = '';
  zEndprimaryContactName: string = '';
  zEndprimaryContactNumber: number;
  zEndprimaryContactEmail: string = '';
  zEndAltContactName: string = '';
  zEndAltContactNumber: number;
  zEndAltContactEmail: string = '';
  statusSummary: string = '';
  quoteExpiry: any;
  //NON-RG Download Start
  networkProx: any = '';
  //NON_RG Sownload end
  LatLngSiteA = { lat: '', lng: '', address: '' };
  LatLngSiteZ = { lat: '', lng: '', address: '' };
  LatLngSiteA2 = { lat: '', lng: '', address: '' };
  LatLngSiteZ2 = { lat: '', lng: '', address: '' };
  public businessList = [];
  public gridModel: CustomAgGrid = {
    gridOptions: {},
    keysToSuppress: [13],
    labelAuthoring: {
      headerPageCount: 'Records',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: '',
      noDataOnSearch: 'No Cable details',
      toTop: 'Back to top'
    }
  };
  public gridModel1: CustomAgGrid = {
    gridOptions: {},
    keysToSuppress: [13],
    labelAuthoring: {
      headerPageCount: 'Records',
      filter: '',
      resetFilter: '',
      paginationHeader: '',
      noData: '',
      noDataOnFilter: '',
      noDataOnSearch: 'No Cable details',
      toTop: 'Back to top'
    }
  };
  customerName: string;
  serviceNowReference: string;
  isReadWriteOnQuoteMgmt: boolean;
  isReadWriteOnOrderManagement: boolean;
  responseReqAbbr: any;
  withdrawnStatus: string;
  public dateTooltipContentModel: TooltipContent = {
    content: 'At least 2 business days after request submission Business days (Monday to Friday) only',
    automationTypeTooltipIcon: 'image',
    automationIdTooltipIcon: 'imgAddressInformation'
  };
  userName: any;
  myLinkedCustomerList: any;
  cidnSelected: any;
  isReadOnlyRole: boolean;
  feasibilityType: any;
  isdiversity: string;
  describeYourDiversity: any;
  aEndLead: any;
  zEndLead: any;
  existingInfracoServiceInfo: any;
  a2EndAdress: any;
  z2EndAdress: any;
  a2EndAdressAlternativeCot: any;
  a2addInformation: any;
  a2EndAltContactName: any;
  a2EndAltContactNumber: any;
  a2EndAltContactEmail: any;
  a2definedPropertyLoca: any;
  a2EndprimaryContactName: any;
  a2EndprimaryContactNumber: any;
  a2EndprimaryContactEmail: any;
  z2EndprimaryContactName: any;
  z2EndprimaryContactNumber: any;
  z2EndprimaryContactEmail: any;
  z2EndAltContactName: any;
  z2EndAltContactNumber: any;
  z2EndAltContactEmail: any;
  z2EndAdressAlternativeCot: any;
  z2definedPropertyLoca: any;
  z2addInformation: any;
  object0: any;
  object1: any;
  lateralBenefitValueDiversity: any;
  siteAssessmentCostDiversity: any;
  monthlyRecurringICAChargeDiversity: any;
  monthlyRecurringChargeDiversity: any;
  rofrDiversity: any;
  rofrFibreSizeDiversity: any;
  rofrFAPIdDiversity: any;
  rofrFAPLatitudeDiversity: any;
  rofrFAPLongitudeDiversity: any;
  newFibreHaulLengthDiversity: any;
  newFibreBuriedLengthDiversity: any;
  newConduitLengthDiversity: any;
  leasedConduitLengthDiversity: any;
  leasedConduitTermDiversity: any;
  leasedConduitOwnerDiversity: any;
  cableSectionsDiversity: any;
  notMetItemsDiversity: any;
  estimatedLossDiversity: any;
  columnDefs1: any;
  public options: GlobalConfig;
  showDiversityDetails: boolean = false;
  thirdPartyPathInfo: any;
  quoteDetails: any;

  isOrder: boolean = false;
  ourCommitmentDate;
  networkProximity;
  completedDate;
  orderType;
  serviceId;
  serviceDeliveryPoint: any;
  nodeCode: any;
  leadInRequired: any;
  poleId: any;
  zEndServiceDeliveryPoint: any;
  zNodeCode: any;
  zPoleId: any;
  zLeadInRequired: any;
  projectReferenceId: any;
  opportunityNo: any;
  maxSignalLoss: any;
  customerContactName: any;
  customerContactNumber: any;
  customerContactEmail: any;
  orderPath: any;
  tAndcText;
  serviceType: any;
  nonRecurringCharge: any;
  estimatedDistanceRange: any;
  siteName: any;
  siteNameZ: any;
  clusterId: any;
  miragepclId: any;
  preferredDistance: any;
  threeYearPriceMonth: any;
  fiveYearPriceMonth: any;
  isPath1TermSelected: boolean = false;
  isPath2TermSelected: boolean = false;
  enableOrderPath1: boolean = false;
  enableOrderPath2: boolean = false;
  termVal: any;
  networkProxDiversity: any;
  custTerm: any;
  customYearMonth: any;
  preferredDistanceDiversity: any;
  customYearMonthDiversity: any;
  threeYearPriceMonthDiversity: any;
  fiveYearPriceMonthDiversity: any;
  nonRecurringChargeDiversity: any;
  estimatedDistanceRangeDiversity: any;
  radioValuePath1: any;
  radioValuePath2: number;
  selectedTerm: any;
  selectedTermForNonRGPath1: any;
  selectedTermForNonRGPath2: any;
  isPredefinedQuote: boolean = false;
  toastIcon: string = "";
  aEndSiteType:string;
  a2EndSiteType:string;
  aEndDcOperator:string;
  a2EndDcOperator:string;
  aEndDcSiteName:string;
  a2EndDcSiteName:string;
  zEndSiteType:string;
  z2EndSiteType:string;
  zEndDcOperator:string;
  z2EndDcOperator:string;
  zEndDcSiteName:string;
  z2EndDcSiteName:string;
  aEndConnectorType:string;
  aEndDemarcationPoint:string;
  aEndsdpType:string;
  aEndAccessType:string;
  aEndAdditionalCabling:string;
  aEndAdditionalCablingLocation:string;
  zEndConnectorType:string;
  zEndDemarcationPoint:string;
  zEndsdpType:string;
  zEndAccessType:string;
  zEndAdditionalCabling:string;
  zEndAdditionalCablingLocation:string;

  constructor(private router: Router, private dataSvc: myQuotesService, private commonService: CommonService, private fb: FormBuilder,
    private route: ActivatedRoute, private homepageservice: HomePageService, private dialog: MatDialog, private modalService: NgbModal, private customerService: CustomerService, private authService: AuthService, @Inject(LOCALE_ID) private locale: string,
    private createQuoteService: CreateQuoteService, private toastr: ToastrService,) {
    this.getCommonServices();
    this.getUserTimeZone();
    // this.quoteID = this.route.snapshot.paramMap.get('id');
    // this.orderPath = this.route.snapshot.paramMap.get('path');
    if (this.route.snapshot.data.path == 'orderSummary') {
      this.isOrder = true;
    }
    this.options = this.toastr.toastrConfig;

    // code for header change role check
    // if (this.commonService.getDefaultCustomer()) {
    //   this.dataSvc.customerNameDropdownChange(this.quoteDetails, this.isOrder);
    // }

    // if (this.isOrder) {
    //   this.commonService.getCustomerName().subscribe((data) => {
    //     this.router.navigate(['/myOrders']);
    //   });
    // }

    this.customerChange = this.commonService.getCustomerName().subscribe((data) => {
      this.dataSvc.customerNameDropdownChange(this.isOrder, data, this.quoteDetails);
    });
  }

  getUserTimeZone() {
    const userTimeZone = Intl.DateTimeFormat(this.locale, {
      timeZoneName: 'long'
    }).resolvedOptions().timeZone;

    this.userTimeZone = userTimeZone;
  }


  ngOnInit(): void {
    this.quoteTermFormPath1 = this.fb.group({
      terms: [null, Validators.required],
      selectedValue: ['', [Validators.pattern(/^(?!3$|5$)([1-9]|[1-2]\d|3[0-5])$/)]]

    })
    this.quoteTermFormPath2 = this.fb.group({
      terms: [null, Validators.required],
      selectedValue: ['', [Validators.pattern(/^(?!3$|5$)([1-9]|[1-2]\d|3[0-5])$/)]]

    })
    this.getLabels();
    // this.quoteID = localStorage.getItem('quoteID');
    this.sysID = localStorage.getItem('snowID');
    // this.cidn = '7898988909'
    this.cidn = localStorage.getItem('selectedHeaderCIDN');
    // this.isReadWriteOnQuoteMgmt = true;
    // this.isReadWriteOnOrderManagement = true;
    const roles = this.commonService.getroleDetails().customerList;
    roles.map((obj) => {
      if (obj?.cidn === this.cidn && obj?.role === 'Read & Write' && obj?.function === 'Quote Request Management') {
        this.isReadWriteOnQuoteMgmt = true;
      }
      if (obj?.cidn === this.cidn && obj?.role === 'Read & Write' && obj?.function === 'Order Management') {
        this.isReadWriteOnOrderManagement = true;
      }
    });

    this.submittedDate = localStorage.getItem('subDate');
    this.route.params.subscribe(params => {
      this.quoteID = params.id;
      this.orderPath = params.path;
      this.getDetails();
    });

    this.commonService.showTopButtonInFooter(true);
    this.selectedIndexV = 0;
    if (document.getElementById('back-to-top')) {
      document.getElementById('back-to-top').style.display = 'none';
    }
    // setTimeout(() => {
    //   this.toggleTabActive(this.tabGroup, 1);
    // }, 1000);

    if (this.cidn) {
      if (this.cidn === this.infraCoCIDN) {
        this.showCustName = true;
        this.showSerNum = true;
      }
    }
  }

  getCommonServices() {
    this.commonService.getDefaultCustomer().pipe().subscribe(resp => {
      if (resp) {
        this.setCustomerDropdown(resp);
      }
    });
  }

  openModal() {
    const dialogRef = this.dialog.open(WithdrawModalComponent, {
      data: {
        status: this.status,
        feasibilityType: this.feasibilityType,
        sysID: this.sysID
      }, disableClose: true
    },);
    dialogRef.afterClosed().subscribe((submit) => {
      if (submit) {
        this.getDetails();
      }
    })
  }

  // onInputFocus(type) {
  //   // Deselect other radio buttons when input field is focused
  //   if (type === 'path1') {
  //     this.quoteTermFormPath1.get('terms').reset();
  //     this.isPath1TermSelected = false;
  //     this.enableOrderPath1 = true;
  //     this.radioValuePath1 = 0;
  //   } else {
  //     this.quoteTermFormPath2.get('terms').reset();
  //     this.isPath2TermSelected = false;
  //     this.enableOrderPath2 = true;
  //     this.radioValuePath2 = 0;
  //   }

  // }

  getCustomTermPath1() {
    const enteredVal = this.quoteTermFormPath1.get('selectedValue').value;
    this.termVal = enteredVal;
  }
  getCustomTermPath2() {
    const enteredVal = this.quoteTermFormPath2.get('selectedValue').value;
    this.termVal = enteredVal;
  }
  sendEnq(type) {
    window.scrollTo(0, 0);
    const payload = {
      action: 'CustomTerm',
      cidn: this.cidnSelected,
      quoteReferenceId: this.quoteRefId,
      modifiedDetails: [
        "terms"
      ],
      "terms": this.termVal
    }
    return new Promise((resolve, reject) => {
      this.createQuoteService.sendTermEnquiry(payload).then((res) => {
        if (res && res.status === 201) {
          const msg = this.aemLabelAuthoring?.pageHeader?.sentEnqMsg;
          this.showToasterMessage(msg, '', this.options, 'success');
          if (type === 'path1') {
            this.quoteTermFormPath2.controls.selectedValue.setValue(this.termVal);
            this.sentEnqPath1 = true;
            this.radioValuePath1 = 0;
            this.radioValuePath2 = 0;
            this.quoteTermFormPath1.get('terms').setErrors({ 'required': true });
          } else if (type === 'path2') {
            this.quoteTermFormPath1.controls.selectedValue.setValue(this.termVal);
            this.sentEnqPath1 = true;
            this.radioValuePath1 = 0;
            this.radioValuePath2 = 0;
            this.quoteTermFormPath2.get('terms').setErrors({ 'required': true });
          }
        }
      }).catch((err) => {
        if (type === 'path1') {
          this.sentEnqPath1 = true;
          this.radioValuePath1 = 0;
        } else if (type === 'path2') {
          this.sentEnqPath1 = true;
          this.radioValuePath2 = 0;
        }
        const msg = 'We can’t submit your request at this time. Please refresh and try again later.';
        this.showToasterMessage(msg, '', this.options, 'error');
        reject(err);
      });

    });
  }

  setCustomerDropdown = (selectedHeaderValue?: string) => {
    const profileDetails = this.homepageservice.userProfileDetails;
    if (profileDetails) {
      const custDetails = profileDetails?.data?.customerDetails;
      if (selectedHeaderValue) {
        const findCIDNobject = this.searchCidn(custDetails, selectedHeaderValue)
        this.cidn = findCIDNobject?.cidn;
      }
    }
  }

  checkRadioButton(type, val?) {
    if (!val) {
      if (type === 'path1') {
        this.isPath1TermSelected = true;
        //    this.isPath2TermSelected = false;
      } else if (type === 'path2') {
        this.isPath2TermSelected = true;
        //     this.isPath1TermSelected = false;
      }
    }
    // else {
    //   if (type === 'path1') {
    //     this.enableOrderPath1 = false;
    //   } else if (type === 'path2') {
    //     this.enableOrderPath2 = false;
    //   }
    // }
  }

  searchCidn(myArray, nameKey) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].customerName === nameKey) {
        return myArray[i];
      }
    }
  }

  termSelect(type, value?) {
    if (type === 'path1') {
      this.enableOrderPath1 = true;
      this.isPath1TermSelected = false;
      //   this.enableOrderPath2 = false;
      this.isPath2TermSelected = false;
      this.radioValuePath1 = value;
      this.quoteTermFormPath1['controls']['terms'].setValue(value);
    } else if (type === 'path2') {
      this.enableOrderPath2 = true;
      //   this.enableOrderPath1 = false;
      this.isPath1TermSelected = false;
      this.isPath2TermSelected = false;
      this.radioValuePath2 = value;
    }
  }


  getDetails() {
    return new Promise((resolve, reject) => {
      this.dataSvc.getSummaryDetails(this.quoteID, this.sysID, this.cidn, this.userTimeZone, this.isOrder, this.orderPath).then((res) => {
        this.quoteDetails = res.data;
        const defaultCustomerValue = sessionStorage.getItem('defaultCustomerValue');
        localStorage.removeItem('snowID');
        //  if (res?.data?.customerName?.toLowerCase() !== defaultCustomerValue?.toLowerCase() && this.cidn !== this.infraCoCIDN) {
        //  this.router.navigateByUrl('/access-denied');
        //  }
        if (!this.quoteDetails) return;
        this.isdiversity = this.quoteDetails.doYouRequireDiversity;
        this.status = this.quoteDetails.quoteStatus || this.quoteDetails.orderStatus;
        if (!this.isOrder) {
        //  this.selectedIndexV = (this.status === 'Quote Ready' || this.status === 'Assessment Complete') ? 0 : 1;
          setTimeout(() => {
            this.toggleTabActive(this.tabGroup, this.selectedIndexV);
          }, 1000);
        }
        /* VARIABLE ASSIGNMENTS FOR ORDER THESE ARE NOT USED FOR QUOTE */
        this.customerRequestedDate = this.quoteDetails.customerRequestedDate
        this.provisionalDateRange = this.quoteDetails.provisionalDateRange;
        this.ourCommitmentDate = this.quoteDetails.ourCommitmentDate;
        this.networkProximity = this.quoteDetails.networkProximity;
        this.completedDate = this.quoteDetails.completedDate;
        this.orderType = this.quoteDetails.orderType;
        this.serviceId = this.quoteDetails.serviceId;
        this.serviceDeliveryPoint = this.quoteDetails.aEndAdress.serviceDeliveryPoint;
        this.nodeCode = this.quoteDetails.aEndAdress.nodeCode;
        this.poleId = this.quoteDetails.aEndAdress.poleId;
        this.leadInRequired = this.quoteDetails.aEndAdress.leadInRequired
        this.zEndServiceDeliveryPoint = this.quoteDetails.zEndAdress.serviceDeliveryPoint;
        this.zNodeCode = this.quoteDetails.zEndAdress.nodeCode;
        this.zPoleId = this.quoteDetails.zEndAdress.poleId;
        this.zLeadInRequired = this.quoteDetails.zEndAdress.leadInRequired;
        this.projectReferenceId = this.quoteDetails.projectReferenceId;
        this.opportunityNo = this.quoteDetails.opportunityId;
        this.customerContactName = this.quoteDetails.customerContactName;
        this.customerContactNumber = this.quoteDetails.customerContactNumber;
        this.customerContactEmail = this.quoteDetails.customerContactEmail;
        this.serviceType = this.quoteDetails.serviceType;
        this.siteName = this.quoteDetails.aEndAdress.siteName;
        this.siteNameZ = this.quoteDetails.zEndAdress.siteName;
        this.clusterId = this.quoteDetails.clusterId;
        this.miragepclId = this.quoteDetails.miragepclId;


        /* END OF ORDER VARIABLE ASSIGNMENT */

        setTimeout(() => {
          this.createGrid();
        }, 2000);
        if (this.isdiversity == 'Yes' && (this.quoteDetails.describeYourDiversity === 'All new services with diversity' || this.quoteDetails.describeYourDiversity === 'A new service diverse from an existing InfraCo service' || this.quoteDetails.describeYourDiversity === 'A new service diverse from a third party supplied service')) {
          //  && (this.status === 'Quote Ready' || this.status === 'Expired')
          this.showDiversity = true;
          if (this.status === 'Quote Ready' || this.status === 'Expired' || this.status === 'Quote Accepted' && this.quoteDetails.describeYourDiversity === 'All new services with diversity') {
            setTimeout(() => {
              // this.createGrid();
              this.createGridPath2();
            }, 2000);
          }


          this.a2EndAdress = this.quoteDetails.a2EndAdress ? this.getSelectedAddress(this.quoteDetails.a2EndAdress) : '';
          this.z2EndAdress = this.quoteDetails.z2EndAdress ? this.getSelectedAddress(this.quoteDetails.z2EndAdress) : '';
          this.a2EndprimaryContactName = this.quoteDetails.a2EndAdress?.primaryContactName;
          this.a2EndprimaryContactNumber = this.quoteDetails.a2EndAdress?.primaryContactNumber;
          this.a2EndprimaryContactEmail = this.quoteDetails.a2EndAdress?.primaryContactEmail;
          this.a2EndAltContactName = this.quoteDetails.a2EndAdress?.altContactName;
          this.a2EndAltContactNumber = this.quoteDetails.a2EndAdress?.altContactNumber;
          this.a2EndAltContactEmail = this.quoteDetails.a2EndAdress?.altContactEmail;
          this.aEndAdressAlternativeCot = this.quoteDetails.aEndAdress?.altContactName;
          this.aEndprimaryContactName = this.quoteDetails.aEndAdress?.primaryContactName;
          this.aEndprimaryContactNumber = this.quoteDetails.aEndAdress?.primaryContactNumber;
          this.aEndprimaryContactEmail = this.quoteDetails.aEndAdress?.primaryContactEmail;
          this.aEndAltContactName = this.quoteDetails.aEndAdress?.altContactName;
          this.aEndAltContactNumber = this.quoteDetails.aEndAdress?.altContactNumber;
          this.aEndAltContactEmail = this.quoteDetails.aEndAdress?.altContactEmail;
          this.z2EndprimaryContactName = this.quoteDetails.z2EndAdress?.primaryContactName;
          this.z2EndprimaryContactNumber = this.quoteDetails.z2EndAdress?.primaryContactNumber;
          this.z2EndprimaryContactEmail = this.quoteDetails.z2EndAdress?.primaryContactEmail;
          this.z2EndAltContactName = this.quoteDetails.z2EndAdress?.altContactName;
          this.z2EndAltContactNumber = this.quoteDetails.z2EndAdress?.altContactNumber;
          this.z2EndAltContactEmail = this.quoteDetails.z2EndAdress?.altContactEmail;
          this.a2EndAdressAlternativeCot = this.quoteDetails.a2EndAdressSiteAlternativeName;
          this.z2EndAdressAlternativeCot = this.quoteDetails.z2EndAdressSiteAlternativeName;
          this.a2addInformation = this.quoteDetails.a2EndAdress?.additionalAddressInfo;
          this.z2addInformation = this.quoteDetails.z2EndAdress?.additionalAddressInfo;
          this.a2definedPropertyLoca = this.quoteDetails.a2EndAdress?.definedPropertyLocation;
          this.z2definedPropertyLoca = this.quoteDetails.z2EndAdress?.definedPropertyLocation;
          this.LatLngSiteA2 = { lat: this.quoteDetails.a2EndAdress?.latitude, lng: this.quoteDetails.a2EndAdress?.longitude, address: this.a2EndAdress }
          this.LatLngSiteZ2 = { lat: this.quoteDetails.z2EndAdress?.latitude, lng: this.quoteDetails.z2EndAdress?.longitude, address: this.z2EndAdress }
        }
        if(this.isOrder && this.orderPath.toLowerCase() === 'path2') {
          setTimeout(() => {
            this.createGridPath2();
          }, 2000);
        }
        if (this.isdiversity == 'Yes' && (this.quoteDetails.describeYourDiversity === 'All new services with diversity' || this.quoteDetails.describeYourDiversity === 'A new service diverse from an existing InfraCo service' || this.quoteDetails.describeYourDiversity === 'A new service diverse from a third party supplied service')) {
          this.showDiversityDetails = true
        } else {
          this.showDiversityDetails = false;
        }
        // siteType dcOperator dcSiteName values
        this.aEndSiteType = this.quoteDetails.aEndAdress?.siteDeliveryType;
        this.a2EndSiteType = this.quoteDetails.a2EndAdress?.siteDeliveryType;

        this.aEndDcOperator = this.quoteDetails.aEndAdress?.dcOperator;
        this.a2EndDcOperator = this.quoteDetails.a2EndAdress?.dcOperator;
        this.aEndDcSiteName = this.quoteDetails.aEndAdress?.dcSiteName;
        this.a2EndDcSiteName = this.quoteDetails.a2EndAdress?.dcSiteName;
        this.zEndSiteType = this.quoteDetails.zEndAdress?.siteDeliveryType; 
        this.z2EndSiteType = this.quoteDetails.z2EndAdress?.siteDeliveryType;
        this.zEndDcOperator = this.quoteDetails.zEndAdress?.dcOperator;
        this.z2EndDcOperator = this.quoteDetails.z2EndAdress?.dcOperator;
        this.zEndDcSiteName = this.quoteDetails.zEndAdress?.dcSiteName;
        this.z2EndDcSiteName = this.quoteDetails.z2EndAdress?.dcSiteName;
        
        // order summary workflows
        this.aEndConnectorType = this.quoteDetails.aEndAdress?.connectorType;
        this.zEndConnectorType = this.quoteDetails.zEndAdress?.connectorType;
        this.aEndDemarcationPoint = this.quoteDetails.aEndAdress?.demarcationPoint;
        this.zEndDemarcationPoint = this.quoteDetails.zEndAdress?.demarcationPoint;
        this.aEndsdpType = this.quoteDetails.aEndAdress?.serviceDeliveryPointType;
        this.zEndsdpType = this.quoteDetails.zEndAdress?.serviceDeliveryPointType;
        this.aEndAccessType = this.quoteDetails.aEndAdress?.accessType;
        this.zEndAccessType = this.quoteDetails.zEndAdress?.accessType;
        this.aEndAdditionalCabling = this.quoteDetails.aEndAdress?.additionalCabling;
        this.zEndAdditionalCabling = this.quoteDetails.zEndAdress?.additionalCabling;
        this.aEndAdditionalCablingLocation = this.quoteDetails.aEndAdress?.additionalCablingLocation;
        this.zEndAdditionalCablingLocation = this.quoteDetails.zEndAdress?.additionalCablingLocation;

        this.describeYourDiversity = this.quoteDetails.describeYourDiversity;
        this.aEndLead = this.quoteDetails.aEndLeadIn;
        this.zEndLead = this.quoteDetails.zEndLeadIn
        this.existingInfracoServiceInfo = this.quoteDetails.existingInfracoServiceInfo;
        this.thirdPartyPathInfo = this.quoteDetails.thirdPartyPathInfo;
        this.zEndprimaryContactName = this.quoteDetails.zEndAdress?.primaryContactName;
        this.zEndprimaryContactNumber = this.quoteDetails.zEndAdress?.primaryContactNumber;
        this.zEndprimaryContactEmail = this.quoteDetails.zEndAdress?.primaryContactEmail;

        this.zEndAltContactName = this.quoteDetails.zEndAdress?.altContactName;
        this.zEndAltContactNumber = this.quoteDetails.zEndAdress?.altContactNumber;
        this.zEndAltContactEmail = this.quoteDetails.zEndAdress?.altContactEmail;
        this.addInformation = this.quoteDetails.aEndAdress?.additionalAddressInfo;
        this.zaddInformation = this.quoteDetails.zEndAdress?.additionalAddressInfo;
        this.rowData = this.dataSvc.quoteDetails;
        this.quoteRefId = this.quoteDetails.quoteReferenceId || this.quoteDetails.orderReferenceId;
        this.cidnSelected = this.quoteDetails.cidn;
        this.custRefId = this.quoteDetails.customerReference;
        this.feasibilityType = this.quoteDetails.feasibilityRequestType;
        this.aEndAdress = this.getSelectedAddress(this.quoteDetails?.aEndAdress);
        this.zEndAdress = this.getSelectedAddress(this.quoteDetails?.zEndAdress);
        this.LatLngSiteA = { lat: this.quoteDetails?.aEndAdress?.latitude, lng: this.quoteDetails?.aEndAdress?.longitude, address: this.aEndAdress }
        this.LatLngSiteZ = { lat: this.quoteDetails?.zEndAdress?.latitude, lng: this.quoteDetails?.zEndAdress?.longitude, address: this.zEndAdress }


        this.product = this.quoteDetails.productName;
        this.productRef = this.quoteDetails.productName;
        this.subProductRef = this.quoteDetails?.subProduct;
        this.linkReq = this.quoteDetails.linkRequirement;
        this.quantityServ = this.quoteDetails.quantityOfService;
        this.lastSub = this.quoteDetails.modifiedDate;
        this.terms = this.quoteDetails.terms;
        this.requestor = this.quoteDetails.requestorUser;
        this.requestorChannel = this.quoteDetails.requestorChannel;
        this.responseReq = this.quoteDetails.responseRequiredDate;
        this.preApprovalCost = this.quoteDetails.preApprovalCost;
        this.fundingSource = this.quoteDetails.fundingSource;
        this.l3WBSCodeCapex = this.quoteDetails.l3WBSCodeCapex;
        this.l3WBSCodeOpex = this.quoteDetails.l3WBSCodeOpex;
        this.otherPowImc = this.quoteDetails.otherPowImc;
        this.preferredMaxDistance = this.quoteDetails.preferredMaxDistance;
        if (this.quoteDetails.maxAllowableOpticalLoss?.length) {
          this.fiberOpticalLoss = this.quoteDetails.maxAllowableOpticalLoss.map(val => val.wavelength);
          this.dbVal = this.quoteDetails.maxAllowableOpticalLoss.map(val => val.dbValue);
        }
        this.businessDriv = this.quoteDetails.businessDriver;
        this.externalReferences = this.quoteDetails.externalReference;
        this.maxSignalLoss = this.quoteDetails.maxSignalLoss;
        if (this.serviceType !== 'RG') {
          this.networkProx = this.quoteDetails.networkProx;
        }
        this.notes = this.quoteDetails.Notes;
        if (this.notes) {
          this.notes.map(h => {
            // const convertedDate = this.moment.tz(h.date, this.userTimezone).format('DD MMM YYYY HH:mm');
            const convertedDate = this.getUtcDateTime(h.date);
            // this.getDateFormatted(h.date)
            h.date = convertedDate;
            //   h.text = h.text.replaceAll(/\\/g, "");
            h.text = h.text?.replaceAll('"', '');
            h.header = h.header?.replaceAll('"', '');
            //    h.subText = h.subText.replaceAll(/\\/g, "");
            h.subText = h.subText?.replaceAll('"', '');
            h.subHeader = h.subHeader?.replaceAll('"', '');
          });
        }
        this.pathAssessmentOutcome = this.quoteDetails.assessmentOutcome;
        if (this.quoteDetails && this.quoteDetails.assessmentOutcome && Array.isArray(this.quoteDetails.assessmentOutcome) && this.quoteDetails.assessmentOutcome.length > 0) {
          this.quoteDetails.assessmentOutcome.map(ao => {
            if (ao.primarySiteAssessment === 'Primary' || ao.primarySiteAssessment === 'Predefined routes') {
              this.object0 = ao;
            } else {
              this.object1 = ao;
            }
          })
          //   this.object0 = this.quoteDetails.assessmentOutcome[0];
          if (this.object0) {
            this.preferredDistance = this.object0.preferredMaximumDistance;
            this.networkProx = this.object0.networkProximity;
            this.custTerm = parseInt(this.object0.CustomTerm);
            this.customYearMonth = this.object0.monthlyRecurringChargeForCustomTerm;
            this.threeYearPriceMonth = this.object0.threeYearPriceMonth;
            this.fiveYearPriceMonth = this.object0.fiveYearPriceMonth;
            this.requestMet = this.object0.requestMet;
            this.requestorSolutionSummary = this.object0.requestorSolutionSummary;
            this.solutionSummary = this.object0.solutionSummary;
            this.concessionItems = this.object0.concessionItems;
            this.indicativeLinkDeliveryTimingClass = this.object0.indicativeLinkDeliveryTimingClass;
            this.indicativeLinkdeliveryDays = this.object0.indicativeLinkdeliveryDays;
            this.totalCapitalCost = this.object0.totalCapitalCost;
            this.totalFibreLength = this.object0.totalFibreLength;
            this.totalMonthlyRecurringCharge = this.object0.totalMonthlyRecurringCharge;
            this.nonRecurringCharge = this.object0.nonRecurringCharge;
            if (this.object0.lateralBenefitValue) {
              let a = this.object0.lateralBenefitValue;
              this.lateralBenefitValue = a.replace(/\,/g, '');
            }

            this.siteAssessmentCost = this.object0.siteAssessmentCost;
            this.monthlyRecurringICACharge = this.object0.monthlyRecurringICACharge;
            this.monthlyRecurringCharge = this.object0.monthlyRecurringCharge;
            this.rofr = this.object0.rofr;
            this.rofrFibreSize = this.object0.rofrFibreSize;
            this.rofrFAPId = this.object0.rofrFAPId;
            this.rofrFAPLatitude = this.object0.rofrFAPLatitude;
            this.rofrFAPLongitude = this.object0.rofrFAPLongitude;
            this.newFibreHaulLength = this.object0.newFibreHaulLength;
            this.newFibreBuriedLength = this.object0.newFibreBuriedLength;
            this.newConduitLength = this.object0.newConduitLength;
            this.leasedConduitLength = this.object0.leasedConduitLength;
            this.leasedConduitTerm = this.object0.leasedConduitTerm;
            this.leasedConduitOwner = this.object0.leasedConduitOwner;
            this.cableSections = this.object0.cableSections;
            this.notMetItems = this.object0.notMetItems;
            this.estimatedLoss = this.object0.estimatedSignalLoss;
            this.estimatedDistanceRange = this.object0.preferredMaximumDistance;

          }

          if (this.object1) {
            this.preferredDistanceDiversity = this.object1.preferredMaximumDistance;
            this.networkProxDiversity = this.object1.networkProximity;
            this.custTerm = parseInt(this.object1.CustomTerm);
            this.customYearMonthDiversity = this.object1.monthlyRecurringChargeForCustomTerm;
            this.threeYearPriceMonthDiversity = this.object1.threeYearPriceMonth;
            this.fiveYearPriceMonthDiversity = this.object1.fiveYearPriceMonth;
            this.requestMetDiversity = this.object1.requestMet;
            this.requestorSolutionSummaryDiversity = this.object1.requestorSolutionSummary;
            this.solutionSummaryDiversity = this.object1.solutionSummary;
            this.concessionItemsDiversity = this.object1.concessionItems;
            this.indicativeLinkDeliveryTimingClassDiversity = this.object1.indicativeLinkDeliveryTimingClass;
            this.indicativeLinkdeliveryDaysDiversity = this.object1.indicativeLinkdeliveryDays;
            this.totalCapitalCostDiversity = this.object1.totalCapitalCost;
            this.totalFibreLengthDiversity = this.object1.totalFibreLength;
            this.totalMonthlyRecurringChargeDiversity = this.object1.totalMonthlyRecurringCharge;
            this.nonRecurringChargeDiversity = this.object1.nonRecurringCharge;
            if (this.object1.lateralBenefitValue) {
              let a = this.object1.lateralBenefitValue;
              this.lateralBenefitValueDiversity = a.replace(/\,/g, '');
            }
            this.siteAssessmentCostDiversity = this.object1.siteAssessmentCost;
            this.monthlyRecurringICAChargeDiversity = this.object1.monthlyRecurringICACharge;
            this.monthlyRecurringChargeDiversity = this.object1.monthlyRecurringCharge;
            this.rofrDiversity = this.object1.rofr;
            this.rofrFibreSizeDiversity = this.object1.rofrFibreSize;
            this.rofrFAPIdDiversity = this.object1.rofrFAPId;
            this.rofrFAPLatitudeDiversity = this.object1.rofrFAPLatitude;
            this.rofrFAPLongitudeDiversity = this.object1.rofrFAPLongitude;
            this.newFibreHaulLengthDiversity = this.object1.newFibreHaulLength;
            this.newFibreBuriedLengthDiversity = this.object1.newFibreBuriedLength;
            this.newConduitLengthDiversity = this.object1.newConduitLength;
            this.leasedConduitLengthDiversity = this.object1.leasedConduitLength;
            this.leasedConduitTermDiversity = this.object1.leasedConduitTerm;
            this.leasedConduitOwnerDiversity = this.object1.leasedConduitOwner;
            this.cableSectionsDiversity = this.object1.cableSections;
            this.notMetItemsDiversity = this.object1.notMetItems;
            this.estimatedLossDiversity = this.object1.estimatedSignalLoss;
            this.estimatedDistanceRangeDiversity = this.object1.preferredMaximumDistance;

          }
        }
        this.submittedDate = this.isOrder ? this.quoteDetails.submittedDate : this.submittedDate;
        this.zEndprimaryContactName = this.isOrder ? this.quoteDetails.zEndAdress?.orderSiteContactDetails[0].primarySiteContactName : this.quoteDetails.zEndAdress?.primaryContactName;
        this.zEndprimaryContactNumber = this.isOrder ? this.quoteDetails.zEndAdress?.orderSiteContactDetails[0].primarySiteContactNumber : this.quoteDetails.zEndAdress?.primaryContactNumber;
        this.zEndprimaryContactEmail = this.isOrder ? this.quoteDetails.zEndAdress?.orderSiteContactDetails[0].primarySiteContactEmail : this.quoteDetails.zEndAdress?.primaryContactEmail;

        this.zEndAltContactName = this.quoteDetails.zEndAdress?.altContactName;
        this.zEndAltContactNumber = this.quoteDetails.zEndAdress?.altContactNumber;
        this.zEndAltContactEmail = this.quoteDetails.zEndAdress?.altContactEmail;

        this.customerName = this.quoteDetails.customerName;
        this.serviceNowReference = this.quoteDetails.serviceNowReference;

        this.aEndAdressAlternativeCot = this.quoteDetails.aEndAdress?.altContactName;
        this.aEndAdressSiteAlternativeName = this.quoteDetails.aEndAdressSiteAlternativeName || this.quoteDetails.aEndAdress.siteAlternativeName;
        this.zEndAdressSiteAlternativeName = this.quoteDetails.zEndAdressSiteAlternativeName || this.quoteDetails.zEndAdress.siteAlternativeName;
        this.definedPropertyLoca = this.quoteDetails.aEndAdress?.definedPropertyLocation;
        this.addInformation = this.quoteDetails.aEndAdress?.additionalAddressInfo;
        this.statusSummary = this.quoteDetails.statusSummary;

        // if (this.quoteDetails.statusSummary) {
        //   const summary = this.quoteDetails.statusSummary
        //   if (summary.startsWith('"')) {
        //     this.statusSummary = summary.substring(1);
        //   }
        // }
        if (this.quoteDetails.quoteExpiry) {
          this.quoteExpiry = this.getQuoteExpiryDaysLeft(this.quoteDetails.quoteExpiry);
        }



        this.aEndprimaryContactName = this.isOrder ? this.quoteDetails?.aEndAdress?.orderSiteContactDetails[0].primarySiteContactName : this.quoteDetails?.aEndAdress?.primaryContactName;
        this.aEndprimaryContactNumber = this.isOrder ? this.quoteDetails?.aEndAdress?.orderSiteContactDetails[0].primarySiteContactNumber : this.quoteDetails?.aEndAdress?.primaryContactNumber;
        this.aEndprimaryContactEmail = this.isOrder ? this.quoteDetails?.aEndAdress?.orderSiteContactDetails[0].primarySiteContactEmail : this.quoteDetails?.aEndAdress?.primaryContactEmail;


        this.aEndAltContactName = this.quoteDetails?.aEndAdress?.altContactName;
        this.aEndAltContactNumber = this.quoteDetails?.aEndAdress?.altContactNumber;
        this.aEndAltContactEmail = this.quoteDetails?.aEndAdress?.altContactEmail;
        this.zEndAdressAlternativeCot = this.quoteDetails?.zEndAdress?.altContactName;
        this.zdefinedPropertyLoca = this.quoteDetails?.zEndAdress?.definedPropertyLocation;
        this.zaddInformation = this.quoteDetails?.zEndAdress?.additionalAddressInfo;
        this.isPredefinedQuote = this.quoteDetails?.predefinedQuote === 'Yes' ? true : false;
        if (this.serviceType !== 'RG') {
          if (this.quoteDetails.selectedTermForNonRGPath1) {
            this.selectedTermForNonRGPath1 = this.quoteDetails.selectedTermForNonRGPath1;
            this.radioValuePath1 = this.selectedTermForNonRGPath1;
            this.quoteTermFormPath1.controls['terms'].setValue(this.radioValuePath1);
          }
          if (this.quoteDetails.selectedTermForNonRGPath2) {
            this.selectedTermForNonRGPath2 = this.quoteDetails.selectedTermForNonRGPath2;
            this.radioValuePath2 = this.selectedTermForNonRGPath2;
            this.quoteTermFormPath2.controls['terms'].setValue(this.radioValuePath2);
          }
        }
        resolve(true);
      }).catch((err) => {
        this.rowData = [];
        if (err?.error?.status === 500 && err?.error?.code === 500) {
          const navigationExtras = {
            state: {
              quoteId: this.quoteID
            }
          }
          this.router.navigateByUrl('/myQuotes', navigationExtras);
        }
      });
    });
  }
  getMonthlyRecurringChargeNonRG() {
    if (this.quoteDetails && this.isOrder && this.serviceType !== 'RG') {
      if (this.orderPath.toLowerCase() === 'path1') {
        if (this.selectedTermForNonRGPath1 === 3) {
          return this.quoteDetails?.assessmentOutcome[0]?.threeYearPriceMonth;
        } else if (this.selectedTermForNonRGPath1 === 5) {
          return this.quoteDetails?.assessmentOutcome[0]?.fiveYearPriceMonth;
        } else if (this.selectedTermForNonRGPath1 && this.selectedTermForNonRGPath1 !== 0) {
          return this.quoteDetails?.assessmentOutcome[0]?.monthlyRecurringChargeForCustomTerm;
        }
      } else if (this.orderPath.toLowerCase() === 'path2') {
        if (this.selectedTermForNonRGPath2 === 3) {
          return this.quoteDetails?.assessmentOutcome[0]?.threeYearPriceMonth;
        } else if (this.selectedTermForNonRGPath2 === 5) {
          return this.quoteDetails?.assessmentOutcome[0]?.fiveYearPriceMonth;
        } else if (this.selectedTermForNonRGPath2 && this.selectedTermForNonRGPath2 !== 0) {
          return this.quoteDetails?.assessmentOutcome[0]?.monthlyRecurringChargeForCustomTerm;
        }
      }
    } else {
      return this.monthlyRecurringCharge;
    }
  }

  getUtcDateTime(date) {
    return this.moment.utc(date).format('DD MMM YYYY HH:mm');
  }

  getDateFormatted(date) {
    const date1 = date.split('T');
    let date2 = date1[0].split('-');
    const finalDate = date2[0] + '-' + date2[1] + '-' + date2[2] + 'T' + date1[1]
    date = this.moment.tz(finalDate).format('DD MMM YYYY HH:mm');
    return date;
  }
  getDateFormattedResponse(date) {
    //  const date1 = date.split('T');
    //  let date2 = date1[0].split('-');
    //   const finalDate = date2[0] + '-' + date2[1] + '-' + date2[2]
    date = this.moment(date).format('DD MMM YYYY');
    return date;
  }

  getQuoteExpiryDaysLeft(date) {
    let currentDate = new Date();
    const formattedDate = this.getDateFormattedResponse(date)
    const dateSent = new Date(formattedDate);
    return Math.floor((Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
  }

  toggleContent() {
    this.isExpanded = !this.isExpanded;
  }
  toggleContent1() {
    this.isExpanded1 = !this.isExpanded1;
  }
  toggleContent2() {
    this.isExpanded2 = !this.isExpanded2;
  }
  toggleContent3() {
    this.isExpanded3 = !this.isExpanded3;
  }
  toggleContent4() {
    this.isExpanded4 = !this.isExpanded4;
  }
  toggleContent5() {
    this.isExpanded5 = !this.isExpanded5;
  }

  toggleContent6() {
    this.isExpanded6 = !this.isExpanded6;
  }
  toggleContent7() {
    this.isExpanded7 = !this.isExpanded7;
  }
  toggleContent8() {
    this.isExpanded8 = !this.isExpanded8;
  }
  toggleContent9() {
    this.isExpanded9 = !this.isExpanded9;
  }
  toggleContent10() {
    this.isExpanded10 = !this.isExpanded10;
    this.gridInitialized1 = true;
  }

  navigateToquotes() {
    if (this.isOrder) {
      this.router.navigate(['myOrders']);
    } else {
      this.router.navigate(['myQuotes']);
    }

  }
  getLabels() {
    return new Promise((resolve, reject) => {
      this.dataSvc.getQuoteSummaryListModel(this.isOrder).then((res) => {
        this.aemLabelAuthoring = res;
        // this.successMsg();
        resolve(true);
      }).catch(() => {
        // reject(false);
      });
    });
  }

  proceedToOrder(path) {
    this.dataSvc.getTandCforOrder(this.cidn, this.product).then(res => {
      this.proccedType = path;
      this.isTermsCondition = true;
      this.tAndcText = res;
      this.selectedTermForNonRGPath1 = this.radioValuePath1;
      this.selectedTermForNonRGPath2 = this.radioValuePath2;
    })
  }

  onClickClose(event) {
    this.isTermsCondition = false;
  }

  createGridPath2() {
    if (this.subscribedflag2) {
      this.subscription2.unsubscribe();
    } else {
      this.subscribedflag2 = true;
    }
    const promise1 = this.createColumnDefsPath2();
    const promise2 = this.createRowDataPath2();
    this.subscription2 = forkJoin([promise1, promise2]).subscribe(result => {
      this.createGridConfigPath2();
    }, error => {
      this.createGridConfigPath2();
    });
  }

  createGrid() {
    if (this.subscribedflag) {
      this.subscription.unsubscribe();
    } else {
      this.subscribedflag = true;
    }
    const promise1 = this.createColumnDefs();
    const promise2 = this.createRowData();
    this.subscription = forkJoin([promise1, promise2]).subscribe(result => {
      this.createGridConfig();
    }, error => {
      this.createGridConfig();
    });
  }

  private createColumnDefs() {
    return new Promise((resolve, reject) => {
      this.dataSvc.getCableDetailsGridHeader().then(res => {
        this.columnDefs = this.dataSvc.cableDetailsGridHeaderDataObj.headers;
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  private createColumnDefsPath2() {
    return new Promise((resolve, reject) => {
      this.dataSvc.getCableDetailsGridHeaderPath2().then(res => {
        this.columnDefs1 = this.dataSvc.cableDetailsGridHeaderDataObj.headers;
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  private createRowData() {
    return new Promise((resolve, reject) => {
      this.dataSvc.getCableDetailsGridHeader().then(res => {
        this.rowData = this.cableSections;
        resolve(true);
      }).catch((err) => {
        this.rowData = [];
        reject(err);
      });
    });
  }

  private createRowDataPath2() {
    return new Promise((resolve, reject) => {
      this.dataSvc.getCableDetailsGridHeaderPath2().then(res => {
        this.cableSectionsDiversity = this.object1.cableSections;
        this.rowData1 = this.cableSectionsDiversity;
        resolve(true);
      }).catch((err) => {
        this.rowData1 = [];
        reject(err);
      });
    });
  }

  createGridConfig() {
    this.gridConfig = <GridOptions>{};
    this.gridConfig.rowData = this.rowData;
    this.gridConfig.paginationPageSize = 10;
    this.gridConfig.columnDefs = this.columnDefs;
    this.gridConfig.suppressHorizontalScroll = true;
    this.gridConfig.pagination = true;
    this.gridConfig.suppressPaginationPanel = true;
    this.gridConfig.rowSelection = 'single';
    this.gridInitialized = true;
    this.gridModel.gridOptions = this.gridConfig;
  }

  createGridConfigPath2() {
    this.gridConfig = <GridOptions>{};
    this.gridConfig.rowData = this.rowData1;
    this.gridConfig.paginationPageSize = 10;
    this.gridConfig.columnDefs = this.columnDefs1;
    this.gridConfig.suppressHorizontalScroll = true;
    this.gridConfig.pagination = true;
    this.gridConfig.suppressPaginationPanel = true;
    this.gridConfig.rowSelection = 'single';
    this.gridInitialized1 = true;
    this.gridModel1.gridOptions = this.gridConfig;
  }

  toggleTabActive(tabGroup, i) {

    // if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;

    // const tabCount = tabGroup._tabs.length;
    // tabGroup.selectedIndex = (i + 1) % tabCount;

    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;

    const tabCount = tabGroup._tabs.length;
    const currentIndex = tabGroup.selectedIndex; // Get the current index (which is zero-based)

    // Calculate the new index
  //  const newIndex = (currentIndex + (i + 1)) % tabCount;
    if (this.status === 'Quote Ready' || this.status === 'Assessment Complete') {
      tabGroup.selectedIndex = 0;
    } else {
      tabGroup.selectedIndex = tabCount === 2 ? 1 : 2;
    }
  }



  scrollToAndOpenTab(tabIndex: number): void {
    if (tabIndex >= 0 && tabIndex < this.tabGroup._tabs.length) {
      this.tabGroup.selectedIndex = tabIndex;

      // Scroll to the tab content wrapper
      this.assessmentOutcome.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  openAssessmentOutcome(el: HTMLElement, tabGroup: MatTabGroup) {
    // this.selectedIndexV = 0;
    this.toggleTabActive(tabGroup, 1);
    el.scrollIntoView();
  }
  getSelectedAddress(adr) {
    if (this.isOrder) {
      if (adr) {
        return adr.address;
      }
    }
    if (adr & adr.selectedAddress && adr.selectedAddress === '(New address)') {
      return (adr && adr.latitude && adr.longitude) ? (adr.latitude + ',  ' + adr.longitude) : '';
    } else {
      return adr.selectedAddress;

    }
  }

  showAssessmentOutcome(status, firstBox?) {
    if (!this.isOrder) {
      this.quoteTermFormPath1.get('terms').reset();
      this.quoteTermFormPath2.get('terms').reset();
      return (status === 'Quote Ready' || status === 'Expired' || status === 'Quote Accepted' || status === 'Assessment Complete' || status === 'Ordered');
    } else if (this.orderPath.toLowerCase() ==='path1') {
      return true;
    } else {
      return false;
    }
  }

  showFundingSourceCodes() {
    return this.fundingSource === 'Other' || this.fundingSource?.toLowerCase().indexOf('sbc') > -1
  }

  modifyQuote() {
    this.dataSvc.getSummaryDetails(this.quoteID, this.sysID, this.cidn, this.userTimeZone, this.isOrder).then((res) => {
      this.createQuoteService.setProductDetailsFormValues(res.data);
      const quoteTermFormData = res.data;
      const navigationExtras = {
        state: {
          formData: quoteTermFormData,
          isModifyQuote: true
        }
      }
      this.createQuoteService.isModifyExistingQuote(true);
      this.router.navigateByUrl('/product-details', navigationExtras);
    })
  }

  disableModifyBtn() {
    return this.status === 'Submitted' || this.status === 'Awaiting Dependent Action' || this.status === 'In Progress'
  }

  hideModifyBtn() {
    return this.status === 'Withdrawn' || this.status === 'Cancelled' || this.status === 'Expired' ||
      this.status === 'Withdraw Submitted' || this.status === 'Quote Ready' || this.status === 'Quote Accepted' ||
      this.status === 'Assessment Complete' || this.status === 'Ordered';
  }

  ngOnDestroy(): void {
    this.commonService.showTopButtonInFooter(false);
    if (this.customerChange) {
      this.customerChange.unsubscribe();
    }
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-top-center';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = false;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    //Setting toastIcon to empty when toaster timeout
    setTimeout(() => { this.toastIcon = "" }, 7000);
  }

  downloadPdf() {
    this.toastIcon = "download";
    const msg = 'Please wait, preparing your download.'
    this.showToasterMessage(msg, '', this.options, 'success');
    this.startDownload();
  }

  async startDownload() {

    // Get the HTML content from the html-content component.
    const htmlContent = document.querySelector('app-html-to-pdf').innerHTML;
    const now = this.currentMoment().format('DDMMYYYYHHmmss');
    const timeInFileName = this.currentMoment().format('DDMMYYYYHHmm').toString();
    const fileName = (this.isOrder ? 'InfraCoOrder' : 'InfraCoQuote') + this.quoteRefId + '-' + (sessionStorage.getItem('defaultCustomerValue')) + '-' + timeInFileName;


    const options = {
      filename: fileName,
      innerWidth: 500,
      margin: [38, 15, 30, 15],
      header: {
        height: 100,
        contents: `

          <h1>My Angular App</h1>
        `
      },
      footer: {
        height: 60,
        contents: `
          <hr>
          <p>Generated by Angular on 2023-09-21 16:34:20 PST</p>
        `
      },
      jsPDF: { orientation: 'landscape', unit: 'pt', format: 'letter' },
      pagebreak: {
        mode: ['avoid-all', 'css', 'legacy'],
        html2canvas: { scale: 100, letterRendering: true },
        // avoid: ['h2', 'h3', 'h4', '.field', '.showDiversity', '.showDiversity2'],
        // before: '.showDiversity2'
      }
      //  pagebreak: {before: '.showDiversity', avoid: ['h2', 'h3', 'h4', '.field']},
    }

    html2pdf().from(htmlContent).set(options).toPdf().get('pdf').then(function (pdf) {
      var totalPages = pdf.internal.getNumberOfPages();
      for (let i = 1; i <= totalPages; i++) {
        pdf.setPage(i);
        pdf.addImage('../../../assets/images/logo.png', "PNG", 15, 10, 100, 25);
        pdf.setFontSize(10);
        pdf.setLineCap(1);
        //  pdf.setLineStyle(0.40);`
        pdf.line(0.5, pdf.internal.pageSize.getHeight() - 20, pdf.internal.pageSize.getWidth() - 2, pdf.internal.pageSize.getHeight() - 20);
        pdf.text('Telstra Corporation Limited ABN 33 051 775 556', 15, pdf.internal.pageSize.getHeight() - 8);
        pdf.text(i + '/' + totalPages, (pdf.internal.pageSize.getWidth() / 1.07), (pdf.internal.pageSize.getHeight() - 8));
      }
    }).save();
  }

  showForQuoteOrderBasedOnRequestMet(value, isDiverse?) {
    if (!isDiverse) {
      if (this.requestMet == value) {
        return true
      } else {
        return false
      }
    } else {
      if (this.requestMetDiversity == value) {
        return true
      } else {
        return false
      }
    }
  }

  radioChange(event: MatRadioChange) {
    this.radioValuePath1 = event.value;
  }
  radioChangePath2(event: MatRadioChange) {
    this.radioValuePath2 = event.value;
  }
  @HostListener('window:keydown', ['$event'])
  handleKeyUp(event) {
    if (event.key === 'Tab') {
      const tabElement = document.querySelectorAll('.mat-tab-label');
      tabElement[0]?.setAttribute('tabindex', '0');
    }
  }

}

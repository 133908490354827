<div class="container-fluid1">
  <div class="toastrContainer" aria-live="polite" role="alert" toastContainer></div>
  <form [formGroup]="createCustomerForm" class="row"
    *ngIf="aemLabelAuthoring1 && aemReviewLabelAuthoring && aemLabelAuthoring">
    <div class="row review-container w-95">
      <label style="position:absolute;left:-9999px" aria-live="assertive" role="alert">Request details page</label>
      <div class="col-3 page-header-container">
        <main aria-labelledby="requestDetailsHeader">
          <h1 class="page-heading" id="requestDetailsHeader" *ngIf="!isModifyQuote"> {{
            aemLabelAuthoring1.pageHeader.pageHeaderLabel }} </h1>
          <span class="page-heading" *ngIf="isModifyQuote"> {{ aemLabelAuthoring1.pageHeader.modifyQuotePageHeader }}
          </span>
        </main>
        <div class="quote-ref-text" *ngIf="quoteRefId">
          {{ aemLabelAuthoring1.pageHeader.pageSubHeaderLabel }} <strong>{{quoteRefId}} </strong>
          <span *ngIf="isQuoteSaved" class="save-lbl"><img src="../../../assets/images/Submitted.svg"
              class="saved-icon" /><strong> {{
              aemLabelAuthoring1.pageHeader.saved }}</strong></span>
        </div>
        <!-- <div class="quote-ref-text" *ngIf="custReference">
          {{ aemLabelAuthoring.pageHeader.reviewPageSubHeaderLabel }} <strong>Ref1234 </strong>
         </div> -->
      </div>

      <div class="col">
        <app-page-stepper [stepperData]="stepperData" [stepName]="stepName"></app-page-stepper>
      </div>
      <div class="col col-center d-flex align-items-center w-100 j-right">
        <div class="p-r-30 font-18">Fields marked with <span class="star">*</span> are mandatory</div>
      </div>
    </div>
    <div *ngFor="let data of productData; let i = index">
      <!-- <form [formGroup]="createCustomerForm" class="row"> -->
      <div class="background-color arrow-border">
        <div class="row heading-font">
          <div class="col-1 cart-img-container">
            <img class="product-img" src="../../../../assets/images/Product.svg" alt="product icon" />
          </div>
          <div class="col-2 product-text title-padding">
            {{aemReviewLabelAuthoring.label.product}}
            <div class="prod-name details-font"> {{data.name}}</div>
          </div>
          <div class="col-4 product-text title-padding">
            {{aemReviewLabelAuthoring.label.address}}
          </div>
        </div>

        <!-- <div class="row">
            <div class="col-2"></div>
            <div class="col-2 prod-name details-font">
              {{data.name}}
            </div>
          </div> -->
        <div class="row address-font position">
          <div class="col-1 cart-img-container"></div>
          <div class="col-2"></div>
          <div class="col-4 path-font site-address-cls">
            <label>{{createQuotePayload.doYouRequireDiversity == "Yes" &&
              createQuotePayload.describeYourDiversity==='All new services with diversity' ?
              aemReviewLabelAuthoring.label.path1SiteAEndAddress :
              aemReviewLabelAuthoring.label.siteAEndAddress}}</label>
          </div>
          <div class="col-4 path-font site-address-cls">
            <label>{{createQuotePayload.doYouRequireDiversity == "Yes" &&
              createQuotePayload.describeYourDiversity==='All new services with diversity' ?
              aemReviewLabelAuthoring.label.path1SiteZEndAddress :
              aemReviewLabelAuthoring.label.siteZEndAddress}}</label>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row details-bold-text position">
          <div class="col-1 cart-img-container"></div>
          <div class="col-2"></div>
          <div class="col-4 text-bold site-address-cls">
            <label aria-live="assertive" class="visually-hidden">Site A end address is</label>
            <label>{{getSelectedAddress(createQuotePayload?.aEndAdress) | titlecase}}</label>
          </div>
          <div class="col-4 text-bold site-address-cls">
            <label aria-live="assertive" class="visually-hidden">Site Z end address is</label>
            <label>{{getSelectedAddress(createQuotePayload?.zEndAdress) | titlecase}}</label>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row details-font position">
          <div class="col-1 cart-img-container"></div>
          <div class="col-2 product-text title-padding pt-0" *ngIf="serviceType === 'BL'">
            {{aemReviewLabelAuthoring.label.subProduct}}
            <div class="prod-name details-font">{{createQuotePayload ? createQuotePayload.subProduct : ''}}</div>
          </div>
          <div class="col-2" *ngIf="serviceType !== 'BL'"></div>
          <!-- <div class="col-2">
            <div class="sub-product" *ngIf="serviceType === 'BL'">{{aemReviewLabelAuthoring.label.subProduct}}</div>
            <div class="sub-product-name" *ngIf="serviceType === 'BL'">{{createQuotePayload ? createQuotePayload.subProduct : ''}}</div>
          </div> -->
          <div class="col-4 prod-name site-address-cls">
            <label class="site-val">
              <input (paste)="onPasteCheckAlphaNumeric($event)" (keydown)="typeAlphaNumeric($event)" type="text"
                inputRef class="form-control text-box" maxlength="255" placeholder="Enter alternative name"
                aria-label="Path 1 Site A End address field" id="aEndAdressSiteAlternativeName"
                formControlName="aEndAdressSiteAlternativeName" name="aEndAdressSiteAlternativeName"
                [ngClass]="{'invalid-form-control': ((createCustomerForm.controls.aEndAdressSiteAlternativeName.touched || createCustomerForm.controls.aEndAdressSiteAlternativeName.dirty) && createCustomerForm.controls.aEndAdressSiteAlternativeName.invalid)}">
              <!-- <app-cui-textfield [model]="path1SiteAModel"  [parentFormGroup]="createCustomerForm">
                </app-cui-textfield>-->
            </label>
            <span role="alert"
              *ngIf="(createCustomerForm.controls.aEndAdressSiteAlternativeName.touched || createCustomerForm.controls.aEndAdressSiteAlternativeName.dirty) && createCustomerForm.controls.aEndAdressSiteAlternativeName.invalid"
              class="help-block errorMsg"><img class="error-icon"
                src="../../../../assets/images/icon-error.svg" />{{aemReviewLabelAuthoring.validationMsg.validationMsgSiteA}}
            </span>
          </div>
          <div class="col-4 prod-name site-address-cls">
            <label class="site-val">
              <input (paste)="onPasteCheckAlphaNumeric($event)" (keydown)="typeAlphaNumeric($event)" type="text"
                inputRef class="form-control text-box" maxlength="255" placeholder="Enter alternative name"
                aria-label="Path 1 Site Z End address field" id="aEndAdressSiteAlternativeName"
                formControlName="zEndAdressSiteAlternativeName" name="zEndAdressSiteAlternativeName"
                [ngClass]="{'invalid-form-control': ((createCustomerForm.controls.zEndAdressSiteAlternativeName.touched || createCustomerForm.controls.zEndAdressSiteAlternativeName.dirty) && createCustomerForm.controls.zEndAdressSiteAlternativeName.invalid)}">
              <!-- <app-cui-textfield [model]="path1SiteZModel"  [parentFormGroup]="createCustomerForm">
                </app-cui-textfield> -->
            </label>
            <span role="alert"
              *ngIf="(createCustomerForm.controls.zEndAdressSiteAlternativeName.touched || createCustomerForm.controls.zEndAdressSiteAlternativeName.dirty) && createCustomerForm.controls.zEndAdressSiteAlternativeName.invalid"
              class="help-block errorMsg"><img class="error-icon"
                src="../../../../assets/images/icon-error.svg" />{{aemReviewLabelAuthoring.validationMsg.validationMsgSiteZ}}
            </span>
          </div>

          <div
            *ngIf="createQuotePayload.doYouRequireDiversity === 'Yes' && createQuotePayload.describeYourDiversity==='All new services with diversity'">
            <div class="row address-font">
              <div class="col-1 cart-img-container"></div>
              <div class="col-2"></div>
              <div class="col-8 site-address-cls-line"><hr></div>
              <div class="col-1"></div>
            </div>
            <div class="row address-font">
              <div class="col-1 cart-img-container"></div>
              <div class="col-2"></div>
              <div class="col-4 path-font site-address-cls">
                <label>{{aemReviewLabelAuthoring.label.path2SiteAEndAddress}}</label>
              </div>
              <div class="col-4 path-font site-address-cls">
                <label>{{aemReviewLabelAuthoring.label.path2SiteZEndAddress}}</label>
              </div>
              <div class="col-1"></div>
            </div>
            <div class="row details-bold-text">
              <div class="col-1 cart-img-container"></div>
              <div class="col-2"></div>
              <div class="col-4 text-bold site-address-cls">
                <label aria-live="assertive" class="visually-hidden">Path2 Site A end address is</label>
                <label>{{getSelectedAddress(createQuotePayload?.a2EndAdress) | titlecase}}</label>
              </div>
              <div class="col-4 text-bold site-address-cls">
                <label aria-live="assertive" class="visually-hidden">Path2 Site Z end address is</label>
                <label>{{getSelectedAddress(createQuotePayload?.z2EndAdress) | titlecase}}</label>
              </div>
              <div class="col-1"></div>
            </div>
            <div class="row details-font">
              <div class="col-1 cart-img-container"></div>
              <div class="col-2"></div>
              <div class="col-4 prod-name site-address-cls">
                <label class="site-val">
                  <input (paste)="onPasteCheckAlphaNumeric($event)" (keydown)="typeAlphaNumeric($event)" type="text"
                    inputRef class="form-control text-box" maxlength="255" placeholder="Enter alternative name"
                    aria-label="Path 2 Site A End address field" id="a2EndAdressSiteAlternativeName"
                    formControlName="a2EndAdressSiteAlternativeName" name="a2EndAdressSiteAlternativeName"
                    [ngClass]="{'invalid-form-control': ((createCustomerForm.controls.a2EndAdressSiteAlternativeName.touched || createCustomerForm.controls.a2EndAdressSiteAlternativeName.dirty) && createCustomerForm.controls.a2EndAdressSiteAlternativeName.invalid)}">
                  <!-- <app-cui-textfield [model]="path1SiteAModel"  [parentFormGroup]="createCustomerForm">
                        </app-cui-textfield>-->
                </label>
                <span role="alert"
                  *ngIf="(createCustomerForm.controls.a2EndAdressSiteAlternativeName.touched || createCustomerForm.controls.a2EndAdressSiteAlternativeName.dirty) && createCustomerForm.controls.a2EndAdressSiteAlternativeName.invalid"
                  class="help-block errorMsg"><img class="error-icon"
                    src="../../../../assets/images/icon-error.svg" />{{aemReviewLabelAuthoring.validationMsg.validationMsgSite2A}}
                </span>
              </div>
              <div class="col-4 prod-name site-address-cls">
                <label class="site-val">
                  <input (paste)="onPasteCheckAlphaNumeric($event)" (keydown)="typeAlphaNumeric($event)" type="text"
                    inputRef class="form-control text-box" maxlength="255" placeholder="Enter alternative name"
                    aria-label="Path 2 Site Z End address field" id="z2EndAdressSiteAlternativeName"
                    formControlName="z2EndAdressSiteAlternativeName" name="z2EndAdressSiteAlternativeName"
                    [ngClass]="{'invalid-form-control': ((createCustomerForm.controls.z2EndAdressSiteAlternativeName.touched || createCustomerForm.controls.z2EndAdressSiteAlternativeName.dirty ) && createCustomerForm.controls.z2EndAdressSiteAlternativeName.invalid)}">
                  <!-- <app-cui-textfield [model]="path1SiteZModel"  [parentFormGroup]="createCustomerForm">
                        </app-cui-textfield> -->
                </label>
                <span role="alert"
                  *ngIf="(createCustomerForm.controls.z2EndAdressSiteAlternativeName.touched || createCustomerForm.controls.z2EndAdressSiteAlternativeName.dirty) && createCustomerForm.controls.z2EndAdressSiteAlternativeName.invalid"
                  class="help-block errorMsg"><img class="error-icon"
                    src="../../../../assets/images/icon-error.svg" />{{aemReviewLabelAuthoring.validationMsg.validationMsgSite2A}}
                </span>
              </div>
              <div class="col-1"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="top-heading product-img">
        <img class="" src="../../../../assets/images/Details-Doc.svg" alt="product icon" />
        <label class="heading-text head-margin">{{aemReviewLabelAuthoring.label.quoteDetails}}</label>
      </div>
      <hr class="half-width">
      <div class="row heading-text heading-font">
        <div class="col-6 text-bold-18 ml-0">{{aemReviewLabelAuthoring.label.references}}</div>
      </div>
      <!-- <div class="row heading-text head-values text-values"> -->
      <!-- <div class="col-4 text-bold-18">{{aemReviewLabelAuthoring.label.customerReference}}</div> -->
      <!-- <div class="col-6 text-bold-18 response-date">

              {{aemReviewLabelAuthoring.label.responseRequiredDate}}
              <span class="inpt-reqrd-cls">*</span>
              <img src="../../../../assets/images/Info-Withdraw.svg"
              [ngbTooltip]="dateTipContent" [content]="dateTooltipContentModel.content"
                automationType="anchor" automationId="anchorAddOrderDateInfo" placement="right"
                #tooltipCustRd="ngbTooltip" (focusin)="tooltipCustRd.open()" (focusout)="tooltipCustRd.close()"
                class="infoIcon pl-5">

                <span class="date-infotext">Format: dd/mm/yyyy</span>
            <ng-template #dateTipContent>
              <div class="text-left"> <span class="pl-10 font-600">Criteria:</span>  <ul class="font-500"> <li> At least 2 business days after request submission </li><li> Business days (Monday to Friday) only </li></ul></div>

            </ng-template>

        </div> -->
      <!-- </div> -->
      <div class="row heading-text details-font">
        <div class="col-4 text-bold-18 ml-0">
          <div class="col-4 text-bold-18 head-values text-values">{{aemReviewLabelAuthoring.label.customerReference}}
          </div>
          <input type="text" class="form-control text-box" placeholder="Enter customer reference"
            aria-label="Customer reference field" formControlName="customerReference" maxlength="255"
            [ngClass]="{'invalid-form-control': ((createCustomerForm.controls.customerReference.touched || createCustomerForm.controls.customerReference.dirty) && createCustomerForm.controls.customerReference.invalid)}">
          <span role="alert"
            *ngIf="(createCustomerForm?.controls?.customerReference?.touched || createCustomerForm.controls.customerReference.dirty) && createCustomerForm?.controls?.customerReference?.invalid"
            class="help-block errorMsg"><img class="error-icon" src="../../../../assets/images/icon-error.svg" />The
            following special characters [<>;] are unable to be used, please revise your input</span>
        </div>
        <div class="col-6 text-bold-18" *ngIf="serviceType === 'RG'">
          <div class="col-7 text-bold-18 head-values text-values">

            {{aemReviewLabelAuthoring.label.responseRequiredDate}}
            <span class="inpt-reqrd-cls">*</span>
            <img src="../../../../assets/images/Info-Withdraw.svg" [ngbTooltip]="dateTipContent"
              [content]="dateTooltipContentModel.content" automationType="anchor" automationId="anchorAddOrderDateInfo"
              placement="right" #tooltipCustRd="ngbTooltip" (focusin)="tooltipCustRd.open()"
              (focusout)="tooltipCustRd.close()" class="infoIcon pl-5" tabindex="0" alt="Response required date field">
            <!-- <span class="date-infotext">Format: dd mmm yyyy</span> -->
            <span class="date-infotext">Format: dd/mm/yyyy</span>
            <ng-template #dateTipContent>
              <div class="text-left"> <span class="pl-10 font-600">Criteria:</span>
                <ul class="font-500">
                  <li> At least 2 business days after request submission </li>
                  <li> Business days (Monday to Friday) only </li>
                </ul>
              </div>
              <!-- <app-cui-tooltip-content [model]="dateTooltipContentModel"></app-cui-tooltip-content> -->
            </ng-template>

          </div>
          <div class="input-group request-datepicker" *ngIf="serviceType === 'RG'">
            <app-cui-datepicker aria-label="fromDatePickerFilter" [datePlaceholder]="'dd/mm/yyyy'"
              [accessibleHeader]="'Response required date required'" [modelDate]="serviceDatePicker"
              [datePickerId]="responseRequiredDate" [navigation]="false" [model]="responseRequiredDate"
              (dateSelected)="onChangeServiceDate($event)" [parentFormGroup]="createCustomerForm"
              class="datepicker_core" placeholder="Enter response required date" [minDate]="fromDate"
              [maxDate]="toMaxDate"
              [ngClass]="{'invalid-form-control': (createCustomerForm.controls.responseRequiredDate.touched && createCustomerForm.controls.responseRequiredDate.invalid)}">
            </app-cui-datepicker>
          </div>
          <span role="alert" *ngIf="createCustomerForm?.controls?.responseRequiredDate?.errors?.requiredField"
            class="help-block errorMsg"><img class="error-icon"
              src="../../../../assets/images/icon-error.svg" />{{aemReviewLabelAuthoring.validationMsg.responseRequiredDateRequiredMsg}}
          </span>
          <!-- createCustomerForm.controls.responseRequiredDate.touched &&  -->
          <!-- {{createCustomerForm.controls.responseRequiredDate.errors | json}} -->
          <span role="alert"
            *ngIf="createCustomerForm.controls.responseRequiredDate.invalid && !createCustomerForm?.controls?.responseRequiredDate?.errors?.requiredField"
            class="help-block errorMsg"><img class="error-icon"
              src="../../../../assets/images/icon-error.svg" />{{aemReviewLabelAuthoring.validationMsg.responseRequiredDateMsg}}
          </span>
        </div>
      </div>
      <div class="row heading-text head-values text-values" *ngIf="serviceType === 'RG'">
        <div class="col-6 text-bold-18">{{aemReviewLabelAuthoring.label.businessDriver}}
          <span class="inpt-reqrd-cls">*</span>
        </div>
      </div>
      <div class="row heading-text details-font" *ngIf="serviceType === 'RG'">
        <div class="form-group col-4 text-bold-18  optical-label">
          <app-cui-searchable-dropdown name="businessDriver" [model]="businessDriverDropDownModel"
            [parentFormGroup]="createCustomerForm" [labelName]="'businessDriver'" (click)="touchBusinessDriver()"
            [placeholder]="'Select business driver'" [formControlName]="'businessDriver'" [isRequired]=true
            [items]="businessDriverList | orderBy:'value'" automationType="dropdown" automationId="businessDriver"
            [noAutoComplete]=false id="businessDriver" class="customer-dropDown"
            [ngClass]="{'invalid-form-control': (createCustomerForm.controls.businessDriver.invalid && createCustomerForm.controls.businessDriver.touched)}"
            required>
          </app-cui-searchable-dropdown>


          <!-- <select formControlName="businessDriver" class="form-control">
             <option *ngFor="let data1 of data.prodSpecCharValueUse.productSpecCharacteristicValue | orderBy:'value'"
             >{{data1.value}}</option>
          </select> -->
          <span role="alert" *ngIf="showAlertDriver
          && createCustomerForm?.controls?.businessDriver?.invalid" class="help-block errorMsg"><img class="error-icon"
              src="../../../../assets/images/icon-error.svg" />Please select Business Driver
          </span>
        </div>
      </div>
      <div class="add-lessons-form" [formGroup]="createCustomerForm" (ngSubmit)="onSubmit()">
        <div *ngIf="serviceType === 'RG'" class="ref-border text-values">
          <div class="row  ">
          <div class="col-4 label-ref head-values">{{aemReviewLabelAuthoring.label.externalReference}}
            <span class="inpt-reqrd-cls">*</span>
          </div>
          <div class="col-4 label-val head-values"><label>{{aemReviewLabelAuthoring.label.value}}</label>
            <span class="inpt-reqrd-cls">*</span>
          </div>
        </div>
          <div class="heading-text details-font reference-block">
            <div class="row">
              <div class="col-4  ml-12">
                <app-cui-searchable-dropdown name="customerType" [model]="externalReferenceDropDownModel" id="type"
                  [labelName]="'externalReferenceDropDown'" [placeholder]="'Select external reference'"
                  formControlName="customerType1" [isRequired]="true" [items]=externalReferenceList
                  automationType="dropdown" automationId="drpdwnCustomerType" [noAutoComplete]="false" id="customerType"
                  class="customer-dropDown" required>
                </app-cui-searchable-dropdown>
              </div>
              <div class="col-4">
               
                  <input type="text" maxlength="64" class="form-control text-box ref-val-field" placeholder="Enter value"
                    aria-label="Value required field" id="externalReference1" formControlName="externalReference1"
                    name="externalReference1"
                    [ngClass]="{'invalid-form-control': ((createCustomerForm.controls.externalReference1.touched || createCustomerForm.controls.externalReference1.dirty) && createCustomerForm.controls.externalReference1.invalid)}">
               
              
                <ng-container
                  *ngIf="createCustomerForm.controls['externalReference1'].invalid && (createCustomerForm.controls['externalReference1'].dirty || createCustomerForm.controls['externalReference1'].touched)">
  
                  <small *ngIf="createCustomerForm.controls['externalReference1'].errors.required"
                    class="text-danger errorMsg">
                    <img class="error-icon" src="../../../../assets/images/icon-error.svg" />
                    {{aemReviewLabelAuthoring.validationMsg.externalReferenceValueMsg}}
                  </small>
                  <small
                    *ngIf="!createCustomerForm.controls['externalReference1'].errors.required && createCustomerForm.controls['externalReference1'].invalid"
                    class="text-danger errorMsg">
                    <img class="error-icon" src="../../../../assets/images/icon-error.svg" /> The following special
                    characters [<>;] are unable to be used, please revise your input
                  </small>
  
                </ng-container>
              </div>
            </div>
            
            <!-- <ng-container formArrayName="lessons"> -->
            <ng-container *ngFor="let lessonForm of lessons.controls; let i = index;" formArrayName="lessons">
              <div class="lesson-form-input-row head-values" *ngIf="lessons?.controls" [formGroup]="lessonForm">
                <div class="row">
                  <div class="col-4 label-ref">{{aemReviewLabelAuthoring.label.externalReference}}
                  </div>
                  <div class="col-4 mt-4 pl-4"><label>{{aemReviewLabelAuthoring.label.value}}</label>
                  </div>
                </div>
                <div class="row heading-text details-font reference-block">
                  <div class="col-4  title external-ref" #tref tabindex="-1">
                    <app-cui-searchable-dropdown name="externalReferenceType"
                      [model]="externalReferenceDropDownModelAdd" id="type" [labelName]="'externalReferenceDropDown'"
                      [placeholder]="'Select external reference'" formControlName="externalReferenceType"
                      [isRequired]=false [items]=externalReferenceList automationType="dropdown"
                      automationId="drpdwnCustomerType" [noAutoComplete]=false id="customerType"
                      class="customer-dropDown">
                    </app-cui-searchable-dropdown>
                  </div>
                  <div class="col-4">
                      <!--  <app-cui-textfield [model]="externalReferenceValue" formControlName="externalReferenceValue"
                        [parentFormGroup]="lessonForm"></app-cui-textfield> -->
                      <input type="text" class="form-control text-box ref-val-field" maxlength="64"
                        placeholder="Enter value" aria-label="Value field" formControlName="externalReferenceValue"
                        [ngClass]="{'invalid-form-control': ((lessonForm.controls.externalReferenceValue.touched || lessonForm.controls.externalReferenceValue.dirty) && lessonForm.controls.externalReferenceValue.invalid)}">
                      <ng-container
                        *ngIf="lessonForm.controls['externalReferenceValue'].invalid && (lessonForm.controls['externalReferenceValue'].dirty || lessonForm.controls['externalReferenceValue'].touched)">

                        <small *ngIf="lessonForm.controls['externalReferenceValue'].errors.required"
                          class="text-danger errorMsg">
                          <img class="error-icon" src="../../../../assets/images/icon-error.svg" />
                          {{aemReviewLabelAuthoring.validationMsg.externalReferenceValueMsg}}
                        </small>
                        <small
                          *ngIf="!lessonForm.controls['externalReferenceValue'].errors.required && lessonForm.controls['externalReferenceValue'].invalid"
                          class="text-danger errorMsg">
                          <img class="error-icon" src="../../../../assets/images/icon-error.svg" /> The following
                          special characters [<>;] are unable to be used, please revise your input
                        </small>
                      </ng-container>
                  </div>
                  <div class="col-2"> <button (click)="deleteLesson(i)" class="removeBtn"
                      attr.aria-label="Remove external reference {{lessonForm.controls['externalReferenceType'].value}}"><img
                        src="../../../../assets/images/Remove.svg">
                      <label class="remove-label remove-font">Remove</label>
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- </ng-container> -->
            <div class="addBtnReview btnRow">
              <button mat-button class="app-btn-green add-btn" (click)="addLesson()"
                aria-label="Add external reference">
                <div class="btn-svg"><img src="../../../../assets/images/Icon-Add-btn-active.svg"></div>
                <label class="label-space">Add</label>
              </button>
            </div>
          </div>
          <!-- <pre> {{ form.value | json }} </pre> -->
        </div>
        <div *ngIf="createQuotePayload.doYouRequireDiversity === 'Yes'">
          <hr class="half-width">
          <div class="heading-text heading-font product-img text-values">
            <label class="heading-text head-margin">{{aemReviewLabelAuthoring.label.diversityDetails}}</label>
          </div>
          <div class="row heading-text head-values site-diverse-top">
            <div class="col-4 text-bold-18">{{aemReviewLabelAuthoring.label.siteADiversity}}
              <span class="inpt-reqrd-cls">*</span>
            </div>
            <div class="col-4 text-bold-18 ">{{aemReviewLabelAuthoring.label.siteZDiversity}}
              <span class="inpt-reqrd-cls">*</span>
            </div>
          </div>
          <div class="row heading-text details-font diverse-input">
            <div class="col-4 text-bold-18">
              <app-cui-searchable-dropdown name="customerType" [model]="siteADiversityDropDownModel" id="type"
                [labelName]="'siteADiversityDropDown'" [placeholder]="'Select site A diversity'"
                formControlName="siteADiversityDropDown" [isRequired]=true [items]=siteADiversityDropDownList
                automationType="dropdown" automationId="drpdwnCustomerType" [noAutoComplete]=false id="customerType"
                class="customer-dropDown" required>
              </app-cui-searchable-dropdown>
            </div>
            <div class="col-4 text-bold-18">
                <app-cui-searchable-dropdown name="customerType" [model]="siteZDiversityDropDownModel" id="type"
                  [labelName]="'siteZDiversityDropDown'" [placeholder]="'Select site Z diversity'"
                  formControlName="siteZDiversityDropDown" [isRequired]=true [items]=siteZDiversityDropDownList
                  automationType="dropdown" automationId="drpdwnCustomerType" [noAutoComplete]=false id="customerType"
                  class="customer-dropDown" required>
                </app-cui-searchable-dropdown>
            </div>
          </div>
        </div>
        <hr class="half-width" *ngIf="(serviceType === 'RG') || showConstraintOption">
        <ng-container *ngIf="(serviceType === 'RG') || showConstraintOption">

          <div class="radio-btn-container text-values consideration-text" tabindex="3">
            <label class="radio-btn-label heading-font text-values">{{aemReviewLabelAuthoring.label.constraints}}
              <span class="inpt-reqrd-cls">*</span></label>
            <mat-radio-group role="radiogroup" class="smart-ui-radio-group details-bold-text"
              formControlName="requestorConstraints" (change)="onDiversityButtonChange($event)">
              <mat-radio-button id="constraintYesRadio" class="col-1 smart-ui-button-group "
                [attr.aria-checked]="(createCustomerForm.value.requestorConstraints === 'Yes') ? 'true' : 'false'"
                [value]="'Yes'" role="radio" (keyup.enter)="onRadioButtonChange('Yes')">
                <div class="constraint-label"
                  aria-label="Are there any further constraints or considerations that you wish to inform us about? required yes">
                  {{aemReviewLabelAuthoring.label.yes}}</div>
              </mat-radio-button>
              <mat-radio-button id="constraintNoRadio" class="col-1 smart-ui-button-group"
                [attr.aria-checked]="(createCustomerForm.value.requestorConstraints === 'No') ? 'true' : 'false'"
                role="radio" [value]="'No'"
                (keyup.enter)="onRadioButtonChange('No')">{{aemReviewLabelAuthoring.label.no}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
          <div *ngIf="isDiversityRequired" class="optical-loss-section">
            <div class="ref-border-new">
              <div class="row label-text optical-loss-header"
                aria-label="Maximum allowable Dark Fibre optical loss">
                {{aemReviewLabelAuthoring.label.maximumOpticalLoss}}
              </div>
              <div class="ml-30 mt-20">
                <div class="row">
                  <div class="col-4 ml-0 pl-7 optical-loss-label">
                    {{aemReviewLabelAuthoring.label.waveLength}}
                    <span class="inpt-reqrd-cls">*</span>
                  </div>
                  <div class="col-4  ml-0 pl-8 optical-loss-label">
                    <label>{{aemReviewLabelAuthoring.label.valueDB}}</label>
                    <span class="inpt-reqrd-cls">*</span>
                    <label class="date-infotext db-info-text">50 maximum limit</label>
                  </div>
                </div>
              </div>


              <div class="row heading-text details-font reference-block">
                <div class="col-4">
                  <app-cui-searchable-dropdown name="customerType" [model]="maximumOpticalLossDropDownModel" id="type"
                    [labelName]="'maximumOpticalLossDropDown'"
                    [placeholder]="'Select maximum allowable Dark Fibre optical loss'"
                    formControlName="maximumOpticalLossDropDown1" [isRequired]=true
                    [items]=maximumOpticalLossDropDownList automationType="dropdown" automationId="drpdwnCustomerType"
                    [noAutoComplete]=false id="customerType" class="customer-dropDown" required>
                  </app-cui-searchable-dropdown>
                </div>
                <div class="col-4">

                  <input type="tel" (keydown)="numberOnly($event)"
                    class="form-control text-box max-loss-input ref-val-field" placeholder="Enter loss (in dB)"
                    aria-label="loss (dB) required field" onpaste="return false" id="maximumOpticalLossDropDownValue1"
                    formControlName="maximumOpticalLossDropDownValue1" name="maximumOpticalLossDropDownValue1"
                    [ngClass]="{'invalid-form-control': (createCustomerForm.controls.maximumOpticalLossDropDownValue1.touched && createCustomerForm.controls.maximumOpticalLossDropDownValue1.invalid)}">

                  <span role="alert"
                    *ngIf="createCustomerForm?.controls?.maximumOpticalLossDropDownValue1?.touched && createCustomerForm?.controls?.maximumOpticalLossDropDownValue1?.value?.length === 0"
                    class="help-block errorMsg"><img class="error-icon"
                      src="../../../../assets/images/icon-error.svg" />{{aemReviewLabelAuthoring.validationMsg.opticalFiberLossValueRequiredMsg}}
                  </span>
                  <span role="alert"
                    *ngIf="createCustomerForm.controls.maximumOpticalLossDropDownValue1.invalid && createCustomerForm?.controls?.maximumOpticalLossDropDownValue1?.value?.length > 0"
                    class="help-block errorMsg"><img class="error-icon"
                      src="../../../../assets/images/icon-error.svg" />{{aemReviewLabelAuthoring.validationMsg.opticalFiberLossValueValidMsg}}
                  </span>
                </div>
              </div>
              <ng-container *ngFor="let lessonForm1 of lessons1.controls; let i = index">

                <div class="lesson-form-row" [formGroup]="lessonForm1">
                  <div class="ml-30 mt-20">
                    <div class="row">
                      <div class="col-4 ml-0 pl-7  optical-loss-label">{{aemReviewLabelAuthoring.label.waveLength}}
                      </div>
                      <div class="col-4 ml-0 pl-8  optical-loss-label">
                        <label>{{aemReviewLabelAuthoring.label.valueDB}}</label>
                        <label class="date-infotext db-info-text">50 maximum limit</label>
                      </div>
                    </div>
                  </div>
                  <div class="row heading-text details-font reference-block">

                    <div class="col-4" #elmntref tabindex="-1">
                      <app-cui-searchable-dropdown name="wavelength" [model]="maximumOpticalLossDropDownModel1"
                        id="type" [labelName]="'externalReferenceDropDown'"
                        [placeholder]="'Select maximum allowable Dark Fibre optical loss'" formControlName="wavelength"
                        [isRequired]=false [items]=maximumOpticalLossDropDownList automationType="dropdown"
                        automationId="drpdwnCustomerType" [noAutoComplete]=false id="customerType"
                        class="customer-dropDown">
                      </app-cui-searchable-dropdown>
                    </div>

                    <div class="col-4">
                      <!-- <label class="max-loss"> -->
                      <input type="tel" class="form-control text-box max-loss-input ref-val-field"
                        (keydown)="numberOnly($event)" placeholder="Enter loss (in dB)" aria-label="loss (dB) field"
                        onpaste="return false" formControlName="dbValue"
                        [ngClass]="{'invalid-form-control': (lessonForm1.controls.dbValue.touched && lessonForm1.controls.dbValue.invalid)}">
                      <!-- </label> -->

                      <span role="alert"
                        *ngIf="lessonForm1?.controls?.dbValue?.invalid && lessonForm1.controls.dbValue?.value?.length > 0"
                        class="help-block errorMsg"><img class="error-icon"
                          src="../../../../assets/images/icon-error.svg" />{{aemReviewLabelAuthoring.validationMsg.opticalFiberLossValueValidMsg}}
                      </span>
                    </div>
                    <div class="col-2"> <button (click)="deleteLesson1(i)" class="max-loss-remove-btn"
                        attr.aria-label="Remove Maximum allowable Dark Fibre optical loss {{lessonForm1.controls['wavelength'].value}}"><img
                          src="../../../../assets/images/Remove.svg">

                        <label class="remove-label remove-font">Remove</label>

                      </button>
                    </div>

                  </div>
                </div>

              </ng-container>

              <div class="addBtnReview btnRow">
                <button mat-button class="app-btn-green app-btn-grey" (click)="addLesson1()"
                  aria-label="Add Wavelenght(nm)">
                  <div class="btn-svg add-img-label"><img src="../../../../assets/images/Icon-Add-btn-active.svg"></div>
                  Add
                </button>
              </div>
            </div>

            <br>
            <div>
              <div class="col-6 ml-0 optical-loss-label">{{aemReviewLabelAuthoring.label.preferredMaxDistance}}
              </div>

              <div class="row heading-text ml-0">
                <div class="col-6 text-bold-18 ml-0 pl-0" [ngClass]="{'disable-input-wrapper': isModifyQuote}">
                  <label class="max-loss" [ngClass]="{'disable-form-input': isModifyQuote}">
                    <input type="tel" [min]="1" maxlength="4"
                      oninput="this.value = this.value.replace(/^0+|[^0-9]/, '')" class="form-control text-box"
                      onpaste="return false" formControlName="preferredMaxDistance"
                      placeholder="Enter preferred maximum distance (in km)"
                      aria-label="Preferred Maximum distance field" [ngClass]="{'disable-form-input': isModifyQuote}">
                  </label>
                </div>
              </div>
            </div>
          </div>
        </ng-container>



        <hr class="half-width">

        <div class="row heading-text heading-font note-label text-values">

          <div class="col-6 text-bold-18">{{aemReviewLabelAuthoring.label.notes}}</div>

        </div>

        <div class="row ms-5 details-font text-values notes-container">

          <textarea class="form-control textarea ms-3" rows="6" id="customerNotes" aria-label="Notes field"
            maxlength="4000" formControlName="customerNotes" name="customerNotes" placeholder="Enter notes"
            (keypress)="onNotesKeyPress($event, createCustomerForm?.value?.customerNotes?.length)"
            [ngClass]="{'invalid-form-control': ((createCustomerForm.controls.customerNotes.touched || createCustomerForm.controls.customerNotes.dirty) && createCustomerForm.controls.customerNotes.invalid)}"></textarea>
            <div class="text-right">
            <span class="text-right-blue">{{createCustomerForm?.value?.customerNotes?.length}}/4000</span>
            <span role="alert"
              *ngIf="(createCustomerForm.controls.customerNotes.touched || createCustomerForm.controls.customerNotes.dirty) && createCustomerForm?.controls?.customerNotes?.invalid"
              class="help-block errorMsg"><img class="error-icon"
                src="../../../../assets/images/icon-error.svg" />{{aemReviewLabelAuthoring.validationMsg.customerNotesMsg}}</span>
          </div>
        </div>

      </div>

      <div class="submitCancelBtnReview btnRow">
        <button mat-button (click)="navigateBack()" class="back-btn cancelBtn save-cancel-btn">
          <span class="cancel-btn">
            <div class="btn-svg back-icon"></div>
            {{ aemLabelAuthoring1?.button.back }}
          </span>
        </button>
        <button mat-button (click)="cancel()" class="cancelBtn save-cancel-btn btn-cancel">
          <i class="icon-icon-ui-cross"></i>{{ aemLabelAuthoring1.button.cancel }}</button>
        <button class="app-btn-green app-btn-white save-cancel-btn" (click)="saveQuote(false)">
          <div class="btn-svg save-icon"></div>
          {{ aemLabelAuthoring1.button.save }}
        </button>
        <label role="alert" *ngIf="saveQuoteShow" class="alert-section">Successfully saved</label>
        <button mat-button class="app-btn-green save-cancel-btn"
          [disabled]="!(createCustomerForm.valid && (lessons1 ? lessons1.valid : true) && (lessonForm ? lessonForm.valid : true))"
          (click)="saveQuote(true, true)">
          {{ aemLabelAuthoring1.button.review }}
        </button>
      </div>
    </div>
  </form>
</div>
import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CreateQuoteService } from '../create-quote.service';
import { CustomDropDown } from 'tw-core-ui/app/components/tw-core-ui-form/tw-core-ui-form.model';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { Subscription } from 'rxjs';
import { GlobalConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { HomePageService } from 'src/app/shared/services/homePage.service';
import { AuthService } from 'tw-core-ui';
import { CustomerService } from 'src/app/admin-settings/customer-list/customer.service';
import { Title } from '@angular/platform-browser';
import { MapsService } from 'src/app/maps.service';
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.less']
})
export class ProductDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;
  createNewQuoteForm: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  saveQuoteShow: boolean = false;
  isProductSelected: boolean = false;
  custReference: boolean = false;
  productData: any;
  stepperData = ['Product details', 'Site details', 'Request details', 'Review and submit']
  stepName = 'Product details';
  productDetailsForm: FormGroup;
  diversityTypeList = [];
  public prodHeaderChange: Subscription;
  thirdPartyPathInfo: string;
  existingInfracoServiceInfo: string;
  showThirdPartyInfo: boolean = false;
  showFNN: boolean = false;
  isDiversityRequired: boolean = false;
  showQuantityInput: boolean = true;
  cidn: string = '';
  customerName: string = ''
  public aemLabelAuthoring: [];
  public aemLabelAuthoring1: [];
  isQuoteSaved: boolean = false;
  public businessList = [];
  subscription: Subscription;
  quoteRefId: string;
  options: GlobalConfig;
  createQuotePayload: any;
  linkRequirementFormValue: any;
  isModifyQuote: boolean = false;
  quoteDataToModify: any;
  modifiedFormFields = {};
  public diversityTypeDropDownModel: CustomDropDown = {
    isDisabled: false,
    accessibleHeaderId: 'Describe your diversity requirement Required Combobox. Press Enter/return to expand the combo box and use Tab+Down/Up arrow keys and enter/return key to select the list under'
  };
  userName: any;
  myLinkedCustomerList: any;
  cidnSelected: any;
  public modifyQuoteSubscription: Subscription;
  pattern = /^[a-zA-Z0-9!@#$%^&*()\-\_=+:'",.?|{}\n\[\]\/\`\~\\ ]*$/;
  serviceType = '';
  isSubProductNbnEe: boolean = false;
  quoteDataFromNavigation: any;
  ///^[a-zA-Z0-9!@#$%^&*()]+$/
  constructor(private fb: FormBuilder,
    private createQuoteService: CreateQuoteService,
    private router: Router,
    private commonService: CommonService,
    private toastr: ToastrService,
    private homepageservice: HomePageService,
    private customerService: CustomerService,
    private authService: AuthService,
    private titleService: Title,
    public mapService: MapsService
  ) {
    // this.cidn = '9999999998';
    if (localStorage.getItem('selectedHeaderCIDN')) {
      this.cidn = localStorage.getItem('selectedHeaderCIDN')
    }
    else {
      this.getCommonServices();
      //  this.cidnSelected = '9999999998'
    }
    this.customerName = this.commonService.setAllPageheaderVal;
    this.options = this.toastr.toastrConfig;
    // this.commonService.getDefaultCustomer().pipe().subscribe(resp => {
    //   if (resp) {
    //     this.customerName = resp;
    //   }
    // });

    // code for header change in myquotes
    this.customerService.getLinkedCIDNs().then(res => {
      if (res && res.data && res.data.businessNameDetails && res.data.businessNameDetails.length > 0) {
        // let businessList = [];
        const iteratedArr = res.data.businessNameDetails.map(({
          bussinessName,
          cidn
        }) => [bussinessName, cidn]);

        for (var i = 0; i < iteratedArr.length; i++) {
          const businessName = iteratedArr[i][0];
          const cidn = iteratedArr[i][1];
          if (businessName || cidn) {
            this.businessList.push({
              businessName: businessName,
              cidn: cidn
            });

          }
        }

      }
      else {
        this.userName = this.authService.user ? this.authService.user.profile.username : window['environment']['contactEmail']
        this.homepageservice
          .getUserProfile(this.userName)
          .then((response) => {
            this.myLinkedCustomerList = response?.data?.customerDetails;
            if (this.myLinkedCustomerList) {
              this.myLinkedCustomerList.forEach((option) => {
                this.businessList.push({
                  businessName: option.customerName,
                  cidn: option.cidn
                });
              });
            }
          });
      }

    }).catch((err) => {

    });

    if (this.commonService.getDefaultCustomer()) {
      this.prodHeaderChange = this.commonService.getDefaultCustomer().pipe().subscribe(resp => {

        this.businessList.forEach(item => {

          if (item.businessName === resp) {
            this.cidnSelected = item.cidn;
            localStorage.setItem('selectedHeaderCIDN', this.cidnSelected)
            const roles = this.commonService.getroleDetails().customerList;
            const isReadWriteOnQuoteMgmt = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.role === 'Read & Write' && obj?.function === 'Quote Request Management'));
            const isReadOnlyRole = roles.some((obj) => (obj?.cidn === this.cidnSelected && obj?.role === 'Read Only' && obj?.function === 'Quote Request Management'));
            if (isReadWriteOnQuoteMgmt || isReadOnlyRole) {
              this.router.navigate(['/myQuotes']);
            }
            else {
              this.router.navigate(['']);
            }

          }

        })

      })
    }
    this.titleService.setTitle('InfraCo Customer Portal - Product Details page');

    // To Get Service Type
    this.getProductCatalogDataFromAPI();

  }

  getCommonServices() {
    this.commonService.getDefaultCustomer().pipe().subscribe(resp => {
      if (resp) {
        this.customerName = resp;
        this.setCustomerDropdown(resp);
      }
    });
  }

  setCustomerDropdown = (selectedHeaderValue?: string) => {
    const profileDetails = this.homepageservice.userProfileDetails;
    if (profileDetails) {
      const custDetails = profileDetails?.data?.customerDetails;
      if (selectedHeaderValue) {
        const findCIDNobject = this.searchCidn(custDetails, selectedHeaderValue)
        this.cidn = findCIDNobject?.cidn;
        localStorage.setItem('CIDNCan', this.cidn)
      }
    }
  }

  searchCidn(myArray, nameKey) {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i].customerName === nameKey) {
        return myArray[i];
      }
    }
  }

  ngOnInit(): void {
    // Get Google maps key from API before going to site details page
    this.mapService.fetchGoogleMapsApiKey();

    this.productDetailsForm = this.fb.group({
      productName: ['', Validators.required],
      subProduct: [''],
      thirdPartyPathInfo: [''],
      existingInfracoServiceInfo: [''],
      describeYourDiversity: [''],
      quantityOfService: ['1', Validators.required],
      doYouRequireDiversity: ['No'],
      linkRequirement: ['Dual fibre (fibre pair)'],
      terms: [10]
    })
    this.getLabels();
    this.formValueChange();
    this.createQuotePayload = this.createQuoteService.getProductDetailsFormValues();
    this.quoteDataToModify = this.createQuotePayload;
    if (this.isProductSelected || this.createQuotePayload?.doYouRequireDiversity?.length > 0) {
      (<HTMLInputElement>document.getElementsByClassName('prod-container-fluid')[0]).style.height = 'auto';
    } else {
      (<HTMLInputElement>document.getElementsByClassName('prod-container-fluid')[0]).style.height = '75vh';
    }
  }

  ngAfterViewInit(): void {
    this.subscription = this.createQuoteService.isNewQuoteButtonClicked.subscribe(val => {
      if (val) {
        (<HTMLInputElement>document.getElementsByClassName('prod-container-fluid')[0]).style.height = '75vh';

        this.isProductSelected = false;
        this.quoteRefId = '';
        this.setFormValue('productName', '')
      } else {
        this.setProductDetails();
        (<HTMLInputElement>document.getElementsByClassName('prod-container-fluid')[0]).style.height = 'auto';
      }
    })

    this.modifyQuoteSubscription = this.createQuoteService.isModifyQuote.subscribe(val => {
      if (val) {
        this.isModifyQuote = val;
        this.diversityTypeDropDownModel.isDisabled = true;
        this.getModifiedFormValues();
      } else {
        this.isModifyQuote = false
      }
    })

    if (this.isProductSelected || this.createQuotePayload?.doYouRequireDiversity?.length > 0) {
      (<HTMLInputElement>document.getElementsByClassName('prod-container-fluid')[0]).style.height = 'auto';
    } else {
      (<HTMLInputElement>document.getElementsByClassName('prod-container-fluid')[0]).style.height = '75vh';
    }
  }

  defaultFormValues() {
    this.setFormValue('quantityOfService', 1);
    this.setFormValue('productName', 'Dark Fibre');
    this.setFormValue('linkRequirement', 'Dual fibre (fibre pair)');
    this.setFormValue('terms', 10);
    this.setFormValue('doYouRequireDiversity', 'No');
    this.setFormValue('subProduct', '');
    this.isDiversityRequired = false;
    this.showFNN = false;
    this.showThirdPartyInfo = false;
    this.isSubProductNbnEe = false;
  }
  setProductDetails() {
    const data = this.createQuoteService.getProductDetailsFormValues();
    if (data) {
      this.quoteRefId = data.quoteReferenceId;
      this.isProductSelected = true;
      this.isQuoteSaved = true;
      this.setFormValue('quantityOfService', data.quantityOfService);
      this.setFormValue('productName', data.productName);
      this.setFormValue('linkRequirement', data.linkRequirement);
      this.setFormValue('terms', data.terms);
      this.setFormValue('doYouRequireDiversity', data.doYouRequireDiversity);
      this.setFormValue('describeYourDiversity', data.describeYourDiversity);
      this.setFormValue('subProduct', data.subProduct);
    }


    if (this.isProductSelected) {
      (<HTMLInputElement>document.getElementsByClassName('prod-container-fluid')[0]).style.height = 'auto';
    } else {
      (<HTMLInputElement>document.getElementsByClassName('prod-container-fluid')[0]).style.height = '75vh';
    }

  }

  formValueChange() {
    this.productDetailsForm.valueChanges.subscribe(val => {
      if (this.isQuoteSaved) {
        this.isQuoteSaved = false;
      }
    })
  }
  getLabels() {
    this.createQuoteService
      .getCreateQuoteModel()
      .then((data: any) => {
        this.aemLabelAuthoring1 = data;
      })
      .catch((error) => { });
    this.createQuoteService
      .getProductDetailsPageLabel()
      .then((data: any) => {
        this.aemLabelAuthoring = data;
      })
      .catch((error) => { });
  }

  private getDiversityDropdownValues() {
    this.productDetailsForm.controls.describeYourDiversity.reset();
    this.diversityTypeList = [];
    if (this.productData) {
      this.productData.forEach(val => {
        if (val.name === 'Dark Fibre') {
          val.prodSpecCharValueUse.forEach(value => {
            if (value.name === 'Diverse From Type') {
              value.productSpecCharacteristicValue.forEach(a => { this.diversityTypeList.push(a.value) })
            }
          })
        }
      })
    }
  }

  onDiversityButtonChange(event) {
    if (event.value === 'Yes') {
      this.getDiversityDropdownValues();
      this.isDiversityRequired = true;
      this.productDetailsForm['controls']['describeYourDiversity'].setValue('All new services with diversity')
    } else {
      this.isDiversityRequired = false;
      this.showThirdPartyInfo = false;
      this.showFNN = false;
      this.productDetailsForm['controls']['describeYourDiversity'].reset();
      this.clearFormValidator('describeYourDiversity');
      this.clearFormValidator('thirdPartyPathInfo');
      this.clearFormValidator('existingInfracoServiceInfo')
    }
  }

  diversityRadioFnc(value) {
    this.onDiversityButtonChange({ value });
    this.productDetailsForm['controls']['doYouRequireDiversity'].setValue(value);
  }

  onLinkRequirementRadioChange(event) {
    const quantityOfServiceInput = this.productDetailsForm.get('quantityOfService');
    if (event.value === 'Dual fibre (fibre pair)') {
      this.clearFormValidator('quantityOfService')
      quantityOfServiceInput.setValidators([Validators.required, Validators.max(4999)]);
    } else {
      this.clearFormValidator('quantityOfService')
      quantityOfServiceInput.setValidators(Validators.required);
    }
    this.isFormValid();
  }

  linkRequirementRadioFnc(value) {
    this.onLinkRequirementRadioChange({ value });
    this.productDetailsForm.controls['linkRequirement'].setValue(value);
  }

  isQuantityOfServiceControlValid() {
    const quantityOfServiceInput = this.productDetailsForm.get('quantityOfService');
    const linRequirementVal = this.productDetailsForm.get('linkRequirement');
    if (linRequirementVal.value === 'Single fibre') {
      return 'Please enter Quantity of service'
    } else if (linRequirementVal.value === 'Dual fibre (fibre pair)' && parseInt(quantityOfServiceInput.value) > 4999) {
      return 'Please enter less than 4999'
    } else {
      return 'Please enter Quantity of service'
    }
  }

  isFormValid() {
    const quantityOfServiceInput = this.productDetailsForm.get('quantityOfService');
    const linRequirementVal = this.productDetailsForm.get('linkRequirement');
    const formValid = this.productDetailsForm.controls.quantityOfService.touched && this.productDetailsForm.controls.quantityOfService.invalid;
    const checkVal = linRequirementVal.value === 'Dual fibre (fibre pair)' && parseInt(quantityOfServiceInput.value) > 4999
    return formValid || checkVal;
  }

  diversityTypeChange(val) {
    this.clearFormValidator('thirdPartyPathInfo');
    this.clearFormValidator('existingInfracoServiceInfo');
    this.productDetailsForm['controls']['existingInfracoServiceInfo'].reset();
    this.productDetailsForm['controls']['thirdPartyPathInfo'].reset();
    if (val === 'All new services with diversity') {
      this.showThirdPartyInfo = false;
      this.showFNN = false;
    } else if (val === 'A new service diverse from an existing InfraCo service') {
      this.showThirdPartyInfo = false;
      this.showFNN = true;
      this.clearFormValidator('thirdPartyPathInfo');
      this.productDetailsForm['controls']['existingInfracoServiceInfo'].setValidators([Validators.required, Validators.pattern(this.pattern)]);
    } else if (val === 'A new service diverse from a third party supplied service') {
      this.showThirdPartyInfo = true;
      this.showFNN = false;
      this.clearFormValidator('existingInfracoServiceInfo');
      this.productDetailsForm['controls']['thirdPartyPathInfo'].setValidators([Validators.required, Validators.pattern(this.pattern)]);
    }
  }

  selectProduct() {
    this.createQuoteService.setProductDetailsFormValues(null);
    this.isProductSelected = true;
    this.defaultFormValues();
    this.productDetailsForm['controls']['productName'].setValue('Dark Fibre');
    const payload = {
      productName: 'Dark Fibre',
      customerName: this.customerName,
      cidn: this.cidn
    };
    this.saveFeasibilityRequest(payload);
    (<HTMLInputElement>document.getElementsByClassName('prod-container-fluid')[0]).style.height = 'auto';

  }

  getProductCatalogDataFromAPI(params?: any) {
    const param = params || null;

    return new Promise((resolve, reject) => {
      this.createQuoteService.getProductCatalogDataFromAPI(param, this.cidn).then((res) => {
        if(res && res.serviceType){
          this.serviceType = res.serviceType;

          this.productData = this.createQuoteService.productDataObj;
          this.createQuoteService.setProductData(this.productData);
          this.productDetailsFormSetValidationByServiceType();

        const navigationState = window.history.state;
        if (navigationState.formData) {
          const formValue = navigationState.formData;
          this.quoteDataFromNavigation = formValue;
          this.getExistingFormData(formValue)
        }
      }
        resolve(true);
        ;
      }).catch((err) => {
        ;
        this.productData = [];
        const msg = err?.error?.message ? err.error.message : 'Something went wrong';
        this.showToasterMessage(msg, '', this.options, 'error');
        reject(err);
      });

    });
  }

  productDetailsFormSetValidationByServiceType(){
    if(this.serviceType === 'BL'){
      this.productDetailsForm.get('subProduct').setValidators([Validators.required]);
      this.productDetailsForm.get('subProduct').updateValueAndValidity();
    }
    else{
      this.productDetailsForm.get('subProduct').clearValidators();
      this.productDetailsForm.get('subProduct').setValue('');
      this.productDetailsForm.get('subProduct').updateValueAndValidity();
    }
  }

  getExistingFormData(formValue) {
    if (formValue) {
      this.isProductSelected = true;
      this.quoteRefId = formValue.quoteReferenceId;
      this.setFormValue('quantityOfService', formValue.quantityOfService);
      this.setFormValue('productName', formValue.productName);
      this.setFormValue('doYouRequireDiversity', formValue.doYouRequireDiversity);
      this.setFormValue('describeYourDiversity', formValue.describeYourDiversity);
      this.setFormValue('existingInfracoServiceInfo', formValue.existingInfracoServiceInfo);
      this.setFormValue('thirdPartyPathInfo', formValue.thirdPartyPathInfo);
      this.setFormValue('linkRequirement', formValue.linkRequirement);
      this.setFormValue('terms', formValue.terms);
      this.setFormValue('subProduct', formValue.subProduct)
    }
  }

  setFormValue(id, value) {
    if (id === 'doYouRequireDiversity' && value === 'Yes') {
      this.onDiversityButtonChange({ value: value });
      this.productDetailsForm['controls'][id].setValue(value);
    } else if (id === 'describeYourDiversity' && value) {
      this.diversityTypeChange(value);
      this.productDetailsForm['controls'][id].setValue(value);
    } else if (id === 'doYouRequireDiversity' && value === 'No') {
      this.showFNN = false;
      this.showThirdPartyInfo = false;
    }
    else if(id === 'subProduct' && value === 'NBN EE'){
      this.isSubProductNbnEe = true;
      this.productDetailsForm.controls['doYouRequireDiversity'].setValue('No');
      this.productDetailsForm.controls['linkRequirement'].setValue('Single fibre');
    }
    this.productDetailsForm['controls'][id].patchValue(value);
  }

  clearFormValidator(id: string) {
    this.productDetailsForm['controls'][id].clearValidators();
    this.productDetailsForm['controls'][id].updateValueAndValidity();
  }

  showQuoteReference(event) {
    if (event) {
      this.isProductSelected = true;
    } else {
      this.isProductSelected = false;
    }
  }

  showReviewLabel(event) {
    if (event) {
      this.custReference = true;
    } else {
      this.custReference = false;
    }
  }
  cancel() {
    if (this.isProductSelected) {
      const payload = this.getSaveFeasibilityPayload();
      if (this.isModifyQuote) {
        this.modifyQuote(false);
      } else {
        this.saveFeasibilityRequest(payload);
      }
      const navigationExtras = {
        state: {
          formData: payload
        }
      }
      this.createQuoteService.setProductDetailsFormValues(payload);
      this.router.navigateByUrl('/myQuotes', navigationExtras);
    } else {
      this.router.navigate(['/myQuotes']);
    }
  }

  navigateToStepTwo() {
    const payload = this.getSaveFeasibilityPayload();
    // NON-RG payload
    if (this.serviceType !== "RG") {
      this.createQuoteService.nonRGPayloadPrepare(payload, this.serviceType);

    }

    if (this.isModifyQuote) {
      this.modifyQuote(false, true);
    } else {
      this.saveFeasibilityRequest(payload, true, true);
    }
    this.createQuoteService.setProductDetailsFormValues(payload);

  }

  saveQuote() {
    this.saveQuoteShow = true;
    setTimeout(() => {
      this.saveQuoteShow = false;
    }, 5000);
    const payload = this.getSaveFeasibilityPayload();

    // NON-RG payload
    if (this.serviceType !== "RG") {
      this.createQuoteService.nonRGPayloadPrepare(payload, this.serviceType);
    }

    if (this.isModifyQuote) {
      this.modifyQuote(true);
    } else {
      this.saveFeasibilityRequest(payload, true);
    }
    this.createQuoteService.setProductDetailsFormValues(payload);
  }

  setAll(obj, val) {
    try {
      Object.keys(obj).forEach(function (index) {
        obj[index] = val
      });
    } catch (e) { }
  }

  getSaveFeasibilityPayload() {
    const payload1 = {
      cidn: this.cidn,
      productName: 'Dark Fibre',
      subProduct: this.productDetailsForm.controls['subProduct'].value,
      avoidPlrLogging: false,
      customerName: this.customerName,
      quoteReferenceId: this.quoteRefId,
      doYouRequireDiversity: this.productDetailsForm.controls['doYouRequireDiversity'].value,
      describeYourDiversity: this.productDetailsForm.controls['describeYourDiversity'].value,
      existingInfracoServiceInfo: this.productDetailsForm.controls['existingInfracoServiceInfo'].value,
      feasibilityRequestType: this.createQuotePayload?.feasibilityRequestType,
      //parseInt(this.productDetailsForm.controls['existingInfracoServiceInfo'].value),
      thirdPartyPathInfo: this.productDetailsForm.controls['thirdPartyPathInfo'].value,
      linkRequirement: this.productDetailsForm.controls['linkRequirement'].value,
      quantityOfService: this.productDetailsForm.controls['quantityOfService'].value ? parseInt(this.productDetailsForm.controls['quantityOfService'].value) : 1,
      terms: parseInt(this.productDetailsForm.controls['terms'].value),
    }

    if (this.serviceType !== 'RG') {
      payload1.terms = 5;
    }

    const aEndAdress = this.createQuotePayload?.aEndAdress;

    let zEndAdress, a2EndAdress, z2EndAdress;
    if (aEndAdress && aEndAdress.selectedAddress && aEndAdress.searchMethod && aEndAdress.siteType) {
      zEndAdress = this.createQuotePayload?.zEndAdress;

      if (zEndAdress && zEndAdress.selectedAddress && zEndAdress.searchMethod && zEndAdress.siteType) {
        a2EndAdress = this.createQuotePayload?.a2EndAdress
      } else {
        this.setAll(this.createQuotePayload?.zEndAdress, "");
        this.setAll(this.createQuotePayload?.a2EndAdress, "");
        this.setAll(this.createQuotePayload?.z2EndAdress, "");
      }

      if (a2EndAdress && a2EndAdress.selectedAddress && a2EndAdress.searchMethod && a2EndAdress.siteType) {
        z2EndAdress = this.createQuotePayload?.z2EndAdress
      } else {
        this.setAll(this.createQuotePayload?.a2EndAdress, "");
        this.setAll(this.createQuotePayload?.z2EndAdress, "");
      }

    } else {
      this.setAll(this.createQuotePayload?.aEndAdress, "");
      this.setAll(this.createQuotePayload?.zEndAdress, "");
      this.setAll(this.createQuotePayload?.a2EndAdress, "");
      this.setAll(this.createQuotePayload?.z2EndAdress, "");
      if (this.createQuotePayload?.fundingSource) this.createQuotePayload.fundingSource = "";
      if (this.createQuotePayload?.l3WBSCodeCapex) this.createQuotePayload.l3WBSCodeCapex = "";
      if (this.createQuotePayload?.l3WBSCodeOpex) this.createQuotePayload.l3WBSCodeOpex = "";
      if (this.createQuotePayload?.otherPowImc) this.createQuotePayload.otherPowImc = "";
      if (this.createQuotePayload?.preApprovalCost) this.createQuotePayload.preApprovalCost = "";

      zEndAdress = this.createQuotePayload?.zEndAdress;
    }

    const payload2 = {
      aEndpreDefinedNodeName: this.createQuotePayload?.aEndpreDefinedNodeName?.length ? this.createQuotePayload?.aEndpreDefinedNodeName?.length : aEndAdress?.definedPropertyLocation,
      zEndpreDefinedNodeName: this.createQuotePayload?.zEndpreDefinedNodeName?.length ? this.createQuotePayload?.zEndpreDefinedNodeName?.length : zEndAdress?.definedPropertyLocation,
      a2EndpreDefinedNodeName: this.createQuotePayload?.a2EndpreDefinedNodeName?.length ? this.createQuotePayload?.a2EndpreDefinedNodeName?.length : a2EndAdress?.definedPropertyLocation,
      z2EndpreDefinedNodeName: this.createQuotePayload?.z2EndpreDefinedNodeName?.length ? this.createQuotePayload?.z2EndpreDefinedNodeName?.length : z2EndAdress?.definedPropertyLocation,
      aEndLeadIn: this.createQuotePayload?.aEndLeadIn,
      zEndLeadIn: this.createQuotePayload?.zEndLeadIn,
      customerNotes: this.createQuotePayload?.customerNotes,
      customerReference: this.createQuotePayload?.customerReference,
      businessDriver: this.createQuotePayload?.businessDriver,
      responseRequiredDate: this.createQuotePayload?.responseRequiredDate,
      siteADiversityDropDown: this.createQuotePayload?.siteADiversityDropDown,
      siteZDiversityDropDown: this.createQuotePayload?.siteZDiversityDropDown,
      requestorConstraints: this.createQuotePayload?.requestorConstraints,
      preferredMaxDistance: this.createQuotePayload?.preferredMaxDistance,
      customerType1: this.createQuotePayload?.customerType1,
      externalReference1: this.createQuotePayload?.externalReference1,
      maximumOpticalLossDropDown1: this.createQuotePayload?.maximumOpticalLossDropDown1,
      maximumOpticalLossDropDownValue1: this.createQuotePayload?.maximumOpticalLossDropDownValue1,
      externalReference: this.createQuotePayload?.externalReference,
      maxAllowableOpticalLoss: this.createQuotePayload?.maxAllowableOpticalLoss,
      aEndAdressSiteAlternativeName: this.createQuotePayload?.aEndAdressSiteAlternativeName,
      zEndAdressSiteAlternativeName: this.createQuotePayload?.zEndAdressSiteAlternativeName,
      a2EndAdressSiteAlternativeName: this.createQuotePayload?.a2EndAdressSiteAlternativeName,
      z2EndAdressSiteAlternativeName: this.createQuotePayload?.z2EndAdressSiteAlternativeName,

      sameAsAEndAddress: this.createQuotePayload?.sameAsAEndAddress,
      sameAsZEndAddress: this.createQuotePayload?.sameAsZEndAddress,
      sameAsAEndContact: this.createQuotePayload?.sameAsAEndContact,
      aEndAdress: {
        siteType: aEndAdress?.siteType ? aEndAdress?.siteType : '',
        searchMethod: aEndAdress?.searchMethod ? aEndAdress?.searchMethod : '',
        selectedAddress: aEndAdress?.selectedAddress ? aEndAdress?.selectedAddress : '',
        definedPropertyLocation: aEndAdress?.definedPropertyLocation ? aEndAdress?.definedPropertyLocation : '',
        additionalAddressInfo: aEndAdress?.additionalAddressInfo ? aEndAdress?.additionalAddressInfo : '',
        adborId: aEndAdress?.adborId ? aEndAdress?.adborId : '',
        latitude: aEndAdress?.latitude ? aEndAdress?.latitude : '',
        longitude: aEndAdress?.longitude ? aEndAdress?.longitude : '',
        nbnLocId: aEndAdress?.nbnLocId ? aEndAdress?.nbnLocId : '',
        nodeCode: aEndAdress?.nodeCode ? aEndAdress?.nodeCode : '',
        primaryContactName: aEndAdress?.primaryContactName ? aEndAdress?.primaryContactName : '',
        primaryContactNumber: aEndAdress?.primaryContactNumber ? aEndAdress?.primaryContactNumber : '',
        primaryContactEmail: aEndAdress?.primaryContactEmail ? aEndAdress?.primaryContactEmail : '',
        altContactName: aEndAdress?.altContactName ? aEndAdress?.altContactName : '',
        altContactNumber: aEndAdress?.altContactNumber ? aEndAdress?.altContactNumber : '',
        altContactEmail: aEndAdress?.altContactEmail ? aEndAdress?.altContactEmail : ''
      },
      zEndAdress: {
        siteType: zEndAdress?.siteType ? zEndAdress?.siteType : '',
        searchMethod: zEndAdress?.searchMethod ? zEndAdress?.searchMethod : '',
        selectedAddress: zEndAdress?.selectedAddress ? zEndAdress?.selectedAddress : '',
        definedPropertyLocation: zEndAdress?.definedPropertyLocation ? zEndAdress?.definedPropertyLocation : '',
        additionalAddressInfo: zEndAdress?.additionalAddressInfo ? zEndAdress?.additionalAddressInfo : '',
        adborId: zEndAdress?.adborId ? zEndAdress?.adborId : '',
        latitude: zEndAdress?.latitude ? zEndAdress?.latitude : '',
        longitude: zEndAdress?.longitude ? zEndAdress?.longitude : '',
        nbnLocId: zEndAdress?.nbnLocId ? zEndAdress?.nbnLocId : '',
        nodeCode: zEndAdress?.nodeCode ? zEndAdress?.nodeCode : '',
        primaryContactName: zEndAdress?.primaryContactName ? zEndAdress?.primaryContactName : '',
        primaryContactNumber: zEndAdress?.primaryContactNumber ? zEndAdress?.primaryContactNumber : '',
        primaryContactEmail: zEndAdress?.primaryContactEmail ? zEndAdress?.primaryContactEmail : '',
        altContactName: zEndAdress?.altContactName ? zEndAdress?.altContactName : '',
        altContactNumber: zEndAdress?.altContactNumber ? zEndAdress?.altContactNumber : '',
        altContactEmail: zEndAdress?.altContactEmail ? zEndAdress?.altContactEmail : ''
      },
      path2Z2SameAsAEndAddress: this.createQuotePayload?.path2Z2SameAsAEndAddress,
      path2Z2SameAsZEndAddress: this.createQuotePayload?.path2Z2SameAsZEndAddress,
      path2A2sameAsAEndContact: this.createQuotePayload?.path2A2sameAsAEndContact,
      path2A2sameAsZEndContact: this.createQuotePayload?.path2A2sameAsZEndContact,
      a2EndAdress: {
        siteType: a2EndAdress?.siteType ? a2EndAdress?.siteType : '',
        searchMethod: a2EndAdress?.searchMethod ? a2EndAdress?.searchMethod : '',
        selectedAddress: a2EndAdress?.selectedAddress ? a2EndAdress?.selectedAddress : '',
        definedPropertyLocation: a2EndAdress?.definedPropertyLocation ? a2EndAdress?.definedPropertyLocation : '',
        additionalAddressInfo: a2EndAdress?.additionalAddressInfo ? a2EndAdress?.additionalAddressInfo : '',
        adborId: a2EndAdress?.adborId ? a2EndAdress?.adborId : '',
        latitude: a2EndAdress?.latitude ? a2EndAdress?.latitude : '',
        longitude: a2EndAdress?.longitude ? a2EndAdress?.longitude : '',
        nbnLocId: a2EndAdress?.nbnLocId ? a2EndAdress?.nbnLocId : '',
        nodeCode: a2EndAdress?.nodeCode ? a2EndAdress?.nodeCode : '',
        primaryContactName: a2EndAdress?.primaryContactName ? a2EndAdress?.primaryContactName : '',
        primaryContactNumber: a2EndAdress?.primaryContactNumber ? a2EndAdress?.primaryContactNumber : '',
        primaryContactEmail: a2EndAdress?.primaryContactEmail ? a2EndAdress?.primaryContactEmail : '',
        altContactName: a2EndAdress?.altContactName ? a2EndAdress?.altContactName : '',
        altContactNumber: a2EndAdress?.altContactNumber ? a2EndAdress?.altContactNumber : '',
        altContactEmail: a2EndAdress?.altContactEmail ? a2EndAdress?.altContactEmail : ''
      },
      path2Z2sameAsAEndContact: this.createQuotePayload?.path2Z2sameAsAEndContact,
      path2Z2sameAsZEndContact: this.createQuotePayload?.path2Z2sameAsZEndContact,
      path2Z2sameAsA2EndContact: this.createQuotePayload?.path2Z2sameAsA2EndContact,
      z2EndAdress: {
        siteType: z2EndAdress?.siteType ? z2EndAdress?.siteType : '',
        searchMethod: z2EndAdress?.searchMethod ? z2EndAdress?.searchMethod : '',
        selectedAddress: z2EndAdress?.selectedAddress ? z2EndAdress?.selectedAddress : '',
        definedPropertyLocation: z2EndAdress?.definedPropertyLocation ? z2EndAdress?.definedPropertyLocation : '',
        additionalAddressInfo: z2EndAdress?.additionalAddressInfo ? z2EndAdress?.additionalAddressInfo : '',
        adborId: z2EndAdress?.adborId ? z2EndAdress?.adborId : '',
        latitude: z2EndAdress?.latitude ? z2EndAdress?.latitude : '',
        longitude: z2EndAdress?.longitude ? z2EndAdress?.longitude : '',
        nbnLocId: z2EndAdress?.nbnLocId ? z2EndAdress?.nbnLocId : '',
        nodeCode: z2EndAdress?.nodeCode ? z2EndAdress?.nodeCode : '',
        primaryContactName: z2EndAdress?.primaryContactName ? z2EndAdress?.primaryContactName : '',
        primaryContactNumber: z2EndAdress?.primaryContactNumber ? z2EndAdress?.primaryContactNumber : '',
        primaryContactEmail: z2EndAdress?.primaryContactEmail ? z2EndAdress?.primaryContactEmail : '',
        altContactName: z2EndAdress?.altContactName ? z2EndAdress?.altContactName : '',
        altContactNumber: z2EndAdress?.altContactNumber ? z2EndAdress?.altContactNumber : '',
        altContactEmail: z2EndAdress?.altContactEmail ? z2EndAdress?.altContactEmail : ''
      },
      fundingSource: this.createQuotePayload?.fundingSource ? this.createQuotePayload?.fundingSource : '',
      l3WBSCodeCapex: this.createQuotePayload?.l3WBSCodeCapex ? this.createQuotePayload?.l3WBSCodeCapex : '',
      l3WBSCodeOpex: this.createQuotePayload?.l3WBSCodeOpex ? this.createQuotePayload?.l3WBSCodeOpex : '',
      otherPowImc: this.createQuotePayload?.otherPowImc ? this.createQuotePayload?.otherPowImc : '',
      preApprovalCost: this.createQuotePayload?.preApprovalCost ? this.createQuotePayload?.preApprovalCost : '',
      predefinedQuote: this.createQuotePayload?.predefinedQuote ? this.createQuotePayload?.predefinedQuote : 'No',
    };
    let payload = { ...payload1, ...payload2 };

    // NON-RG payload
    if (payload && this.serviceType !== "RG") {
      this.createQuoteService.nonRGPayloadPrepare(payload, this.serviceType);
    }

    return payload;
  }

  saveFeasibilityRequest(payload, isSaveProduct?: boolean, navigationAllow?) {
    
    // NON-RG payload
    if (payload && this.serviceType !== "RG") {
      this.createQuoteService.nonRGPayloadPrepare(payload, this.serviceType);
    }

    return new Promise((resolve, reject) => {
      this.createQuoteService.saveNewQuote(payload, 'Save').then((res) => {
        this.quoteRefId = res.data.quoteReferenceId;
        if (isSaveProduct) {
          this.isQuoteSaved = true;
        }
        if (navigationAllow) {
          this.router.navigateByUrl('/site-details');
        }
        resolve(true);
        ;
      }).catch((err) => {
        const msg = 'We can’t submit your request at this time. Please refresh and try again later.';
        this.showToasterMessage(msg, '', this.options, 'error');
        reject(err);
      });

    });
  }

  showToasterMessage(message: string, header: string, options: GlobalConfig, type: string) {
    this.toastr.overlayContainer = this.toastContainer;
    this.options.positionClass = 'toast-bottom-right';
    this.options.disableTimeOut = false;
    this.options.autoDismiss = true;
    this.options.timeOut = 5000;
    this.options.closeButton = true;
    this.options.preventDuplicates = true;
    this.options.tapToDismiss = false;
    this.toastr.show(message, header === '' ? null : header, options, this.options.iconClasses[type]);
    document.getElementsByClassName('overlay-container')[0]?.setAttribute('role', 'alert');
    document.getElementsByClassName('overlay-container')[0]?.setAttribute('aria-live', 'polite');
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  quantityOfServiceMaxLenght() {
    this.linkRequirementFormValue = this.productDetailsForm.controls['linkRequirement'].value;
    const quantityOfServiceInput = this.productDetailsForm.get('quantityOfService');
    if (this.linkRequirementFormValue === 'Dual fibre (fibre pair)') {
      quantityOfServiceInput.setValidators([Validators.required, Validators.max(4999)]);
      return '4999 maximum limit'
    } else {
      quantityOfServiceInput.setValidators(Validators.required);
      return '9999 maximum limit'
    }
  }

  modifyQuote(isQuoteSaved, allowNavigation?) {
    const payload = {
      action: 'Save',
      avoidPlrLogging: false,
      cidn: this.cidn,
      quoteReferenceId: this.quoteRefId,
      modifiedDetails: Object.keys(this.modifiedFormFields),
      ...this.modifiedFormFields
    }
    return new Promise((resolve, reject) => {
      this.createQuoteService.modifyFeasibilityRequest(payload).then((res) => {
        if (isQuoteSaved) {
          this.isQuoteSaved = true;
        }
        const navigationState = window.history.state;
        const navigationExtras = {
          state: {
            isModifyFlow: navigationState.isModifyFlow
          }
        }
        if (allowNavigation) {
          this.router.navigateByUrl('/site-details', navigationExtras);
        }
        resolve(true);
      }).catch((err) => {
        const msg = 'We can’t submit your request at this time. Please refresh and try again later.';
        this.showToasterMessage(msg, '', this.options, 'error');
        reject(err);
      });
    });
  }

  getModifiedFormValues() {
    this.productDetailsForm.valueChanges.subscribe((updatedval) => {
      updatedval['doYouRequireDiversity'] = this.quoteDataToModify['doYouRequireDiversity'];
      updatedval['describeYourDiversity'] = this.quoteDataToModify['describeYourDiversity'];
      updatedval['existingInfracoServiceInfo'] = this.quoteDataToModify['existingInfracoServiceInfo'];
      updatedval['thirdPartyPathInfo'] = this.quoteDataToModify['thirdPartyPathInfo'];
      for (const key of Object.keys(updatedval)) {
        if (updatedval[key] !== this.quoteDataToModify[key]) {
          this.modifiedFormFields[key] = updatedval[key]
        } else {
          delete this.modifiedFormFields[key];
        }
      }
    });
  }

  termsRadioFnc(value) {
    this.productDetailsForm.controls['terms'].setValue(value);
  }

  onSubProductChange(event) {
    if (this.isModifyQuote) {
      // INFPO-349 - as per this story we are making subProduct disable so this if condition will no longer
      // triggerd from now, only the else part will play the role. Still we're keeping this logic.
      let tempDescribeYourDiversity = this.quoteDataFromNavigation['describeYourDiversity'];
      let tempThirdPartyPathInfo = this.quoteDataFromNavigation['thirdPartyPathInfo'];
      let tempExistingInfracoServiceInfo = this.quoteDataFromNavigation['existingInfracoServiceInfo'];
      let tempDoYouRequireDiversity = this.quoteDataFromNavigation['doYouRequireDiversity'];
      if (event.value && event.value == 'NBN EE') {
        this.isSubProductNbnEe = true;
        this.productDetailsForm.controls['doYouRequireDiversity'].setValue('No');
        this.productDetailsForm.controls['describeYourDiversity'].setValue('');
        this.productDetailsForm.controls['thirdPartyPathInfo'].setValue('');
        this.productDetailsForm.controls['existingInfracoServiceInfo'].setValue('');
        this.productDetailsForm.controls['linkRequirement'].setValue('Single fibre');
        this.clearFormValidator('describeYourDiversity');
        this.clearFormValidator('thirdPartyPathInfo');
        this.clearFormValidator('existingInfracoServiceInfo');
      }
      else {
        this.isSubProductNbnEe = false;
        this.productDetailsForm.controls['doYouRequireDiversity'].setValue(tempDoYouRequireDiversity);
        this.productDetailsForm.controls['describeYourDiversity'].setValue(tempDescribeYourDiversity);
        this.productDetailsForm.controls['thirdPartyPathInfo'].setValue(tempThirdPartyPathInfo);
        this.productDetailsForm.controls['existingInfracoServiceInfo'].setValue(tempExistingInfracoServiceInfo);
      }
    }
    else if (!this.isModifyQuote) {
      if (event.value && event.value == 'NBN EE') {
        this.isSubProductNbnEe = true;
        this.productDetailsForm.controls['doYouRequireDiversity'].setValue('No');
        this.productDetailsForm.controls['linkRequirement'].setValue('Single fibre');
      }
      else {
        this.isSubProductNbnEe = false;
      }
    }
  }

  subProductRadioFnc(value){
    this.onSubProductChange({value});
    this.productDetailsForm['controls']['subProduct'].setValue(value);
  }

  isSubProductSelected(){
    if(this.serviceType === 'BL'){
      if(this.productDetailsForm.controls['subProduct'].value === ""){
        return false;
      }
      else if(this.productDetailsForm.controls['subProduct'].value != ""){
        return true;
      }
    }
    else{
      return true;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.prodHeaderChange) {
      this.prodHeaderChange.unsubscribe();
    }
    if (this.modifyQuoteSubscription) {
      this.modifyQuoteSubscription.unsubscribe();
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyUp(event) {
    // const element = document.getElementById('select-product-radio-btn');
    //     element.removeAttribute('tabindex');
    document.getElementById('select-product-radio-btn')?.setAttribute('tabindex', '0');
    document.getElementById('nbnEE')?.setAttribute('tabindex', '0');
    document.getElementById('nbnFixedWireless')?.setAttribute('tabindex', '0');
    document.getElementById('diversityYesRadio')?.setAttribute('tabindex', '0');
    document.getElementById('diversityNoRadio')?.setAttribute('tabindex', '0');
    document.getElementById('link-req-single')?.setAttribute('tabindex', '0');
    document.getElementById('link-req-dual')?.setAttribute('tabindex', '0');
    document.getElementById('termsRadio1')?.setAttribute('tabindex', '0');
    document.getElementById('termsRadio2')?.setAttribute('tabindex', '0');
    document.getElementById('termsRadio3')?.setAttribute('tabindex', '0');
    document.getElementById('termsRadio4')?.setAttribute('tabindex', '0');
    if (event.key === 'Tab') {
      // element.focus();
      // element.setAttribute('tabindex', '0');
    }
  }
}
